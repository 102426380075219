import { useState } from 'react';

import { ClassName } from 'types';
import { cx } from 'utils';

interface ButtonGroupProps extends ClassName {
  buttons: Record<string, () => void>;
  defaultButton?: string;
}

const ButtonGroup = ({ buttons, defaultButton, className }: ButtonGroupProps) => {
  const [selected, setSelected] = useState(defaultButton ?? Object.keys(buttons)[0]);

  const onClick = (title: string, callback: () => void) => {
    setSelected(title);
    callback();
  };

  return (
    <div
      className={cx('flex w-full border border-gray-400 rounded-4 bg-gray-400 overflow-hidden space-x-px', className)}
    >
      {Object.entries(buttons).map(([title, callback]) => (
        <button
          key={title}
          onClick={() => onClick(title, callback)}
          className={cx(
            'flex-1 px-1 py-0.5 bg-gray-100 hover:bg-gray-300',
            title === selected && 'text-white !bg-opacity-0',
          )}
        >
          {title}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
