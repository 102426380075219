import { useContext } from 'react';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import ToolbarSelect from 'components/Toolbar/ToolbarSelect';
import { Period } from 'generated/graphql';
import { SelectOption } from 'types';
import { defaultDashboardTimePeriod } from 'common/constants';

const DateFilter = ({
  selectedPeriod,
  onSelect,
}: {
  selectedPeriod?: Period;
  onSelect?: (value: SelectOption<Period>) => void;
}) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { periodDescriptionMap },
    },
  } = i18nContext;

  const options: SelectOption<Period>[] = [
    { value: Period.P24h, label: periodDescriptionMap[Period.P24h] },
    { value: Period.P7d, label: periodDescriptionMap[Period.P7d] },
    { value: Period.P30d, label: periodDescriptionMap[Period.P30d] },
  ];

  if (!onSelect) return null;

  return (
    <ToolbarSelect
      items={options}
      onSelect={onSelect}
      selectedItem={
        selectedPeriod
          ? { value: selectedPeriod, label: periodDescriptionMap[selectedPeriod] }
          : { value: defaultDashboardTimePeriod, label: periodDescriptionMap[defaultDashboardTimePeriod] }
      }
      textRight
      icon={<IonIcon name="calendarOutline" />}
    />
  );
};

export default DateFilter;
