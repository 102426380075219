import { toast } from 'react-toastify';

import IonIcon, { IonIconName } from 'atoms/IonIcon';
import { cx } from 'utils';

const Toast = ({ msg, icon, iconClassName }: { msg: string; icon: IonIconName; iconClassName?: string }) => (
  <div className="flex items-center gap-1">
    <IonIcon name={icon} className={cx('text-3xl text-success', iconClassName)} />

    <span className="text-md">{msg}</span>
  </div>
);

export const successToast = (msg: string | undefined) =>
  msg &&
  toast.success(<Toast msg={msg} icon="checkmarkCircleOutline" iconClassName="text-success" />, {
    icon: '',
    progressClassName: 'bg-success',
  });

export const warningToast = (msg: string | undefined) =>
  msg &&
  toast.warning(<Toast msg={msg} icon="warningOutline" iconClassName="text-amber" />, {
    icon: '',
    progressClassName: 'bg-amber',
  });

export const errorToast = (msg: string | undefined) =>
  msg &&
  toast.warning(<Toast msg={msg} icon="closeCircleOutline" iconClassName="text-error" />, {
    icon: '',
    progressClassName: 'bg-error',
    autoClose: 10000,
  });
