import { useContext } from 'react';

import Popover from 'atoms/Popover';
import { I18nContext } from 'common/useT';
import { tableColumnWidthMap } from 'components/BulkUpload/BulkUploadTable';
import { CellDataWithErrors } from 'components/BulkUpload/BulkUploadValidatedData';
import { capitalize, cx, isDefined } from 'utils';

interface BulkUploadTableCellProps {
  data: CellDataWithErrors;
  recordNumber: number;
  missingFields: string[];
}
const BulkUploadTableCell = ({ data, missingFields }: BulkUploadTableCellProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const { field, serverSideErrorMessages, yupErrorMessages, value, isValid, fieldKey } = data;

  const missingFieldMessage = missingFields.some((x) => x === fieldKey)
    ? tSafe('components.BulkUpload.BulkUploadTable.BulkUploadTableRow.BulkUploadTableCell.field-is-required', {
        field,
        defaultValue: '{{field}} is a required field!',
      })
    : undefined;

  const allErrors = [...yupErrorMessages, ...serverSideErrorMessages, missingFieldMessage].filter(isDefined);
  const hasError = allErrors.length || !isValid;

  return (
    <>
      <td className={cx('border-r-px border-b-px border-gray-100 h-[inherit]', tableColumnWidthMap[field] ?? 'w-30')}>
        <div className={cx('rounded-8 w-full h-full whitespace-nowrap p-1', hasError && 'bg-error text-white')}>
          <Popover
            content={
              allErrors.length
                ? allErrors.map(capitalize)
                : tSafe('components.BulkUpload.BulkUploadTable.BulkUploadTableRow.BulkUploadTableCell.value-is-valid', {
                    value,
                    defaultValue: '{{value}} is a valid value.',
                  })
            }
          >
            {hasError && !value
              ? tSafe('components.BulkUpload.BulkUploadTable.BulkUploadTableRow.BulkUploadTableCell.click-more-info', {
                  defaultValue: 'Click for more info',
                })
              : value}
          </Popover>
        </div>
      </td>
    </>
  );
};

export default BulkUploadTableCell;
