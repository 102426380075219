import { atom } from 'recoil';

import { HistogramBarSegment } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/TripSpeedHistogram';

/** @package */
export const streetViewOnHoverAtom = atom({
  key: 'streetViewOnHover',
  default: false,
});

/** @package */
export const activityHistoryHoveredSegment = atom<HistogramBarSegment | undefined>({
  key: 'activityHistoryHoveredSegment',
  default: undefined,
});

/** @package */
export const activityHistoryClickedSegment = atom<HistogramBarSegment | undefined>({
  key: 'activityHistoryClickedSegment',
  default: undefined,
});

export const activityHistoryTripIds = atom<string[]>({
  key: 'activityHistoryTripIds',
  default: [],
});

export const activityHistorySelectedTripId = atom<string | undefined>({
  key: 'activityHistorySelectedTripId',
  default: undefined,
});
