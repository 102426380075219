import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import { thirtyTwoBitSignedIntLimit } from 'common/constants';
import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { VehicleEditFormData } from 'components/Vehicle/Detail/Edit/VehicleEditForm';
import { useVehicleDetails } from 'components/Vehicle/Detail/hooks';
import { FuelType, UpdateVehicleDoc } from 'generated/graphql';
import { kmToMi, miToKm } from 'utils';
import { errorToast, successToast } from 'utils/toasts';

import ButtonWithConfirmModal from './ButtonWithConfirmModal';
import IonIcon from './IonIcon';
import { useModalContext } from './Modal';
import Tooltip from './Tooltip';

interface OdometerProps {
  value: number;
}

export const Odometer = ({ value }: OdometerProps) => {
  const { tSafe, commonTranslations } = useContext(I18nContext) ?? {};
  const { data: [vehicleDetails] = [] } = useVehicleDetails();
  const { distanceInMiles } = useSettings();
  const { register, reset, handleSubmit } = useForm<VehicleEditFormData>({
    defaultValues: { fuelType: vehicleDetails.vehicle?.fuelType ?? FuelType.Diesel },
    mode: 'onSubmit',
  });

  const modalContext = useModalContext();
  const [odoValue, setOdoValue] = useState(0);
  const odometerCharLimit = 10;

  const [updateVehicle, { loading }] = useMutation(UpdateVehicleDoc, {
    update: (cache, { data: response }) => {
      if (!response) return;
      const { status } = vehicleDetails;

      cache.modify({
        id: cache.identify(status),
        fields: {
          odometer() {
            return odoValue;
          },
        },
      });
    },
    onCompleted: () => {
      if (tSafe) {
        successToast(
          tSafe('components.Vehicle.Detail.OdometerSeed.success-toast', {
            defaultValue: 'The seed odometer was set successfully',
          }),
        );
      }
      reset();
      modalContext?.closeModal();
    },
  });

  const handleInput = (value: number) => {
    setOdoValue(Math.round(distanceInMiles ? miToKm(value ?? 0) : value ?? 0));
  };

  const onSubmit = ({ externalId: _, ...vehicle }: VehicleEditFormData) => {
    if (!vehicle) return;

    if (odoValue > thirtyTwoBitSignedIntLimit && tSafe) {
      errorToast(
        tSafe('components.Vehicle.Detail.OdometerSeed.too-high', {
          defaultValue: 'Seed odometer value is too high',
        }),
      );
      reset();
      return;
    }

    const { id, licencePlate, make, model, year } = vehicleDetails.vehicle;

    updateVehicle({
      variables: {
        vehicleId: id,
        vehicle: {
          ...vehicle,
          make,
          model,
          licencePlate,
          year,
          seedOdo: odoValue * 1000,
        },
      },
    });
    reset();
  };

  const OdometerSeed = () => {
    const convertedValue = distanceInMiles
      ? kmToMi(vehicleDetails.status.odometer ?? 0)
      : vehicleDetails.status.odometer;
    return (
      tSafe && (
        <>
          <div className="flex">
            <input
              {...register('seedOdo')}
              type="number"
              placeholder={convertedValue?.toString()}
              onKeyDown={(e) =>
                (['e', 'E', '+', '-'].includes(e.key) ||
                  (odoValue.toString().length >= odometerCharLimit && !['Backspace', 'Delete'].includes(e.key))) &&
                e.preventDefault()
              }
              onChange={(e) => handleInput(parseInt(e.currentTarget.value))}
              className="w-[400px] outline outline-gray-300 rounded-4 tracking-[18px] p-2 placeholder-gray-300"
            />

            <div className="p-2">{commonTranslations?.uom.user_distance_text}</div>
          </div>
        </>
      )
    );
  };

  return (
    <div className="flex">
      <div className="flex border-px border-black rounded-4 p-[0.5rem]">
        {value
          .toString()
          .split('')
          .map((char, idx) => (
            <span
              key={`odo_${idx}`}
              className="text-black bg-white bg-gradient-to-t border-px border-gray-400 rounded-2 from-gray-300 p-[0.3rem]"
            >
              {char}
            </span>
          ))}

        <span className="flex items-center ml-0.5">{commonTranslations?.uom.user_distance_text}</span>
      </div>

      {vehicleDetails.status.odoSource === 0 && (
        <ButtonWithConfirmModal
          confirmContentClassName="w-50"
          confirmTitle={
            tSafe && tSafe('components.Vehicle.Detail.set-odometer-seed', { defaultValue: 'Set seed odometer' })
          }
          onConfirm={() => handleSubmit(onSubmit)()}
          onReject={() => setOdoValue(0)}
          confirmContent={OdometerSeed()}
          className="ml-0.5 px-0.5 ui-button"
        >
          <Tooltip
            text={tSafe && tSafe('components.Vehicle.Detail.set-odometer-seed', { defaultValue: 'Set odometer seed' })}
          >
            {loading ? <IonIcon name="syncOutline" className="animate-spin" /> : <IonIcon name="settingsOutline" />}
          </Tooltip>
        </ButtonWithConfirmModal>
      )}
    </div>
  );
};
