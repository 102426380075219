import { useMemo } from 'react';

const useMinMaxValues = <T extends object, K extends string>(
  data: T[],
  columns: [id: K, accessor: (item: T) => number | null | undefined][],
) => {
  return useMemo(() => {
    return data.reduce((acc, item) => {
      columns.forEach(([id, accessor]) => {
        const value = accessor(item);

        if (typeof value === 'number') {
          acc[id] = { min: Math.min(acc[id]?.min ?? 0, value), max: Math.max(acc[id]?.max ?? 0, value) };
        }
      });

      return acc;
    }, {} as Record<K, { min: number; max: number }>);
  }, [columns, data]);
};

export default useMinMaxValues;
