import * as yup from 'yup';

import { CenteredSpinner } from 'atoms/Spinner';
import { StaticCsvImportFields } from 'components/BulkUpload';
import BulkUploadTable from 'components/BulkUpload/BulkUploadTable';
import BulkUploadValidationHeader from 'components/BulkUpload/BulkUploadValidatedData/BulkUploadValidationHeader';
import {
  allDriverFields,
  getIsIncompleteDriver,
  getIsIncompleteVehicle,
  getMissingDriverFields,
  getMissingVehicleFields,
  getIsEmptyRow,
  staticFieldToKeyMap,
  getIsEmptyDriver,
  getIsEmptyVehicle,
  requiredVehicleFields,
} from 'components/BulkUpload/utils';
import { BulkUploadPreValidateDoc, FleetMetaFieldDataType, PreValidationErrorType } from 'generated/graphql';
import { Result } from 'types';
import { BulkUploadRowSchema } from 'types/bulkUpload';

export type MemoizedMetaField = {
  label: string;
  key: string;
  dataType: FleetMetaFieldDataType;
  required: boolean;
  validValues: string[] | null | undefined;
};

export type TableData = RowData[];

export type RowData = {
  recordNumber: number;
  cells: CellDataWithErrors[];
  hasServerValidationErrors: boolean;
  rowIsValid: boolean;
  missingDriverFields: string[];
  missingVehicleFields: string[];
};

export type CellDataWithErrors = {
  value: string | null | undefined;
  field: string;
  fieldKey: string;
  error?: string;
  isValid: boolean;
  yupErrorMessages: string[];
  serverSideErrorMessages: string[];
};

interface BulkUploadValidatedDataProps {
  supportedFields: string[];
  validRows: BulkUploadRowSchema[];
  invalidRows: BulkUploadRowSchema[];
  validationErrors?: Result<typeof BulkUploadPreValidateDoc>;
  preValidationLoading: boolean;
  runtimeSchema: yup.AnySchema;
  memoizedVehicleMetaFields: MemoizedMetaField[];
  memoizedDriverMetaFields: MemoizedMetaField[];
}

const BulkUploadValidatedData = ({
  supportedFields,
  validRows,
  invalidRows,
  validationErrors,
  preValidationLoading,
  runtimeSchema,
  memoizedDriverMetaFields,
  memoizedVehicleMetaFields,
}: BulkUploadValidatedDataProps) => {
  const isValid = !invalidRows.length;

  const memoizedMetaFields = [...memoizedDriverMetaFields, ...memoizedVehicleMetaFields];

  if (preValidationLoading) return <CenteredSpinner />;

  const metaFieldToValidationFieldMap = memoizedMetaFields.reduce((acc, curr) => {
    acc[curr.label] ??= curr.key;

    return acc;
  }, {} as Record<string, string>);

  const rows = isValid ? validRows : invalidRows;

  let invalidRowCount = 0;
  let invalidCellCount = 0;

  const tableData: TableData = rows.map((row, idx) => {
    const recordNumber = idx + 1;
    const driverMetaFieldLabels = memoizedDriverMetaFields.map((x) => x.label);
    const vehicleMetaFieldLabels = memoizedVehicleMetaFields.map((x) => x.label);

    const isEmptyRow = getIsEmptyRow(row, [...driverMetaFieldLabels, ...vehicleMetaFieldLabels]);
    const isIncompleteDriver = getIsIncompleteDriver(row, driverMetaFieldLabels);
    const isIncompleteVehicle = getIsIncompleteVehicle(row, vehicleMetaFieldLabels);
    const isEmptyDriverAndVehicle =
      getIsEmptyDriver(row, driverMetaFieldLabels) && getIsEmptyVehicle(row, vehicleMetaFieldLabels);
    const missingIncompleteDriverFields = isIncompleteDriver
      ? getMissingDriverFields(row).map((field) => staticFieldToKeyMap[field as keyof StaticCsvImportFields])
      : [];
    const missingIncompleteVehicleFields = isIncompleteVehicle
      ? getMissingVehicleFields(row).map((field) => staticFieldToKeyMap[field as keyof StaticCsvImportFields])
      : [];
    const missingDriverFields =
      isEmptyRow || isEmptyDriverAndVehicle
        ? allDriverFields.map((field) => staticFieldToKeyMap[field as keyof StaticCsvImportFields])
        : missingIncompleteDriverFields;
    const missingVehicleFields =
      isEmptyRow || isEmptyDriverAndVehicle
        ? Array.from(requiredVehicleFields).map((field) => staticFieldToKeyMap[field as keyof StaticCsvImportFields])
        : missingIncompleteVehicleFields;

    const cells = supportedFields.map((field) => {
      const cellValue = row[field as keyof StaticCsvImportFields];

      let yupErrorMessages: string[] = [];
      if (runtimeSchema) {
        try {
          yup.reach(runtimeSchema, field).validateSync(cellValue);
        } catch (e) {
          if (e instanceof yup.ValidationError) {
            yupErrorMessages = e.errors;
          }
        }
      }

      let key = staticFieldToKeyMap[field as keyof StaticCsvImportFields] as string | undefined;

      if (!key) {
        key = metaFieldToValidationFieldMap[field];
      }

      const serverSideValidationErrorsForThisValue = cellValue
        ? validationErrors?.filter((x) => {
            if (x.type === PreValidationErrorType.DisassociationWarning) {
              return false;
            }
            if (
              (x.erroneousValues && x.erroneousValues?.length > 1 && x.erroneousValues?.includes(cellValue)) ||
              (x.field === key && x.erroneousValues?.includes(cellValue))
            ) {
              return true;
            }

            return false;
          })
        : [];

      const isDisassociationWarning = !!serverSideValidationErrorsForThisValue?.filter(
        (x) => x.type === PreValidationErrorType.DisassociationWarning,
      ).length;

      const serverSideErrorMessages = serverSideValidationErrorsForThisValue
        ? serverSideValidationErrorsForThisValue
            .map((x) => x.message)
            .reduce((acc, msg) => {
              if (!acc.includes(msg)) {
                acc.push(msg);
              }
              return acc;
            }, [] as string[])
        : [];

      const hasServerSideErrors = !!(serverSideErrorMessages.length && !isDisassociationWarning);

      const cellIsValid =
        !yupErrorMessages.length &&
        !hasServerSideErrors &&
        !missingDriverFields.includes(key as keyof object) &&
        !missingVehicleFields.includes(key as keyof object);

      if (!cellIsValid) {
        invalidCellCount += 1;
      }

      const cellData: CellDataWithErrors = {
        value: cellValue,
        field,
        isValid: cellIsValid,
        yupErrorMessages,
        fieldKey: key,
        serverSideErrorMessages,
      };

      return cellData;
    });
    const rowIsValid = cells.every((x) => x.isValid) && !isEmptyRow && !isEmptyDriverAndVehicle;

    if (!rowIsValid) {
      invalidRowCount += 1;
    }

    const rowData: RowData = {
      recordNumber,
      cells,
      hasServerValidationErrors: !!cells.filter((cell) => cell.serverSideErrorMessages.length).length,
      rowIsValid,
      missingDriverFields,
      missingVehicleFields,
    };

    return rowData;
  });

  const displayedTableData =
    invalidRows?.length ||
    (validRows?.length &&
      validationErrors?.filter((x) => x.type !== PreValidationErrorType.DisassociationWarning).length)
      ? tableData.filter((row) => !row.rowIsValid)
      : tableData;

  return (
    <div className="flex flex-col my-1 items-center w-full mt-1">
      <BulkUploadValidationHeader
        isValid={
          isValid && !validationErrors?.filter((x) => x.type !== PreValidationErrorType.DisassociationWarning).length
        }
        invalidCellCount={invalidCellCount}
        invalidRowCount={invalidRowCount}
        validationErrors={validationErrors}
      />

      <div className="flex flex-col w-full">
        <div className="my-1 overflow-auto flex-1 border rounded-8">
          <BulkUploadTable data={displayedTableData} supportedFields={supportedFields} />
        </div>
      </div>
    </div>
  );
};

export default BulkUploadValidatedData;
