import * as RToolbar from '@radix-ui/react-toolbar';
import { Route, Switch } from 'react-router-dom';

import IonIcon from 'atoms/IonIcon';
import Modal, { useModal } from 'atoms/Modal';
import Separator from 'atoms/Separator';
import DriverFleetSelector from 'components/Driver/Detail/Edit/DriverFleetSelector';
import FleetHierarchy, { FleetHierarchyMode } from 'components/Fleet/FleetList';
import FleetPageToolbar from 'components/Fleet/FleetList/Toolbar';
import FleetSelector from 'components/FleetSelector';
import { useCurrentFleetId, useSetCurrentFleetId } from 'components/FleetSelector/hooks';
import ListViewToolbar from 'components/List/ListView/ListViewToolbar';
import AddGeofenceToolbarItem from 'components/Live/Geofence/AddGeofenceToolbarItem';
import ReportingToolbar from 'components/Reporting/ReportingToolbar';
import AddWidgetMenu from 'components/Toolbar/AddWidgetMenu';
import FleetGeofencesMenu from 'components/Toolbar/FleetGeofencesMenu';
import UserListToolbar from 'components/User/UserList/UserListToolbar';
import useRole from 'components/User/useRole';
import VehicleFleetSelector from 'components/Vehicle/Detail/Edit/VehicleFleetSelector';

const Toolbar = () => {
  const { isManager } = useRole();
  const currentFleetId = useCurrentFleetId();
  const setCurrentFleetId = useSetCurrentFleetId();
  const { closeModal, props: modalProps } = useModal({ title: 'Select Fleet' }); //TODO: translate

  return (
    <>
      <Separator className="!bg-white h-[2px]" />

      <RToolbar.Root className="flex justify-between px-1 bg-gray-100">
        <Switch>
          <Route exact path="/:fleetId/drivers/:driverId">
            <DriverFleetSelector />
          </Route>

          <Route exact path="/:fleetId/vehicles/:vehicleId">
            <VehicleFleetSelector />
          </Route>

          <Route>
            <span className="flex">
              <Modal
                {...modalProps}
                trigger={
                  <div className="p-0.5 ui-button-dark">
                    <IonIcon name="carSportOutline" />
                  </div>
                }
                contentClassName="w-[95vw] h-[95vh] !bg-gray-100"
              >
                <FleetHierarchy
                  mode={FleetHierarchyMode.SELECTION}
                  onFleetSelect={(fleetId) => {
                    setCurrentFleetId(fleetId);
                    closeModal();
                  }}
                />
              </Modal>

              <FleetSelector
                selectedFleetId={currentFleetId}
                onSelect={({ value }) => setCurrentFleetId(value)}
                readonly
              />
            </span>
          </Route>
        </Switch>

        <div className="flex children:px-1 children:py-0.5 divide-gray-400 divide-x items-center">
          <Switch>
            <Route exact path="/:fleetId">
              <AddWidgetMenu />
            </Route>

            <Route exact path="/:fleetId/live">
              <FleetGeofencesMenu />

              {isManager && <AddGeofenceToolbarItem />}
            </Route>

            <Route exact path="/:fleetId/list-view">
              <ListViewToolbar />
            </Route>

            <Route exact path="/:fleetId/users">
              <UserListToolbar />
            </Route>

            <Route exact path="/:fleetId/fleets">
              <FleetPageToolbar />
            </Route>

            <Route exact path="/:fleetId/reporting">
              <ReportingToolbar />
            </Route>
          </Switch>
        </div>
      </RToolbar.Root>

      <Separator className="z-10 shadow-sm !bg-white h-[2px]" />
    </>
  );
};

export default Toolbar;
