import { ApolloProvider } from '@apollo/client';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';

import Version from 'atoms/Version';
import history from 'common/history';
import Navbar from 'components/Navbar';
import Toolbar from 'components/Toolbar';
import RequireUser from 'components/User/RequireUser';
import Routes from 'Routes';
import { getApolloClient } from 'utils/apolloClient';

import './i18n';

import 'react-toastify/dist/ReactToastify.min.css';

export const App = () => {
  const { getAccessTokenSilently } = useAuth0();

  return (
    <RecoilRoot>
      <ApolloProvider client={getApolloClient(getAccessTokenSilently)}>
        <HelmetProvider>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className="text-md"
          />

          <Router history={history}>
            <div className="flex flex-col h-screen text-dark-gray font-open-sans text-sm bg-gray-100">
              <RequireUser>
                <Navbar />

                <Toolbar />

                <div className="flex-1 bg-gray-100 border-solid border-white border-t-px">
                  <Routes />
                </div>

                <div>
                  <Version />
                </div>
              </RequireUser>
            </div>
          </Router>
        </HelmetProvider>
      </ApolloProvider>
    </RecoilRoot>
  );
};

export default withAuthenticationRequired(App);
