import { CellContext } from '@tanstack/react-table';

import CountWithDetails from 'atoms/react-table/CountWithDetails';
import { AggregateListRow } from 'types/settings';
import { getProgressColor } from 'utils';

const EcoScoreCell = ({ cell }: { cell: CellContext<AggregateListRow, string> }) => {
  const value = cell.getValue<number>();
  if (value === undefined || value === null) {
    return null;
  }

  return (
    <div className="flex-center">
      <CountWithDetails label="Eco Scores" count={Math.round(value)} bgColor={getProgressColor(value)}>
        <div className="flex gap-1 items-center">
          <span
            className="w-3 h-3 text-white text-md rounded-4 flex-center"
            style={{ backgroundColor: getProgressColor(value) }}
          >
            {Math.round(value)}
          </span>
        </div>
      </CountWithDetails>
    </div>
  );
};

export default EcoScoreCell;
