import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { I18nContext } from 'common/useT';
import Widget, { WidgetPageType } from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { InitialCoordsState } from 'components/Live';
import { selectedGeofenceState, selectedVehicleState } from 'components/Live/state';
import { GeofenceCard } from 'components/Live/VehicleSidebar/GeofenceList/GeofenceCard';
import { Geofence, GetFleetGeofencesDoc, TripActivityHistoryEvent } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

import { useCurrentVehicleId, useVehicleDetails } from '../hooks';

export const title = 'Geofences';

const Geofences = () => {
  const i18nContext = useContext(I18nContext);
  const vehicleId = useCurrentVehicleId();
  const setSelectedGeofence = useSetRecoilState(selectedGeofenceState);
  const setLiveMapSelectedVehicle = useSetRecoilState(selectedVehicleState);
  const history = useHistory<InitialCoordsState>();
  const currentFleetId = useCurrentFleetId();
  const { loading, error, data: [vehicleDetails] = [] } = useVehicleDetails();

  const { data: fleetGeofences } = useQ(GetFleetGeofencesDoc, {
    skip: !vehicleDetails.associations.fleetIds.length,
    variables: {
      fleetId: vehicleDetails.associations.fleetIds[0],
      withSubfleets: false,
      withParentFleets: true,
    },
  });

  if (loading || !i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { unavailable_text },
      domain: {
        vehicle: { vehicle_text },
        vehicleDetails: {
          fields: { geofences_text },
        },
        fleet: { fleet_text },
      },
      errors: { entity_not_found_tfn, error_text },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  if (!vehicleDetails) return <div className="text-xl">{entity_not_found_tfn(vehicle_text)}</div>;

  const {
    geofences,
    status: { lat, lng, time },
    trips: { data: lastTrips },
    associations: { driver, fleetIds },
  } = vehicleDetails;

  const [lastTrip] = lastTrips as TripActivityHistoryEvent[];

  const redirectToLiveMap = (geofence: Geofence) => {
    setSelectedGeofence(geofence);

    if (lat && lng) {
      setLiveMapSelectedVehicle({
        tripEnded: lastTrip?.tripEnded ?? true,
        vehicleId: vehicleId!,
        driverId: driver?.id!,
        lastKnownLocation: { lat, lng, time: time ?? unavailable_text },
        fleetIds,
      });
    }

    const { coords } = geofence;

    history.push(`/${currentFleetId}/live`, { location: coords, zoom: 16 });
  };

  return (
    <Widget title={geofences_text} collapsible scrollable page={WidgetPageType.Vehicle}>
      <div className="mx-1 px-1">
        {fleetGeofences?.length || geofences.length ? (
          <div className="overflow-y-auto">
            {fleetGeofences?.length ? (
              <div>
                <h5 className="text-md font-bold">{fleet_text}</h5>

                <div className="flex flex-wrap">
                  {fleetGeofences.map((geofence) => (
                    <GeofenceCard key={geofence.id} geofence={geofence} onClick={redirectToLiveMap} />
                  ))}
                </div>
              </div>
            ) : null}

            {geofences.length ? (
              <div className="mt-1">
                <h5 className="font-bold text-md">{vehicle_text}</h5>

                <div className="flex flex-wrap">
                  {geofences.map((geofence) => (
                    <GeofenceCard key={geofence.id} geofence={geofence} onClick={redirectToLiveMap} />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            {tSafe('components.Vehicle.Detail.Geofences.no-vehicle-geofences-assigned', {
              defaultValue: 'No geofences assigned to this vehicle',
            })}
          </div>
        )}
      </div>
    </Widget>
  );
};

export default Geofences;
