import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Button from 'atoms/Button';
import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import IonIcon from 'atoms/IonIcon';
import { TileIconMap } from 'atoms/WidgetTile';
import WidgetTileGroup from 'atoms/WidgetTile/WidgetTileGroup';
import { defaultDashboardTimePeriod } from 'common/constants';
import { tripStatisticConversionRatios } from 'common/uomConverters';
import { I18nContext } from 'common/useT';
import { DrivingStatsHistogram, DrivingStatsHistogramProps } from 'components/Charts/DrivingStatsHistogram';
import Widget from 'components/Dashboard/Widget';
import useFleetStatsMap from 'components/Fleet/FleetList/useFleetStatsMap';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useSettings from 'components/Settings/useSettings';
import useUser from 'components/User/useUser';
import { EntityAssociation, GetFleetDrivingStatsDoc, Period, TripStatisticType } from 'generated/graphql';
import { SelectOption } from 'types';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';

import DateFilter from '../DateFilter';
import { selectedFleetIdState } from './state';

const iconMap: TileIconMap<TripStatisticType> = {
  FUEL_CONSUMPTION: 'fuelconsumption',
  FUEL_EFFICIENCY: 'speedAverage',
  IDLE_TIME_PCT: 'speedIdle',
  IDLE_TIME_HRS: 'speedIdle',
  TOTAL_DISTANCE_DRIVEN: 'distancedriven',
  TOTAL_TIME_DRIVEN: 'timedriven',
  TRIP_COUNT: 'tripcount',
  ECO_SCORE: 'uncategorised',
};

interface DropdownSelectWrapperProps {
  items: {
    value: string;
    label: string;
  }[];
  initialItem: {
    value: string;
    label: string;
  };
  selectedItem?: {
    value: string;
    label: string;
  };
}

const DropdownSelectWrapper = ({ items, initialItem, selectedItem }: DropdownSelectWrapperProps) => {
  const setSelectedFleetIdState = useSetRecoilState(selectedFleetIdState);

  const { getProps } = useDropdownSelect(items, {
    onSelect: (item: SelectOption) => setSelectedFleetIdState(item.value),
    initialItem,
    buttonIcon: <IonIcon name="chevronDownOutline" />,
  });
  return <DropdownSelect {...getProps()} selectedItem={selectedItem ?? null} className="!w-20" />;
};

const Filter = () => {
  const { accessibleFleets } = useUser();
  const currentFleetId = useCurrentFleetId();
  const selectedFleetId = useRecoilValue(selectedFleetIdState);

  const options = [...accessibleFleets]
    .map((fleet) => ({
      label: fleet.name,
      value: fleet.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  if (!options) return null;

  return (
    <DropdownSelectWrapper
      items={options}
      selectedItem={options.find((item) => item.value === selectedFleetId) ?? options[0]}
      initialItem={options.find((item) => item.value === currentFleetId) ?? options[0]}
    />
  );
};

const FleetDrivingStatsWidget = () => {
  const i18nContext = useContext(I18nContext);
  const { distanceInMiles, volumeInGallons, idleTimeAsPercentage } = useSettings();
  const currentFleetId = useCurrentFleetId();
  const fleetStats = useFleetStatsMap();
  const history = useHistory();
  const [period, setPeriod] = useState<Period>(defaultDashboardTimePeriod);

  const setSelectedFleetIdState = useSetRecoilState(selectedFleetIdState);
  const selectedFleetId = useRecoilValue(selectedFleetIdState);
  const {
    loading,
    error,
    data: fleetDrivingStats,
  } = useQ(GetFleetDrivingStatsDoc, {
    variables: {
      period,
      fleetId: selectedFleetId,
    },
    skip: !selectedFleetId,
  });

  useEffect(() => {
    setSelectedFleetIdState(currentFleetId);
  }, [currentFleetId, setSelectedFleetIdState]);

  const conversionRatioMap = tripStatisticConversionRatios({ distanceInMiles, volumeInGallons });

  const [selectedTileType, setSelectedTileName] = useState<TripStatisticType>(TripStatisticType.TotalDistanceDriven);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { tripStatisticTypeDescriptionMap, dashboardWidgetDescriptionMap },
    },
  } = i18nContext;

  const selectedDataType = fleetDrivingStats?.find(({ type }) => type === selectedTileType);

  const histogram = (
    <DrivingStatsHistogram
      period={period as DrivingStatsHistogramProps['period']}
      histogramData={
        selectedDataType
          ? selectedDataType.histogram?.map(({ date, value }) => ({
              date,
              value: value * conversionRatioMap[selectedTileType],
            }))
          : null
      }
      description={tripStatisticTypeDescriptionMap[selectedTileType]}
      className="flex-1 p-2 !w-full"
    />
  );

  const tiles = fleetDrivingStats
    ?.filter((x) => x.type !== TripStatisticType.EcoScore)
    .filter((x) =>
      idleTimeAsPercentage ? x.type !== TripStatisticType.IdleTimeHrs : x.type !== TripStatisticType.IdleTimePct,
    )
    .map(({ type, value }) => ({
      name: type,
      description: tripStatisticTypeDescriptionMap[type],
      value: Math.round(value * conversionRatioMap[type]),
      icon: iconMap[type],
    }));

  const Counter = ({
    icon,
    value,
    associationState,
  }: {
    icon: 'carOutline' | 'personCircleOutline';
    value: string | number;
    associationState: EntityAssociation;
  }) => (
    <Button
      onClick={() =>
        history.push({
          pathname: `/${currentFleetId}/list-view`,
          state: {
            associationState,
            defaultList: true,
          },
        })
      }
      className="flex border-px border-gray-300 rounded-16 hover:bg-gray-100"
    >
      <IonIcon className="text-3xl" name={icon} />

      <div className="px-2 flex h-full items-center">{value}</div>
    </Button>
  );

  return (
    <Widget
      title={dashboardWidgetDescriptionMap[DashboardWidget.fleetDrivingStatsWidget]}
      filterComponent={<Filter />}
      loading={loading}
      error={error}
    >
      <div className="flex flex-col h-full overflow-hidden">
        <div className="flex">
          <div className="flex px-2 space-x-1">
            <Counter
              icon="personCircleOutline"
              value={fleetStats ? fleetStats[selectedFleetId]?.driverCount : '-'}
              associationState={EntityAssociation.AllDrivers}
            />

            <Counter
              icon="carOutline"
              value={fleetStats ? fleetStats[selectedFleetId]?.vehicleCount : '-'}
              associationState={EntityAssociation.AllVehicles}
            />
          </div>

          <div className="flex ml-auto mr-2">
            <DateFilter selectedPeriod={period} onSelect={(option) => setPeriod(option.value)} />
          </div>
        </div>

        {fleetDrivingStats?.length ? histogram : null}

        <WidgetTileGroup
          tiles={tiles ?? []}
          onSelected={setSelectedTileName}
          initialSelection={selectedDataType?.type}
          showZeroValues
        />
      </div>
    </Widget>
  );
};

export default FleetDrivingStatsWidget;
