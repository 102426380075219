import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import { findAddress } from 'common/geocodingApi';
import useSearchSource, { SearchSourceComponent } from 'common/useSearchSource';
import { mapState } from 'components/Live/state';
import { SearchAddress } from 'types/geocoding';

const SearchAddressItem: SearchSourceComponent = ({ id, innerHtml, onSelect }) => (
  <li
    className="flex items-center px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
    onClick={() => onSelect?.(id)}
  >
    <IonIcon name="locationOutline" className="mr-1 text-3xl" />

    <span className="text-md" dangerouslySetInnerHTML={{ __html: innerHtml }} />
  </li>
);

const useAddressSearchSource = (searchTerm: string, setSearchTerm: (term: string) => void) => {
  const map = useRecoilValue(mapState);
  const [addresses, setAddresses] = useState<SearchAddress[]>([]);

  const selectHandler = useCallback(
    (address: SearchAddress) => {
      setSearchTerm('');

      map?.flyTo([address.lat, address.lng], 16, { animate: false });
    },
    [map, setSearchTerm],
  );

  const updateResults = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (!searchTerm) return setAddresses([]);

        findAddress(searchTerm).then(setAddresses);
      }, 500),
    [],
  );

  useEffect(() => {
    updateResults(searchTerm);
  }, [searchTerm, updateResults]);

  return useSearchSource({
    memoizedData: addresses,
    searchKey: 'label',
    searchTerm,
    Component: SearchAddressItem,
    onSelect: selectHandler,
    scoreMultiplier: Infinity,
  });
};

export default useAddressSearchSource;
