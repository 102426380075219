import { useContext } from 'react';

import { I18nContext } from 'common/useT';
import Widget, { WidgetPageType } from 'components/Dashboard/Widget';
import { cx } from 'utils';

import { useVehicleDetails } from '../hooks';

interface TyreData {
  label: string;
  value: string | null | undefined;
  className: string;
}

const tyrePositions = ['front_left', 'front_right', 'rear_left', 'rear_right'] as const;

type TyrePosition = typeof tyrePositions[number];

const TyreComponent = ({ label, value, className }: TyreData) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { unavailable_text },
      uom: { bar_text },
    },
  } = i18nContext;

  return (
    <div className={cx('absolute', className)}>
      <span
        className={cx(
          className.includes('right-6') ? 'right-8' : 'left-8',
          'absolute top-2 w-10 border-t-px border-gray-300',
        )}
      ></span>

      <div>
        <div className="text-sm text-gray-400">{label}</div>

        <div className="">
          {value ? (
            <span>
              <strong>{value}</strong> {bar_text}
            </span>
          ) : (
            <span className="text-gray-300">{unavailable_text}</span>
          )}
        </div>
      </div>
    </div>
  );
};

const TyrePressure = () => {
  const i18nContext = useContext(I18nContext);
  const { data: [vehicleDetails] = [] } = useVehicleDetails();
  const { status } = vehicleDetails;

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      domain: {
        vehicleDetails: {
          fields: {
            typePressures: { frontLeft_text, frontRight_text, rearLeft_text, rearRight_text },
            tyre_pressure_text,
          },
        },
      },
    },
  } = i18nContext;

  const { frontLeft, frontRight, rearLeft, rearRight } = status?.tyrePressures ?? {};

  const noData = !frontLeft && !frontLeft && !rearLeft && !rearRight;

  const TyreMap: Record<TyrePosition, TyreData> = {
    front_left: { label: frontLeft_text, value: frontLeft, className: 'right-6 top-0' },
    front_right: { label: frontRight_text, value: frontRight, className: 'right-6 bottom-0' },
    rear_left: { label: rearLeft_text, value: rearLeft, className: 'left-5 top-0' },
    rear_right: { label: rearRight_text, value: rearRight, className: 'left-5 bottom-0' },
  };

  return (
    <Widget title={tyre_pressure_text} collapsible page={WidgetPageType.Vehicle}>
      <div className="bg-white p-1 mt-1">
        <div className="flex flex-wrap w-full h-[150px] -m-1">
          <div className={cx(noData && 'text-gray-300', 'relative w-full')}>
            <span className="absolute left-[229px] z-0 top-2 h-[110px] border-r-px border-gray-300"></span>

            <img
              alt="Car outline vector"
              src="/car.png"
              className="z-20 absolute left-[178px] top-[12px]"
              width={240}
            />

            <span className="absolute right-[239px] z-0 top-2 h-[110px] border-r-px border-gray-300"></span>

            {tyrePositions.map((position, i) => (
              <TyreComponent
                label={TyreMap[position].label}
                value={TyreMap[position].value}
                className={TyreMap[position].className}
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
    </Widget>
  );
};

export default TyrePressure;
