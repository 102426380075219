import { useContext } from 'react';

import { I18nContext } from 'common/useT';
import MoveDriverFleetOptions from 'components/Driver/Detail/Edit/DriverFleetSelector/MoveDriverFleetOptions';
import { useDriverDetails } from 'components/Driver/Detail/hooks';
import FleetSelectorWithOptions from 'components/FleetSelector/FleetSelectorWithOptions';

const DriverFleetSelector = () => {
  const i18nContext = useContext(I18nContext);
  const user = useDriverDetails().data?.[0].user;

  if (!user || !i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <FleetSelectorWithOptions
      currentFleetId={user.fleets[0].id}
      optionsComponent={MoveDriverFleetOptions}
      title={tSafe('components.Driver.Detail.Edit.DriverFleetSelector.reassign-fleet', {
        defaultValue: 'Re-assign fleet',
      })}
    />
  );
};

export default DriverFleetSelector;
