import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { BulkUploadItem } from 'generated/graphql';

const { persistAtom } = recoilPersist({
  key: 'bulkUpload',
  storage: localStorage,
});

export const validUploadDataState = atom({
  key: 'validUploadData',
  default: {
    isValid: false,
    variables: {
      fleetId: '',
      data: [] as BulkUploadItem[],
    },
  },
});

export const bulkUploadState = atom<string | undefined>({
  key: 'bulkUpload',
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
