import { Loader } from '@googlemaps/js-api-loader';
import { format } from 'date-fns';
import { useContext, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { GOOGLE_MAPS_API_KEY } from 'common/constants';
import { I18nContext } from 'common/useT';
import {
  activityHistoryClickedSegment,
  activityHistoryHoveredSegment,
  streetViewOnHoverAtom,
} from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/state';
import useSettings from 'components/Settings/useSettings';
import { ClassName } from 'types';
import { cx } from 'utils';

const Wrapper = styled.div`
  .gm-style-cc, 
  a[href^="https://maps.google.com/maps"], 
  a[href^="https://www.google.com/maps"]
  {
    display: none !important;
  }
`;

const loader = new Loader({ apiKey: GOOGLE_MAPS_API_KEY });

const StreetView = ({ className }: ClassName) => {
  const i18nContext = useContext(I18nContext);
  const clickSelectedSegment = useRecoilValue(activityHistoryClickedSegment);
  const hoverSelectedSegment = useRecoilValue(activityHistoryHoveredSegment);
  const activateOnHover = useRecoilValue(streetViewOnHoverAtom);
  const { distanceInMiles } = useSettings();
  const [map, setMap] = useState<google.maps.StreetViewPanorama>();
  const [streetName, setStreetName] = useState<string>();
  const mapContainer = useRef<HTMLDivElement>(null);

  const selectedSegment = activateOnHover ? hoverSelectedSegment : clickSelectedSegment;

  useEffect(() => {
    if (!mapContainer.current) {
      return;
    }

    loader.load().then((google) => {
      const map = new google.maps.StreetViewPanorama(mapContainer.current!, {
        controlSize: 30,
        addressControl: false,
        visible: false,
      });

      map.addListener('position_changed', () => {
        const location = map.getLocation();

        if (location?.shortDescription) {
          setStreetName(location.shortDescription);
        }

        map.setVisible(true);
      });

      setMap(map);
    });
  }, []);

  useEffect(() => {
    if (selectedSegment) {
      map?.setPosition(selectedSegment.segmentGeometry[0]);
    } else {
      map?.setVisible(false);
    }
  }, [map, selectedSegment]);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <Wrapper className={cx('w-full h-full relative', className)}>
      <div ref={mapContainer} className="w-full h-full relative !bg-gray-300/50">
        {!selectedSegment && (
          <span className="z-10 absolute w-full h-full flex-center text-center text-black">
            <span>
              {activateOnHover
                ? tSafe('components.ActivityHistoryList.EventTypes.Details.TripEventDetail.StreetView.hover', {
                    defaultValue: 'Hover over one of the segments',
                  })
                : tSafe('components.ActivityHistoryList.EventTypes.Details.TripEventDetail.StreetView.click', {
                    defaultValue: 'Click on one of the segments',
                  })}
            </span>

            <br />

            <span>
              {tSafe(
                'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.StreetView.on-the-chart-below',
                {
                  defaultValue: 'on-the-chart-below',
                },
              )}
            </span>
          </span>
        )}
      </div>

      {selectedSegment && streetName && (
        <div className="absolute flex flex-col top-0.5 left-0.5 p-1 bg-black/50 text-gray-300 z-10 leading-tight">
          <span className="font-bold">{streetName}</span>

          <span className="text-sm">
            {tSafe(
              'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.StreetView.selected-segment.time',
              {
                time: format(selectedSegment.timeFrom, 'HH:mm:ss'),
                defaultValue: 'Time: {{time}}',
              },
            )}
          </span>

          <span className="text-sm">
            {tSafe(
              'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.StreetView.selected-segment.average-speed',
              {
                avgSpeed: selectedSegment.averageSpeed,
                speedUnit: distanceInMiles ? 'mi/h' : 'km/h',
                defaultValue: 'Avg Speed: {{avgSpeed}} {{speedUnit}}',
              },
            )}
          </span>
        </div>
      )}
    </Wrapper>
  );
};

export default StreetView;
