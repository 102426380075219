import { cx } from 'utils';

const Separator = ({ absolute, className }: { absolute?: boolean; className?: string }) => (
  <>
    <div className={cx('w-full bg-dark-gray/10', className, absolute && 'absolute left-0 right-0')} role="separator" />

    {absolute && <div className="h-px" />}
  </>
);

export default Separator;
