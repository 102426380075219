import {
  DtcEventType,
  EntityAssociation,
  ListColumnId,
  LogicalCombinator,
  StateAggregationFilterInput,
  StateAggregatorSortField,
} from 'generated/graphql';
import { ColumnsState, ListItem } from 'types/settings';

export const maxItemsPerPage = 20;
export const defaultListName = 'Default list view';

export const columnIdToSortFieldMap: (id: ListColumnId, idleTimeAsPercentage: boolean) => StateAggregatorSortField = (
  id,
  idleTimeAsPercentage,
) => {
  return {
    [ListColumnId.BatteryCharge]: StateAggregatorSortField.BatteryVoltage,
    [ListColumnId.BatteryHealth]: StateAggregatorSortField.BatteryHealthState,
    [ListColumnId.BatteryStatus]: StateAggregatorSortField.LatestBatteryNotification,
    [ListColumnId.DashboardLights]: StateAggregatorSortField.ActiveDashboardLightCount,
    [ListColumnId.DeviceStatus]: StateAggregatorSortField.DeviceStatus,
    [ListColumnId.DistanceDriven]: StateAggregatorSortField.TotalDistanceDriven,
    [ListColumnId.DriverName]: StateAggregatorSortField.DriverName,
    [ListColumnId.DtcPending]: StateAggregatorSortField.DtcPending,
    [ListColumnId.DtcPermanent]: StateAggregatorSortField.DtcPermanent,
    [ListColumnId.DtcStored]: StateAggregatorSortField.DtcStored,
    [ListColumnId.DrivingTime]: StateAggregatorSortField.TotalTripDuration,
    [ListColumnId.EcoScore]: StateAggregatorSortField.EcoScore,
    [ListColumnId.Fleets]: StateAggregatorSortField.FleetName,
    [ListColumnId.FuelConsumption]: StateAggregatorSortField.FuelConsumption,
    [ListColumnId.FuelEfficiency]: StateAggregatorSortField.FuelEfficiency,
    [ListColumnId.IdleTime]: idleTimeAsPercentage
      ? StateAggregatorSortField.IdleTimePercentage
      : StateAggregatorSortField.TotalIdleTime,
    [ListColumnId.ImpactCount]: StateAggregatorSortField.NumVehicleImpacts,
    [ListColumnId.LicencePlate]: StateAggregatorSortField.VehicleLicencePlate,
    [ListColumnId.LongIdlingEventCount]: StateAggregatorSortField.NumDriverLongIdlingEvents,
    [ListColumnId.Make]: StateAggregatorSortField.VehicleMake,
    [ListColumnId.TripCount]: StateAggregatorSortField.NumTrips,
    [ListColumnId.Model]: StateAggregatorSortField.VehicleModel,
  }[id];
};

export const sortFieldMapToVehicleColumnId: (sortField: StateAggregatorSortField) => ListColumnId = (sortField) => {
  return {
    [StateAggregatorSortField.BatteryChargeState]: ListColumnId.BatteryCharge,
    [StateAggregatorSortField.BatteryVoltage]: ListColumnId.BatteryCharge,
    [StateAggregatorSortField.BatteryHealthState]: ListColumnId.BatteryHealth,
    [StateAggregatorSortField.LatestBatteryNotification]: ListColumnId.BatteryStatus,
    [StateAggregatorSortField.ActiveDashboardLightCount]: ListColumnId.DashboardLights,
    [StateAggregatorSortField.DeviceStatus]: ListColumnId.DeviceStatus,
    [StateAggregatorSortField.TotalDistanceDriven]: ListColumnId.DistanceDriven,
    [StateAggregatorSortField.DriverName]: ListColumnId.DriverName,
    [StateAggregatorSortField.DtcPending]: ListColumnId.DtcPending,
    [StateAggregatorSortField.DtcPermanent]: ListColumnId.DtcPermanent,
    [StateAggregatorSortField.DtcStored]: ListColumnId.DtcStored,
    [StateAggregatorSortField.TotalTripDuration]: ListColumnId.DrivingTime,
    [StateAggregatorSortField.EcoScore]: ListColumnId.EcoScore,
    [StateAggregatorSortField.FleetName]: ListColumnId.Fleets,
    [StateAggregatorSortField.FuelConsumption]: ListColumnId.FuelConsumption,
    [StateAggregatorSortField.FuelEfficiency]: ListColumnId.FuelEfficiency,
    [StateAggregatorSortField.IdleTimePercentage]: ListColumnId.IdleTime,
    [StateAggregatorSortField.TotalIdleTime]: ListColumnId.IdleTime,
    [StateAggregatorSortField.NumDriverImpacts]: ListColumnId.ImpactCount,
    [StateAggregatorSortField.NumVehicleImpacts]: ListColumnId.ImpactCount,
    [StateAggregatorSortField.VehicleLicencePlate]: ListColumnId.LicencePlate,
    [StateAggregatorSortField.LicencePlate]: ListColumnId.LicencePlate,
    [StateAggregatorSortField.NumDriverLongIdlingEvents]: ListColumnId.LongIdlingEventCount,
    [StateAggregatorSortField.VehicleMake]: ListColumnId.Make,
    [StateAggregatorSortField.NumTrips]: ListColumnId.TripCount,
    [StateAggregatorSortField.VehicleModel]: ListColumnId.Model,
  }[sortField];
};

export const aggregationFiltersToListColumnId = ({
  key,
  value,
}: {
  key: keyof StateAggregationFilterInput;
  value?: boolean | number | string[] | EntityAssociation | LogicalCombinator | null;
}) => {
  type DtcTypes = 'dtcStored' | 'dtcPending' | 'dtcPermanent';

  const map: Partial<Record<keyof StateAggregationFilterInput | DtcTypes, ListColumnId>> = {
    batteryNotificationTypesIncludes: ListColumnId.BatteryStatus,
    batterySohsIncludes: ListColumnId.BatteryHealth,
    dashboardLightsIncludes: ListColumnId.DashboardLights,
    deviceConnectedStatusIncludes: ListColumnId.DeviceStatus,
    distanceDrivenCeiling: ListColumnId.DistanceDriven,
    distanceDrivenFloor: ListColumnId.DistanceDriven,
    driverHasImpacts: ListColumnId.ImpactCount,
    driverNames: ListColumnId.DriverName,
    dtcStored: ListColumnId.DtcStored,
    dtcPending: ListColumnId.DtcPending,
    dtcPermanent: ListColumnId.DtcPermanent,
    fleetIds: ListColumnId.Fleets,
    driverHasLongIdlingEvents: ListColumnId.LongIdlingEventCount,
    vehicleMakes: ListColumnId.Make,
    vehicleModels: ListColumnId.Model,
  };

  if (Array.isArray(value) && key === 'dtcsIncludeType') {
    switch (value[0]) {
      case DtcEventType.Stored:
        return 'dtcStored';
      case DtcEventType.Pending:
        return 'dtcPending';
      case DtcEventType.Permanent:
        return 'dtcPermanent';
      default:
        return map[key];
    }
  }

  return map[key];
};

export const entityAssociationColumnMap: Record<EntityAssociation, ColumnsState> = {
  [EntityAssociation.UnassignedDrivers]: {
    disabledColumns: [ListColumnId.LicencePlate, ListColumnId.Make, ListColumnId.Model, ListColumnId.DeviceStatus],
    enforcedColumns: [ListColumnId.DriverName],
  },
  [EntityAssociation.UnassignedVehicles]: {
    disabledColumns: [ListColumnId.DriverName],
    enforcedColumns: [ListColumnId.LicencePlate],
  },
  [EntityAssociation.DriversAndVehicles]: {
    disabledColumns: [],
    enforcedColumns: [],
  },
  [EntityAssociation.AllDrivers]: {
    disabledColumns: [],
    enforcedColumns: [ListColumnId.DriverName],
  },
  [EntityAssociation.AllVehicles]: {
    disabledColumns: [],
    enforcedColumns: [ListColumnId.LicencePlate],
  },
};

export const listUniqueValueColumns: [ListColumnId, (x: ListItem) => string | null | undefined][] = [
  [ListColumnId.Make, (x) => x.vehicle?.make],
  [ListColumnId.Model, (x) => x.vehicle?.model],
];

export const listMinMaxValueColumns: [ListColumnId, (x: ListItem) => number | null | undefined][] = [
  [ListColumnId.DistanceDriven, (x) => x.tripStatistics?.totalDistanceDriven ?? 0],
];
