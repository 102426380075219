import { useContext } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

import { I18nContext } from 'common/useT';
import { bulkUploadSchema, StaticCsvImportFields } from 'components/BulkUpload';
import { ClassName } from 'types';

const Table = styled.table`
  td,
  th {
    ${tw`text-left whitespace-nowrap px-0.5`}
  }
`;

const data: Record<keyof StaticCsvImportFields, string>[] = [
  {
    'vehicle ID': '50db86bc-d14c-4a8c-bfc5-17447fafe845',
    'licence plate': 'LC12PLT',
    make: 'Ford',
    model: 'Transit Custom',
    year: '2018',
    'fuel type': 'diesel',
    VIN: '12345678901234567',
    'serial number': '123456789012345',
    'driver ID': '711d169a-0c9f-45ea-a42f-53e7bc28b185',
    name: 'John',
    email: 'Doe',
    'phone number': '+441234567890',
    'fleet name': 'Fleet 1',
  },
  {
    'vehicle ID': '93e87c2b-8ff0-4a83-8264-3e6ce1575029',
    'licence plate': 'LC00PLT',
    make: 'VW',
    model: 'Transporter T6',
    year: '2017',
    'fuel type': 'diesel',
    VIN: '12345678901234567',
    'serial number': '123456789054321',
    'driver ID': '58a9b393-e837-4863-8f06-56780dc4b049',
    name: '',
    email: '',
    'phone number': '',
    'fleet name': 'Fleet 2',
  },
  {
    'vehicle ID': 'acdf6285-f03e-428c-bd0c-a2f74b4f02c1',
    'licence plate': '',
    make: '',
    model: '',
    year: '',
    'fuel type': '',
    VIN: '',
    'serial number': '',
    'driver ID': '5a7bd9a3-fe75-48b7-abb6-79dced2752e7',
    name: 'Jack',
    email: 'Smith',
    'phone number': '',
    'fleet name': 'Fleet 1',
  },
];

interface SampleBulkUploadDataProps extends ClassName {
  supportedFields: string[];
}

const SampleBulkUploadData = ({ className, supportedFields }: SampleBulkUploadDataProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <Table className={className}>
      <thead>
        <tr className="bg-gray-300">
          <th>
            {tSafe('components.BulkUpload.SampleBulkUploadData.supported-columns', {
              defaultValue: 'Supported columns',
            })}
            : &nbsp;&nbsp;&nbsp;
          </th>

          {supportedFields.map((field) => (
            <th key={field}>
              {field}

              {bulkUploadSchema.fields[field as keyof StaticCsvImportFields]?.['exclusiveTests']?.required && (
                <strong className="ml-[0.3rem] text-error opacity-80">*</strong>
              )}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((row, i) => (
          <tr key={i} className="odd:bg-gray-300/30">
            {i === 0 && (
              <td rowSpan={data.length} className="bg-gray-300">
                {tSafe('components.BulkUpload.SampleBulkUploadData.sample-data', {
                  defaultValue: 'Sample Data',
                })}{' '}
                &nbsp;&nbsp;&nbsp;
              </td>
            )}

            {supportedFields.map((field) => (
              <td key={field} className="w-[0.1%] whitespace-nowrap px-0.5">
                {row[field as keyof typeof row]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SampleBulkUploadData;
