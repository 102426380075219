import * as React from 'react';
import { SVGProps } from 'react';

export const AnwbFleetLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1365.3333 1365.33337"
    xmlSpace="preserve"
    {...props}
  >
    <defs>
      <clipPath id="e" clipPathUnits="userSpaceOnUse">
        <path d="M0 1024h1024V0H0Z" />
      </clipPath>

      <clipPath id="f" clipPathUnits="userSpaceOnUse">
        <path d="M340.929 894.546H450.42V788.48H340.929Z" />
      </clipPath>

      <clipPath id="g" clipPathUnits="userSpaceOnUse">
        <path d="M379.196 848.172h71.224V788.48h-71.224z" />
      </clipPath>

      <clipPath id="h" clipPathUnits="userSpaceOnUse">
        <path d="M345.757 851.706h32.919v-24.537h-32.919z" />
      </clipPath>

      <clipPath id="i" clipPathUnits="userSpaceOnUse">
        <path d="M340.929 894.546h6.368v-40.782h-6.368z" />
      </clipPath>

      <clipPath id="j" clipPathUnits="userSpaceOnUse">
        <path d="M.355 0H1024v1020.394H.355Z" />
      </clipPath>

      <clipPath id="k" clipPathUnits="userSpaceOnUse">
        <path d="M0 1024h1024V0H0Z" />
      </clipPath>

      <clipPath id="l" clipPathUnits="userSpaceOnUse">
        <path d="M-1933.645-3.251H-910v1023.645h-1023.645z" />
      </clipPath>

      <radialGradient
        id="b"
        spreadMethod="pad"
        gradientTransform="matrix(175.46844 0 0 -175.46844 373.676 946.874)"
        gradientUnits="userSpaceOnUse"
        r={1}
        cy={0}
        cx={0}
        fy={0}
        fx={0}
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: '#009fe3',
          }}
        />

        <stop
          offset={0.534}
          style={{
            stopOpacity: 1,
            stopColor: '#009fe3',
          }}
        />

        <stop
          offset={0.85}
          style={{
            stopOpacity: 1,
            stopColor: '#0069b4',
          }}
        />

        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: '#005ca9',
          }}
        />
      </radialGradient>

      <radialGradient
        id="c"
        spreadMethod="pad"
        gradientTransform="matrix(175.46985 0 0 -175.46985 373.677 946.875)"
        gradientUnits="userSpaceOnUse"
        r={1}
        cy={0}
        cx={0}
        fy={0}
        fx={0}
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: '#009fe3',
          }}
        />

        <stop
          offset={0.534}
          style={{
            stopOpacity: 1,
            stopColor: '#009fe3',
          }}
        />

        <stop
          offset={0.85}
          style={{
            stopOpacity: 1,
            stopColor: '#0069b4',
          }}
        />

        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: '#005ca9',
          }}
        />
      </radialGradient>

      <radialGradient
        id="d"
        spreadMethod="pad"
        gradientTransform="matrix(175.4691 0 0 -175.4691 373.677 946.874)"
        gradientUnits="userSpaceOnUse"
        r={1}
        cy={0}
        cx={0}
        fy={0}
        fx={0}
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: '#009fe3',
          }}
        />

        <stop
          offset={0.534}
          style={{
            stopOpacity: 1,
            stopColor: '#009fe3',
          }}
        />

        <stop
          offset={0.85}
          style={{
            stopOpacity: 1,
            stopColor: '#0069b4',
          }}
        />

        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: '#005ca9',
          }}
        />
      </radialGradient>

      <linearGradient
        id="a"
        spreadMethod="pad"
        gradientTransform="scale(78.23828 -78.23828) rotate(-17.84 -35.962 -22.29)"
        gradientUnits="userSpaceOnUse"
        y2={0}
        x2={1}
        y1={0}
        x1={0}
      >
        <stop
          offset={0}
          style={{
            stopOpacity: 1,
            stopColor: '#fd0',
          }}
        />

        <stop
          offset={0.681}
          style={{
            stopOpacity: 1,
            stopColor: '#f49500',
          }}
        />

        <stop
          offset={1}
          style={{
            stopOpacity: 1,
            stopColor: '#f49500',
          }}
        />
      </linearGradient>
    </defs>

    <path
      style={{
        fill: '#fff',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M0 0h1024v1024H0Z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: '#ffcd00',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M0 0h1024v795H0Z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: 'url(#a)',
        stroke: 'none',
      }}
      d="M391.38 975.461c-9.319-3.888-17.992-9.036-26.334-14.383l19.141-26.752c14.291 8.536 30.235 14.006 45.178 16.114l26.241 34.647-6.386-33.814c13.07-.236 25.331-4.166 34.457-11.124l49.245 30.37c-21.474 12.996-44.781 20.598-71.281 20.598-21.154 0-44.344-4.844-70.261-15.656"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: 'url(#b)',
        stroke: 'none',
      }}
      d="m306.193 903.912 15.31-7.169-24.022-8.429c-10.335-21.71-14.091-45.017-8.297-68.073l57.65 35.409c-12.529 23.155 2.755 57.53 37.353 78.676l-19.141 26.752c-23.864-15.277-44.657-35.068-58.853-57.166"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: 'url(#c)',
        stroke: 'none',
      }}
      d="M290.512 815.571c8.113-25.241 31.504-41.706 62.599-45.528l17.653 14.724 1.715-15.473c30.63 1.224 66.336 13.422 101.533 39.49l-28.183 39.389c-13.964-7.766-28.132-12.494-41.353-14.303l-31.759-44.214 5.959 44.655c-11.759 2.304-21.638 7.686-28.341 16.057-.352.44-.688.887-1.015 1.338z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: 'url(#d)',
        stroke: 'none',
      }}
      d="M268.926 791.302c21.784-60.849 129.116-78.594 221.848-5.946l-6.988 9.766c16.008 13.445 26.818 26.062 31.186 34.438l-4.168-.015c-100.398-102.618-210.115-77.776-228.099-15.2-19.592 68.173 48.966 143.93 120.058 169.468-64.731-13.132-165.447-104.215-133.837-192.511"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <g clipPath="url(#e)" transform="matrix(1.33333 0 0 -1.33333 0 1365.333)">
      <g clipPath="url(#f)">
        <g
          style={{
            opacity: 0.69999701,
          }}
          clipPath="url(#g)"
        >
          <path
            style={{
              fill: '#213a8f',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            d="m0 0-22.875-31.845-2.405-13.545L3.929-7.04C16.957-4.924 32.139.013 45.944 7.886l-4.591 6.416C27.389 6.537 13.222 1.809 0 0"
            transform="translate(404.476 833.87)"
          />
        </g>

        <g
          style={{
            opacity: 0.69999701,
          }}
          clipPath="url(#h)"
        >
          <path
            style={{
              fill: '#213a8f',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            d="m0 0 .953 7.142c-11.759 2.304-21.638 7.686-28.341 16.057-.352.44-.687.887-1.015 1.338l-3.563-2.189a38.236 38.236 0 0 1 2.394-3.828C-22.807 9.019-12.47 2.781 0 0"
            transform="translate(377.723 827.17)"
          />
        </g>

        <g
          style={{
            opacity: 0.69999701,
          }}
          clipPath="url(#i)"
        >
          <path
            style={{
              fill: '#213a8f',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
            d="M0 0c-6.875-14.026-8.388-28.549-3.535-40.783l3.072 1.886C-6.551-27.645-6.071-13.745 0 0"
            transform="translate(347.297 894.546)"
          />
        </g>
      </g>

      <path
        style={{
          fill: '#003d86',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="M0 0c-4.754-6.839-11.319-11.934-18.307-12.056-6.172-.109-11.975 3.444-8.398 14.701 3.697 11.693 11.325 29.572 11.325 29.572 18.025 7.275 23.878-2.025 23.671-9.246C8.161 18.392 5.911 8.503 0 0m28.909 31.427C25.607 43.879 15.794 48.914 1.981 48.461c-3.452-.113-7.485-.658-11.64-1.435L2.534 78.875H-21.04L-60.567-24.14l-.301-.745h.014l-.015-.035.028.035 16.475.014 6.804 4.97s3.759-4.749 11.791-5.878c20.466-2.881 37.696 9.441 46.868 23.653 6.473 10.015 10.359 23.95 7.812 33.553"
        transform="translate(731.365 881.483)"
      />

      <path
        style={{
          fill: '#003d86',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="M0 0s-7.478-20.204-12.979-31.889c-5.301-11.263-12.616-15.107-18.469-14.707-6.385.443-10.179 4.894-10.039 12.056.173 8.876 5.665 18.441 9.599 22.975C-28.302-7.428-17.936 2.527 0 0m-7.551 13.917c-15.673-1.538-27.866-6.013-40.241-17.326-9.547-8.724-15.783-22.4-16.063-33.257-.361-14.081 10.212-26.613 31.163-23.361 5.672.878 12.809 5.584 12.809 5.584l3.274-4.97 16.483-.015L25.881 8.396c-5.03 2.53-17.475 7.091-33.432 5.521"
        transform="translate(434.663 916.056)"
      />

      <path
        style={{
          fill: '#003d86',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="m0 0 23.696 61.922h23.369L24.831 3.838C31.467.382 43.912 2.322 49.265 14.341l18.156 47.581h23.493L68.53 3.73C77.797.684 87.778 2.322 92.867 14.336l18.245 47.586h23.307l-18.592-48.086c-4.664-10.761-12.25-25.509-38.268-25.509-8.714 0-16.616 3.076-21.673 7.11-5.712-3.497-17.651-7.11-26.645-7.11S3.909-8.467 0 0"
        transform="translate(545.712 866.125)"
      />

      <path
        style={{
          fill: '#003d86',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="m0 0-26.16-68.196h23.921l22.1 57.253c3.096.783 7.859 1.43 11.801 1.43 7.736 0 11.063-2.981 10.54-6.879-.115-.903-.401-1.811-.853-2.856l-18.8-48.948h23.945l18.497 48.044c.739 1.949 1.299 3.76 1.533 5.58C67.906-4.061 60.32 4.91 37.42 4.935c-7.21.008-13.618-.464-17.727-.957C15.943 3.536 9.186 2.573 0 0"
        transform="translate(478.6 924.75)"
      />

      <path
        style={{
          fill: '#e56e02',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="m0 0-3.724-19.539c1.905-.021 3.707-.148 5.589-.327L7.356 12.9Z"
        transform="translate(452.943 970.82)"
      />

      <path
        style={{
          fill: '#fff',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="M0 0c-30.122 26.061-80.011 30.68-130.3 13.276l.053.012c-71.092-25.539-139.65-101.296-120.057-169.468 17.984-62.576 127.701-87.419 228.098 15.199h-10.836s-12.603-12.176-25.993-20.71l.037-.052c-35.197-26.067-70.903-38.265-101.532-39.489l-1.715 15.473-17.654-14.725c-31.095 3.824-54.486 20.287-62.599 45.529l58.789 36.146c-1.244 1.669-1.979 3.046-2.486 3.944l-57.631-35.419c-5.793 23.056-2.037 46.363 8.298 68.072l24.021 8.429-15.31 7.17c14.197 22.097 34.99 41.889 58.854 57.165 8.342 5.348 17.015 10.495 26.333 14.383a262.19 262.19 0 0 0 5.434 2.195C-80.468 28.904-37.497 22.634-.088-.007z"
        transform="translate(533.029 970.515)"
      />

      <path
        style={{
          fill: '#fff',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="m0 0 .843 4.238-26.246-34.642c2.08.275 3.79.485 5.869.636z"
        transform="translate(454.763 980.848)"
      />

      <path
        style={{
          fill: '#fff',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="M0 0a79.08 79.08 0 0 0-5.012.798l-5.958-44.655 2.479 3.453z"
        transform="translate(383.683 833.516)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0h73.092s7.865 80.232-40.722 124.992c-48.595 44.76-112.646 41.861-142.524 62.779-14.661 10.26-87.054 78.346-140.263 85.057-94.08 11.86-172.385-1.687-216.235-21.31-30.01-12.69-66.587-45.136-91.181-78.576-31.725-43.151-52.625-111.515-35.163-177.245"
        transform="translate(744.258 218.19)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0c0-37.995 30.797-68.783 68.788-68.783S137.576-37.995 137.576 0c0 37.991-30.797 68.788-68.788 68.788S0 37.991 0 0Z"
        transform="translate(603.394 227.24)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0h-483.822"
        transform="translate(672.606 390.205)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0v-162.815"
        transform="translate(453.164 383.347)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0c0-37.986 30.797-68.783 68.788-68.783S137.576-37.995 137.576 0c0 37.991-30.797 68.788-68.788 68.788S0 37.991 0 0Z"
        transform="translate(150.184 227.24)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0h310.049"
        transform="translate(289.978 218.19)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0h37.385"
        transform="translate(366.034 341.211)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0h31.105"
        transform="translate(780.428 281.426)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0h37.393"
        transform="translate(501.94 341.211)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0v0c.016 46.497-18.146 90.262-51.103 123.219-32.973 32.973-76.726 51.123-123.239 51.123"
        transform="translate(877.348 509.367)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0c0 75.31-61.293 136.602-136.603 136.603"
        transform="translate(813.164 482.918)"
      />

      <path
        style={{
          fill: 'none',
          stroke: '#003d86',
          strokeWidth: 38,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 4,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0c.004 26.362-10.278 51.183-28.973 69.878-18.696 18.695-43.533 28.993-69.894 28.989"
        transform="translate(748.96 456.45)"
      />

      <path
        style={{
          fill: '#003d86',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        d="M0 0c-11.596 11.596-30.46 11.592-42.052 0-11.596-11.596-11.601-30.46-.004-42.056 11.592-11.592 30.444-11.576 42.04.02C11.576-30.444 11.592-11.592 0 0"
        transform="translate(685.376 491.716)"
      />
    </g>

    <path
      style={{
        fill: '#e1e6e7',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="m0 0 1020.393-1020.394h1.216l.098.098L1.412 0Z"
      transform="matrix(1.33333 0 0 -1.33333 .865 4.808)"
    />

    <path
      style={{
        fill: '#e1e6e7',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="m0 0-1018.939-1018.94v-.711l.35-.351L1.413 0Z"
      transform="matrix(1.33333 0 0 -1.33333 1359.06 4.808)"
    />

    <path
      style={{
        fill: '#e1e6e7',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M.355 510.571h1021.648v.997H.355Z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: '#e1e6e7',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M510.18 0h.997v1020.394h-.997zM955.156 0h.998v1020.394h-.998zM66.201 0h.998v1020.394h-.998zM317.624 0h.997v1020.394h-.997zM704.732 0h.998v1020.394h-.998z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: '#e1e6e7',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M.355 317.016h1021.648v.997H.355ZM.355 703.128h1021.648v.997H.355ZM.355 65.595h1021.648v.997H.355ZM.355 954.549h1021.648v.998H.355Z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <g clipPath="url(#j)" transform="matrix(1.33333 0 0 -1.33333 0 1365.333)">
      <path
        style={{
          fill: 'none',
          stroke: '#e1e6e7',
          strokeWidth: 1,
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        d="M0 0c164.472 0 297.803-133.331 297.803-297.803 0-164.471-133.331-297.803-297.803-297.803s-297.803 133.332-297.803 297.803C-297.803-133.331-164.472 0 0 0Zm481.899-297.803c0-266.146-215.753-481.899-481.899-481.899s-481.899 215.753-481.899 481.899S-266.146 184.096 0 184.096 481.899-31.657 481.899-297.803z"
        transform="translate(511.178 808.373)"
      />
    </g>

    <path
      style={{
        fill: '#f05822',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M956 701.5h1v5h-1zM67 701.5h1.001v5H67Z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: '#f05822',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M67 703.5h890v1H67ZM956 61.5h1v5h-1zM67 61.5h1.001v5H67Z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: '#f05822',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M67 63.5h890v1H67ZM897 64.5h5v1h-5zM897 702.5h5v1h-5z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: '#f05822',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M899 64.5h1v639h-1zM124 64.5h4.999v1H124ZM124 702.5h4.999v1H124Z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <path
      style={{
        fill: '#f05822',
        fillOpacity: 1,
        fillRule: 'nonzero',
        stroke: 'none',
      }}
      d="M126 64.5h1v639h-1z"
      transform="matrix(1.33333 0 0 -1.33333 0 1365.333)"
    />

    <g clipPath="url(#k)" transform="matrix(1.33333 0 0 -1.33333 0 1365.333)">
      <g clipPath="url(#l)">
        <path
          style={{
            fill: 'none',
            stroke: '#e1e6e7',
            strokeWidth: 1,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1,
          }}
          d="M0 0c151.529 0 274.369-122.839 274.369-274.369 0-151.529-122.84-274.368-274.369-274.368-151.529 0-274.369 122.839-274.369 274.368C-274.369-122.839-151.529 0 0 0Zm0 169.61c245.203 0 443.978-198.776 443.978-443.979 0-245.203-198.775-443.978-443.978-443.978s-443.979 198.775-443.979 443.978S-245.203 169.61 0 169.61z"
          transform="translate(1253.249 783.94)"
        />
      </g>
    </g>
  </svg>
);
