import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ButtonGroup from 'atoms/ButtonGroup';
import NoDataResponse, { ResponseType } from 'atoms/NoDataResponse';
import Tooltip from 'atoms/Tooltip';
import { defaultDashboardTimePeriod } from 'common/constants';
import { I18nContext } from 'common/useT';
import { idleLeaderboardTypeState } from 'components/Dashboard/IdleTimeLeaderboardWidget/state';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetIdleLeaderboardDoc, LeaderboardType } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';

import Widget from '../Widget';
import IdleLeaderboardTable from './IdleLeaderboardTable';

const Filter = () => {
  const i18nContext = useContext(I18nContext);
  const setIdleLeaderboardType = useSetRecoilState(idleLeaderboardTypeState);

  if (!i18nContext) return null;
  const {
    commonTranslations: {
      enums: { leaderboardTypeDescriptionMap },
      general: { n_day_period_text },
    },
  } = i18nContext;

  const bottom20 = leaderboardTypeDescriptionMap[LeaderboardType.Bottom](20);
  const top20 = leaderboardTypeDescriptionMap[LeaderboardType.Top](20);

  return (
    <Tooltip text={n_day_period_text(30)}>
      <ButtonGroup
        className="w-20"
        buttons={{
          [bottom20]: () => setIdleLeaderboardType(LeaderboardType.Bottom),
          [top20]: () => setIdleLeaderboardType(LeaderboardType.Top),
        }}
      />
    </Tooltip>
  );
};

const IdleTimeLeaderboardWidget = () => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();
  const type = useRecoilValue(idleLeaderboardTypeState);
  const {
    loading,
    error,
    data: leaderboard,
  } = useQ(GetIdleLeaderboardDoc, {
    variables: {
      period: defaultDashboardTimePeriod,
      limit: 20,
      fleetId: useCurrentFleetId(),
      type,
    },
  });
  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap },
    },
  } = i18nContext;

  return (
    <Widget
      title={dashboardWidgetDescriptionMap[DashboardWidget.idleTimeLeaderboardWidget]}
      filterComponent={!error && <Filter />}
      loading={loading}
      error={error}
    >
      <>
        <div className="ml-2">
          <div className="italic">
            {tSafe('components.Dashboard.IdleLeaderboardWidget.results-display-bottom-ten-percent', {
              defaultValue: 'Results will display the bottom 10% idling-performing drivers across the entire fleet',
            })}
          </div>

          <Link to={`/${currentFleetId}/idling-performance`} className="underline">
            {tSafe('components.Dashboard.IdleLeaderboardWidget.view-full-results', {
              defaultValue: 'Click here to view full results',
            })}
          </Link>
        </div>

        {leaderboard?.length ? (
          <IdleLeaderboardTable leaders={leaderboard} className="m-auto w-full" />
        ) : (
          <NoDataResponse reason={ResponseType.NoFleetData} />
        )}
      </>
    </Widget>
  );
};

export default IdleTimeLeaderboardWidget;
