import { Circle } from 'react-leaflet';
import { useRecoilState } from 'recoil';

import { geofenceColorMap } from 'components/Live/Geofence/GeofenceSidebar/GeofenceSettings';
import { selectedGeofenceState } from 'components/Live/state';

const SelectedGeofenceCircle = () => {
  const [selectedGeofence, setSelectedGeofence] = useRecoilState(selectedGeofenceState);

  if (!selectedGeofence) return null;

  const {
    coords: { lat, lng },
    radius,
    color,
  } = selectedGeofence;

  return (
    <Circle
      key={selectedGeofence?.id}
      center={[lat, lng]}
      radius={radius}
      pathOptions={{ color: geofenceColorMap[color], fillColor: geofenceColorMap[color] }}
      eventHandlers={{
        add: ({ target }: { target: L.Circle }) => target.pm.enable(),
        // @ts-ignore reson: Geoman event
        'pm:edit': ({ layer: circle }: { layer: L.Circle }) => {
          setSelectedGeofence({
            ...selectedGeofence,
            coords: { lat: circle.getLatLng().lat, lng: circle.getLatLng().lng },
            radius: circle.getRadius(),
          });
        },
      }}
    />
  );
};

export default SelectedGeofenceCircle;
