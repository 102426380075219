import { CellContext } from '@tanstack/react-table';
import IonIcon from 'atoms/IonIcon';
import { UserListRow } from 'types/settings';

const UserListActiveCell = ({ cell }: { cell: CellContext<UserListRow, boolean> }) => {
  return cell.getValue() ? (
    <IonIcon name="checkmarkCircleOutline" className="text-success text-xl" />
  ) : (
    <IonIcon name="closeOutline" className="text-error text-xl" />
  );
};

export default UserListActiveCell;
