import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import { useModalContext } from 'atoms/Modal';
import Spinner, { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { VehicleEditForm, VehicleEditFormData } from 'components/Vehicle/Detail/Edit/VehicleEditForm';
import { useVehicleDetails } from 'components/Vehicle/Detail/hooks';
import { FuelType, UpdateVehicleDoc } from 'generated/graphql';

export const Edit = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, error, data: [vehicleDetails] = [] } = useVehicleDetails();
  const modalContext = useModalContext();

  const [updateVehicle, { error: updateVehicleError, loading: updateVehicleLoading }] = useMutation(UpdateVehicleDoc, {
    onCompleted: () => {
      modalContext?.closeModal();
    },
  });

  if (loading || !i18nContext) return <Spinner />;

  const {
    commonTranslations: {
      errors: { entity_not_found_tfn, error_text },
      domain: {
        vehicle: { vehicle_text },
      },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  if (!vehicleDetails) return <div className="text-xl">{entity_not_found_tfn(vehicle_text)}</div>;

  const onSubmit = ({ externalId: _, ...vehicle }: VehicleEditFormData) => {
    if (!vehicle) return;

    updateVehicle({
      variables: { vehicleId: vehicleDetails.vehicle.id, vehicle },
    });
  };

  return (
    <div className="flex flex-col">
      {updateVehicleLoading && <CenteredSpinner className="!text-[0.5em]" />}

      {updateVehicleError && <span className="text-error text-md normal-case">{updateVehicleError.message}</span>}

      <VehicleEditForm
        onSubmit={onSubmit}
        initialState={{ ...vehicleDetails.vehicle, fuelType: vehicleDetails.vehicle.fuelType ?? FuelType.Diesel }}
      />
    </div>
  );
};
