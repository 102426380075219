import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Tile } from 'atoms/WidgetTile';
import WidgetTileGroup from 'atoms/WidgetTile/WidgetTileGroup';
import { defaultDashboardTimePeriod } from 'common/constants';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { EntityAssociation, GetUnassociatedCountDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

const UtilisationWidget = () => {
  const i18nContext = useContext(I18nContext);

  const currentFleetId = useCurrentFleetId();
  const { loading, error, data } = useQ(GetUnassociatedCountDoc, {
    variables: {
      fleetId: currentFleetId,
      period: defaultDashboardTimePeriod,
    },
  });
  const history = useHistory();

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      domain: {
        driver: { unassigned_drivers_text },
        vehicle: { unassigned_vehicles_text },
      },
    },
  } = i18nContext;

  const tiles = [
    {
      name: 'unassignedDrivers',
      description: unassigned_drivers_text,
      value: data?.unassociatedDriverCount,
      icon: 'unassociatedDriver',
      onClick: () =>
        history.push(`/${currentFleetId}/list-view`, {
          associationState: EntityAssociation.UnassignedDrivers,
          defaultList: true,
        }),
    } as Tile,
    {
      name: 'unassignedVehicles',
      description: unassigned_vehicles_text,
      value: data?.unassociatedVehicleCount,
      icon: 'unassociatedVehicle',
      onClick: () =>
        history.push(`/${currentFleetId}/list-view`, {
          associationState: EntityAssociation.UnassignedVehicles,
          defaultList: true,
        }),
    } as Tile,
  ];

  return (
    <Widget title={'Utilisation'} loading={loading} error={error}>
      <WidgetTileGroup tiles={tiles ?? []} defaultIconClassName="text-error" valueClassName="text-error" count />
    </Widget>
  );
};

export default UtilisationWidget;
