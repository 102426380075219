import { useContext } from 'react';
import { Link } from 'react-router-dom';

import IonIcon from 'atoms/IonIcon';
import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetVehicleDoc, Period } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

interface ExistingVehicleDetailsProps {
  vehicleId: string;
  onChangeDriverCheckbox?: (checked: boolean) => void;
  creationMode?: boolean;
}

const ExistingVehicleDetails = ({ vehicleId, onChangeDriverCheckbox, creationMode }: ExistingVehicleDetailsProps) => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();
  const {
    data: vehicleDetails,
    loading,
    error,
  } = useQ(GetVehicleDoc, {
    variables: {
      vehicleIds: vehicleId!,
      period: Period.P24h,
    },
    fetchPolicy: 'cache-first',
  });

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { yes_text },
      errors: { error_text },
      domain: {
        vehicleDetails: { vehicle_details_text },
      },
    },
  } = i18nContext;

  if (error) return <span>{error_text}</span>;

  if (loading)
    return (
      <div className="ml-4 my-1">
        <Spinner className="!text-[0.5em]" />
      </div>
    );
  if (!vehicleDetails) return null;

  const {
    vehicle: { make, model, licencePlate, year, fuelType },
    associations: { driver },
  } = vehicleDetails[0];

  return (
    <div className="flex flex-col text-md">
      <span className="text-amber font-bold">
        {tSafe(
          'components.Vehicle.VehicleList.VehicleListToolbar.ExistingVehicleDetails.device-serial-already-associated',
          {
            defaultValue:
              'This Device Serial Number is already associated with a vehicle. Proceeding will remove the currently associated vehicle from the portal!',
          },
        )}
      </span>

      <span className="mt-1 flex">
        <span>{vehicle_details_text}: </span>

        <span className="flex font-bold items-center ml-1">
          {`${make} ${model} ${year} ${fuelType} ${licencePlate}`}

          <Link target="_blank" className="ml-0.5" to={`/${currentFleetId}/vehicles/${vehicleId}`}>
            <IonIcon className="text-lg ml-1" name="eyeOutline" />
          </Link>
        </span>
      </span>

      {driver && creationMode && (
        <div className="mt-1 flex flex-col">
          <span className="mt-1">
            {tSafe(
              'components.Vehicle.VehicleList.VehicleListToolbar.ExistingVehicleDetails.also-driver-linked-to-vehicle',
              {
                defaultValue:
                  'There is also a driver linked to the vehicle that is currently associated with this device:',
              },
            )}

            <span className="flex ml-1 font-bold items-center">
              {`${driver.user.name} ${driver.user.phoneNumber}`}

              <Link target="_blank" className="ml-0.5" to={`/${currentFleetId}/drivers/${driver.id}`}>
                <IonIcon className="text-lg ml-1" name="eyeOutline" />
              </Link>
            </span>
          </span>

          <span className="mt-1">
            {tSafe('components.Vehicle.VehicleList.VehicleListToolbar.ExistingVehicleDetails.check-box-link-driver', {
              defaultValue: 'Check this box if you would also like to link this driver to the new vehicle:',
            })}

            <input
              className="ml-1 align-middle"
              type="checkbox"
              placeholder={yes_text}
              defaultChecked={false}
              onChange={(e) => onChangeDriverCheckbox?.(e.target.checked)}
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default ExistingVehicleDetails;
