import { useRecoilValue } from 'recoil';

import { Geofence } from 'generated/graphql';
import { cx } from 'utils';

import { selectedGeofenceState } from '../../state';

interface GeofenceCardProps {
  geofence: Geofence;
  onClick?: (geofence: Geofence) => void;
}

export const GeofenceCard = ({ geofence, onClick }: GeofenceCardProps) => {
  const selectedGeofence = useRecoilValue(selectedGeofenceState);

  return (
    <div
      className="flex flex-row mt-0.5 mr-2 p-0.5 text-md bg-white rounded-4 cursor-pointer ui-button"
      onClick={() => onClick?.(geofence)}
    >
      <div className="w-4">
        <span
          className="w-2 h-2 text-sm font-normal rounded-[50%] flex-center"
          style={{ backgroundColor: geofence.color }}
        ></span>
      </div>

      <div className={cx(selectedGeofence?.id === geofence.id ? 'font-bold' : 'font-normal')}>{geofence.name}</div>
    </div>
  );
};
