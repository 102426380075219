import { CellContext } from '@tanstack/react-table';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import CountWithDetails from 'atoms/react-table/CountWithDetails';
import Tooltip from 'atoms/Tooltip';
import { dashboardLightsIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import { DashboardLightEvent, DashboardLightMessageType } from 'generated/graphql';
import { AggregateListRow } from 'types/settings';

const DashboardLights = ({ cell }: { cell: CellContext<AggregateListRow, string> }) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      domain: {
        vehicleDetails: {
          fields: { activeDashboardLights_text },
        },
      },
      enums: { dashboardLightMessageTypeDescriptionMap },
    },
  } = i18nContext;

  const activeDLs = cell.getValue<DashboardLightEvent[]>();

  return (
    <div className="flex-center">
      <CountWithDetails label={activeDashboardLights_text} count={activeDLs?.length}>
        <div className="flex gap-2 items-center text-error">
          {activeDLs?.map((light) => (
            <div className="gap-0.5 flex-center" key={light.messageType}>
              {dashboardLightMessageTypeDescriptionMap[light.messageType as DashboardLightMessageType]}

              {dashboardLightsIconMap[light.messageType as DashboardLightMessageType] && (
                <Icon
                  key={light.messageType}
                  name={dashboardLightsIconMap[light.messageType as DashboardLightMessageType]}
                  className="!w-4"
                />
              )}
            </div>
          ))}
        </div>
      </CountWithDetails>
    </div>
  );
};

export default DashboardLights;
