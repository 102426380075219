import { ReactNode } from 'react';

import { ClassName } from 'types';
import { cx } from 'utils';

interface SettingGroupProps {
  children: ReactNode;
  title: string;
}

const SettingGroup = ({ title, children, className }: SettingGroupProps & ClassName) => (
  <div className={cx('p-2 border border-dark-gray rounded-4', className)}>
    <h3 className="text-lg font-bold text-dark-gray mb-2 capitalize">{title}</h3>

    {children}
  </div>
);

export default SettingGroup;
