import L from 'leaflet';

import { ClassName } from 'types';

interface VehicleMarkerProps extends ClassName {
  driverName?: string;
  licencePlate?: string;
  tripEnded: boolean;
  isSelected: boolean;
}

export const VehicleMarker = ({ driverName, licencePlate, tripEnded, isSelected, className }: VehicleMarkerProps) => {
  const circleColor = tripEnded ? '#a1a1a1' : '#39B54A';
  return L.divIcon({
    html: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
							viewBox="0 0 198.67 150" style="enable-background:new 0 0 198.67 150;" xml:space="preserve">
	
							<style type="text/css">
							.st0{fill:#EFF3F5;stroke:#666666;stroke-miterlimit:10;}
							.st1{fill:${circleColor};}
							.st2{fill:#666666;}
							.st3{font-family:'Arial';text-align: left;}
							.st4{font-size:18px;}

              #bubble:hover path {
								fill: #ffb800 !important;
							}
							@keyframes blink { 50% { fill: transparent }}
									.dot { 
										animation: 1s blink infinite;
										fill: grey;
									}
									.dot:nth-child(2) { animation-delay: 250ms }
									.dot:nth-child(3) { animation-delay: 500ms }
									.loader {
										width: 40px;
										display: block;
										margin: 0 auto;
										color: grey;
									}
							</style>
	
							<g id="bubble">
								<path class="st0" style="fill:${
                  isSelected ? '#fff' : '#ffcc00'
                }" d="M0.12,28.47C0.12,12.8,12.8,0.13,28.47,0.12c0,0,141.73,0,141.73,0c15.66,0,28.34,12.68,28.34,28.35
									s-12.68,28.35-28.35,28.35h-54.74L99.33,90.18L83.21,56.82c0,0-54.74,0-54.74,0C12.8,56.82,0.13,44.14,0.12,28.47z"/>
							</g>
	
							<g id="statusIcon">
								<circle class="st1" cx="25" cy="28.46" r="18" style="fill:${circleColor}"/>
							</g>

							<g id="driverName">
								<text transform="matrix(1 0 0 1 48 22)" class="st2 st3 st4" style="font-weight:${
                  isSelected ? 'bold' : 'normal'
                }; pointer-events:none">${driverName ?? 'Unassigned'}</text>
							</g>

							<g id="registrationNumber">
								<text transform="matrix(1 0 0 1 48 45)" class="st2 st3 st4" style="font-weight:${
                  isSelected ? 'bold' : 'normal'
                }; pointer-events:none">${licencePlate ?? ''}</text>
							</g>
						</svg>
	`,
    className,
    iconSize: [120, 100],
    iconAnchor: [60, 55],
  });
};
