import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { IconName } from 'atoms/Icon/icons';
import ProgressBar, { ProgressBarUnitMap, ProgressBarUnitType } from 'atoms/ProgressBar';
import { I18nContext } from 'common/useT';
import { cx } from 'utils';

import { Threshold } from '.';

const warningColorMap: Record<WarningLevel, string> = {
  OK: 'bg-success',
  CAUTION: 'bg-navbar',
  WARNING: 'bg-error',
};

export const thresholdMap: Record<string, Threshold> = {
  fuel: {
    midThreshold: 15,
    highThreshold: 30,
  },
  adblue: {
    midThreshold: 25,
    highThreshold: 75,
  },
  oilLife: {
    midThreshold: 25,
    highThreshold: 75,
  },
  dpfSootlevel: {
    descending: true,
    midThreshold: 40,
    highThreshold: 70,
  },
  coolantTemp: {
    midThreshold: 15,
    highThreshold: 30,
  },
};

export enum WarningLevel {
  OK = 'OK',
  CAUTION = 'CAUTION',
  WARNING = 'WARNING',
}

export const getWarningLevel = (value: string | number, thresholds: Threshold) => {
  if (value > thresholds?.midThreshold && value <= thresholds?.highThreshold) {
    return WarningLevel.CAUTION;
  }

  if (
    (thresholds.descending && value >= thresholds?.midThreshold) ||
    (!thresholds.descending && value <= thresholds?.midThreshold)
  ) {
    return WarningLevel.WARNING;
  }

  return WarningLevel.OK;
};

export interface LevelRowProps {
  title: string;
  disabled?: boolean;
  icon: IconName | string;
  thresholdMapKey: string;
  value?: string | number;
  unit?: ProgressBarUnitType;
}

export const LevelRow = ({
  title,
  disabled,
  icon,
  thresholdMapKey,
  value,
  unit = ProgressBarUnitType.Percentage,
}: LevelRowProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { unavailable_text },
    },
  } = i18nContext;

  return (
    <div className={cx('p-1 bg-white', disabled && 'text-gray-300')}>
      <div className="flex mb-0.5">
        <Icon className="max-w-3 mr-1" name={icon as IconName} />

        {title}

        <span className="ml-2">{value ? `${value}${ProgressBarUnitMap[unit]}` : unavailable_text}</span>
      </div>

      <ProgressBar
        value={Number(value)}
        color={warningColorMap[getWarningLevel(value!, thresholdMap[thresholdMapKey])]}
      />
    </div>
  );
};
