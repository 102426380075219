import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import { useLazyQuery } from '@apollo/client';
import L from 'leaflet';
import { TileLayer, Polyline, ZoomControl } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useRecoilValue } from 'recoil';

import Geofence from 'components/Live/Geofence';
import { selectedVehicleState } from 'components/Live/state';
import { VehicleMarkers } from 'components/Live/VehicleMarkers';
import { GetTripsByVehicleDoc, VehicleLastKnownLocation } from 'generated/graphql';
import { useUniqueKey } from 'utils';

interface MapProps {
  vehicles: VehicleLastKnownLocation[];
}

const Map = ({ vehicles }: MapProps) => {
  const selectedVehicle = useRecoilValue(selectedVehicleState);
  const clusterKey = useUniqueKey([vehicles]);
  const polyLineKey = useUniqueKey([vehicles]);

  const [loadTrips, { data: tripData, stopPolling: stopPollingTripsData }] = useLazyQuery(GetTripsByVehicleDoc, {
    pollInterval: 60 * 1000,
  });

  const polyLinePoints: L.LatLng[] =
    selectedVehicle && tripData?.vehicleTrips?.[0]?.tripEnded === false && tripData.vehicleTrips[0].routeGeometry
      ? tripData.vehicleTrips[0].routeGeometry.map((latLng) => L.latLng(latLng.lat, latLng.lng))
      : [];

  return (
    <>
      <ZoomControl position="bottomleft" />

      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <Geofence />

      <MarkerClusterGroup chunkedLoading key={clusterKey}>
        <VehicleMarkers vehicles={vehicles} loadTrips={loadTrips} stopPollingTripsData={stopPollingTripsData} />
      </MarkerClusterGroup>

      <Polyline key={polyLineKey} positions={[polyLinePoints]} color="blue" weight={5} />
    </>
  );
};

export default Map;
