import { useContext } from 'react';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { I18nContext } from 'common/useT';
import { Maybe, UserLocale } from 'generated/graphql';
import { SelectOption } from 'types';
import { values } from 'utils';

const LocaleDropdown = ({
  currentValue,
  onSelect,
  className = '!w-full text-lg',
}: {
  currentValue?: Maybe<UserLocale>;
  onSelect: (item: SelectOption) => void;
  className?: string;
}) => {
  const i18nContext = useContext(I18nContext);
  const {
    commonTranslations: {
      enums: { userLocaleDescriptionMap },
    },
  } = i18nContext ?? { commonTranslations: { enums: {} } };

  const localeOptions = values(UserLocale).map((x) => ({
    label: userLocaleDescriptionMap ? userLocaleDescriptionMap[x] : '',
    value: x,
  }));
  const { getProps } = useDropdownSelect(localeOptions, {
    initialItem: localeOptions.find((option) => option.value === currentValue)!,
    onSelect,
  });

  return <DropdownSelect {...getProps()} className={className} />;
};

export default LocaleDropdown;
