import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
  ColumnFiltersState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';

import useColumnUniqueValues from 'atoms/react-table/tableFilters/useColumnUniqueValues';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { userListState } from 'components/User/UserList/state';
import { useUserListColumns, userListUniqueValueColumns } from 'components/User/UserList/useUserListColumns';
import { GetUsersDoc, ListSortDirection, UserListColumnId } from 'generated/graphql';
import { entries, withTitle } from 'utils';
import { useQ } from 'utils/apolloClient';
import FullPageTable from 'atoms/react-table/FullPageTable';
import ResetUrlParamsToolbarItem from 'components/Toolbar/ResetUrlParamsToolbarItem';
import { maxItemsPerPage } from 'components/List/utils';

type UserListSortFieldInput = {
  field: UserListColumnId;
  direction?: ListSortDirection;
};

const UserList = () => {
  const history = useHistory();
  const [sortBy, setSortBy] = useState<UserListSortFieldInput | null>(null);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: maxItemsPerPage,
  });
  const { data, loading } = useQ(GetUsersDoc, {
    variables: { fleetId: useCurrentFleetId() },
    fetchPolicy: 'cache-first',
  });
  const setUserListState = useSetRecoilState(userListState);

  const urlItems = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const users = useMemo(() => data ?? [], [data]);
  const uniqueValues = useColumnUniqueValues<typeof users[0], UserListColumnId>(users, userListUniqueValueColumns);

  const table = useReactTable({
    data: users.map((x) => ({
      ...x,
      fleets: x.fleets.map((y) => y.name),
      actions: x,
    })),
    columns: useUserListColumns(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination,
      columnFilters,
    },
  });

  useEffect(() => {
    setUserListState({
      isInEditMode: false,
      uniqueValues,
      onReset: () => {
        table.setColumnFilters([]);
      },
    });
  }, [setUserListState, table, uniqueValues]);

  useEffect(() => {
    setPagination({ ...pagination, pageIndex: 0 });
  }, [urlItems, history.location.search]);

  useEffect(() => {
    if (!urlItems) {
      setColumnFilters([]);
      return;
    }

    const params = entries(Object.fromEntries(urlItems)).map(([key, value]) => ({
      key: key.toString() as UserListColumnId,
      value: value.split(','),
    }));

    const filters = params.reduce((acc, urlParam) => {
      acc.push({ id: urlParam.key, value: urlParam.value[0] });

      return acc;
    }, [] as ColumnFiltersState);

    setColumnFilters(filters);
    setPagination({ ...pagination, pageIndex: 0 });
  }, [urlItems, history.location.search]);

  return (
    <div>
      {!loading && (
        <div className="py-1 w-full flex justify-end border-b-px border-gray-300">
          <ResetUrlParamsToolbarItem
            resetFilters={() => {
              setSortBy(null);
            }}
            table={table}
          />
        </div>
      )}

      <FullPageTable
        columnOrder={false}
        totalCount={table.getRowCount()}
        currentPage={pagination.pageIndex}
        onPageChange={(selectedPage: number) => setPagination({ pageIndex: selectedPage, pageSize: 20 })}
        toggleSortBy={(id, direction) =>
          id &&
          setSortBy({
            field: id as UserListColumnId,
            direction: direction ? direction : undefined,
          })
        }
        sortedColumn={
          sortBy
            ? {
                field: sortBy.field,
                direction: sortBy.direction ? sortBy.direction : undefined,
              }
            : null
        }
        table={table}
        loading={loading}
        useTablePagination={true}
      />
    </div>
  );
};

export default withTitle(UserList, 'User List');
