import { useContext } from 'react';

import { I18nContext } from 'common/useT';
import UserList from 'components/User/UserList';

const UserManagementPage = () => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="relative px-2">
      <h1 className="absolute top-1.5 text-md font-bold">
        {tSafe('components.User.UserManagementPage.title', { defaultValue: "Selected fleet's users" })}
      </h1>

      <div className="m-auto">
        <UserList />
      </div>
    </div>
  );
};

export default UserManagementPage;
