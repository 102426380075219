import { StaticCsvImportFields } from 'components/BulkUpload';
import { BulkUploadItem, BulkUploadStatus, FuelType } from 'generated/graphql';
import { BulkUploadRowSchema } from 'types/bulkUpload';
import { entries } from 'utils';

export const fuelTypeInToFuelTypeMap: Record<string, FuelType> = {
  diesel: FuelType.Diesel,
  petrol: FuelType.Petrol,
  hybrid: FuelType.Hybrid,
  electric: FuelType.Electric,
  benzine: FuelType.Petrol,
  'petrol/electric': FuelType.Hybrid,
  'diesel/ele': FuelType.Hybrid,
  'hyb-petrol': FuelType.Hybrid,
  'hyb-diesel': FuelType.Hybrid,
  'petrol/gas': FuelType.Petrol,
  'petrol/ele': FuelType.Hybrid,
  'petrol/alcohol': FuelType.Petrol,
  gas: FuelType.Petrol,
  cng: FuelType.Petrol,
  'petrol/cng': FuelType.Petrol,
  hydrogen: FuelType.Hybrid,
  'hydrogen/ele': FuelType.Electric,
  lpg: FuelType.Petrol,
  elektrisch: FuelType.Electric,
  waterstof: FuelType.Electric,
  'hybride benzine': FuelType.Hybrid,
  'hybride diesel': FuelType.Hybrid,
  'euro 95': FuelType.Petrol,
  'super loodvr.98': FuelType.Petrol,
  e85: FuelType.Petrol,
  'super loodh. 98': FuelType.Petrol,
  'elektrisch met lpg range extender': FuelType.Electric,
  'elektrisch met diesel range extender': FuelType.Electric,
  'elektrisch met benzine range extender': FuelType.Electric,
  ethanol: FuelType.Petrol,
  benzin: FuelType.Petrol,
  erdgas: FuelType.Petrol,
  elektro: FuelType.Electric,
  'benzin/e85': FuelType.Petrol,
  ohne: FuelType.Petrol,
  el: FuelType.Electric,
  'hybrid benzin': FuelType.Hybrid,
  'hybrid diesel': FuelType.Hybrid,
  'hybrid bensin': FuelType.Hybrid,
  bensin: FuelType.Petrol,
  gass: FuelType.Petrol,
  elettrica: FuelType.Electric,
  benzina: FuelType.Petrol,
  ibrida: FuelType.Hybrid,
  unknown: FuelType.Unknown,
};

export const staticFieldToKeyMap: Record<keyof StaticCsvImportFields | string, string> = {
  'driver ID': 'driverId',
  email: 'email',
  'fleet name': 'recordFleetId',
  'fuel type': 'fuelType',
  'licence plate': 'licencePlate',
  make: 'make',
  model: 'model',
  name: 'name',
  'phone number': 'phoneNumber',
  'serial number': 'externalId',
  'vehicle ID': 'vehicleId',
  VIN: 'vin',
  year: 'year',
};

/** @package */
export const rowToBulkUploadRecordMapper = (
  row: BulkUploadRowSchema,
  fleetNameToIdMap: Record<string, string>,
  vehicleMetaFields: {
    label: string;
    key: string;
  }[],
  driverMetaFields: {
    label: string;
    key: string;
  }[],
): BulkUploadItem => {
  let vehicleMetaFieldData: { key: string; value: string }[] = [];
  vehicleMetaFields.forEach(({ key, label }) => {
    if (row[label]) {
      vehicleMetaFieldData.push({ key, value: row[label]! });
    }
  });

  let driverMetaFieldData: { key: string; value: string }[] = [];
  driverMetaFields.forEach(({ key, label }) => {
    if (row[label]) {
      driverMetaFieldData.push({ key, value: row[label]! });
    }
  });

  return {
    vehicleId: row['vehicle ID'],
    licencePlate: row['licence plate'],
    make: row['make'],
    model: row['model'],
    vin: row['VIN'],
    year: row['year'],
    driverId: row['driver ID'],
    name: row['name'],
    email: row['email'],
    phoneNumber: row['phone number'],
    externalId: row['serial number'],
    fuelType: row['fuel type'] ? fuelTypeInToFuelTypeMap[row['fuel type'].toLowerCase()] ?? undefined : undefined,
    fleetId: row['fleet name'] ? fleetNameToIdMap[row['fleet name'].trim()] : undefined,
    vehicleMetaFields: vehicleMetaFieldData,
    driverMetaFields: driverMetaFieldData,
  };
};

/** @package */
export const requiredVehicleFields = new Set<keyof StaticCsvImportFields>([
  'licence plate',
  'make',
  'model',
  'year',
  'fuel type',
  'serial number',
]);

export const allVehicleFields = [...Array.from(requiredVehicleFields), 'VIN'];

/** @package */
export const getIsEmptyRow = (row: BulkUploadRowSchema, allMetaFields: string[]) => {
  const fields = [...entries(staticFieldToKeyMap).map(([field]) => field), ...allMetaFields];

  return fields.every((field) => !row[field]);
};

/** @package */
export const getIsIncompleteVehicle = (row: BulkUploadRowSchema, vehicleMetaFields: string[]) => {
  const fields = Array.from(requiredVehicleFields);

  const hasDefinedFields = [...fields, ...vehicleMetaFields].some((field) => row[field]);
  const hasMissingFields = fields.some((field) => !row[field]);

  return hasMissingFields && hasDefinedFields;
};

export const getIsEmptyVehicle = (row: BulkUploadRowSchema, vehicleMetaFields: string[]) => {
  const fields = [...Array.from(requiredVehicleFields), ...vehicleMetaFields];

  return fields.every((field) => !row[field]);
};

export const getMissingVehicleFields = (row: BulkUploadRowSchema) => {
  const fields = Array.from(requiredVehicleFields);
  const missingFields = fields.filter((field) => !row[field]);

  return missingFields;
};

export const requiredDriverFields = new Set<keyof StaticCsvImportFields>(['name', 'email', 'phone number']);
export const allDriverFields = [...Array.from(requiredDriverFields)];

export const getMissingDriverFields = (row: BulkUploadRowSchema) => {
  const fields = Array.from(requiredDriverFields);
  const missingFields = fields.filter((field) => !row[field]);

  return missingFields;
};

/** @package */
export const getIsIncompleteDriver = (row: BulkUploadRowSchema, driverMetaFields: string[]) => {
  const fields = Array.from(requiredDriverFields);

  const hasDefinedFields = [...fields, ...driverMetaFields].some((field) => row[field]);
  const hasMissingFields = fields.some((field) => !row[field]);

  return hasMissingFields && hasDefinedFields;
};

export const getIsEmptyDriver = (row: BulkUploadRowSchema, driverMetaFields: string[]) => {
  const fields = [...allDriverFields, ...driverMetaFields];

  return fields.every((field) => !row[field]);
};

export const getStatusToPercentageMap = (status: BulkUploadStatus | undefined): number => {
  switch (status) {
    case 'CREATED':
      return 0;
    case 'ERROR':
      return 100;
    case 'FINISHED':
      return 100;
    case 'VALIDATING_RECORDS':
      return 5;
    case 'PERSISTING_ENTITIES':
      return 30;
    case 'CALCULATING_ASSOCIATION_UPDATES':
      return 50;
    case 'PERSISTING_ASSOCIATION_UPDATES':
      return 80;
    default:
      return 0;
  }
};
