import { PropsWithChildren, useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Cell, createColumnHelper } from '@tanstack/react-table';

import { I18nContext } from 'common/useT';
import { userListState } from 'components/User/UserList/state';
import useRole from 'components/User/useRole';
import { GetUsersDoc, User, UserListColumnId, UserRole } from 'generated/graphql';
import { Result } from 'types';

import useAccessibleFleetMap from '../useAccessibleFleetMap';
import fuzzySearchFilter from 'atoms/react-table/tableFilters/fuzzySearchFilter';
import multiSelectFilter from 'atoms/react-table/tableFilters/multiSelectFilter';
import { capitalize } from 'utils';
import UserListRoleCell from './UserListRoleCell';
import UserListDriverCell from './UserListDriverCell';
import UserListActiveCell from './UserListActiveCell';
import UserListFleetCell from './UserListFleetsCell';
import UserListActionsCell from './UserListActionsCell';
import { UserListItem, UserListRow } from 'types/settings';

export const userListUniqueValueColumns: [UserListColumnId, (x: UserListItem) => string | null | undefined][] = [
  [UserListColumnId.Role, (x) => x.role],
];

export const useUserListColumns = () => {
  const i18nContext = useContext(I18nContext);
  const { uniqueValues } = useRecoilValue(userListState);
  const { isManager } = useRole();
  const accessibleFleetMap = useAccessibleFleetMap();

  const columns = useMemo(() => {
    if (i18nContext) {
      const {
        tSafe,
        commonTranslations: {
          general: { yes_text, no_text, inactive_text },
          domain: {
            user: {
              fields: { name_text, role_text, active_text, email_text },
            },
            fleet: { fleets_text },
            driver: { driver_text },
          },
        },
      } = i18nContext;

      const columnHelper = createColumnHelper<UserListRow>();

      const ArrayFilterFn = (row: any, key: string, searchValue: string) => {
        return !!row?.original[key].find((item: string) => item.toLowerCase().includes(searchValue.toLowerCase()));
      };

      const columnsDefs = [
        columnHelper.accessor<UserListColumnId, string>(UserListColumnId.Name, {
          cell: (props) => props.getValue(),
          id: UserListColumnId.Name,
          header: () => name_text,
          size: 120,
          meta: { filterComponent: () => fuzzySearchFilter(UserListColumnId.Name) },
        }),
        columnHelper.accessor<UserListColumnId, string>(UserListColumnId.Role, {
          cell: (props) => <UserListRoleCell cell={props} />,
          id: UserListColumnId.Role,
          header: () => role_text,
          size: 120,
          meta: {
            filterComponent: () =>
              multiSelectFilter(
                UserListColumnId.Role,
                [
                  {
                    label: tSafe('components.User.UserList.useUserListColumns.no-role', { defaultValue: 'No Role' }),
                    value: null,
                  },
                  ...(uniqueValues.role?.map((value) => ({
                    label: capitalize(String(value).toLocaleLowerCase()),
                    value,
                  })) ?? []),
                ],
                {
                  useTableFiltering: true,
                },
              ),
          },
        }),

        columnHelper.accessor<UserListColumnId, { id: string }>(UserListColumnId.Driver, {
          cell: (props) => <UserListDriverCell cell={props} />,
          id: UserListColumnId.Driver,
          header: () => driver_text,
          size: 120,
          meta: {
            filterComponent: () =>
              multiSelectFilter(
                UserListColumnId.Driver,
                [
                  { label: yes_text, value: 'yes' },
                  { label: no_text, value: 'no' },
                ],
                {
                  useTableFiltering: true,
                },
              ),
          },
        }),
        columnHelper.accessor<UserListColumnId, boolean>(UserListColumnId.Active, {
          cell: (props) => <UserListActiveCell cell={props} />,
          id: UserListColumnId.Active,
          header: () => active_text,
          size: 120,
          meta: {
            filterComponent: () =>
              multiSelectFilter(
                UserListColumnId.Active,
                [
                  { label: active_text, value: UserListColumnId.Active },
                  { label: inactive_text, value: 'inactive' },
                ],
                { useTableFiltering: true },
              ),
          },
        }),
        columnHelper.accessor<UserListColumnId, string>(UserListColumnId.Email, {
          cell: (props) => props.getValue(),
          id: UserListColumnId.Email,
          header: () => email_text,
          size: 120,
          meta: { filterComponent: () => fuzzySearchFilter(UserListColumnId.Email) },
        }),
        columnHelper.accessor<UserListColumnId, string>(UserListColumnId.Fleets, {
          cell: (props) => <UserListFleetCell cell={props} />,
          id: UserListColumnId.Fleets,
          header: () => fleets_text,
          size: 120,
          filterFn: ArrayFilterFn,
          meta: { filterComponent: () => fuzzySearchFilter(UserListColumnId.Fleets) },
        }),
        columnHelper.accessor<
          UserListColumnId,
          Pick<
            User,
            | 'id'
            | 'driverId'
            | UserListColumnId.Email
            | UserListColumnId.Fleets
            | UserListColumnId.Role
            | UserListColumnId.Role
          >
        >(UserListColumnId.Actions, {
          cell: (props) => <UserListActionsCell cell={props} />,
          id: 'actions',
          header: () => 'Actions',
          size: 120,
        }),
      ];

      return columnsDefs;
    } else {
      return [];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibleFleetMap, isManager, uniqueValues.role, i18nContext]);

  return columns;
};
