import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import useSearchSource, { SearchSourceComponent } from 'common/useSearchSource';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { liveSettingsState, mapState, selectedGeofenceState } from 'components/Live/state';
import { Geofence, GetFleetGeofencesDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

const SearchGeofenceItem: SearchSourceComponent = ({ id, innerHtml, onSelect }) => (
  <li
    className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
    onClick={() => onSelect?.(id)}
  >
    <IonIcon name="locateOutline" className="mr-1 text-3xl" />

    <div className="flex flex-col">
      <span className="text-md" dangerouslySetInnerHTML={{ __html: innerHtml }} />
    </div>
  </li>
);

const useGeofenceSearchSource = (searchTerm: string, onSelect: () => void) => {
  const map = useRecoilValue(mapState);
  const setSelectedGeofence = useSetRecoilState(selectedGeofenceState);
  const showAllGeofences = useRecoilValue(liveSettingsState).fleetGeofenceDisplayMode === 'all';

  const { data: geofences } = useQ(GetFleetGeofencesDoc, {
    variables: {
      fleetId: useCurrentFleetId(),
      withSubfleets: showAllGeofences,
      withParentFleets: showAllGeofences,
    },
  });

  const selectHandler = useCallback(
    (geofence: Geofence) => {
      setSelectedGeofence(geofence);

      map?.flyTo([geofence.coords.lat, geofence.coords.lng], 13, { animate: false });

      onSelect?.();
    },
    [map, onSelect, setSelectedGeofence],
  );

  return useSearchSource({
    memoizedData: geofences,
    searchKey: 'name',
    searchTerm,
    Component: SearchGeofenceItem,
    onSelect: selectHandler,
  });
};

export default useGeofenceSearchSource;
