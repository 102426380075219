import * as RSwitch from '@radix-ui/react-switch';
import { useState } from 'react';
import styled from 'styled-components';

import { ClassName } from 'types';
import { cx } from 'utils';

const Thumb = styled(RSwitch.Thumb)`
  &[data-state='checked'] {
    transform: translateX(1.9rem);
  }
`;

interface SwitchProps extends ClassName {
  id?: string;
  initialValue?: boolean;
  onChange: (state: boolean) => void;
  disabled?: boolean;
  forceChecked?: boolean;
}

const Switch = ({
  id,
  className,
  onChange,
  initialValue = false,
  disabled = false,
  forceChecked = false,
}: SwitchProps) => {
  const [isChecked, setIsChecked] = useState(initialValue);

  const bgColor =
    forceChecked || isChecked
      ? disabled
        ? `opacity-50 bg-success`
        : `bg-success`
      : disabled
      ? `bg-black/10`
      : `bg-black/50`;

  return (
    <RSwitch.Root
      id={id}
      className={cx(
        'w-[4.2rem] h-2.5 rounded-full relative hover:[box-shadow:0_0_0_0.2rem_rgb(0_0_0_/_0.2)]',
        className,
        bgColor,
      )}
      defaultChecked={isChecked}
      checked={forceChecked || isChecked}
      onCheckedChange={(state) => {
        if (!disabled) {
          setIsChecked(state);
          onChange(state);
        }
      }}
    >
      <Thumb className="block w-[2.1rem] h-[2.1rem] bg-white rounded-full shadow-card transition-transform duration-100 translate-x-[0.2rem] will-change-transform" />
    </RSwitch.Root>
  );
};

export default Switch;
