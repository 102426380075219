import BulkUploadTableCell from 'components/BulkUpload/BulkUploadTable/BulkUploadTableRow/BulkUploadTableCell';
import { RowData } from 'components/BulkUpload/BulkUploadValidatedData';

interface BulkUploadTableRowProps {
  data: RowData;
}

const BulkUploadTableRow = ({ data }: BulkUploadTableRowProps) => {
  const { cells, recordNumber, missingVehicleFields, missingDriverFields } = data;

  return (
    <tr key={recordNumber} className="h-3.5 flex w-full">
      {cells.map((cell, idx) => (
        <BulkUploadTableCell
          key={`cell_${recordNumber}_${idx}`}
          data={cell}
          recordNumber={recordNumber}
          missingFields={[...missingVehicleFields, ...missingDriverFields]}
        />
      ))}
    </tr>
  );
};

export default BulkUploadTableRow;
