import { useContext } from 'react';

import CountWithDetails from 'atoms/react-table/CountWithDetails';
import { I18nContext } from 'common/useT';
import { AggregateDtc, DtcEventMessage, DtcEventType } from 'generated/graphql';

interface ListDtcProps {
  dtcs: AggregateDtc[];
  dtcType: DtcEventType;
}

const ListDtc = ({ dtcs, dtcType }: ListDtcProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      domain: {
        vehicleDetails: {
          fields: { activeDtcs_text },
        },
      },
    },
  } = i18nContext;

  const filteredDtcs = dtcs.filter((x) => x.dtcType.toUpperCase() === dtcType);

  return (
    <div className="flex-center">
      <CountWithDetails label={activeDtcs_text} count={filteredDtcs.length}>
        <div className="flex flex-col gap-2">
          {filteredDtcs.map((dtc) => (
            <div className="flex gap-1 items-center" key={dtc.dtcType}>
              <span className="px-1 py-0.5 text-white text-md bg-error rounded-4">{dtc.code}</span>

              <span className="text-sm">{dtc.description}</span>
            </div>
          ))}
        </div>
      </CountWithDetails>
    </div>
  );
};

export default ListDtc;
