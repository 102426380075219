import { useLazyQuery, useMutation } from '@apollo/client';
import { VariablesOf } from '@graphql-typed-document-node/core';
import Papa from 'papaparse';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useRecoilState, useResetRecoilState } from 'recoil';
import * as yup from 'yup';

import Button from 'atoms/Button';
import { CollapsibleList } from 'atoms/CollapsibleList';
import { CopyToClipboardButton } from 'atoms/CopyToClipboardButton';
import IonIcon from 'atoms/IonIcon';
import { useConfirmModal } from 'atoms/Modal/ConfirmModal';
import ProgressBar from 'atoms/ProgressBar';
import { CenteredSpinner } from 'atoms/Spinner';
import Switch from 'atoms/Switch';
import { useT } from 'common/useT';
import BulkUploadValidatedData from 'components/BulkUpload/BulkUploadValidatedData';
import SampleBulkUploadData from 'components/BulkUpload/SampleBulkUploadData';
import {
  fuelTypeInToFuelTypeMap,
  getIsIncompleteDriver,
  getIsIncompleteVehicle,
  getStatusToPercentageMap,
  getIsEmptyRow,
  rowToBulkUploadRecordMapper,
} from 'components/BulkUpload/utils';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import {
  BulkUploadDoc,
  BulkUploadPreValidateDoc,
  BulkUploadProgressStatusDoc,
  BulkUploadStatus,
  FleetMetaFieldDataType,
  FleetStatsDoc,
  PreValidationErrorType,
} from 'generated/graphql';
import { cx, entries } from 'utils';
import { getError, useQ } from 'utils/apolloClient';
import { errorToast } from 'utils/toasts';

import { validUploadDataState, bulkUploadState } from './state';

const nullableString = yup
  .string()
  .nullable(true)
  .trim()
  .transform((value) => (!value ? null : value));

const requiredString = yup.string().required('This field is required!').nullable(false).trim();

const metaFieldYupDataTypeMap: Record<'required' | 'notRequired', Record<FleetMetaFieldDataType, yup.AnySchema>> = {
  required: {
    STRING: requiredString,
    NUMBER: requiredString,
    DATE: requiredString,
    BOOLEAN: yup.boolean().required(),
  },
  notRequired: {
    STRING: nullableString,
    NUMBER: nullableString,
    DATE: nullableString,
    BOOLEAN: yup.boolean().notRequired(),
  },
};

const shouldBeUuidMessage = 'This should be a valid GUID';

/** @package */
export const bulkUploadSchema = yup.object({
  'vehicle ID': nullableString.uuid(shouldBeUuidMessage),
  'licence plate': nullableString,
  make: nullableString,
  model: nullableString,
  year: nullableString.length(4),
  'fuel type': nullableString.lowercase().oneOf([...Object.keys(fuelTypeInToFuelTypeMap), null]),
  VIN: nullableString.length(17).matches(/^[a-zA-Z0-9]+$/),
  'serial number': nullableString,
  'driver ID': nullableString.uuid(shouldBeUuidMessage),
  name: nullableString,
  email: nullableString.email().lowercase(),
  'phone number': nullableString.matches(
    /^\+/,
    'Please enter a telephone number including country code beginning with + (eg +44)',
  ),
  'fleet name': requiredString,
});

/** @package */
export type StaticCsvImportFields = yup.InferType<typeof bulkUploadSchema>;

interface BulkUploadProps {
  fleetId: string;
  onCompleted?: () => void;
}

const BulkUpload = ({ fleetId }: BulkUploadProps) => {
  const {
    tSafe,
    commonTranslations: {
      errors: { error_text },
      general: { aborted_text, back_text },
      enums: { bulkUploadStatusDescriptionMap },
      domain: {
        bulkUploadResult: {
          fields: { bulkUploadId_text },
        },
      },
    },
  } = useT();
  const { refetch } = useQ(FleetStatsDoc);
  const fleetMap = useAccessibleFleetMap();
  const fleetNameToIdMap = entries(fleetMap).reduce((acc, [, maybeFleet]) => {
    if (maybeFleet) {
      acc[maybeFleet.name] = maybeFleet.id;
    }
    return acc;
  }, {} as Record<string, string>);
  const selectedFleet = fleetMap[fleetId];

  const memoizedVehicleMetaFields = useMemo(() => {
    const vehicleMetaFields = selectedFleet?.metaFields.vehicle
      ? selectedFleet.metaFields.vehicle?.map((x) => ({
          label: x.displayName,
          key: x.name,
          dataType: x.dataType,
          required: x.required ?? false,
          validValues: x.validValues,
        }))
      : [];

    return vehicleMetaFields;
  }, [selectedFleet?.metaFields.vehicle]);

  const memoizedDriverMetaFields = useMemo(() => {
    const driverMetaFields = selectedFleet?.metaFields.driver
      ? selectedFleet.metaFields.driver?.map((x) => ({
          label: x.displayName,
          key: x.name,
          dataType: x.dataType,
          required: x.required ?? false,
          validValues: x.validValues,
        }))
      : [];

    return driverMetaFields;
  }, [selectedFleet?.metaFields.driver]);

  const runtimeFleetSchema = bulkUploadSchema.omit(['fleet name']).concat(
    yup.object({
      'fleet name': requiredString.oneOf(
        [...entries(fleetMap).map(([, v]) => v?.name)],
        tSafe('components.BulkUpload.validation.fleet-name', { defaultValue: 'The fleet name is not valid' }),
      ),
    }),
  );

  const runtimeSchema = [...memoizedVehicleMetaFields, ...memoizedDriverMetaFields].reduce(
    (acc, { label, dataType, required, validValues }) => {
      const yupDataType = metaFieldYupDataTypeMap[required ? 'required' : 'notRequired'][dataType];

      return acc.concat(
        yup.object({
          [label]: validValues
            ? required
              ? yupDataType.oneOf(validValues)
              : yupDataType.oneOf([...validValues, null])
            : yupDataType,
        }),
      );
    },
    runtimeFleetSchema,
  );

  type DynamicCsvImportFields = yup.InferType<typeof runtimeSchema>;

  const supportedFields = [
    'vehicle ID',
    'licence plate',
    'make',
    'model',
    'year',
    'fuel type',
    'serial number',
    'VIN',
    ...memoizedVehicleMetaFields.map((x) => x.label),
    'driver ID',
    'name',
    'email',
    'phone number',
    ...memoizedDriverMetaFields.map((x) => x.label),
    'fleet name',
  ];

  const [bulkUpload] = useMutation(BulkUploadDoc, {
    onError: (rawError) => {
      const { message } = getError(rawError) ?? {};

      errorToast(message);
    },
  });

  const handleServerError = (e: Error) => {
    errorToast(`${e}`);
    setSubmitted(false);
    setValidRows([]);
    setBulkUploadId(undefined);
  };

  const { openModal: openConfirmModal } = useConfirmModal();

  const [invalidRows, setInvalidRows] = useState<DynamicCsvImportFields[]>([]);
  const [validRows, setValidRows] = useState<DynamicCsvImportFields[]>([]);
  const [showSampleData, setShowSampleData] = useState(false);
  const [shouldSendEmails, setShouldSendEmails] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const [preValidateData, { data: preValidationQueryData, loading: preValidationLoading }] =
    useLazyQuery(BulkUploadPreValidateDoc);
  const [pollBulklUploadStatus, { stopPolling, data: bulkUploadStatus }] = useLazyQuery(BulkUploadProgressStatusDoc, {
    pollInterval: 4000,
    onError: (e) => handleServerError(e),
  });
  const [validUploadData, setValidUploadData] = useRecoilState(validUploadDataState);
  const [bulkUploadId, setBulkUploadId] = useRecoilState(bulkUploadState);
  const resetData = useResetRecoilState(validUploadDataState);

  useEffect(() => {
    if (!submitted && bulkUploadId) {
      pollBulklUploadStatus({ variables: { bulkUploadId } });
    }
  }, [submitted, bulkUploadId, openConfirmModal, setValidUploadData, pollBulklUploadStatus]);

  const onDrop = useCallback(
    ([file]: Blob[]) => {
      if (!file) return;
      const reader = new FileReader();

      reader.onabort = () => errorToast(aborted_text);
      reader.onerror = () => errorToast(error_text);

      reader.onload = async () => {
        const dataAsString = reader.result?.toString().trim();

        if (!dataAsString) return;

        const { data } = Papa.parse<DynamicCsvImportFields>(dataAsString, { header: true });

        const invalidRows: DynamicCsvImportFields[] = [];
        const validRows: DynamicCsvImportFields[] = [];

        const driverMetaFieldLabels = memoizedDriverMetaFields.map((x) => x.label);
        const vehicleMetaFieldLabels = memoizedVehicleMetaFields.map((x) => x.label);

        await Promise.all(
          data.map(async (row) => {
            if (
              (await runtimeSchema.isValid(row)) &&
              !getIsEmptyRow(row, [...driverMetaFieldLabels, ...vehicleMetaFieldLabels]) &&
              !getIsIncompleteDriver(row, driverMetaFieldLabels) &&
              !getIsIncompleteVehicle(row, vehicleMetaFieldLabels)
            ) {
              validRows.push(await runtimeSchema.validate(row, { stripUnknown: true }));
            } else {
              invalidRows.push(row);
            }
          }),
        );

        setInvalidRows(invalidRows);
        setValidRows(validRows);

        if (!invalidRows.length) {
          const validData = {
            isValid: true,
            variables: {
              fleetId,
              data: validRows.map((row) =>
                rowToBulkUploadRecordMapper(row, fleetNameToIdMap, memoizedVehicleMetaFields, memoizedDriverMetaFields),
              ),
            },
          };

          setValidUploadData(validData);
          preValidateData(validData);
        } else {
          resetData();
        }
      };

      reader.readAsText(file);
    },
    [
      aborted_text,
      error_text,
      memoizedDriverMetaFields,
      memoizedVehicleMetaFields,
      runtimeSchema,
      fleetId,
      setValidUploadData,
      preValidateData,
      fleetNameToIdMap,
      resetData,
    ],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: ['.csv', 'text/csv'], onDrop });

  const variables: VariablesOf<typeof BulkUploadDoc> = {
    fleetId,
    data: validRows.map((row) =>
      rowToBulkUploadRecordMapper(row, fleetNameToIdMap, memoizedVehicleMetaFields, memoizedDriverMetaFields),
    ),
    skipLoginEmail: !shouldSendEmails,
  };

  const onSubmit = async () => {
    setSubmitted(true);
    bulkUpload({ variables })
      .then(async ({ data: result }) => {
        if (result) {
          const {
            bulkUpload: { bulkUploadId },
          } = result;
          setBulkUploadId(bulkUploadId);
          pollBulklUploadStatus({ variables: { bulkUploadId } });
        }
      })
      .catch((err) => alert(err));
  };

  const downloadTemplate = () => {
    const uri = encodeURI('data:text/csv;charset=utf-8,' + supportedFields.join(','));

    const el = document.createElement('a');
    el.href = uri;
    el.download = 'bulk upload template.csv';
    document.body.appendChild(el);

    el.click();

    el.remove();
  };

  const {
    id,
    status,
    totalRowsInFile,
    createdVehicleIds,
    updatedVehicleIds,
    createdDriverIds,
    updatedDriverIds,
    updatedAssociationDeviceIds,
    finishedAt,
  } = bulkUploadStatus?.bulkUploadProgressStatus || { status: undefined };

  const uploadFinished = status === BulkUploadStatus.Finished || status === BulkUploadStatus.Error;

  if (uploadFinished) {
    refetch();
    stopPolling();
  }

  const hasErrors = status === BulkUploadStatus.Error;

  const allUpdatedRecordIds: {
    idArray: string[] | undefined;
    text: string;
  }[] = [
    {
      idArray: createdDriverIds,
      text: tSafe('components.BulkUpload.results.created-drivers', { defaultValue: 'Created drivers' }),
    },
    {
      idArray: updatedDriverIds,
      text: tSafe('components.BulkUpload.results.updated-drivers', { defaultValue: 'Updated drivers' }),
    },
    {
      idArray: createdVehicleIds,
      text: tSafe('components.BulkUpload.results.created-vehicles', { defaultValue: 'Created vehicles' }),
    },
    {
      idArray: updatedVehicleIds,
      text: tSafe('components.BulkUpload.results.updated-vehicles', { defaultValue: 'Updated vehicles' }),
    },
    {
      idArray: updatedAssociationDeviceIds,
      text: tSafe('components.BulkUpload.results.updated-relationships', {
        defaultValue: 'Updated device relationship(s)',
      }),
    },
  ];

  if (viewReport) {
    return (
      <div className="w-80 min-h-30 max-h-60 overflow-y-scroll">
        <div className="">
          <div className="text-center text-2xl">
            {tSafe('components.BulkUpload.bulk-upload-report', { defaultValue: 'Bulk Upload Report' })}
          </div>

          {bulkUploadId && (
            <div className="flex-center text-sm py-0.5">
              <div className="align-middle text-right mx-1 text-gray-400">
                {tSafe('components.BulkUpload.upload-id', { defaultValue: 'Upload ID:' })}{' '}
              </div>

              <div className="flex text-gray-400 p-0.5 rounded-4 border-px border-gray-100">
                {id}

                <CopyToClipboardButton value={id!} className={'mx-1'} />
              </div>
            </div>
          )}
        </div>

        <div className="text-center space-y-1 mt-1">
          <div>
            <span>Status: </span>

            <span className={cx('font-semibold', uploadFinished && hasErrors ? 'text-error' : 'text-success')}>
              {status ? bulkUploadStatusDescriptionMap[status] : 'Contacting server...'}
            </span>
          </div>

          <div>
            <span>{tSafe('components.BulkUpload.finished-at', { defaultValue: 'Finished at: ' })}</span>

            <span>{new Date(Date.parse(finishedAt)).toLocaleString()}</span>
          </div>

          <div>
            {tSafe('components.BulkUpload.processed-count-records', {
              defaultValue: 'Processed {{count}} records',
              count: totalRowsInFile,
            })}
          </div>
        </div>

        {hasErrors && (
          <div className="text-error my-1 p-1 flex-row justify-between rounded-4 border-px border-error">
            <p className="py-0.5">
              {tSafe('components.BulkUpload.there-were-errors', {
                defaultValue:
                  'There were some errors during upload, some records may not have updated correctly. Please contact support referencing the upload ID above. Please see below for the driver / vehicle IDs affected.',
              })}
            </p>
          </div>
        )}

        {allUpdatedRecordIds.map(({ idArray, text }) =>
          idArray?.length ? (
            <CollapsibleList title={<div>{text}</div>} items={idArray} uniqueKey={text.replace(' ', '')} />
          ) : null,
        )}

        <div className="flex-center w-full bg-white mt-1 left-0 bottom-0">
          <Button className="max-w-20 text-md focus:outline-none active:translate-x-px active:translate-y-px disabled:opacity-50 disabled:cursor-auto p-1 border border-amber rounded-8 hover:bg-amber/20">
            <div onClick={() => setViewReport(false)}>{back_text}</div>
          </Button>
        </div>
      </div>
    );
  }

  if (bulkUploadId || (submitted && !invalidRows.length)) {
    return (
      <div className="flex-center w-80">
        <div className="h-auto w-full text-center">
          <div className={cx('text-2xl', uploadFinished && (hasErrors ? 'text-error' : 'text-success'))}>
            {bulkUploadId && status && bulkUploadStatusDescriptionMap[status]}

            {hasErrors && (
              <div className="text-center text-error text-sm font-normal">
                {tSafe('components.BulkUpload.there-was-an-error', {
                  defaultValue: 'There was an error.',
                })}
                &nbsp;
                {uploadFinished
                  ? tSafe('components.BulkUpload.please-see-below', {
                      defaultValue: 'Please see below for for information',
                    })
                  : tSafe('components.BulkUpload.upload-still-in-progress', {
                      defaultValue: 'Upload still in progress.',
                    })}
              </div>
            )}

            {bulkUploadId && (
              <div className="flex-center text-sm py-0.5">
                <div className="align-middle text-right mx-1 text-gray-400">{bulkUploadId_text}</div>

                <div className="flex text-gray-400 p-0.5 rounded-4 border-px border-gray-100">
                  {id}

                  <CopyToClipboardButton value={id!} className={'mx-1'} />
                </div>
              </div>
            )}
          </div>

          {submitted && bulkUploadId && status ? (
            <div className="">
              <div className="my-1 space-y-1">
                <div className="w-full text-center">
                  {tSafe('components.BulkUpload.processing-count-records', {
                    count: totalRowsInFile,
                    defaultValue: 'Processing {{count}} records.',
                  })}
                </div>

                <ProgressBar color={hasErrors ? 'bg-error' : 'bg-success'} value={getStatusToPercentageMap(status)} />
              </div>
            </div>
          ) : (
            <CenteredSpinner className="my-1" />
          )}

          {hasErrors && (
            <div className="text-error">
              {tSafe('components.BulkUpload.has-errors', {
                defaultValue: 'Has Errors',
              })}
            </div>
          )}

          {submitted && bulkUploadId && uploadFinished && (
            <div className="flex-center mt-2 space-x-3">
              <Button
                onClick={() => setViewReport(true)}
                className="max-w-20 text-md focus:outline-none active:translate-x-px active:translate-y-px disabled:opacity-50 disabled:cursor-auto p-1 border border-amber rounded-8 hover:bg-amber/20"
              >
                {tSafe('components.BulkUpload.view-full-report', {
                  defaultValue: 'View full report',
                })}
              </Button>

              <Button
                onClick={() => {
                  setValidRows([]);
                  setSubmitted(false);
                  resetData();
                  if (bulkUploadId) setBulkUploadId(undefined);
                }}
                className="max-w-20 text-md focus:outline-none active:translate-x-px active:translate-y-px disabled:opacity-50 disabled:cursor-auto p-1 border border-amber rounded-8 hover:bg-amber/20"
              >
                {tSafe('components.BulkUpload.new-bulk-upload', {
                  defaultValue: 'New bulk upload',
                })}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  const { bulkUploadPreValidate: preValidationResult } = preValidationQueryData ?? { length: 0 };
  const hasServerSideValidationErrors = !!preValidationResult?.filter(
    (x) => x.type !== PreValidationErrorType.DisassociationWarning,
  ).length;

  return (
    <div className="relative">
      <div className="flex flex-col p-1 items-start gap-1">
        <div className="w-full align-top min-w-60 flex justify-between">
          <div className="w-40 flex-0">
            <h1 className="text-lg">
              <span>
                {tSafe('components.BulkUpload.bulk-upload-drivers-and-vehicles-to', {
                  defaultValue: 'Bulk Upload vehicles and drivers to ',
                })}
              </span>

              <span className="font-semibold">{fleetMap[fleetId]?.name}</span>
            </h1>

            <div className="my-1">
              <Button onClick={() => setShowSampleData(!showSampleData)}>
                {showSampleData
                  ? tSafe('components.BulkUpload.hide-sample-data', {
                      defaultValue: 'Hide Sample Data',
                    })
                  : tSafe('components.BulkUpload.view-sample-data', {
                      defaultValue: 'View Sample Data',
                    })}
              </Button>
            </div>
          </div>

          {preValidationLoading ? (
            <div className="w-full text-2xl text-center">
              {tSafe('components.BulkUpload.validating-csv-file-ellipsis', {
                defaultValue: 'Validating CSV file...',
              })}
            </div>
          ) : (
            <div
              {...getRootProps()}
              className={cx(
                'self-center flex-center flex-col w-30 py-3 border-2 rounded-8 border-dashed text-md',
                isDragActive && 'border-success',
              )}
            >
              <input {...getInputProps()} />

              <div className="min-h-4">
                {tSafe('components.BulkUpload.drop-csv-file', {
                  defaultValue: 'Drop CSV file with vehicle/driver data',
                })}
              </div>
            </div>
          )}

          <div className="w-40 flex flex-row justify-end h-auto py-2">
            <Button onClick={downloadTemplate} className="flex-center self-start gap-0.5 px-1 py-1 border rounded-4">
              <IonIcon name="downloadOutline" className="text-lg" />

              {tSafe('components.BulkUpload.download-csv-template', {
                defaultValue: 'Download CSV template',
              })}
            </Button>
          </div>
        </div>

        <div className={cx('overflow-x-auto', !showSampleData && 'hidden')}>
          <SampleBulkUploadData supportedFields={supportedFields} className="my-1" />
        </div>
      </div>

      {!!(invalidRows.length || validRows.length) && (
        <BulkUploadValidatedData
          supportedFields={supportedFields}
          invalidRows={invalidRows}
          validRows={invalidRows.length ? [] : validRows}
          validationErrors={!invalidRows.length ? preValidationResult : []}
          preValidationLoading={preValidationLoading}
          runtimeSchema={runtimeSchema}
          memoizedVehicleMetaFields={memoizedVehicleMetaFields}
          memoizedDriverMetaFields={memoizedDriverMetaFields}
        />
      )}

      {!preValidationLoading && validUploadData.isValid && (
        <>
          {!invalidRows.length && !hasServerSideValidationErrors && preValidationResult && (
            <div className="self-center m-2 space-y-1">
              <div className="flex-center gap-1">
                <Button
                  className="p-1 text-lg border rounded-8 hover:bg-success"
                  onClick={(e) => {
                    e.stopPropagation();
                    onSubmit();
                  }}
                >
                  {tSafe('components.BulkUpload.upload-to-fleet-name', {
                    fleetName: fleetMap[fleetId]?.name,
                    defaultValue: 'Upload to {{fleetName}}',
                  })}
                </Button>

                <label htmlFor="s1">
                  {tSafe('components.BulkUpload.send-password-emails', {
                    defaultValue: 'Send password emails',
                  })}
                </label>

                <Switch
                  id="s1"
                  initialValue={shouldSendEmails}
                  onChange={() => setShouldSendEmails(!shouldSendEmails)}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BulkUpload;
