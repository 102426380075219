import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import useSearchSource, { SearchSourceComponent } from 'common/useSearchSource';
import { selectedGeofenceState } from 'components/Live/state';
import useUser from 'components/User/useUser';
import { AssociateGeofenceWithVehicleDoc, GetVehicleLastKnownLocationsDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

const SearchVehicleItem: SearchSourceComponent = ({ id, innerHtml, onSelect }) => (
  <li
    className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
    onClick={() => onSelect?.(id)}
  >
    <IonIcon name="carSportOutline" className="mr-1 text-3xl" />

    <div className="flex flex-col">
      <span className="text-md" dangerouslySetInnerHTML={{ __html: innerHtml }} />
    </div>
  </li>
);

const useVehicleSearchSource = (searchTerm: string, onSelect: () => void) => {
  const [selectedGeofence, setSelectedGeofence] = useRecoilState(selectedGeofenceState);
  const [associateWithVehicle] = useMutation(AssociateGeofenceWithVehicleDoc);
  const { accessibleFleets } = useUser();
  const { data: locations } = useQ(GetVehicleLastKnownLocationsDoc, {
    skip: !accessibleFleets,
    variables: { fleetIds: accessibleFleets?.map(({ id }) => id) ?? '' },
  });

  const vehicles = useMemo(
    () =>
      locations
        ?.map(({ vehicleId, licencePlate, driverName }) => ({
          id: vehicleId,
          licencePlate: licencePlate ?? '',
          driverName: driverName,
        }))
        .filter((vehicle) => !selectedGeofence!.vehicleIds.includes(vehicle.id)),
    [locations, selectedGeofence],
  );

  const selectHandler = useCallback(
    (vehicle: NonNullable<typeof vehicles>[0]) => {
      if (!selectedGeofence?.id) return;

      const updatedSelectedGeofence: typeof selectedGeofence = {
        ...selectedGeofence,
        vehicleIds: [...selectedGeofence.vehicleIds, vehicle.id],
      };

      setSelectedGeofence(updatedSelectedGeofence);

      associateWithVehicle({
        variables: { geofenceId: selectedGeofence.id!, vehicleId: vehicle.id },
        optimisticResponse: {
          associateGeofenceWithVehicle: { __typename: 'Geofence', id: selectedGeofence.id, ...updatedSelectedGeofence },
        },
      });

      onSelect?.();
    },
    [associateWithVehicle, onSelect, selectedGeofence, setSelectedGeofence],
  );

  return useSearchSource({
    memoizedData: vehicles,
    searchKey: 'licencePlate',
    searchTerm,
    Component: SearchVehicleItem,
    onSelect: selectHandler,
  });
};

export default useVehicleSearchSource;
