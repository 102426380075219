import { VariablesOf } from '@graphql-typed-document-node/core';
import { yupResolver } from '@hookform/resolvers/yup';
import produce from 'immer';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from 'atoms/Button';
import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { useModalContext } from 'atoms/Modal';
import { fakeEmailPlaceholder, fakePhoneNumberPlaceholder } from 'common/constants';
import { I18nContext } from 'common/useT';
import MultipleFleetSelectorWithSearch from 'components/User/UserList/MultipleFleetSelectorWithSearch';
import useRole from 'components/User/useRole';
import { CreateUserDoc, UserRoleInput } from 'generated/graphql';
import { KeysWithValueOfType, SelectOption } from 'types';
import { values } from 'utils';

export type UserEditFormData = Pick<
  VariablesOf<typeof CreateUserDoc>,
  'name' | 'phoneNumber' | 'email' | 'active' | 'isDriver' | 'role'
> & { sendPasswordEmail?: boolean; fleetIds: string[] };

interface UserEditFormProps {
  initialState?: UserEditFormData;
  onSubmit: (user: UserEditFormData, dirtyFields: { [key in keyof UserEditFormData]?: boolean | boolean[] }) => void;
  creationMode?: boolean;
}

const UserEditForm = ({ initialState, onSubmit: onSubmitCallback, creationMode = false }: UserEditFormProps) => {
  const i18nContext = useContext(I18nContext);

  const stringFields: {
    key: KeysWithValueOfType<UserEditFormData, string | undefined | null>;
    label: string;
    required?: boolean;
    placeholder?: string;
  }[] = i18nContext
    ? [
        {
          key: 'email',
          label: i18nContext.commonTranslations.domain.user.fields.email_text,
          required: true,
          placeholder: fakeEmailPlaceholder,
        },
        { key: 'name', label: i18nContext.commonTranslations.domain.user.fields.name_text, required: true },
        {
          key: 'phoneNumber',
          label: i18nContext.commonTranslations.domain.user.fields.phoneNumber_text,
          required: true,
          placeholder: fakePhoneNumberPlaceholder,
        },
      ]
    : [];

  const schema = yup
    .object()
    .shape({
      name: yup.string().required(),
      phoneNumber: yup
        .string()
        .matches(
          /^\+/,
          i18nContext?.tSafe('components.User.UserList.UserEditForm.validation.telephone', {
            defaultValue: 'Please enter a telephone number including country code beginning with + (eg +44)',
          }),
        )
        .required(),
      email: yup.string().email().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { dirtyFields, errors },
  } = useForm<UserEditFormData>({
    defaultValues: {
      role: initialState?.role ?? undefined,
      active: initialState?.active ?? true,
      isDriver: initialState?.isDriver ?? false,
      fleetIds: initialState?.fleetIds ?? [],
      sendPasswordEmail: true,
    },
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });
  const [hasDriverRoleNoDriverIdError, setHasDriverRoleNoDriverIdError] = useState(false);
  const modalContext = useModalContext();
  const { isSuperAdmin } = useRole();

  const roles = i18nContext
    ? [
        { label: '', value: undefined },
        ...values(UserRoleInput).map((inputRole) => ({
          label: i18nContext.commonTranslations.enums.userRoleInputDescriptionMap[inputRole],
          value: inputRole,
        })),
      ]
    : [];

  const { getProps } = useDropdownSelect(
    isSuperAdmin ? roles : roles.filter((x) => x.value !== UserRoleInput.Superadmin),
    {
      initialItem: roles.find((type) => type.value === watch('role'))!,
      onSelect: (item: SelectOption<UserRoleInput | undefined>) => setValue('role', item.value, { shouldDirty: true }),
    },
  );

  const fields = produce(stringFields, (fields) => {
    if (!creationMode) {
      fields.forEach((field) => {
        field.required = false;
      });
    }
  });

  const onSubmit = (data: UserEditFormData) => {
    const { role, isDriver } = getValues();
    if (role === 'DRIVER_ONLY' && !isDriver) {
      setHasDriverRoleNoDriverIdError(true);
      return;
    } else {
      setHasDriverRoleNoDriverIdError(false);
    }

    if (Object.keys(dirtyFields).length && watch('fleetIds')?.length && !values(errors).length) {
      onSubmitCallback(data, dirtyFields);
    }
  };

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      domain: {
        user: {
          fields: { role_text, active_text, sendPasswordEmail_text, accessibleFleets_text },
        },
        driver: { driver_text },
      },
      forms: {
        required_text,
        buttons: { cancel_text, save_text },
      },
    },
  } = i18nContext;

  return (
    <div className="flex flex-col w-full rounded-8">
      {hasDriverRoleNoDriverIdError && (
        <span className="text-error">
          {tSafe('components.User.UserList.UserEditForm.validation.role-driver-checkbox', {
            defaultValue: 'If setting the role to Driver the driver checkbox must be checked',
          })}
        </span>
      )}

      <section about="User Details">
        <form onSubmit={(e) => e.preventDefault()} className="grid gap-1 grid-cols-2 items-start my-2 p-1">
          {fields.map(({ key, label, required, placeholder }) => (
            <div className="flex flex-col" key={key}>
              <label className="font-bold text-md">
                {label}

                {required ? <span className="ml-0.5 text-error font-bold">*</span> : null}

                <span className="text-error">{errors[key]?.message}</span>
              </label>

              <input
                defaultValue={initialState?.[key] ?? ''}
                placeholder={placeholder}
                className="p-0.5 w-full text-md border-px border-dark-gray rounded-4 outline-none"
                {...register(key)}
              />
            </div>
          ))}

          <div className="flex flex-col">
            <label className="font-bold text-md">{role_text}</label>

            <DropdownSelect {...getProps()} className="!w-full" innerWrapperClassName="py-1 border-dark-gray" />
          </div>

          <div className="flex items-center gap-1 mt-1">
            <label className="font-bold text-md">{active_text}</label>

            <input type="checkbox" placeholder="Active" {...register('active', {})} />
          </div>

          <div className="flex items-center mt-1">
            <div className="flex w-1/2 gap-1">
              <label htmlFor="isDriver" className="font-bold text-md">
                {driver_text}
              </label>

              <input id="isDriver" type="checkbox" placeholder="Driver" {...register('isDriver', {})} />
            </div>

            {creationMode && (
              <div className="flex w-1/2 gap-1">
                <label htmlFor="isDriver" className="font-bold text-md">
                  {sendPasswordEmail_text}
                </label>

                <input
                  id="sendPassword"
                  type="checkbox"
                  placeholder={sendPasswordEmail_text}
                  {...register('sendPasswordEmail', {})}
                />
              </div>
            )}
          </div>
        </form>

        <div className="flex flex-col gap-1 p-1">
          <label className="font-bold text-md">
            <span>{accessibleFleets_text}</span>

            {creationMode && <span className="text-error">*</span>}
          </label>

          <MultipleFleetSelectorWithSearch
            onChange={(fleetIds) => setValue('fleetIds', fleetIds, { shouldDirty: true })}
            initialValue={new Set(initialState?.fleetIds ?? [])}
            allowMultiple
          />
        </div>

        <div className="flex gap-1 justify-center my-3 w-full">
          <Button
            className="px-2 py-0.5 font-semibold border-px rounded-4 ui-button"
            onClick={() => modalContext?.closeModal?.()}
          >
            {cancel_text}
          </Button>

          {!!(!values(errors).length && !!Object.keys(dirtyFields).length) && !!watch('fleetIds')?.length && (
            <Button
              className="px-2 py-0.5 font-semibold bg-primary hover:brightness-150 text-white border-px rounded-4"
              onClick={() => handleSubmit(onSubmit)()}
            >
              {save_text}
            </Button>
          )}
        </div>

        <div className="flex justify-end -mt-2 text-sm text-gray-400">* {required_text}</div>
      </section>
    </div>
  );
};

export default UserEditForm;
