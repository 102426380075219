import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import { cx } from 'utils';

interface NavbarLinkProps {
  to: string;
  exact?: boolean;
  children: ReactElement | string;
  onClick?: () => void;
  className?: string;
}

const NavbarLink = ({ to, exact, children, onClick, className }: NavbarLinkProps) => {
  return (
    <NavLink
      exact={exact}
      activeClassName="border-solid border-black bg-navbarHover cursor-default"
      to={to}
      className={cx(
        'flex border-b-2 border-transparent px-[22px] transform-gpu h-7 items-center hover:bg-navbarHover hover:bg-opacity-10] active:translate-y-px',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};

export default NavbarLink;
