import {
  BatteryNotificationEvent,
  DashboardLightEvent,
  DtcEvent,
  VehicleDeviceDiagnosticEvent,
  VehicleGeofenceBreachEvent,
  VehicleImpactEvent,
  VehicleTripEvent,
} from 'generated/graphql';

export enum VehicleActivityEventType {
  BATTERY = 'battery',
  DASHBOARD_LIGHT = 'dashboard_light',
  DISCONNECTED = 'disconnected',
  DTC = 'dtc',
  RECONNECTED = 'reconnected',
  IMPACT = 'impact',
  TRIP = 'trip',
}

export type VehicleActivityEventItem =
  | (Partial<VehicleTripEvent> & { geofenceNotifications: Partial<VehicleGeofenceBreachEvent>[] })
  | Partial<DtcEvent>
  | Partial<DashboardLightEvent>
  | Partial<VehicleImpactEvent>
  | Partial<BatteryNotificationEvent>
  | Partial<VehicleDeviceDiagnosticEvent>;
