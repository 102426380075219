import { useSelect } from 'downshift';

import { SelectOption } from 'types';
import { cx } from 'utils';

interface ToolbarSelectProps<T> extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'> {
  items: SelectOption<T>[];
  onSelect: (item: SelectOption<T>) => void;
  selectedItem: SelectOption<T>;
  icon?: JSX.Element;
  textRight?: boolean;
  disabled?: boolean;
}

const ToolbarSelect = <T extends unknown>({
  items,
  selectedItem,
  onSelect,
  icon,
  textRight,
  className,
  disabled = false,
  ...divProps
}: ToolbarSelectProps<T>) => {
  const remainingItems = items.filter((item) => item.label !== selectedItem.label);

  const { getItemProps, getMenuProps, getToggleButtonProps, isOpen, highlightedIndex } = useSelect({
    items: remainingItems,
    onSelectedItemChange: ({ selectedItem }) => selectedItem && onSelect(selectedItem),
    selectedItem,
  });

  return (
    <div className={cx('flex relative', !disabled && 'ui-button-dark', className ?? '')} {...divProps}>
      <button
        className={cx('w-full px-1 text-sm flex-center', disabled || (!remainingItems.length && 'cursor-default'))}
        {...getToggleButtonProps()}
        disabled={disabled || !remainingItems.length}
      >
        <div className="mr-0.5 text-lg flex-center">{icon}</div>

        {selectedItem.label}
      </button>

      <ul
        className={cx(
          'absolute z-999 w-max min-w-full text-sm focus:outline-none top-[calc(100%+.5rem)]',
          textRight ? 'right-0' : 'left-0',
        )}
        {...getMenuProps()}
      >
        {isOpen && (
          <div className="flex flex-col bg-white border rounded-4 shadow overflow-hidden border-dark-gray/20">
            {remainingItems.map((item, index) => (
              <span
                className={cx(
                  'px-1 py-0.5 cursor-pointer',
                  index === highlightedIndex && 'text-white bg-hover',
                  textRight ? 'text-right' : 'text-left',
                )}
                {...getItemProps({
                  key: typeof item.value === 'string' || typeof item.value === 'number' ? item.value : index,
                  index,
                  item,
                })}
              >
                {item.label}
              </span>
            ))}
          </div>
        )}
      </ul>
    </div>
  );
};

export default ToolbarSelect;
