import { useContext } from 'react';
import { Link } from 'react-router-dom';

import Icon from 'atoms/Icon';
import IonIcon from 'atoms/IonIcon';
import Modal from 'atoms/Modal';
import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { BulkUploadPreValidateDoc, GetVehicleDetailsDoc, Period, PreValidationErrorType } from 'generated/graphql';
import { Result } from 'types';
import { isDefined } from 'utils';
import { useQ } from 'utils/apolloClient';

interface BulkUploadValidationHeaderProps {
  isValid: boolean;
  validationErrors?: Result<typeof BulkUploadPreValidateDoc>;
  invalidCellCount: number;
  invalidRowCount: number;
}

const BulkUploadValidationHeader = ({
  isValid,
  validationErrors,
  invalidCellCount,
  invalidRowCount,
}: BulkUploadValidationHeaderProps) => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();
  const disassociationWarnings = validationErrors?.filter(
    (x) => x.type === PreValidationErrorType.DisassociationWarning,
  );
  const disassociatedVehicleIds = disassociationWarnings?.map((x) => x.erroneousValues?.[1]).filter(isDefined);
  const { data: disassociatedVehicles, loading: disassociatedVehiclesLoading } = useQ(GetVehicleDetailsDoc, {
    variables: { vehicleIds: disassociatedVehicleIds ?? [], period: Period.P24h },
    skip: !disassociatedVehicleIds,
  });
  const disassociatedVehicleDetails = disassociatedVehicles?.map((x) => x.vehicle);

  if (disassociatedVehiclesLoading || !i18nContext) return <CenteredSpinner />;

  const { tSafe } = i18nContext;

  if (isValid)
    return (
      <div className="flex flex-col flex-center">
        <div className="w-4">
          <Icon name="connected" className="text-success" aria-label="Success" />
        </div>

        <span>
          {tSafe('components.BulkUpload.BulkUploadValidatedData.BulkUploadValidationHeader.all-fields.valid', {
            defaultValue: 'All fields Valid!',
          })}
        </span>

        {!!disassociatedVehicleDetails?.length && (
          <div className="flex flex-col bg-amber text-white p-0.5">
            <Modal
              trigger={
                <span>
                  {tSafe(
                    'components.BulkUpload.BulkUploadValidatedData.BulkUploadValidationHeader.vehicles-will-be-unassigned',
                    {
                      defaultValue:
                        'Warning: Vehicles will be unassigned from their devices and will disappear from the portal. They can be recovered later by re-assigning them to a device. Click here to view.',
                    },
                  )}
                </span>
              }
              title={tSafe('components.BulkUpload.BulkUploadValidatedData.BulkUploadValidationHeader.modal-title', {
                defaultValue: 'Vehicles to be unassigned from device',
              })}
              contentClassName="w-200 text-black overflow-auto"
            >
              <div className="mt-1">
                <ul>
                  {disassociatedVehicleDetails?.map((x, idx) => (
                    <li key={idx}>
                      <div className="flex">
                        <span>
                          <IonIcon name="carOutline" className="h-2" />
                        </span>

                        <span className="ml-0.5">{x.licencePlate}</span>

                        <span className="ml-0.5">{x.make}</span>

                        <span className="ml-0.5">{x.model}</span>

                        <Link
                          className="ml-0.5 underline"
                          target={'_blank'}
                          to={`/${currentFleetId}/vehicles/${x.id}`}
                          aria-label="View Vehicle"
                        >
                          {tSafe('common.domain.vehicleDetails.view-vehicle', {
                            defaultValue: 'View Vehicle',
                          })}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Modal>
          </div>
        )}
      </div>
    );

  const generalFileErrors = validationErrors?.filter((x) => !x.recordNumber);
  const text = `${invalidCellCount} invalid field${invalidCellCount > 1 ? 's' : ''} across ${invalidRowCount} row${
    invalidRowCount > 1 ? 's' : ''
  }`;

  return (
    <div>
      <IonIcon name="warningOutline" className="text-error text-6xl" />

      <div className="flex-center text-error">{text}</div>

      {!!generalFileErrors?.length && (
        <div className="flex-center bg-error text-white p-0.5">
          <Modal
            trigger={
              <span>
                {tSafe(
                  'components.BulkUpload.BulkUploadValidatedData.BulkUploadValidationHeader.count-file-errors-click-to-view',
                  {
                    defaultValue: '{{count}} file errors. Click here to view.',
                    count: generalFileErrors.length,
                  },
                )}
              </span>
            }
            title={`${generalFileErrors.length} file errors`}
            contentClassName="w-200 text-error overflow-auto"
          >
            <div className="mt-1 min-w-80">
              <ul>
                {generalFileErrors?.map((x, idx) => (
                  <li key={idx}>
                    <div className="flex">
                      <span>
                        <IonIcon name="warningOutline" className="h-2" />
                      </span>

                      <span className="ml-0.5">{x.message}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Modal>
        </div>
      )}

      {!!disassociationWarnings?.length && (
        <div className="flex flex-col bg-amber text-white p-0.5">
          <Modal
            trigger={<span>{disassociationWarnings.length} association warnings. Click here to view.</span>}
            title={`${disassociationWarnings.length} Association warnings`}
            contentClassName="w-200 text-black overflow-auto"
          >
            <div className="mt-1 min-w-80 ">
              <ul>
                {disassociationWarnings?.map((x, idx) => (
                  <li key={idx}>
                    <div className="flex">
                      <span>
                        <IonIcon name="warningOutline" className="h-2" />
                      </span>

                      <span className="ml-0.5">{x.message}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default BulkUploadValidationHeader;
