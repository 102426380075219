import L, { DivIcon } from 'leaflet';
import { renderToString } from 'react-dom/server';

import { GeofenceBreachType, TripEvent, TripEventType } from 'generated/graphql';

import Icon from './Icon';
import { IconName } from './Icon/icons';

const createIcon = (iconName?: IconName) => {
  return L.divIcon({
    html: renderToString(
      <div className="w-[32px] h-[32px] p-[4px] text-white rounded-full bg-error">
        {iconName ? <Icon name={iconName} /> : null}
      </div>,
    ),
    className: 'eventmarker',
    popupAnchor: [0, 0],
    iconSize: [30, 30],
  });
};

export const TripEventMarker = (event?: TripEvent) => {
  const IconMap: Record<TripEventType, DivIcon> = {
    [TripEventType.GeofenceBreach]:
      event?.details.geofenceBreachType === GeofenceBreachType.Exit
        ? createIcon('geofenceExit')
        : createIcon('geofenceEnter'),
    [TripEventType.HardAcceleration]: createIcon('acceleration'),
    [TripEventType.HardBraking]: createIcon('hardBraking'),
    [TripEventType.HardCorneringLeft]: createIcon('hardCorneringLeft'),
    [TripEventType.HardCorneringRight]: createIcon('hardCorneringRight'),
    [TripEventType.IdleStart]: createIcon('idle'),
    [TripEventType.IdleEnd]: createIcon('idle'),
    [TripEventType.OverSpeedStart]: createIcon('overSpeed'),
    [TripEventType.OverSpeedEnd]: createIcon('overSpeed'),
    [TripEventType.LongIdle]: createIcon('longIdle'),
  };

  return event?.type ? IconMap[event.type] : createIcon(undefined);
};
