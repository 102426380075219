import { CellContext } from '@tanstack/react-table';
import { AggregateListRow } from 'types/settings';

const DriverListFleetCell = ({ cell }: { cell: CellContext<AggregateListRow, string[]> }) => {
  const fleetNames = cell.getValue();
  if (!fleetNames) return null;

  return (
    <div className="w-full">
      {fleetNames.length > 1 ? (
        fleetNames.map((fleetName) => <div key={fleetName}>{fleetName}</div>)
      ) : (
        <div>{fleetNames}</div>
      )}
    </div>
  );
};

export default DriverListFleetCell;
