import Icon from 'atoms/Icon';
import { IconName } from 'atoms/Icon/icons';
import { cx } from 'utils';

interface DashboardLightIconProps {
  iconName: IconName;
  description: string;
  warning?: boolean;
}

export const DashboardLightIcon = ({ iconName, description, warning }: DashboardLightIconProps) => {
  return (
    <Icon
      name={iconName}
      className={cx('!w-5 !h-5', warning ? 'text-error' : 'text-gray-100')}
      aria-label={description}
    />
  );
};
