import { useState } from 'react';

import IonIcon from 'atoms/IonIcon';
import { cx } from 'utils';

export const CollapsibleList = ({
  items,
  title,
  uniqueKey,
}: {
  title: JSX.Element;
  items: string[] | JSX.Element[];
  uniqueKey: string;
}) => {
  const [expanded, setExpanded] = useState(false);
  const chevronName = expanded ? 'chevronDownOutline' : 'chevronForwardOutline';

  return (
    <>
      <div className={cx('flex my-1 font-semibold cursor-pointer')} onClick={() => setExpanded(!expanded)}>
        <IonIcon className="inline-block mx-0.5" name={chevronName} />

        {title}
      </div>

      <div
        className={cx(
          'border-px border-gray-300 text-sm transition-all ease-out delay-150',
          expanded ? 'block' : 'hidden',
        )}
      >
        <ul>
          {items.map((elem, i) => (
            <li key={`${uniqueKey}_${i}`}>{elem}</li>
          ))}
        </ul>
      </div>
    </>
  );
};
