import * as RadixTooltip from '@radix-ui/react-tooltip';
import { ComponentProps } from 'react';

interface TooltipProps extends ComponentProps<typeof RadixTooltip.Trigger> {
  children: React.ReactNode;
  text: string | React.ReactNode;
  side?: ComponentProps<typeof RadixTooltip.Content>['side'];
  className?: string;
  delayDuration?: number;
}

const Tooltip = ({ children, text, className, side, delayDuration = 200, ...props }: TooltipProps) => (
  <RadixTooltip.Provider delayDuration={delayDuration}>
    <RadixTooltip.Root>
      <RadixTooltip.Trigger {...props} tabIndex={-1}>
        {children}
      </RadixTooltip.Trigger>

      <RadixTooltip.Content
        side={side}
        className={`rounded-4 py-1 px-1.5 text-md bg-white shadow-card text-dark-gray ${className}`}
      >
        <RadixTooltip.Arrow className="fill-[white]" />

        {text}
      </RadixTooltip.Content>
    </RadixTooltip.Root>
  </RadixTooltip.Provider>
);

export default Tooltip;
