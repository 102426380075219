import { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import useUser from 'components/User/useUser';

export const useCurrentFleetId = <T extends boolean = false>({ optional }: { optional?: T } = {}): T extends true
  ? string | undefined
  : string => {
  const currentFleetId = useRouteMatch<{ fleetId: string }>('/:fleetId')?.params?.fleetId?.toLowerCase();

  if (!optional && !currentFleetId) {
    throw new Error('A component relying on the "currentFleedId" is rendered before it is available');
  }

  return currentFleetId!;
};

export const useLoadCurrentFleetId = () => {
  const fleets = useUser()?.accessibleFleets;
  const currentFleetId = useCurrentFleetId({ optional: true });
  const history = useHistory();

  useEffect(() => {
    // don't redirect if the currentFleetId is within accessibleFleets
    if (!fleets || (currentFleetId && fleets.some((fleet) => fleet.id === currentFleetId))) {
      return;
    }

    const [topLevelFleet] = fleets.filter((fleet) => !fleet.parentId).sort((a, b) => a.name.localeCompare(b.name));

    history.replace('/' + (topLevelFleet?.id ?? ''));
  }, [history, currentFleetId, fleets]);
};

export const useSetCurrentFleetId = () => {
  const location = useLocation();
  const history = useHistory();
  const currentFleetId = useCurrentFleetId();

  return (fleetId: string) => {
    const pathWithoutFleetId = location.pathname.match(new RegExp(`/${currentFleetId}(.*)`))?.[1] ?? location.pathname;
    history.push(`/${fleetId}${pathWithoutFleetId}`);
  };
};
