import { useContext } from 'react';

import { InfoPanel } from 'atoms/InfoPanel';
import { InfoPanelItem } from 'atoms/InfoPanel/InfoPanelItem';
import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { useVehicleDetails } from 'components/Vehicle/Detail/hooks';

const textMinWidthClassName = 'min-w-15';

const DriverModalContent = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, error, data: [vehicleDetails] = [] } = useVehicleDetails();

  if (loading || !i18nContext)
    return (
      <div className="flex-center">
        <Spinner />
      </div>
    );

  const {
    commonTranslations: {
      errors: { error_text, entity_not_found_tfn },
      domain: {
        vehicle: { vehicle_text },
        user: {
          fields: { name_text, phoneNumber_text },
        },
        driverDetails: {
          fields: { id_text },
        },
      },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  if (!vehicleDetails) return <div className="text-xl">{entity_not_found_tfn(vehicle_text)}</div>;

  const {
    associations: { driver: driverDetails },
  } = vehicleDetails;

  if (!driverDetails) return null;

  const {
    id,
    user: { name, phoneNumber },
  } = driverDetails;

  return (
    <div className="flex flex-col text-md">
      <div className="flex flex-col mb-2 p-1">
        <InfoPanel>
          <InfoPanelItem
            description={id_text}
            value={id}
            minWidthClassName={textMinWidthClassName}
            showCopyToClipboard
          />

          <InfoPanelItem
            description={name_text}
            value={name ?? ''}
            minWidthClassName={textMinWidthClassName}
            showCopyToClipboard
          />

          <InfoPanelItem
            description={phoneNumber_text}
            value={phoneNumber ?? ''}
            minWidthClassName={textMinWidthClassName}
            showCopyToClipboard
          />
        </InfoPanel>
      </div>
    </div>
  );
};

export default DriverModalContent;
