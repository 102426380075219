interface TabNotificationBubbleProps {
  count: number;
}

export const TabNotificationBubble = ({ count }: TabNotificationBubbleProps) => {
  if (count === 0) return null;

  return (
    <>
      <span className="w-2 h-2 text-white text-sm font-normal bg-error rounded-[50%] flex-center">{count}</span>
    </>
  );
};
