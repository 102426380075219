// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB } from 'date-fns/locale';
import { useContext } from 'react';

import { InfoPanel } from 'atoms/InfoPanel';
import { InfoPanelItem } from 'atoms/InfoPanel/InfoPanelItem';
import { I18nContext } from 'common/useT';
import { LatLng } from 'generated/graphql';

interface StartEndTripInfoPanelProps {
  time?: string;
  address?: string;
  location?: LatLng | null;
  fuel?: number | null;
}

const minWidthClassName = 'min-w-8';

export const StartEndTripInfoPanel = ({ address, time, fuel, location }: StartEndTripInfoPanelProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { time_text, unavailable_text },
      domain: {
        common: { location_text, address_text },
        trip: {
          fields: { fuel_level_text: fuelLevelField },
        },
      },
    },
  } = i18nContext;

  const dateTimeFormat = 'Pp';
  const formattedTime = time && format(parseISO(time), dateTimeFormat, { locale: enGB });

  return (
    <InfoPanel>
      <InfoPanelItem
        minWidthClassName={minWidthClassName}
        description={time_text}
        value={formattedTime ?? unavailable_text}
      />

      <InfoPanelItem
        minWidthClassName={minWidthClassName}
        description={address_text}
        value={address ?? unavailable_text}
      />

      <InfoPanelItem
        minWidthClassName={minWidthClassName}
        description={location_text}
        value={location ? `${location.lat},${location.lng}` : unavailable_text}
        showCopyToClipboard
      />

      <InfoPanelItem
        minWidthClassName={minWidthClassName}
        description={fuelLevelField}
        value={fuel ?? unavailable_text}
        unitOfMeasure={fuel ? '%' : undefined}
      />
    </InfoPanel>
  );
};
