import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import GeofenceBreachListItem from 'components/Live/Geofence/GeofenceSidebar/GeofenceBreaches/GeofenceBreachListItem';
import { useGeofenceBreachNotifications } from 'components/Live/Geofence/GeofenceSidebar/GeofenceBreaches/useGeofenceBreachNotifications';
import { geofenceNotificationsListState } from 'components/Live/state';
import { cx } from 'utils';

const GeofenceBreachList = () => {
  const i18nContext = useContext(I18nContext);
  const [{ breachTypeFilter, filterVehicles }] = useRecoilState(geofenceNotificationsListState);

  const { data: geofenceNotifications, error, loading } = useGeofenceBreachNotifications();

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { error_text },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;
  if (loading || !geofenceNotifications)
    return (
      <div className="flex-center h-15 text-sm mt-2">
        <CenteredSpinner />
      </div>
    );

  const filteredNotifications = geofenceNotifications
    .filter((x) => {
      if (breachTypeFilter !== 'All' && x.type !== breachTypeFilter) {
        return false;
      }
      return true;
    })
    .filter((x) => {
      if (filterVehicles.length) {
        if (!!filterVehicles.find((vehicle) => vehicle.vehicleId.toLowerCase() === x.vehicle.id.toLowerCase())) {
          return true;
        }
        return false;
      }
      return true;
    });

  return (
    <div className="overflow-hidden shadow-inner flex flex-grow">
      <div className="flex flex-col w-full h-[480px] overflow-y-auto">
        {filteredNotifications.length ? (
          <div>
            {filteredNotifications.map((notification, index) => (
              <div className={cx(index % 2 && 'bg-white')}>
                <GeofenceBreachListItem key={notification.messageId} geofenceNotification={notification} />
              </div>
            ))}
          </div>
        ) : (
          <span className="ml-1 mt-1 italic">
            {tSafe(
              'components.Live.Geofence.GeofenceSidebar.GeofenceBreaches.GeofenceBreachList.no-breaches-to-display',
              { defaultValue: 'No breaches to display' },
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default GeofenceBreachList;
