import { addHours, format, isEqual, startOfHour, subDays, subHours } from 'date-fns';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';
import { theme } from 'twin.macro';

import { Period, TripStatistic, TripStatsHistoryRecord } from 'generated/graphql';

const now = new Date();
const timeBucketMap: Record<HistogramPeriod, Date[]> = {
  P24h: [...Array(24)].map((_, i) => subHours(startOfHour(now), i)).reverse(),
  P7d: [...Array(8)].map((_, i) => subDays(now, i)).reverse(),
  P30d: [...Array(31)].map((_, i) => subDays(now, i)).reverse(),
  P90d: [...Array(91)].map((_, i) => subDays(now, i)).reverse(),
};

type HistogramPeriod = Exclude<Period, Period.Forever>;

const xAxisTickFormatter = (tick: string, period: HistogramPeriod) => {
  switch (period) {
    case Period.P24h:
      return format(new Date(tick), 'HH:mm');
    case Period.P7d:
      return format(new Date(tick), 'eee');
    case Period.P30d:
      return format(new Date(tick), 'do');
    case Period.P90d:
      const monthsArray = timeBucketMap[period].map((x) => x.getMonth());
      const monthSet = new Set(monthsArray);
      const formatter = monthsArray.length === monthSet.size ? 'LLL' : 'dd/MM';
      return format(new Date(tick), formatter);
  }
};

const tickFilter = (period: HistogramPeriod) => {
  switch (period) {
    case Period.P24h:
      return timeBucketMap[period].map((x) => x.toString());
    case Period.P30d:
      return timeBucketMap[period].filter((x) => x.getDate() === 1 || x.getDate() % 7 === 0).map((x) => x.toString());
    case Period.P90d:
      return timeBucketMap[period].filter((x) => x.getDate() === 1).map((x) => x.toString());
    default:
      return timeBucketMap[period].map((x) => x.toString());
  }
};

const histogramHourFilter = (record: TripStatsHistoryRecord, date: Date) => {
  const [recordDate, recordHour] = record.date.split(' ');
  const recordDateTime = addHours(new Date(recordDate), +recordHour - 1);
  return isEqual(recordDateTime, date);
};

const histogramDateFilter = (record: TripStatsHistoryRecord, date: Date) => {
  const [recordDateAsString] = record.date.split(' ');
  const [currentDateAsString] = date.toISOString().split('T');
  return isEqual(new Date(recordDateAsString), new Date(currentDateAsString));
};

export interface DrivingStatsHistogramProps {
  description: TripStatistic['description'];
  histogramData: TripStatistic['histogram'];
  period: HistogramPeriod;
  className?: string;
  yAxisRange?: [number, number];
}

export const DrivingStatsHistogram = ({
  description,
  period,
  histogramData,
  className,
  yAxisRange,
}: DrivingStatsHistogramProps) => {
  const paddedHistogramData = timeBucketMap[period].map((date) => {
    const record = histogramData?.find((record) =>
      period === Period.P24h ? histogramHourFilter(record, date) : histogramDateFilter(record, date),
    );
    return {
      date,
      value: record?.value ?? 0,
    };
  });

  const maxValue = histogramData?.reduce((acc, curr) => {
    if (curr.value > acc) {
      acc = curr.value;
    }
    return acc;
  }, 0);

  const defaultYAxisIndent = 16;
  const defaultYAxisWidth = 46;
  const maxValueLength = maxValue?.toString().length ?? 0;
  const calculatedThreshold = 3;
  const excessChars = maxValueLength - calculatedThreshold;
  const yAxisLabelIndent = excessChars ? defaultYAxisIndent + excessChars * 3 : defaultYAxisIndent;
  const yAxisWidth = excessChars ? defaultYAxisWidth + excessChars * 5 : defaultYAxisWidth;

  return (
    <div className={`min-w-10 min-h-10 ${className}`}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={paddedHistogramData}>
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis
            dataKey="date"
            tickMargin={2}
            height={20}
            minTickGap={10}
            ticks={tickFilter(period)}
            tickFormatter={(tick) => xAxisTickFormatter(tick, period)}
          />

          <YAxis width={yAxisWidth} domain={yAxisRange}>
            <Label angle={-90} fill={theme`colors.dark-gray`} dx={yAxisLabelIndent * -1.5}>
              {description}
            </Label>
          </YAxis>

          <Tooltip
            labelStyle={{ color: theme`colors.dark-gray` }}
            itemStyle={{ color: theme`colors.dark-gray` }}
            formatter={(value: number) => [Math.round(value * 100) / 100, description]}
            labelFormatter={(value: number) => `Date: ${value}`}
          />

          <Bar dataKey="value" fill={theme`colors.secondary`} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
