import { useContext } from 'react';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import ToolbarSelect from 'components/Toolbar/ToolbarSelect';
import { SelectOption } from 'types';
import { FleetHierarchy } from 'types/fleet';

interface SubfleetSelectorProps {
  fleets: FleetHierarchy[];
  parentFleetId: string | undefined;
  onSelect: (item: SelectOption<string>) => void;
  selectedFleet?: FleetHierarchy;
  disabled?: boolean;
  showSeparator?: boolean;
}

const SubfleetSelector = ({
  fleets,
  selectedFleet,
  parentFleetId,
  onSelect,
  disabled = false,
  showSeparator = true,
}: SubfleetSelectorProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const allOption = {
    label: tSafe('components.FleetSelector.SubfleetSelector.all-option', {
      numFleets: fleets.length,
      defaultValue: 'All subfleets ({{numFleets}})',
    }),
    value: parentFleetId ?? '',
  };

  const items = fleets.map((fleet) => ({ label: fleet.name, value: fleet.id }));
  const selectedItem = selectedFleet ? { label: selectedFleet.name, value: selectedFleet.id } : allOption;

  if (parentFleetId) {
    items.unshift(allOption);
  }

  const separator = <IonIcon name="chevronForwardOutline" className="separator" role="separator" />;

  return (
    <>
      {showSeparator && separator}

      <ToolbarSelect
        items={items}
        selectedItem={selectedItem}
        onSelect={(selectedItem) => selectedItem.value && onSelect(selectedItem)}
        disabled={disabled}
      />
    </>
  );
};

export default SubfleetSelector;
