import { useMutation } from '@apollo/client';
import { useContext, useMemo, useState } from 'react';
import { NIL } from 'uuid';

import Button from 'atoms/Button';
import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import BlockingModal from 'atoms/Modal/BlockingModal';
import ConfirmModal, { useConfirmModal } from 'atoms/Modal/ConfirmModal';
import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { useAvailableDrivers, useVehicleDetails } from 'components/Vehicle/Detail/hooks';
import { GetVehicleDoc, UpdateAssociationDoc } from 'generated/graphql';
import { Result, SelectOption } from 'types';

interface DropdownSelectWrapperProps {
  items: SelectOption[];
  onSelect: (item: string) => void;
  initialItem?: SelectOption;
}

const DropdownSelectWrapper = ({ items, onSelect, initialItem }: DropdownSelectWrapperProps) => {
  const { getProps } = useDropdownSelect(items, {
    onSelect: (item: SelectOption) => onSelect(item.value),
    searchable: true,
    initialItem,
  });

  return <DropdownSelect {...getProps()} className="!w-20" />;
};

interface DriverSelectorProps {
  currentDriver?: Result<typeof GetVehicleDoc>[0]['associations']['driver'];
  cancelEditMode: () => void;
}

export const DriverSelector = ({ currentDriver, cancelEditMode }: DriverSelectorProps) => {
  const i18nContext = useContext(I18nContext);
  const { tSafe } = i18nContext ?? {};
  const { loading, error, data: [vehicleDetails] = [] } = useVehicleDetails();
  const [fleetId] = vehicleDetails.associations.fleetIds;
  const drivers = useAvailableDrivers(fleetId, { fetchPolicy: 'cache-and-network' });
  const [selectedDriverId, setSelectedDriverId] = useState<string>();

  const currentDriverSelectOption = useMemo(
    () => (currentDriver ? { label: currentDriver.user.name, value: currentDriver.id } : undefined),
    [currentDriver],
  );

  const driverSelectOptions = useMemo((): SelectOption[] => {
    if (!tSafe) return [];

    const availableDrivers = drivers?.map((driver) => ({ label: driver.user.name ?? '', value: driver.id })) ?? [];
    const unassignSelectOption = {
      label: tSafe('components.Vehicle.Detail.Header.DriverSelector.no-driver-option', { defaultValue: 'No driver' }),
      value: NIL,
    };

    if (!currentDriverSelectOption) return availableDrivers;

    return [currentDriverSelectOption, unassignSelectOption, ...availableDrivers];
  }, [drivers, tSafe, currentDriverSelectOption]);

  const [updateAssociation, { loading: updateAssociationMutationLoading }] = useMutation(UpdateAssociationDoc);
  const { openModal, props } = useConfirmModal();

  const spinner = (
    <div className="flex-center">
      <div className="ml-4">
        <Spinner className="!text-[0.5em]" />
      </div>
    </div>
  );

  if (!i18nContext || !tSafe) return null;

  const {
    commonTranslations: {
      errors: { entity_not_found_tfn, error_text },
      domain: {
        vehicle: { vehicle_text },
      },
      forms: {
        buttons: { cancel_text },
      },
    },
  } = i18nContext;

  if (loading || !drivers) return spinner;

  if (error) return <div>{error_text}</div>;

  if (!vehicleDetails) return <div className="text-xl">{entity_not_found_tfn(vehicle_text)}</div>;

  const { associations } = vehicleDetails;
  const { deviceId } = associations;

  const onSelectDriver = (driverId: string) => {
    setSelectedDriverId(driverId);
    openModal();
  };

  const onConfirm = (driverId?: string) => {
    if (!driverId) return;

    if (driverId === NIL) {
      unassignDriver();
    } else {
      assignDriver(driverId);
    }
  };

  const unassignDriver = () => {
    updateAssociation({
      variables: { deviceId, driverId: NIL, withVehicleDetailsAssociations: true },
    }).finally(cancelEditMode);
  };

  const assignDriver = (driverId: string) => {
    const driver = drivers.find((driver) => driver.id === driverId)!;

    return updateAssociation({
      variables: { deviceId, driverId: driver.id, withVehicleDetailsAssociations: true },
    }).finally(cancelEditMode);
  };

  const selectedDriverName = drivers?.find((x) => x.id === selectedDriverId)?.user.name;

  return (
    <>
      <ConfirmModal
        modalProps={props}
        confirmContent={
          selectedDriverId !== NIL ? (
            <span className="max-w-40 mb-1">
              {tSafe(
                'components.Vehicle.Detail.Header.DriverInfo.DriverSelector.are-you-sure-reassign-vehicle-to-driver',
                {
                  defaultValue: "Are you sure you'd like to reassign this vehicle to {{selectedDriverName}}?",
                  selectedDriverName,
                },
              )}
            </span>
          ) : (
            <span className="max-w-40 mb-1">
              {tSafe(
                'components.Vehicle.Detail.Header.DriverInfo.DriverSelector.are-you-sure-unassign-driver-from-vehicle',
                {
                  defaultValue: "Are you sure you'd like to unassign a driver from this vehicle?",
                },
              )}
            </span>
          )
        }
        onConfirm={() => onConfirm(selectedDriverId)}
      />

      <div className="flex">
        {!updateAssociationMutationLoading ? (
          <>
            <DropdownSelectWrapper
              items={driverSelectOptions}
              onSelect={onSelectDriver}
              initialItem={currentDriverSelectOption}
            />

            <span className="ml-1">
              <Button className="text-sm hover:underline" onClick={cancelEditMode}>
                {cancel_text}
              </Button>
            </span>
          </>
        ) : (
          <div className="flex-center">
            <BlockingModal />

            {selectedDriverId !== NIL ? (
              <span>
                {tSafe(
                  'components.Vehicle.Detail.Header.DriverInfo.DriverSelector.updating-driver-to-selectedDriverName',
                  {
                    defaultValue: 'updating driver to {{selectedDriverName}}... this may take a minute',
                    selectedDriverName,
                  },
                )}
              </span>
            ) : (
              <span>
                {tSafe('components.Vehicle.Detail.Header.DriverInfo.DriverSelector.unassigning-driver', {
                  defaultValue: 'unassigning driver... this may take a minute',
                })}
              </span>
            )}

            <div className="ml-4">
              <Spinner className="!text-[0.5em]" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
