import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import { useModalContext } from 'atoms/Modal';
import Spinner, { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { DriverEditForm, DriverEditFormData } from 'components/Driver/Detail/Edit/DriverEditForm';
import { useDriverDetails } from 'components/Driver/Detail/hooks';
import { UpdateUserDoc } from 'generated/graphql';
import { cx } from 'utils';

export const Edit = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, error, data: [driverDetails] = [] } = useDriverDetails();
  const modalContext = useModalContext();
  const [updateUser, { loading: updateUserLoading, error: updateUserError }] = useMutation(UpdateUserDoc, {
    onCompleted: () => {
      modalContext?.closeModal();
    },
  });

  if (loading || !i18nContext)
    return (
      <div className="flex justify-center">
        <Spinner className="!text-md" />
      </div>
    );

  const {
    commonTranslations: {
      errors: { entity_not_found_tfn, error_text },
      domain: {
        driver: { driver_translatedWithCount },
      },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  if (!driverDetails) return <div className="text-xl">{entity_not_found_tfn(driver_translatedWithCount(1))}</div>;

  const onSubmit = (user: DriverEditFormData) => {
    if (!user) return;

    const { name, email, phoneNumber } = user;

    updateUser({
      variables: { id: driverDetails.user.id, name, email, phoneNumber },
    });
  };

  return (
    <>
      {updateUserLoading && <CenteredSpinner className="absolute left-1/2 top-1/2" />}

      <div className={cx('flex flex-col', updateUserLoading && 'opacity-20 pointer-events-none')}>
        {updateUserError && <span className="text-error text-md normal-case">{updateUserError.message}</span>}

        <DriverEditForm onSubmit={onSubmit} initialState={{ ...driverDetails.user, sendPasswordEmail: false }} />
      </div>
    </>
  );
};
