import { ColumnDef } from '@tanstack/react-table';
import debounce from 'lodash.debounce';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { I18nContext } from 'common/useT';
import { AggregateListRow } from 'types/settings';

const Component = ({ column: { id, meta } }: { column: ColumnDef<AggregateListRow> }) => {
  const i18nContext = useContext(I18nContext);
  const history = useHistory();
  const [value, setValue] = useState('');
  const filterRef = useRef<HTMLInputElement>(null);

  const updateResults = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (!id) return;

        const params = new URLSearchParams(history.location.search);

        if (searchTerm && searchTerm.length) {
          params.set(id, searchTerm);
        } else {
          params.delete(id);
        }

        history.replace({ pathname: history.location.pathname, search: params.toString() });
      }, 600),
    [],
  );

  const updateValue = useCallback(
    (value: string) => {
      if (!id) return;

      setValue(value);
      meta?.filterComponent?.(value);
      updateResults(value);
    },
    [id, updateResults],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!id) return;

    const urlValue = new URLSearchParams(history.location.search).get(id) ?? '';
    setValue(urlValue);
  }, []);

  useEffect(() => {
    if (filterRef.current && value !== '') filterRef.current.focus();
  }, [value]);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { search_ellipsis_text },
    },
  } = i18nContext;

  return (
    <input
      value={value}
      ref={filterRef}
      onChange={(e) => updateValue(e.target.value)}
      placeholder={search_ellipsis_text}
      className="w-full px-1 py-0.5"
    />
  );
};

const fuzzySearchFilter = (id: string) => <Component column={{ id }} />;

export default fuzzySearchFilter;
