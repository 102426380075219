import { cx } from 'utils';

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

const Button = ({ children, className, ...props }: ButtonProps) => (
  <button
    className={cx(
      'focus:outline-none active:translate-x-px active:translate-y-px disabled:opacity-50 disabled:cursor-auto',
      className,
    )}
    {...props}
  >
    {children}
  </button>
);

export default Button;
