import { useMutation } from '@apollo/client';
import produce from 'immer';
import { useContext, useEffect, useState } from 'react';

import Button from 'atoms/Button';
import { allNotificationTopics } from 'common/notificationSettingsFields';
import { I18nContext } from 'common/useT';
import SettingGroup from 'components/Settings/SettingGroup';
import TableRow from 'components/Settings/UserNotificationSettings/TableRow';
import { convertUserNotificationSettingsToInput } from 'components/Settings/UserNotificationSettings/utils';
import useSettings from 'components/Settings/useSettings';
import { ApiError, SetUserNotificationSettingsDoc, UserNotificationSettingsInput } from 'generated/graphql';
import { usePrevious } from 'utils';
import { getError } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';

export type NotificationChannels = {
  email: boolean;
  sms: boolean;
  portal: boolean;
};

const UserNotificationSettings = () => {
  const i18nContext = useContext(I18nContext);
  const { notificationSettings } = useSettings();
  const [formState, setFormState] = useState<UserNotificationSettingsInput>(
    convertUserNotificationSettingsToInput(notificationSettings),
  );
  const [showSaveButton, setShowSaveButton] = useState(false);
  const prevFormState = usePrevious(formState);
  const [setUserNotificationsSettings, { loading: mutationLoading }] = useMutation(SetUserNotificationSettingsDoc, {
    onCompleted: () =>
      successToast(
        tSafe('components.Settings.NotificationSettings.toast-success', {
          defaultValue: 'Your notification settings have been updated',
        }),
      ),
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });

  useEffect(() => {
    if (JSON.stringify(prevFormState) !== JSON.stringify(formState)) {
      setShowSaveButton(true);
    }
  }, [formState, prevFormState]);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      enums: { topicDescriptionMap },
      forms: {
        buttons: { save_text, saving_ellipsis_text },
      },
    },
  } = i18nContext;

  return (
    <SettingGroup
      title={tSafe('components.Settings.NotificationSettings.title', { defaultValue: 'Notification Settings' })}
    >
      <div className="flex flex-col gap-2">
        <div>
          {tSafe('components.Settings.NotificationSettings.extra-info', {
            defaultValue:
              'Please note that you may still receive email / SMS notifications if they are enforced by your Fleet Manager.',
          })}
        </div>

        <table className="border-spacing-2">
          <thead className="mb-1">
            <tr className="bg-dark-gray text-white font-bold text-left">
              <th className="p-1">
                {tSafe('components.Settings.NotificationSettings.notified-event', { defaultValue: 'Notified Event' })}
              </th>

              <th className="p-1">
                {tSafe('components.Settings.NotificationSettings.portal-alerts', { defaultValue: 'Portal Alerts' })}
              </th>

              <th className="p-1">
                {tSafe('components.Settings.NotificationSettings.emails', { defaultValue: 'Emails' })}
              </th>

              <th className="p-1">{tSafe('components.Settings.NotificationSettings.sms', { defaultValue: 'SMS' })}</th>
            </tr>
          </thead>

          <tbody>
            {allNotificationTopics.map((topic) => {
              return (
                <TableRow
                  key={`row_${topic}`}
                  data={formState[topic]}
                  eventName={topicDescriptionMap[topic]}
                  topic={topic}
                  onChangeEmailEnabled={(value) => {
                    setFormState(
                      produce(formState, (draft) => {
                        draft![topic].email = value;
                      }),
                    );
                  }}
                  onChangeSmsEnabled={(value) => {
                    setFormState(
                      produce(formState, (draft) => {
                        draft![topic].sms = value;
                      }),
                    );
                  }}
                  onChangePortalEnabled={(value) => {
                    setFormState(
                      produce(formState, (draft) => {
                        draft![topic].portal = value;
                      }),
                    );
                  }}
                />
              );
            })}
          </tbody>
        </table>

        {showSaveButton && (
          <div className="text-center">
            <Button
              className="bg-success text-white border border-success p-0.5 rounded-4 w-20 hover:brightness:90"
              onClick={() => {
                setUserNotificationsSettings({ variables: { notificationSettings: formState } }).then(() => {
                  setShowSaveButton(false);
                });
              }}
            >
              {mutationLoading ? saving_ellipsis_text : save_text}
            </Button>
          </div>
        )}
      </div>
    </SettingGroup>
  );
};

export default UserNotificationSettings;
