import { QueryHookOptions } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';

import { defaultDashboardTimePeriod } from 'common/constants';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import {
  GetDriverDetailsDoc,
  GetDriverDetailsQuery,
  Period,
  GetVehiclesWithoutDriversDoc,
  GetVehiclesWithoutDriversQuery,
} from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

export const useCurrentDriverId = () =>
  useRouteMatch<{ driverId: string }>('/:fleetId/drivers/:driverId')?.params?.driverId?.toLowerCase();

type QueryOptions = QueryHookOptions<GetDriverDetailsQuery>;

export const useDriverDetails = ({
  fetchPolicy = 'cache-only',
  nextFetchPolicy: _,
  period,
  ...rest
}: Omit<QueryOptions, 'variables'> & { period?: Period } = {}) => {
  const driverId = useCurrentDriverId();

  return useQ(GetDriverDetailsDoc, {
    variables: { driverId, period: period ?? defaultDashboardTimePeriod },
    fetchPolicy,
    ...rest,
  });
};

export const useAvailableVehicles = (
  fleetId?: string,
  {
    fetchPolicy = 'cache-only',
    nextFetchPolicy: _,
    ...rest
  }: Omit<QueryHookOptions<GetVehiclesWithoutDriversQuery>, 'variables'> = {},
) => {
  const currentFleetId = useCurrentFleetId();
  const { data: vehiclesWithoutDriver } = useQ(GetVehiclesWithoutDriversDoc, {
    variables: { fleetIds: [fleetId ?? currentFleetId] },
    fetchPolicy,
    ...rest,
  });

  return vehiclesWithoutDriver;
};
