import { useEffect, useState } from 'react';

import { useT } from 'common/useT';

export const DeferredRender = ({ children }: { children: JSX.Element }) => {
  const [hasRendered, setHasRendered] = useState(false);
  const {
    commonTranslations: {
      general: { loading_text },
    },
  } = useT();

  useEffect(() => {
    const id = requestIdleCallback(() => setHasRendered(true));
    return () => cancelIdleCallback(id);
  }, []);

  if (!hasRendered) {
    return (
      <div className="w-full h-full flex-center">
        <img src="/ellipsis.gif" className="flex-center" width={200} alt={loading_text} />
      </div>
    );
  }
  return children;
};
