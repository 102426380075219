import BulkUploadTableRow from 'components/BulkUpload/BulkUploadTable/BulkUploadTableRow';
import { TableData } from 'components/BulkUpload/BulkUploadValidatedData';
import { cx } from 'utils';

interface BulkUploadTableProps {
  data: TableData;
  supportedFields: string[];
}

export const tableColumnWidthMap: Record<string, string> = {
  'driver ID': 'w-30',
  email: 'w-30',
  'fleet ID': 'w-30',
  'fuel type': 'w-10',
  'licence plate': 'w-10',
  make: 'w-10',
  model: 'w-10',
  name: 'w-20',
  'phone number': 'w-15',
  'serial number': 'w-15',
  'vehicle ID': 'w-30',
  VIN: 'w-15',
  year: 'w-5',
};

const BulkUploadTable = ({ data, supportedFields }: BulkUploadTableProps) => {
  return (
    <div>
      <table className="">
        <thead className="flex w-full">
          <tr className="text-left children:p-1 w-full border-b-px border-gray-400">
            {supportedFields.map((field) => (
              <th
                key={field}
                className={cx('capitalize border-r-px border-gray-100', tableColumnWidthMap[field] ?? 'w-30')}
              >
                {field}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="flex flex-col items-center overflow-y-scroll  h-30">
          {data.map((row, idx) => (
            <BulkUploadTableRow key={`row_${idx}`} data={row} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BulkUploadTable;
