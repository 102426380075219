import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { liveSettingsState } from 'components/Live/state';
import ToolbarSelect from 'components/Toolbar/ToolbarSelect';
import { SelectOption } from 'types';

type ShowFleetGeofencesOptions = typeof liveSettingsState.__tag[number]['fleetGeofenceDisplayMode'];

const FleetGeofencesMenu = () => {
  const i18nContext = useContext(I18nContext);
  const [liveSettings, setLiveSettings] = useRecoilState(liveSettingsState);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const options: SelectOption<ShowFleetGeofencesOptions>[] = [
    {
      value: 'all',
      label: tSafe('components.Toolbar.FleetGeofencesMenu.all-geofences', { defaultValue: 'All geofences' }),
    },
    {
      value: 'selectedFleet',
      label: tSafe('components.Toolbar.FleetGeofencesMenu.only-current-fleet-geofences', {
        defaultValue: 'Only current fleet geofences',
      }),
    },
    {
      value: 'none',
      label: tSafe('components.Toolbar.FleetGeofencesMenu.geofences-are-hidden', {
        defaultValue: 'Geofences are hidden',
      }),
    },
  ];

  let selectedItem = options.find((option) => option.value === liveSettings.fleetGeofenceDisplayMode);

  const onSelect = ({ value }: SelectOption<ShowFleetGeofencesOptions>) =>
    setLiveSettings({
      ...liveSettings,
      fleetGeofenceDisplayMode: value,
    });

  if (!selectedItem) return null;

  return (
    <ToolbarSelect
      items={options}
      onSelect={onSelect}
      selectedItem={selectedItem}
      textRight
      icon={<IonIcon name="locateOutline" />}
    />
  );
};

export default FleetGeofencesMenu;
