import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useRecoilValue } from 'recoil';
import tw from 'twin.macro';

import IonIcon from 'atoms/IonIcon';
import { LicencePlate } from 'atoms/LicencePlate';
import { CenteredSpinner } from 'atoms/Spinner';
import { TabNotificationBubble } from 'atoms/TabNotificationBubble';
import Tooltip from 'atoms/Tooltip';
import { defaultDashboardTimePeriod } from 'common/constants';
import { deviceOemTypeToNativeType } from 'common/descriptionMaps';
import { reverseGeocodeLookup } from 'common/geocodingApi';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { mapState, selectedVehicleState } from 'components/Live/state';
import {
  BatteryNotificationType,
  BatteryState,
  DeviceType,
  GetTripsByVehicleDoc,
  GetVehicleDetailsDoc,
} from 'generated/graphql';
import { NominatimReverseGeocodingResult } from 'types/geocoding';
import { cx } from 'utils';
import { useQ } from 'utils/apolloClient';

import DriverDetails from './DriverDetails';
import GeofenceList from './GeofenceList';
import VehicleDetails from './VehicleDetails';

type LastKnownLocationState = {
  result: NominatimReverseGeocodingResult;
  latLng: {
    lat: number;
    lng: number;
  };
};

const StyledTab = tw(
  Tab,
)`inline-flex justify-center p-0.5 w-1/3 text-gray-400 text-md border-px border-solid border-gray-400 bg-gray-300 hover:bg-gray-400 hover:text-black rounded-tl-8 rounded-tr-8 cursor-pointer list-none`;

const InfoSummary = () => {
  const i18nContext = useContext(I18nContext);
  const map = useRecoilValue(mapState);
  const [lastKnownLocationState, setLastKnownLocationState] = useState<LastKnownLocationState>();
  const selectedVehicle = useRecoilValue(selectedVehicleState)!;
  const currentFleetId = useCurrentFleetId();
  const {
    loading,
    error,
    data: [vehicleDetails] = [],
  } = useQ(GetVehicleDetailsDoc, {
    variables: {
      vehicleIds: selectedVehicle.vehicleId,
      period: defaultDashboardTimePeriod,
    },
  });
  const { data: vehicleTrips = [] } = useQ(GetTripsByVehicleDoc, {
    variables: {
      vehicleId: selectedVehicle.vehicleId,
      period: defaultDashboardTimePeriod,
    },
    pollInterval: 30000,
  });

  useEffect(() => {
    setLastKnownLocationState(undefined);
    if (!vehicleTrips[0]?.routeGeometry || !vehicleTrips[0]?.routeGeometry?.length) return;

    const fetchAddress = async () => {
      const { routeGeometry } = vehicleTrips[0];
      const lastKnownLocation = routeGeometry![routeGeometry!.length - 1];
      const { lat, lng } = lastKnownLocation;
      const result = await reverseGeocodeLookup({ lat, lng });

      setLastKnownLocationState({ result, latLng: { lat, lng } });
    };
    fetchAddress();
  }, [selectedVehicle, vehicleTrips]);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { unknown_text },
      errors: { failed_to_fetch_data_text },
      domain: {
        driver: { driver_text },
        driverDetails: { view_driver_text },
        vehicle: { vehicle_text },
        vehicleDetails: { view_vehicle_text },
        geofence: { geofence_text },
        lastKnownLocation: { last_known_location_text },
      },
    },
  } = i18nContext;

  if (error) return <div>{failed_to_fetch_data_text}</div>;

  if (!selectedVehicle) return null;

  const {
    vehicle: { id, make, model },
    associations: { driver, device },
    exceptions: { battery, dashboardLight, dtc },
  } = vehicleDetails ?? { vehicle: {}, associations: {}, exceptions: { battery: [], dashboardLight: [], dtc: [] } };

  const filteredBatteryEvents = battery.filter((x) => x.type !== BatteryState.NotificationOk);

  const exceptionCounts: number = [...filteredBatteryEvents, ...dashboardLight, ...dtc].filter(
    (x) => x.count > 0,
  ).length;

  const vehicleEventCount = loading
    ? 0
    : exceptionCounts +
      (vehicleDetails?.status.batteryStatus !== BatteryNotificationType.Ok ? 1 : 0) +
      (vehicleDetails?.status.fuel && vehicleDetails?.status.fuel === 0 ? 1 : 0);
  const driverEventCount =
    vehicleDetails?.associations.driver?.statistics.impactEventCounts.filter((x) => x.count !== 0).length || 0;

  const maybeAddress = lastKnownLocationState?.result?.address;

  const addressFields = maybeAddress
    ? [
        maybeAddress.road,
        maybeAddress.village,
        maybeAddress.town,
        maybeAddress.city,
        maybeAddress.county,
        maybeAddress.postcode,
        maybeAddress.country,
      ]
    : [];

  const vehicleMovementStatus: () => string = () => {
    if (selectedVehicle.tripEnded || vehicleTrips[0]?.tripEnded) {
      return tSafe('components.Live.VehicleSidebar.InfoSummary.vehicleMovementStatus.parked', {
        defaultValue: 'Parked',
      });
    }
    if (!selectedVehicle.tripEnded || !vehicleTrips[0]?.tripEnded) {
      return tSafe('components.Live.VehicleSidebar.InfoSummary.vehicleMovementStatus.moving', {
        defaultValue: 'Moving',
      });
    }

    if (device?.sourceType && deviceOemTypeToNativeType[device.sourceType] === DeviceType.Embedded)
      return tSafe('components.Live.VehicleSidebar.InfoSummary.vehicleMovementStatus.embedded', {
        defaultValue: 'Embedded',
      });

    return unknown_text;
  };

  return (
    <div className="flex flex-col p-0.5 min-h-[420px]">
      <div className="flex flex-col items-center justify-around pb-1 pt-0 text-center">
        {selectedVehicle.licencePlate || vehicleDetails?.vehicle.licencePlate ? (
          <LicencePlate
            licenceNumber={
              selectedVehicle.licencePlate?.toUpperCase() ?? vehicleDetails?.vehicle.licencePlate.toUpperCase()
            }
            textClass="text-4xl"
          />
        ) : null}

        <div className="flex items-center text-lg font-bold">
          <h3 className="mb-1 pt-1">
            {selectedVehicle.driverName ?? vehicleDetails?.associations.driver?.user.name.toUpperCase()}
          </h3>

          {selectedVehicle || driver ? (
            <Tooltip text={view_driver_text}>
              <Link
                className="ml-1 flex items-center"
                to={{
                  state: {
                    driverName: selectedVehicle.driverName ?? driver?.user.name,
                  },
                  pathname: `/${currentFleetId}/drivers/${selectedVehicle?.driverId ?? driver?.id}`,
                }}
              >
                <IonIcon className="text-2xl ui-button-dark" name="eyeOutline" />
              </Link>
            </Tooltip>
          ) : null}
        </div>

        <span
          className={cx(
            'px-1 py-0.5 w-min text-sm font-medium border-0 rounded-4',
            selectedVehicle.tripEnded || vehicleTrips[0]?.tripEnded
              ? 'text-white bg-gray-400'
              : 'text-black font-semibold bg-success',
          )}
        >
          {vehicleMovementStatus()}
        </span>

        <div className="flex-center mt-1 text-sm capitalize">
          <div>{`${selectedVehicle.make ?? make ?? ''} ${selectedVehicle.model ?? model ?? ''}`}</div>

          <Tooltip text={view_vehicle_text}>
            <Link
              className="flex ml-1 items-center ui-button-dark"
              to={`/${currentFleetId}/vehicles/${selectedVehicle.vehicleId ?? id}`}
            >
              <IonIcon className="text-2xl" name="carOutline" />
            </Link>
          </Tooltip>
        </div>

        <div>
          <div className="flex flex-col px-2 text-sm rounded-2 min-h-6 ">
            {lastKnownLocationState ? (
              <div
                className="flex flex-col p-1 cursor-pointer"
                onClick={() => {
                  map?.flyTo(lastKnownLocationState.latLng, 13, { animate: false });
                }}
              >
                <IonIcon name="locationOutline" className="text-2xl" aria-label={last_known_location_text} />

                {addressFields.map((field) => (
                  <div key={field}>{field}</div>
                ))}
              </div>
            ) : (
              <CenteredSpinner className="text-[7px] mt-2" />
            )}
          </div>
        </div>
      </div>

      <Tabs>
        <TabList className="flex flex-row mb-1">
          <StyledTab
            className={cx(!driver ? '!w-1/2' : 'w-1/3', 'space-x-1')}
            selectedClassName="!text-black font-bold !border-b-0 !bg-gray-100 cursor-default"
          >
            <TabNotificationBubble count={vehicleEventCount} />

            <span className="ml-0.5">{vehicle_text}</span>
          </StyledTab>

          {driver ? (
            <StyledTab
              className={'space-x-1'}
              selectedClassName="!text-black font-bold !border-b-0 !bg-gray-100 cursor-default"
            >
              <TabNotificationBubble count={driverEventCount} />

              <span className="ml-0.5">{driver_text}</span>
            </StyledTab>
          ) : null}

          <StyledTab
            className={!driver ? '!w-1/2' : 'w-1/3'}
            selectedClassName="!text-black font-bold !border-b-0 !bg-gray-100 cursor-default"
          >
            <span>{geofence_text}</span>
          </StyledTab>
        </TabList>

        <TabPanel>
          <VehicleDetails />
        </TabPanel>

        {driver ? (
          <TabPanel>
            <DriverDetails driverDetails={driver} />
          </TabPanel>
        ) : null}

        <TabPanel>
          <GeofenceList />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default InfoSummary;
