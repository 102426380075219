import {
  Topic,
  UserNotificationChannelSettingsInput,
  UserNotificationSettings,
  UserNotificationSettingsInput,
} from 'generated/graphql';
import { keys, omit } from 'utils';

export const convertUserNotificationSettingsToInput = (
  userNotificationSettings: UserNotificationSettings,
): UserNotificationSettingsInput =>
  keys(Topic).reduce((acc, curr) => {
    const key = Topic[curr];
    acc[key] = omit(userNotificationSettings[key], '__typename');

    return acc;
  }, {} as Record<keyof UserNotificationSettingsInput, UserNotificationChannelSettingsInput>);
