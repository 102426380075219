import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import Icon from 'atoms/Icon';
import useSearchSource, { SearchSourceComponent } from 'common/useSearchSource';
import useUser from 'components/User/useUser';

import { focusedFleetTreeFleetIdState } from '../../components/Fleet/FleetList/state';

export const SearchFleetItem: SearchSourceComponent = ({ id, innerHtml, onSelect }) => (
  <li
    className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
    onClick={() => onSelect?.(id)}
  >
    <Icon name="fleet" className="mr-1 !w-2" />

    <div className="flex flex-col">
      <span className="text-md" dangerouslySetInnerHTML={{ __html: innerHtml }} />
    </div>
  </li>
);

const useFleetSearchSource = (searchTerm: string, onSelectFleet: (id: string) => void) => {
  const setFocusedFleetTreeFleetId = useSetRecoilState(focusedFleetTreeFleetIdState);

  const { accessibleFleets } = useUser();

  const selectHandler = useCallback(
    (fleet: NonNullable<typeof accessibleFleets>[0]) => {
      setFocusedFleetTreeFleetId(fleet.id);
      onSelectFleet?.(fleet.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return useSearchSource({
    memoizedData: accessibleFleets,
    searchKey: 'name',
    searchTerm,
    Component: SearchFleetItem,
    onSelect: selectHandler,
  });
};

export default useFleetSearchSource;
