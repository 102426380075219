import { useMutation } from '@apollo/client';
import deepEqual from 'fast-deep-equal';
import { useMemo } from 'react';
import { Layouts, Responsive as ResponsiveGridLayout, WidthProvider } from 'react-grid-layout';

import { layoutToSerializedLayout, serializedLayoutToLayout } from 'components/Dashboard/utils';
import { draggableHandleClass, WidgetContextProvider } from 'components/Dashboard/Widget';
import widgetMap from 'components/Dashboard/widgetMap';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useSettings from 'components/Settings/useSettings';
import { SetDashboardLayoutDoc } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';

const GridLayout = WidthProvider(ResponsiveGridLayout);

const Dashboard = () => {
  // const [fetchLastKnownLocations] = useLazyQuery(GetVehicleLastKnownLocationsDoc, {
  //   variables: { fleetIds: useCurrentFleetId() },
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'cache-first',
  // });
  // const [fetchDriverList] = useLazyQuery(GetDriverListDoc, {
  //   variables: { fleetId: useCurrentFleetId(), period: Period.P90d },
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'cache-first',
  //   onCompleted: () => fetchLastKnownLocations(),
  // });
  // useVehicleList({
  //   fetchPolicy: 'cache-first',
  //   notifyOnNetworkStatusChange: true,
  //   onCompleted: () => fetchDriverList(),
  // });
  const { dashboardWidgetSettings } = useSettings();
  const currentFleetId = useCurrentFleetId();

  const [setDashboardLayout] = useMutation(SetDashboardLayoutDoc, { fetchPolicy: 'no-cache' });

  const layout = useMemo(() => serializedLayoutToLayout(dashboardWidgetSettings), [dashboardWidgetSettings]);

  const onLayoutChange = (layout: Layouts) =>
    setDashboardLayout({ variables: { dashboardLayout: layoutToSerializedLayout(layout) } });

  if (!layout || !currentFleetId) return null;

  return (
    <GridLayout
      className="layout m-auto max-w-[300rem]"
      layouts={layout}
      cols={{ sm: 1, md: 2, lg: 3, xl: 4 }}
      breakpoints={{ sm: 0, md: 750, lg: 1300, xl: 1800 }}
      rowHeight={60}
      draggableHandle={'.' + draggableHandleClass}
      onLayoutChange={(_, updatedLayout) => {
        if (!deepEqual(layoutToSerializedLayout(updatedLayout), layoutToSerializedLayout(layout))) {
          onLayoutChange(updatedLayout);
        }
      }}
    >
      {layout?.sm?.map(({ i: widgetKey }) => {
        const Component = widgetMap[widgetKey as DashboardWidget].component;

        return (
          <div key={widgetKey}>
            <WidgetContextProvider widgetKey={widgetKey}>
              <Component />
            </WidgetContextProvider>
          </div>
        );
      })}
    </GridLayout>
  );
};

export default Dashboard;
