import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  createHttpLink,
  DocumentNode,
  InMemoryCache,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { useMemo } from 'react';

import { ApiError, PortalNotifications } from 'generated/graphql';
import { values } from 'utils';
import { errorToast } from 'utils/toasts';

const getAuthLink = (getAccessTokenSilently?: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>) =>
  setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently?.();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

const errorLink = onError((rawError) => {
  const error = getError(rawError);

  if (error?.type === ApiError.Unauthorized) {
    errorToast(`An error occurred: ${error.message}`);
  } else {
    console.error(error);
  }
});

export const getApolloClient = (
  getAccessTokenSilently?: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL || 'https://fleet-api.drvnsolutions.app'}/graphql`,
  });
  const authLink = getAuthLink(getAccessTokenSilently);

  return new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            portalNotifications: {
              keyArgs: false,
              merge(existing: PortalNotifications = { count: 0, notifications: [] }, incoming: PortalNotifications) {
                return {
                  count: incoming.count,
                  notifications: [...(existing?.notifications ?? []), ...(incoming?.notifications ?? [])],
                };
              },
            },
          },
        },
        Association: {
          keyFields: ['deviceId'],
        },
        VehicleDetails: {
          keyFields: ['vehicle', ['id']],
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        Vehicle: {
          keyFields: ['id'],
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        VehicleBatteryStatus: {
          keyFields: ['vehicleId'],
        },
        DriverStatistics: {
          keyFields: ['driverId'],
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        DriverDetails: {
          keyFields: ['id'],
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
        User: {
          keyFields: ['id'],
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    }),
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });
};

export const useQ = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> | undefined,
) => {
  const res = useQuery(query, options);

  const { data } = res;

  const unwrappedRes = useMemo(() => {
    if (typeof data !== 'object') {
      return res;
    }

    const { __typename, ...dataWithoutTypename } = data as typeof data & { __typename?: string };
    const [unwrappedData] = values(dataWithoutTypename);

    return { ...res, data: unwrappedData };
  }, [data, res]);

  type Data = TData extends { __typename?: string } ? TData[Exclude<keyof TData, '__typename'>] : never;

  return unwrappedRes as Omit<QueryResult<TData, TVariables>, 'data'> & {
    data: Data | undefined;
  };
};

export const getError = (error: ApolloError | ErrorResponse | undefined) =>
  (error?.graphQLErrors?.[0]?.extensions?.code as ApiError | ErrorResponse | undefined) && {
    type: error!.graphQLErrors![0].extensions!.code as ApiError,
    message: error!.graphQLErrors![0].message,
    extensions: error!.graphQLErrors![0].extensions,
  };
