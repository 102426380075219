import fuzzysort from 'fuzzysort';
import React, { useEffect, useState } from 'react';

import { MultiSearchData } from 'components/Live/MultiSearch';
import { StringKeys } from 'types';

export type SearchSourceComponent = React.VoidFunctionComponent<{
  id?: string;
  innerHtml: string;
  onSelect?: (id?: string) => void;
}>;

const useSearchSource = <
  T extends object & { id?: string },
  _ extends StringKeys<T>,
  K extends _ extends string ? _ : never,
>({
  memoizedData: data,
  searchKey,
  searchTerm,
  Component,
  onSelect,
  scoreMultiplier = 1,
}: {
  memoizedData: T[] | undefined;
  searchKey: K;
  searchTerm: string;
  Component: SearchSourceComponent;
  onSelect?: (item: T) => void;
  scoreMultiplier?: number;
}) => {
  const [results, setResults] = useState<MultiSearchData[]>([]);

  useEffect(() => {
    data &&
      fuzzysort
        .goAsync(searchTerm, data, {
          key: searchKey,
          limit: 10,
          threshold: -2000,
          allowTypo: true,
        })
        .then((results) =>
          results.map((result): MultiSearchData => {
            const formattedLabel = fuzzysort.highlight(result, '<b>', '</b>');

            const { obj } = result;

            return {
              score: result.score * scoreMultiplier,
              jsx: (
                <Component
                  key={obj.id ?? obj[searchKey] as unknown as string}
                  id={obj.id}
                  innerHtml={formattedLabel ?? obj[searchKey] as unknown as string}
                  onSelect={() => onSelect?.(obj)}
                />
              ),
            };
          }),
        )
        .then(setResults);
  }, [Component, data, onSelect, scoreMultiplier, searchKey, searchTerm]);

  return results;
};

export default useSearchSource;
