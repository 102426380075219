import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from 'atoms/Button';
import { useModalContext } from 'atoms/Modal';
import { fakeEmailPlaceholder, fakePhoneNumberPlaceholder } from 'common/constants';
import { I18nContext } from 'common/useT';
import { DriverDetails } from 'generated/graphql';

export type DriverEditFormData = Pick<DriverDetails['user'], 'name' | 'phoneNumber' | 'email'> & {
  creationMode?: boolean;
  sendPasswordEmail: boolean;
};

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
    phoneNumber: yup.string().required(),
    email: yup.string().email().required(),
  })
  .required();

interface DriverEditFormProps {
  initialState?: DriverEditFormData;
  onSubmit: (driver: DriverEditFormData) => void;
  creationMode?: boolean;
}

export const DriverEditForm = ({
  initialState,
  onSubmit: onSubmitCallback,
  creationMode = false,
}: DriverEditFormProps) => {
  const i18nContext = useContext(I18nContext);
  const {
    tSafe,
    commonTranslations: {
      forms: {
        buttons: { cancel_text, save_text },
        required_text,
      },
    },
  } = i18nContext ?? {
    commonTranslations: {
      forms: {
        buttons: {},
      },
    },
  };
  const fields: { key: keyof DriverEditFormData; label: string; required?: boolean; placeholder?: string }[] =
    i18nContext
      ? [
          {
            key: 'name',
            label: i18nContext.commonTranslations.domain.user.fields.name_text,
            required: true,
          },
          {
            key: 'phoneNumber',
            label: i18nContext.commonTranslations.domain.user.fields.phoneNumber_text,
            required: true,
            placeholder: fakePhoneNumberPlaceholder,
          },
          {
            key: 'email',
            label: i18nContext.commonTranslations.domain.user.fields.email_text,
            required: true,
            placeholder: fakeEmailPlaceholder,
          },
        ]
      : [];

  const {
    register,
    handleSubmit,
    formState: { isValid, dirtyFields, isDirty, errors },
  } = useForm<DriverEditFormData>({
    defaultValues: {
      email: initialState?.email ?? '',
      name: initialState?.name ?? '',
      phoneNumber: initialState?.phoneNumber ?? '',
      sendPasswordEmail: creationMode,
    },
    mode: 'onTouched',
    resolver: yupResolver(schema),
  });
  const modalContext = useModalContext();

  const onSubmit = (...args: Parameters<typeof onSubmitCallback>) => {
    if (isValid && Object.keys(dirtyFields).length) {
      onSubmitCallback(...args);
    }
  };

  if (!tSafe) return null;

  return (
    <div className="flex flex-col w-full text-md rounded-8">
      <section about="Driver Details">
        <form onSubmit={(e) => e.preventDefault()} className="grid gap-1 grid-cols-2 items-start my-2 p-1">
          {fields.map(({ key, label, required, placeholder }) => (
            <div className="flex flex-col" key={key}>
              <label className="font-bold">
                {label}

                {required ? <span className="ml-0.5 text-error font-bold">*</span> : null}

                <span className="text-error">{errors[key]?.message}</span>
              </label>

              <input
                className="p-0.5 w-full font-normal rounded-4 outline outline-1 outline-dark-gray hover:outline-2"
                placeholder={placeholder}
                {...register(key, { required, minLength: required ? 1 : 0 })}
              />
            </div>
          ))}

          {creationMode && (
            <div className="flex pt-2.5">
              <input
                id="sendPasswordEmail"
                type="checkbox"
                checked={initialState?.sendPasswordEmail}
                placeholder="Send Password Email"
                {...register('sendPasswordEmail', {})}
              />

              <label htmlFor="sendPasswordEmail" className="inline ml-1">
                {tSafe('components.Driver.Detail.Edit.DriverEditForm.send-password-email', {
                  defaultValue: 'Send password email',
                })}
              </label>
            </div>
          )}
        </form>

        <div className="flex -mt-2 ml-1 justify-end font-light text-sm text-gray-400">* {required_text}</div>

        <div className="flex gap-1 justify-center mb-2 w-full">
          <Button
            className="px-2 py-0.5 border-px rounded-4 hover:bg-gray-100"
            onClick={() => modalContext?.closeModal?.()}
          >
            {cancel_text}
          </Button>

          {isDirty && (
            <Button
              className="px-2 py-0.5 bg-navbar border-px rounded-4 hover:bg-navbarHover"
              onClick={() => handleSubmit(onSubmit)()}
            >
              {save_text}
            </Button>
          )}
        </div>
      </section>
    </div>
  );
};
