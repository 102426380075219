import { IconName } from 'atoms/Icon/icons';
import { CrashSeverity, DriverEventType } from 'generated/graphql';

export const driverEventsIconMap: Record<DriverEventType, IconName> = {
  IMPACT_RED: 'impact',
  IMPACT_AMBER: 'impact',
  IMPACT_GREEN: 'impact',
  LONG_IDLING: 'longIdle',
  NOT_REPORTING: 'deviceNotReporting',
  UNPLUG: 'deviceDisconnected',
};

export const driverEventsIconStyleMap: Record<DriverEventType, string> = {
  IMPACT_GREEN: 'text-success',
  IMPACT_AMBER: 'text-amber',
  IMPACT_RED: 'text-error',
  LONG_IDLING: 'text-error',
  NOT_REPORTING: 'text-error',
  UNPLUG: 'text-error',
};

export const impactEventsStyleMap: Record<CrashSeverity, string> = {
  GREEN: 'text-success',
  AMBER: 'text-amber',
  RED: 'text-error',
};
