import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import de from 'locales/de/translation.json';
import en from 'locales/en/translation.json';
import nl from 'locales/nl/translation.json';
import ro from 'locales/ro/translation.json';

export const defaultNS = 'translation';
export const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  ro: {
    translation: ro,
  },
  nl: {
    translation: nl,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['en', 'de', 'nl', 'ro'],
    defaultNS,
    resources,
    returnNull: false,
    fallbackLng: 'en',
    debug: false,
    detection: { caches: ['localStorage'] },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
