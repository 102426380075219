import { Table } from '@tanstack/react-table';
import { useContext } from 'react';
import { useHistory } from 'react-router';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { useRecoilState } from 'recoil';
import { listState } from 'components/List/state';

interface ResetUrlParamsToolbarItemProps {
  table: Table<any>;
  resetFilters?: () => void;
}

const ResetUrlParamsToolbarItem = ({ table, resetFilters }: ResetUrlParamsToolbarItemProps) => {
  const i18nContext = useContext(I18nContext);
  const history = useHistory();
  const [listRecoilState, setRecoilListState] = useRecoilState(listState);

  const resetFiltersAndSorting = () => {
    table.setSorting([]);
    table.setColumnFilters([]);
    resetFilters?.();
    setRecoilListState({
      ...listRecoilState,
      selectedApiFilters: undefined,
    });

    history.push(history.location.pathname);
  };

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <span className="ml-2">
      <Button
        onClick={() => {
          resetFiltersAndSorting();
        }}
        className="text-error text-sm flex-center ui-button-dark"
      >
        <IonIcon
          name="closeOutline"
          aria-label={tSafe('components.Toolbar.ResetUrlParamsToolbarItem.clear-filters-reset-sorting', {
            defaultValue: 'Clear Filters and Sorting',
          })}
        />

        {tSafe('components.Toolbar.ResetUrlParamsToolbarItem.clear-filters-reset-sorting', {
          defaultValue: 'Clear Filters and Sorting',
        })}
      </Button>
    </span>
  );
};

export default ResetUrlParamsToolbarItem;
