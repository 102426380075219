import { useState, VoidFunctionComponent } from 'react';
import { useSetRecoilState } from 'recoil';

import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';
import FleetSelector from 'components/FleetSelector';
import { useConditionalKey } from 'utils';

export interface FleetSelectorOptions {
  targetFleetId: string;
  resetTarget: () => void;
  setIsLoading?: (state: boolean) => void;
}

interface FleetSelectorWithOptionsProps {
  currentFleetId: string;
  title?: string;
  optionsComponent: VoidFunctionComponent<FleetSelectorOptions>;
}

const FleetSelectorWithOptions = ({
  currentFleetId,
  optionsComponent: OptionsComponent,
  title,
}: FleetSelectorWithOptionsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [targetFleetId, setTargetFleetId] = useState<string>();
  const fleetSelectorKey = useConditionalKey(targetFleetId === undefined || isLoading);
  const setFocusedFleetTreeFleetId = useSetRecoilState(focusedFleetTreeFleetIdState);

  setFocusedFleetTreeFleetId(currentFleetId);

  return (
    <div className="flex flex-col text-md">
      <FleetSelector
        key={fleetSelectorKey}
        selectedFleetId={targetFleetId ?? currentFleetId}
        onSelect={({ value: fleetId }) => setTargetFleetId(fleetId)}
        title={title}
        {...(targetFleetId && isLoading ? { readonly: true } : { editableInEditMode: true })}
      />

      {targetFleetId && (
        <OptionsComponent
          targetFleetId={targetFleetId!}
          resetTarget={() => setTargetFleetId(undefined)}
          setIsLoading={setIsLoading}
        />
      )}
    </div>
  );
};

export default FleetSelectorWithOptions;
