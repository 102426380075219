import { TripStatisticType } from 'generated/graphql';

type UoMConversion = 'litresToGallons' | 'kmPerLitreToMilesPerGallon' | 'kmPerLitreToMilesPerLitre' | 'kmToMiles';

const conversionRatios: Record<UoMConversion, number> = {
  litresToGallons: 0.219969,
  kmPerLitreToMilesPerGallon: 2.82481,
  kmPerLitreToMilesPerLitre: 0.62137,
  kmToMiles: 0.62137,
};

export const tripStatisticConversionRatios = ({
  distanceInMiles,
  volumeInGallons,
}: {
  distanceInMiles: boolean;
  volumeInGallons: boolean;
}): Record<TripStatisticType, number> => ({
  ECO_SCORE: 1,
  FUEL_CONSUMPTION: volumeInGallons ? conversionRatios['litresToGallons'] : 1,
  FUEL_EFFICIENCY: distanceInMiles
    ? volumeInGallons
      ? conversionRatios['kmPerLitreToMilesPerGallon']
      : conversionRatios['kmPerLitreToMilesPerLitre']
    : 1,
  IDLE_TIME_HRS: 1,
  IDLE_TIME_PCT: 1,
  TOTAL_DISTANCE_DRIVEN: distanceInMiles ? conversionRatios['kmToMiles'] : 1,
  TOTAL_TIME_DRIVEN: 1,
  TRIP_COUNT: 1,
});
