import { useMutation } from '@apollo/client';
import deepEqual from 'fast-deep-equal';
import { useContext, useMemo } from 'react';
import { Layouts, Responsive as ResponsiveGridLayout, WidthProvider } from 'react-grid-layout';
import { Helmet } from 'react-helmet-async';
import { useRecoilValue } from 'recoil';

import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { draggableHandleClass, WidgetContextProvider } from 'components/Dashboard/Widget';
import useSettings from 'components/Settings/useSettings';
import { ActivityHistoryPanel } from 'components/Vehicle/Detail/ActivityHistoryPanel';
import { Header } from 'components/Vehicle/Detail/Header';
import widgetMap from 'components/Vehicle/Detail/widgetMap';
import { SetVehiclePageLayoutDoc } from 'generated/graphql';
import { cx, withTitle } from 'utils';

import { useVehicleDetails } from './hooks';
import { vehiclePageCollapsedWidgetsState } from './state';
import { layoutToSerializedLayout, serializedLayoutToLayout } from './utils';

const GridLayout = WidthProvider(ResponsiveGridLayout);

const VehicleDetail = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, error, data: [vehicleDetails] = [] } = useVehicleDetails({ fetchPolicy: 'cache-first' });
  const {
    vehiclePageSettings: { widgetLayout },
  } = useSettings();
  const [setVehiclePageLayout] = useMutation(SetVehiclePageLayoutDoc, {
    fetchPolicy: 'no-cache',
  });
  const collapsedWidgets = useRecoilValue(vehiclePageCollapsedWidgetsState);
  const layout = useMemo(
    () => serializedLayoutToLayout({ widgetSettings: widgetLayout, collapsedWidgets }),
    [widgetLayout, collapsedWidgets],
  );

  if (loading || !i18nContext)
    return (
      <div className="p-4 flex-center">
        <Spinner />
      </div>
    );

  const {
    commonTranslations: {
      errors: { entity_not_found_tfn, error_text },
      domain: {
        vehicle: { vehicle_text },
      },
    },
  } = i18nContext;

  const onLayoutChange = (layout: Layouts) =>
    setVehiclePageLayout({ variables: { vehiclePageLayout: layoutToSerializedLayout(layout) } });

  if (error) return <div>{error_text}</div>;

  if (!vehicleDetails) return <div className="text-xl">{entity_not_found_tfn(vehicle_text)}</div>;

  return (
    <div className="flex h-[calc(100vh_-_110px)] max-w-[1700px] m-auto">
      <Helmet title={vehicleDetails.vehicle.licencePlate ?? undefined} defer={false} />

      <div className="flex w-full overflow-hidden">
        <div className="w-[62%] relative flex flex-auto flex-wrap">
          <Header />

          <ActivityHistoryPanel />
        </div>

        <div className="w-[38%] flex-none overflow-y-scroll">
          <div className="flex flex-col ml-0.5 h-full">
            <div className="flex text-md flex-col">
              <GridLayout
                className={cx('layout m-auto w-full')}
                layouts={layout}
                cols={{ sm: 1, md: 1, lg: 1, xl: 1 }}
                breakpoints={{ sm: 0, md: 750, lg: 1300, xl: 1800 }}
                rowHeight={50}
                isResizable={false}
                draggableHandle={`.${draggableHandleClass}`}
                onLayoutChange={(_, updatedLayout) => {
                  if (!deepEqual(layoutToSerializedLayout(updatedLayout), layoutToSerializedLayout(layout))) {
                    onLayoutChange(updatedLayout);
                  }
                }}
              >
                {layout?.sm?.map(({ i: widgetKey }) => {
                  const Component = widgetMap[widgetKey].component;

                  return (
                    <div key={widgetKey}>
                      <WidgetContextProvider widgetKey={widgetKey}>
                        <Component />
                      </WidgetContextProvider>
                    </div>
                  );
                })}
              </GridLayout>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTitle(VehicleDetail, 'Vehicle');
