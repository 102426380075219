import { useEffect, useState } from 'react';

import { ConditionalWrapper } from 'atoms/ConditionalWrapper';
import NoDataResponse, { ResponseType } from 'atoms/NoDataResponse';
import Tooltip from 'atoms/Tooltip';
import WidgetTile, { Tile } from 'atoms/WidgetTile';

interface WidgetTileGroupProps<T extends Tile> {
  tiles: T[];
  onSelected?: (selected: T['name']) => void;
  valueClassName?: string;
  defaultIconClassName?: string;
  initialSelection?: T['name'];
  showZeroValues?: boolean;
  count?: boolean;
}

const WidgetTileGroup = <T extends Tile & { tooltip?: string }>({
  tiles,
  onSelected,
  valueClassName,
  defaultIconClassName,
  initialSelection,
  showZeroValues,
  count,
}: WidgetTileGroupProps<T>) => {
  const [selected, setSelected] = useState(initialSelection);

  useEffect(() => {
    selected && onSelected?.(selected);
  }, [onSelected, selected]);

  return tiles.length ? (
    <div className="grid gap-1 grid-rows-1 p-1 auto-rows-fr grid-cols-[repeat(auto-fill,minmax(23rem,1fr))]">
      {tiles.map(({ name, description, value, tooltip, icon: iconName, onClick }) => (
        <ConditionalWrapper
          condition={!!tooltip}
          wrapper={({ children }: { children: JSX.Element }) => <Tooltip text={tooltip}>{children}</Tooltip>}
        >
          <WidgetTile
            key={name}
            name={name}
            description={description}
            value={value}
            icon={iconName}
            selected={selected === name}
            onClick={() => {
              onClick?.();
              onSelected && setSelected(name);
            }}
            valueClassName={valueClassName}
            defaultIconClassName={defaultIconClassName}
            showZeroValues={showZeroValues}
            count={count}
          />
        </ConditionalWrapper>
      ))}
    </div>
  ) : (
    <NoDataResponse reason={ResponseType.NoFleetData} />
  );
};

export default WidgetTileGroup;
