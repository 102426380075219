import { useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ButtonGroup from 'atoms/ButtonGroup';
import NoDataResponse, { ResponseType } from 'atoms/NoDataResponse';
import Tooltip from 'atoms/Tooltip';
import { defaultDashboardTimePeriod } from 'common/constants';
import { I18nContext } from 'common/useT';
import LeaderboardTable from 'components/Dashboard/LeaderboardWidget/LeaderboardTable';
import { leaderboardTypeState } from 'components/Dashboard/LeaderboardWidget/state';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetLeaderboardDoc, LeaderboardType } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';

const Filter = () => {
  const setLeaderboardType = useSetRecoilState(leaderboardTypeState);
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { leaderboardTypeDescriptionMap },
      general: { n_day_period_text },
    },
  } = i18nContext;

  const top10 = leaderboardTypeDescriptionMap[LeaderboardType.Top](10);
  const bottom10 = leaderboardTypeDescriptionMap[LeaderboardType.Bottom](10);

  return (
    <Tooltip text={n_day_period_text(30)}>
      <ButtonGroup
        className="w-20"
        buttons={{
          [top10]: () => setLeaderboardType(LeaderboardType.Top),
          [bottom10]: () => setLeaderboardType(LeaderboardType.Bottom),
        }}
      />
    </Tooltip>
  );
};

const LeaderboardWidget = () => {
  const i18nContext = useContext(I18nContext);
  const type = useRecoilValue(leaderboardTypeState);
  const {
    loading,
    error,
    data: leaderboard,
  } = useQ(GetLeaderboardDoc, {
    fetchPolicy: 'cache-first',
    variables: {
      period: defaultDashboardTimePeriod,
      fleetId: useCurrentFleetId(),
      type,
    },
  });

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap },
    },
  } = i18nContext;

  return (
    <Widget
      title={dashboardWidgetDescriptionMap[DashboardWidget.leaderboardWidget]}
      filterComponent={!loading && <Filter />}
      loading={loading}
      error={error}
    >
      {leaderboard?.length ? (
        <LeaderboardTable leaders={leaderboard!} className="m-auto w-full" />
      ) : (
        <NoDataResponse reason={ResponseType.NoFleetData} />
      )}
    </Widget>
  );
};

export default LeaderboardWidget;
