import { useContext } from 'react';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { GetDriverCardDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

interface DriverCardProps {
  id: string;
  onClickDelete: () => void;
}

const DriverCard = ({ id, onClickDelete }: DriverCardProps) => {
  const i18nContext = useContext(I18nContext);

  const { data, loading, error } = useQ(GetDriverCardDoc, { variables: { driverId: id } });

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { error_text },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;
  if (loading) return <CenteredSpinner className="text-[8px]" />;
  if (!data) return null;

  return (
    <div className="relative flex items-center px-1 py-0.5 border-px rounded-4 w-20">
      <Button
        className="absolute right-1 ui-button"
        onClick={onClickDelete}
        aria-label={tSafe('atoms.DriverSearch.DriverSelectorWithSearch.DriverCard.remove-driver', {
          defaultValue: 'Remove Driver',
        })}
      >
        <IonIcon name="closeOutline" className="text-error text-lg" />
      </Button>

      {data?.[0]?.user.name}
    </div>
  );
};

export default DriverCard;
