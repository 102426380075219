import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { selectedGeofenceState } from 'components/Live/state';
import useRole from 'components/User/useRole';
import { DisassociateGeofenceFromVehicleDoc, Geofence, GetVehicleLicencePlateAndDriverDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

interface VehicleAssociationProps {
  vehicleId: string;
}

const VehicleAssociation = ({ vehicleId }: VehicleAssociationProps) => {
  const i18nContext = useContext(I18nContext);
  const { isManager } = useRole();
  const [selectedGeofence, setSelectedGeofence] = useRecoilState(selectedGeofenceState);
  const { data: [vehicle] = [] } = useQ(GetVehicleLicencePlateAndDriverDoc, {
    variables: { vehicleIds: vehicleId },
  });
  const [disassociateVehicle] = useMutation(DisassociateGeofenceFromVehicleDoc);

  if (!vehicle || !i18nContext) return null;

  const { tSafe } = i18nContext;

  const {
    vehicle: { licencePlate },
    associations: { driver },
  } = vehicle;

  const onClick = () => {
    if (!selectedGeofence?.id) return;

    const updatedSelectedGeofence: typeof selectedGeofence = {
      ...selectedGeofence,
      vehicleIds: selectedGeofence.vehicleIds.filter((id) => id !== vehicleId),
    };

    setSelectedGeofence(updatedSelectedGeofence);

    disassociateVehicle({
      variables: { geofenceId: selectedGeofence.id, vehicleId },
      optimisticResponse: { disassociateGeofenceFromVehicle: updatedSelectedGeofence as Geofence },
    });
  };

  return (
    <div className="flex p-1 border rounded-2 space-x-1">
      <IonIcon name="carSportOutline" className="text-4xl" />

      <div className="flex flex-1 flex-col">
        <h4 className="text-sm font-bold">{licencePlate ?? 'UK00 PLT'}</h4>

        <span>
          {driver?.user.name ||
            tSafe(
              'components.Live.Geofence.GeofenceSidebar.GeofenceSettings.AssociationList.VehicleAssociation.unknown-driver-name',
              { defaultValue: 'Unknown driver name' },
            )}
        </span>
      </div>

      {isManager && (
        <Button aria-label="unlink" onClick={onClick}>
          <IonIcon name="unlinkOutline" className="text-xl" />
        </Button>
      )}
    </div>
  );
};

export default VehicleAssociation;
