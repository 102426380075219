import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { CopyToClipboardButton } from 'atoms/CopyToClipboardButton';
import DeviceStatusIcon from 'atoms/DeviceStatusIcon';
import IonIcon from 'atoms/IonIcon';
import { LicencePlate } from 'atoms/LicencePlate';
import Modal, { useModal } from 'atoms/Modal';
import { Odometer } from 'atoms/Odometer';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useSettings from 'components/Settings/useSettings';
import useRole from 'components/User/useRole';
import DriverInfo from 'components/Vehicle/Detail/Header/DriverInfo';
import { getDeviceConnectionStatus, kmToMi } from 'utils';

import { Edit } from '../Edit';
import { useVehicleDetails } from '../hooks';

export const Header = () => {
  const i18nContext = useContext(I18nContext);
  const { isManager } = useRole();

  const { props: vehicleModalProps } = useModal({
    title: 'Edit vehicle details',
    trigger: (
      <Tooltip text="Edit vehicle details">
        <IonIcon name="pencil" className="w-2 h-2 cursor-pointer" />
      </Tooltip>
    ),
    triggerClassName: 'ui-button',
    contentClassName: 'w-80',
  });
  const currentFleetId = useCurrentFleetId();
  const { distanceInMiles } = useSettings();
  const { loading, error, data: [vehicleDetails] = [] } = useVehicleDetails();

  if (loading || !i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { entity_not_found_tfn, error_text },
      domain: {
        vehicle: {
          vehicle_text,
          vehicles_text,
          fields: { vin_text },
        },
      },
      enums: { deviceConnectionStatusDescriptionMap },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  if (!vehicleDetails) return <div className="text-xl">{entity_not_found_tfn(vehicle_text)}</div>;

  const {
    vehicle: { licencePlate, make, model, year, vin, fuelType },
    status: { odometer },
    associations: { device },
  } = vehicleDetails;

  const { connected, firstConnected, reporting } = device || {};

  const odometerDisplayed = Math.round(distanceInMiles ? kmToMi(odometer ?? 0) : odometer ?? 0);
  const deviceConnectionStatus = getDeviceConnectionStatus(connected, firstConnected, reporting);

  return (
    <div className="flex w-full my-0.5 px-1 py-1 text-xl bg-white">
      <div className="flex w-full items-center ">
        <div className="hover:underline cursor-pointer">
          <Link to={`/${currentFleetId}/list-view`}>{vehicles_text}</Link>
        </div>

        <div className="mx-1">&#62;</div>

        <div className="align-middle mx-1">
          <span>
            <Tooltip text={deviceConnectionStatusDescriptionMap[deviceConnectionStatus]}>
              <DeviceStatusIcon connectionStatus={deviceConnectionStatus} />
            </Tooltip>
          </span>
        </div>

        <div className="relative flex flex-col justify-start mx-1">
          <div className="flex">
            <IonIcon
              name="carOutline"
              className="mx-1"
              aria-label={tSafe('components.Vehicle.Detail.Header.car-make-and-model', {
                defaultValue: 'Car make and model',
              })}
            />

            <span className="font-bold">{`${make} ${model} (${year ? `${year} ` : ''} ${fuelType})`}</span>

            {isManager && (
              <div className="flex flex-1 justify-start mx-1 text-xl">
                <Modal {...vehicleModalProps}>
                  <Edit />
                </Modal>
              </div>
            )}
          </div>

          <div className="flex text-md cursor-pointer">
            <DriverInfo />
          </div>
        </div>
      </div>

      <div className="flex-center w-1/2 items-end flex-col justify-end">
        <div className="flex">
          {licencePlate && <LicencePlate licenceNumber={licencePlate} textClass="text-4xl" />}

          {(!!odometer || vehicleDetails.status.odoSource === 0) && (
            <div className="flex mx-1">
              <Odometer value={odometerDisplayed} />
            </div>
          )}
        </div>

        {!!vin && (
          <div className="flex my-0.5 mx-1">
            <div className="flex-center font-bold text-md pr-1">{vin_text}:</div>

            <div className="uppercase">{vin}</div>

            <CopyToClipboardButton value={vin} className="ml-1" />
          </div>
        )}
      </div>
    </div>
  );
};
