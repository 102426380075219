import axios from 'axios';
import memoize from 'lodash.memoize';

import { NominatimReverseGeocodingResult, NominatimSearchResult, SearchAddress } from 'types/geocoding';

const API_URL = 'https://nominatim.openstreetmap.org';

interface ReverseGeocodeLookupArgs {
  lat: number;
  lng: number;
}

// MAX ONE REQUEST PER SECOND
export const findAddress = (serchTerm: string) =>
  axios
    .get<NominatimSearchResult[]>(`${API_URL}/search`, { params: { q: serchTerm, format: 'jsonv2' } })
    .then(({ data }) =>
      data.map(({ display_name, lat, lon }): SearchAddress => ({ label: display_name, lat: +lat, lng: +lon })),
    );

export const reverseGeocodeLookup = memoize(({ lat, lng }: ReverseGeocodeLookupArgs) =>
  axios
    .get<NominatimReverseGeocodingResult>(`${API_URL}/reverse`, {
      params: { lat, lon: lng, format: 'jsonv2' },
    })
    .then((response) => response.data),
);
