import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { NIL } from 'uuid';

import Button from 'atoms/Button';
import ButtonWithConfirmModal from 'atoms/ButtonWithConfirmModal';
import BlockingModal from 'atoms/Modal/BlockingModal';
import Spinner from 'atoms/Spinner';
import { FleetSelectorOptions } from 'components/FleetSelector/FleetSelectorWithOptions';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import { useVehicleDetails } from 'components/Vehicle/Detail/hooks';
import { UpdateAssociationDoc, UpdateUserDoc } from 'generated/graphql';

interface MoveVehicleFleetOptionsProps extends FleetSelectorOptions {}

const MoveVehicleFleetOptions = ({ targetFleetId, resetTarget, setIsLoading }: MoveVehicleFleetOptionsProps) => {
  const association = useVehicleDetails().data?.[0].associations;
  const fleetMap = useAccessibleFleetMap();
  const targetFleetName = fleetMap[targetFleetId]?.name;

  const [updateAssociation, { loading: updateAssociationMutationLoading }] = useMutation(UpdateAssociationDoc);
  const [updateUser, { loading: updateUserLoading }] = useMutation(UpdateUserDoc);

  useEffect(() => {
    setIsLoading?.(updateAssociationMutationLoading || updateUserLoading);
  }, [updateAssociationMutationLoading, updateUserLoading, setIsLoading]);

  if (updateAssociationMutationLoading || updateUserLoading) {
    return (
      <div className="flex text-sm items-center mt-1">
        <BlockingModal />

        <div className="mr-4">
          Updating the vehicle fleet to {targetFleetName}... Please be patient and do not navigate away! This may take
          up to a minute.
        </div>

        <div className="ml-4">
          <Spinner className="!text-[0.5em]" />
        </div>
      </div>
    );
  }

  if (!association) return null;

  const { deviceId, driver } = association;

  const updateVehicleAssociationAndCache = () =>
    updateAssociation({
      variables: { deviceId, fleetId: targetFleetId, withVehicleDetailsAssociations: true },
    });

  const updateUserFleet = () => {
    updateUser({
      variables: { id: driver!.user.id, fleetIds: targetFleetId },
    });
  };

  const changeVehicleFleetOnly = () => {
    updateVehicleAssociationAndCache().then(resetTarget);
  };

  const moveBothVehicleAndDriverToTargetFleet = async () => {
    updateVehicleAssociationAndCache().then(updateUserFleet).then(resetTarget);
  };

  const unassignDriverAndUpdateVehicleFleet = () => {
    updateAssociation({
      variables: { deviceId, fleetId: targetFleetId!, driverId: NIL, withVehicleDetailsAssociations: true },
    }).then(resetTarget);
  };

  const cancelButton = (
    <Button onClick={resetTarget} className="px-1 bg-gray-100 border-px rounded-2 mr-1">
      Cancel
    </Button>
  );

  if (driver) {
    return (
      <div className="flex mt-1">
        {cancelButton}

        <ButtonWithConfirmModal
          onConfirm={moveBothVehicleAndDriverToTargetFleet}
          className="px-1 bg-gray-100 border-px rounded-2 mr-1"
          confirmContent={`Please confirm that you wish to move both vehicle and driver to ${targetFleetName}`}
        >
          Move both vehicle and driver to {targetFleetName}
        </ButtonWithConfirmModal>

        <ButtonWithConfirmModal
          onConfirm={unassignDriverAndUpdateVehicleFleet}
          confirmContent={`Please confirm that you wish to unassign the driver and move the vehicle to ${targetFleetName}`}
          className="px-1 bg-gray-100 border-px rounded-2"
        >
          Unassign driver and move vehicle to {targetFleetName}
        </ButtonWithConfirmModal>
      </div>
    );
  }

  return (
    <>
      <div className="my-1">
        {cancelButton}

        <ButtonWithConfirmModal
          onConfirm={changeVehicleFleetOnly}
          confirmContent={`Please confirm that you wish to move the vehicle to ${targetFleetName}`}
          className="px-1 bg-gray-100 border-px rounded-2"
        >
          Move vehicle to {targetFleetName}
        </ButtonWithConfirmModal>
      </div>
    </>
  );
};

export default MoveVehicleFleetOptions;
