import { ClassName } from 'types';

interface SpeedIconProps extends ClassName {
  level?: 0 | 1 | 2 | 3 | 4;
}

const speedometerHands = [
  <path d="M19.7,41c5,1.2,11.9,2.9,16.9,4c0.3,0.9,0.7,1.8,1.4,2.6c2.9,3.5,7.9,4,11.4,1.1 c3.5-2.9,4-7.9,1.1-11.4c-2.9-3.5-7.9-4-11.4-1.1c-0.8,0.6-1.4,1.5-1.9,2.3c-5.1,0.1-12.1,0.4-17.3,0.6 C18.8,39.2,18.5,40.8,19.7,41z" />,
  <path d="M29.9,22.6c2.2,4.7,5.3,11.1,7.6,15.6c-0.5,0.8-1,1.7-1.2,2.7c-0.9,4.4,1.9,8.6,6.3,9.5 s8.6-1.9,9.5-6.3s-1.9-8.6-6.3-9.5c-1-0.2-2-0.2-3,0c-3.3-3.8-8.1-9.2-11.5-13C30.7,20.6,29.4,21.6,29.9,22.6z" />,
  <path d="M43.1,18c-0.7,5.2-1.6,12.2-2.2,17.2c-0.8,0.4-1.8,0.9-2.5,1.6c-3.2,3.2-3.1,8.3,0.1,11.5 c3.2,3.2,8.3,3.1,11.5-0.1c3.2-3.2,3.1-8.3-0.1-11.5c-0.7-0.7-1.6-1.3-2.5-1.6c-0.7-5-1.7-12.1-2.5-17.2 C44.9,16.7,43.2,16.8,43.1,18z" />,
  <path d="M56.7,21.3c-3.4,3.9-8,9.3-11.2,13.2c-0.9-0.1-2-0.2-3,0c-4.4,1-7.1,5.3-6.2,9.7 c1,4.4,5.3,7.1,9.7,6.2s7.1-5.3,6.2-9.7c-0.2-1-0.6-1.9-1.2-2.7c2.2-4.6,5.1-11.1,7.3-15.7C58.8,21.2,57.4,20.4,56.7,21.3z" />,
  <path d="M68.1,36.7c-5.2,0.3-12.3,0.8-17.3,1.2c-0.6-0.7-1.3-1.5-2.1-2.1C45,33.2,40,34.3,37.5,38 c-2.5,3.8-1.5,8.7,2.3,11.2c3.8,2.5,8.7,1.5,11.2-2.3c0.6-0.8,0.9-1.8,1.1-2.8c4.8-1.6,11.5-4,16.4-5.7 C69.6,38.1,69.2,36.6,68.1,36.7z" />,
];

const SpeedIcon = ({ level = 0, className }: SpeedIconProps) => (
  <svg
    viewBox="0 0 96 51.1"
    className={`children:fill-[none] children:stroke-current children:stroke-2 ${className ?? ''}`}
  >
    <g>
      <g>
        <path d="M47,12.4c1.9,0.3,3.8,0.7,5.6,1.3c0.6,0.2,1.2-0.1,1.4-0.6l4.1-9.9c0.3-0.7-0.1-1.4-0.8-1.6 c-3.4-0.7-6.8-1.1-10.2-1.1c-0.6,0-1.2,0.5-1.2,1.2v9.6C46,11.9,46.5,12.4,47,12.4z" />
      </g>

      <g>
        <path d="M73.4,8.3c-3.1-2-6.4-3.6-9.9-4.9c-0.6-0.2-1.2,0.1-1.5,0.7l-4.3,10.5c-0.2,0.6,0,1.2,0.5,1.5 c1.7,0.9,3.4,1.9,4.9,3c0.5,0.3,1.1,0.3,1.5-0.1l8.9-8.9C74.1,9.6,74,8.7,73.4,8.3z" />
      </g>

      <g>
        <path d="M86.9,21c-2-2.8-4.3-5.4-6.9-7.7c-0.4-0.4-0.9-0.8-1.4-1.2s-1.1-0.3-1.5,0.1l-9.3,9.3c-0.4,0.4-0.5,1.2,0,1.6 c0.4,0.4,0.8,0.8,1.2,1.3c1,1.1,1.8,2.3,2.6,3.5c0.3,0.5,0.9,0.7,1.4,0.4l13.5-5.6C87.1,22.4,87.3,21.6,86.9,21z" />
      </g>

      <g>
        <path d="M95.4,39c-1.1-4.4-2.8-8.6-5-12.5c-0.3-0.5-0.9-0.7-1.5-0.5l-14,5.8c-0.6,0.2-0.9,0.9-0.6,1.5 c0.9,2,1.5,4.1,2,6.2c0.1,0.5,0.6,0.9,1.1,0.9h16.8C95,40.4,95.5,39.7,95.4,39z" />
      </g>

      <g>
        <path d="M14,15.2c-0.5-0.5-1.2-0.5-1.6,0c-0.3,0.3-0.7,0.7-1,1c-2,2.2-3.7,4.6-5.1,7c-0.4,0.6-0.1,1.4,0.6,1.6 l3.8,1.6c0.5,0.2,1,0.1,1.3-0.3c1.4-1.7,2.9-3.3,4.6-4.8c0.3-0.3,0.7-0.6,1-0.8c0.5-0.4,0.6-1.2,0.1-1.7L14,15.2z" />
      </g>

      <g>
        <path d="M42.1,10.9V2.1c0-0.7-0.6-1.2-1.3-1.2c-3.3,0.4-6.5,1.1-9.6,2.2c-0.6,0.2-0.9,0.9-0.7,1.5l3,7.3 c0.2,0.5,0.7,0.8,1.3,0.7c2-0.4,4.1-0.6,6.1-0.6C41.6,12,42.1,11.5,42.1,10.9z" />
      </g>

      <g>
        <path d="M26.9,6.2c-0.3-0.6-1-0.9-1.6-0.6c-2.9,1.5-5.7,3.3-8.3,5.3c-0.5,0.4-0.6,1.2-0.1,1.7l4.3,4.3 c0.4,0.4,0.9,0.4,1.4,0.2c2-1.2,4.1-2.1,6.2-2.9c0.6-0.2,0.9-0.9,0.7-1.5L26.9,6.2z" />
      </g>

      <g>
        <path d="M7.9,29.6l-3-1.2c-0.6-0.2-1.3,0-1.5,0.6C2,32.3,1,35.7,0.5,39.2c-0.2,0.7,0.4,1.4,1.1,1.4h2.1 c0.5,0,0.9-0.3,1.1-0.8c0.9-3,2.2-5.8,3.7-8.5C8.8,30.7,8.5,29.9,7.9,29.6z" />
      </g>

      {speedometerHands[level]}
    </g>
  </svg>
);

export default SpeedIcon;
