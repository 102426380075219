import { useContext } from 'react';

import { ProgressBarUnitType } from 'atoms/ProgressBar';
import { dashboardLightsIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import Widget, { WidgetPageType } from 'components/Dashboard/Widget';
import { DashboardLightMessageType } from 'generated/graphql';

import { useVehicleDetails } from '../hooks';
import { getWarningLevel, LevelRow, thresholdMap, WarningLevel } from './LevelRow';

export type Threshold = {
  descending?: boolean;
  midThreshold: number;
  highThreshold: number;
};
const Levels = () => {
  const i18nContext = useContext(I18nContext);
  const { data: [vehicleDetails] = [] } = useVehicleDetails();
  const {
    status: { fuel, vehicleLevels, serviceInfo },
  } = vehicleDetails;

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardLightMessageTypeDescriptionMap },
      domain: {
        vehicleDetails: {
          fields: { vehicle_levels_text, fuel_level_text },
        },
      },
    },
  } = i18nContext;

  const { adbluLevel, coolantTemp, dpfSootlevel } = vehicleLevels ?? {};

  const levelRows = [
    {
      value: fuel,
      title: fuel_level_text,
      icon: 'fuelconsumption',
      thresholdMapKey: 'fuel',
    },
    {
      value: adbluLevel,
      title: dashboardLightMessageTypeDescriptionMap[DashboardLightMessageType.Adblue],
      icon: dashboardLightsIconMap[DashboardLightMessageType.Adblue],
      thresholdMapKey: 'adblue',
    },
    {
      value: serviceInfo?.engineOilLife,
      title: dashboardLightMessageTypeDescriptionMap[DashboardLightMessageType.EngineOilLevel],
      icon: dashboardLightsIconMap[DashboardLightMessageType.EngineOilLevel],
      thresholdMapKey: 'oilLife',
      unit: ProgressBarUnitType.Percentage,
    },
    {
      value: coolantTemp,
      title: dashboardLightMessageTypeDescriptionMap[DashboardLightMessageType.CoolantTemperature],
      icon: dashboardLightsIconMap[DashboardLightMessageType.CoolantTemperature],
      thresholdMapKey: 'coolantTemp',
      unit: ProgressBarUnitType.Degrees,
    },
    {
      value: dpfSootlevel,
      title: dashboardLightMessageTypeDescriptionMap[DashboardLightMessageType.Dpf],
      icon: dashboardLightsIconMap[DashboardLightMessageType.Dpf],
      thresholdMapKey: 'dpfSootlevel',
    },
  ];

  const warning = !!levelRows
    .filter((x) => x.value)
    .map((level) => getWarningLevel(level.value!, thresholdMap[level.thresholdMapKey]))
    .filter((x) => x === WarningLevel.WARNING).length;

  return (
    <Widget title={vehicle_levels_text} warning={warning} collapsible page={WidgetPageType.Vehicle}>
      <div className="flex bg-white flex-wrap w-full h-40 p-1" data-testid="fluid-levels">
        <div className="w-full">
          {levelRows.map(({ value, title, icon, thresholdMapKey, unit }, i) => (
            <LevelRow
              disabled={!value}
              title={title}
              icon={icon}
              thresholdMapKey={thresholdMapKey}
              value={value ?? 0}
              unit={unit}
              key={i}
            />
          ))}
        </div>
      </div>
    </Widget>
  );
};
export default Levels;
