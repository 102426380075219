import { useMutation } from '@apollo/client';
import produce from 'immer';
import { useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';

import Button from 'atoms/Button';
import { CenteredSpinner } from 'atoms/Spinner';
import Switch from 'atoms/Switch';
import { I18nContext } from 'common/useT';
import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';
import Setting from 'components/Fleet/FleetList/Toolbar/EditSubfleetToolbarItem/Setting';
import SettingGroup from 'components/Fleet/FleetList/Toolbar/EditSubfleetToolbarItem/SettingGroup';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import useRole from 'components/User/useRole';
import { TopFleetSettingsInput, UpdateTopFleetSettingsDoc } from 'generated/graphql';
import { omit } from 'utils';
import { successToast } from 'utils/toasts';

const TopFleetFeatureSettingsForm = () => {
  const i18nContext = useContext(I18nContext);
  const { isSuperAdmin } = useRole();
  const focusedFleetTreeFleetId = useRecoilValue(focusedFleetTreeFleetIdState)!;

  const fleetMap = useAccessibleFleetMap();
  const currentFleet = focusedFleetTreeFleetId ? fleetMap[focusedFleetTreeFleetId] : undefined;
  const [setTopFleetLevelSettings, { loading }] = useMutation(UpdateTopFleetSettingsDoc, {
    onCompleted: () => successToast('The top fleet settings have been updated'),
  });
  const initialState = currentFleet?.topFleetSettings ? omit(currentFleet.topFleetSettings, '__typename') : undefined;
  const [formState, setFormState] = useState<TopFleetSettingsInput | undefined>(initialState);

  const isTopLevelFleet = currentFleet ? !currentFleet.parentId : false;

  if (!currentFleet || !isSuperAdmin || !isTopLevelFleet) return null;

  const { topFleetSettings } = currentFleet;

  if (!topFleetSettings || !i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      forms: {
        buttons: { save_text },
      },
    },
  } = i18nContext;

  if (loading)
    return (
      <div>
        <CenteredSpinner />;
      </div>
    );

  return (
    <div className="text-lg">
      <SettingGroup
        title={tSafe(
          'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.TopFleetFeatureSettingsForm.enabled-features',
          { defaultValue: 'Enabled Features' },
        )}
      >
        <Setting
          label={tSafe(
            'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.TopFleetFeatureSettingsForm.reporting',
            { defaultValue: 'Reporting' },
          )}
        >
          <Switch
            onChange={(value) => {
              setFormState(
                produce(formState, (draft) => {
                  draft!.reportingEnabled = value;
                }),
              );
            }}
            initialValue={formState?.reportingEnabled}
          />
        </Setting>

        <Setting
          label={tSafe(
            'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.TopFleetFeatureSettingsForm.reverse-geocoding',
            { defaultValue: 'Reverse Geocoding' },
          )}
        >
          <Switch
            onChange={(value) => {
              setFormState(
                produce(formState, (draft) => {
                  draft!.reverseGeocodingEnabled = value;
                }),
              );
            }}
            initialValue={formState?.reverseGeocodingEnabled}
          />
        </Setting>

        <Setting
          label={tSafe(
            'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.TopFleetFeatureSettingsForm.street-viewer',
            { defaultValue: 'Street Viewer' },
          )}
        >
          <Switch
            onChange={(value) => {
              setFormState(
                produce(formState, (draft) => {
                  draft!.streetViewerEnabled = value;
                }),
              );
            }}
            initialValue={formState?.streetViewerEnabled}
          />
        </Setting>

        <Setting
          label={tSafe(
            'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.TopFleetFeatureSettingsForm.email-notifications',
            { defaultValue: 'Email Notifications' },
          )}
        >
          <Switch
            onChange={(value) => {
              setFormState(
                produce(formState, (draft) => {
                  draft!.emailNotificationsEnabled = value;
                }),
              );
            }}
            initialValue={formState?.emailNotificationsEnabled}
          />
        </Setting>

        <Setting
          label={tSafe(
            'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.TopFleetFeatureSettingsForm.sms-notifications',
            { defaultValue: 'SMS Notifications' },
          )}
        >
          <Switch
            onChange={(value) => {
              setFormState(
                produce(formState, (draft) => {
                  draft!.smsNotificationsEnabled = value;
                }),
              );
            }}
            initialValue={formState?.smsNotificationsEnabled}
          />
        </Setting>
      </SettingGroup>

      {formState && (
        <div className="flex-center">
          <Button
            className="bg-success text-white border border-success p-0.5 rounded-4 mt-2 w-20"
            onClick={() => {
              setTopFleetLevelSettings({ variables: { settings: formState } });
            }}
          >
            {save_text}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TopFleetFeatureSettingsForm;
