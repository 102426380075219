import L from 'leaflet';
import { useContext } from 'react';
import { renderToString } from 'react-dom/server';
import { Marker, Polyline, TileLayer, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useRecoilValue } from 'recoil';

import Icon from 'atoms/Icon';
import Spinner from 'atoms/Spinner';
import { TripEventMarker } from 'atoms/TripEventMarker';
import { I18nContext } from 'common/useT';
import { activityHistoryHoveredSegment } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/state';
import useSettings from 'components/Settings/useSettings';
import { GetTripDoc, GetVehicleDoc, Maybe, TripEvent, TripEventType } from 'generated/graphql';
import { CommonTranslations } from 'types/react-i18next';
import { kmToMi } from 'utils';
import { useQ } from 'utils/apolloClient';

type TripEventLabel = {
  label: string;
  value: Maybe<number> | Maybe<string> | undefined;
  uom?: string;
  geofenceId?: string | null;
};

type TripEventDetailFieldTranslations = CommonTranslations['domain']['tripEventDetails']['fields'];

interface TripDetailMapProps {
  tripId: string;
  vehicleId: string;
}

const tripEventMarkers = (
  tripEvents: TripEvent[],
  distanceInMiles: boolean,
  tripEventDetailFieldTranslations: TripEventDetailFieldTranslations,
  tripEventTypeDescriptionMap: Record<TripEventType, string>,
  distanceUomText: string,
  accelerationUomText: string,
  durationUomText: string,
) => (
  <MarkerClusterGroup chunkedLoading maxClusterRadius={30}>
    {tripEvents.map((event) => {
      const {
        location: { lat, lng },
      } = event;

      const i18nContext = useContext(I18nContext);

      if (!i18nContext) return null;

      const {
        commonTranslations: {
          general: { unknown_text },
        },
      } = i18nContext;

      const tripEventTypeFieldsMap = (
        {
          details: {
            maxAcceleration,
            maxDeceleration,
            duration,
            speed,
            geofenceBreachType,
            geofenceName,
            idleDuration,
            idleThreshold,
            geofenceId,
          },
        }: TripEvent,
        distanceInMiles: boolean,
        tripEventDetailFieldTranslations: TripEventDetailFieldTranslations,
        distanceUomText: string,
        accelerationUomText: string,
        durationUomText: string,
      ): Record<TripEventType, TripEventLabel[]> => {
        const {
          duration_text,
          geofenceBreachType_text,
          geofenceName_text,
          idleDuration_text,
          idleThreshold_text,
          maxAcceleration_text,
          maxDeceleration_text,
          speed_text,
        } = tripEventDetailFieldTranslations;

        const speedField = {
          label: speed_text,
          value: speed ? (distanceInMiles ? Math.round(kmToMi(speed)) : Math.round(speed)) : unknown_text,
          uom: distanceUomText,
        };
        const maxDecelerationField = {
          label: maxDeceleration_text,
          value: maxDeceleration
            ? distanceInMiles
              ? Math.round(kmToMi(maxDeceleration))
              : Math.round(maxDeceleration)
            : unknown_text,
          uom: accelerationUomText,
        };
        const maxAccelerationField = {
          label: maxAcceleration_text,
          value: maxAcceleration
            ? distanceInMiles
              ? Math.round(kmToMi(maxAcceleration))
              : Math.round(maxAcceleration)
            : unknown_text,
          uom: accelerationUomText,
        };
        const durationField = {
          label: duration_text,
          value: duration ? Math.round(duration) : unknown_text,
          uom: durationUomText,
        };
        const geofenceBreachTypeField = {
          label: geofenceBreachType_text,
          value: geofenceBreachType?.toString(),
        };
        const geofenceNameField = {
          label: geofenceName_text,
          value: geofenceName ?? unknown_text,
          geofenceId,
        };
        const idleThresholdField = {
          label: idleThreshold_text,
          value: idleThreshold ? Math.round(idleThreshold) : unknown_text,
        };
        const idleDurationField = {
          label: idleDuration_text,
          value: idleDuration ? Math.round(idleDuration) : unknown_text,
          uom: durationUomText,
        };

        return {
          HARD_ACCELERATION: [maxAccelerationField],
          HARD_BRAKING: [maxDecelerationField],
          HARD_CORNERING_LEFT: [maxAccelerationField],
          HARD_CORNERING_RIGHT: [maxAccelerationField],
          OVER_SPEED_END: [speedField, durationField],
          OVER_SPEED_START: [speedField],
          GEOFENCE_BREACH: [geofenceNameField, geofenceBreachTypeField],
          IDLE_START: [speedField, idleThresholdField],
          IDLE_END: [speedField, idleDurationField],
          LONG_IDLE: [speedField, idleThresholdField],
        };
      };

      return (
        <Marker position={L.latLng(lat, lng)} icon={TripEventMarker(event)} key={event.id} title={event.type}>
          <Tooltip>
            <div className="flex flex-col text-sm">
              <span className="font-bold" key={`outer_${event.id}`}>
                {tripEventTypeDescriptionMap[event.type]}
              </span>

              {tripEventTypeFieldsMap(
                event,
                distanceInMiles,
                tripEventDetailFieldTranslations,
                distanceUomText,
                accelerationUomText,
                durationUomText,
              )[event.type].map((item) => (
                <span key={`inner_${event.id}`}>{`${item.label}: ${item.value} ${item.uom ?? ''}`}</span>
              ))}
            </div>
          </Tooltip>
        </Marker>
      );
    })}
  </MarkerClusterGroup>
);

export const TripDetailMap = ({ tripId, vehicleId }: TripDetailMapProps) => {
  const i18nContext = useContext(I18nContext);

  const { distanceInMiles } = useSettings();
  const {
    loading: vehicleDetailsLoading,
    error: vehicleDetailsError,
    data: vehicleDetails,
  } = useQ(GetVehicleDoc, {
    variables: {
      vehicleIds: vehicleId!,
    },
    fetchPolicy: 'no-cache', // NB: this needs to be here to prevent re-rendering
  });
  const { segmentGeometry: selectedSegmentPositions } = useRecoilValue(activityHistoryHoveredSegment) ?? {};

  const {
    loading: tripLoading,
    error: tripError,
    data: trip,
  } = useQ(GetTripDoc, {
    variables: {
      tripId,
    },
  });

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      enums: { tripEventTypeDescriptionMap },
      errors: { error_text: genericErrorText },
      uom: { mph_text, kph_text, seconds_text },
      domain: {
        tripEventDetails: { fields: tripEventDetailFieldTranslations },
      },
    },
  } = i18nContext;

  if (vehicleDetailsError || tripError) return <div>{genericErrorText}</div>;

  if (vehicleDetailsLoading || tripLoading)
    return (
      <div className="flex-center h-full">
        <Spinner />
      </div>
    );

  if (!trip || !vehicleDetails) return null;

  const [{ status }] = vehicleDetails;

  const { endLocation, events, geometryDetail, startLocation } = trip;
  const { lat, lng } = endLocation ? endLocation : status;

  const mainTripPath: L.LatLng[] = geometryDetail
    ? geometryDetail.map(({ location: { lat, lng } }) => L.latLng(lat, lng))
    : [];

  const markerStartLocation = mainTripPath[0] ?? startLocation;
  const markerEndLocation = mainTripPath[mainTripPath.length - 1] ?? [lat, lng];

  return (
    <>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      {markerEndLocation && trip.tripEnded ? (
        <Marker
          key="end"
          position={[markerEndLocation.lat, markerEndLocation.lng]}
          icon={L.divIcon({
            html: renderToString(
              <div className="w-3 -mt-4 -ml-1">
                <Icon name={'tripEndMarker'} className="text-error" />
              </div>,
            ),
            className: 'eventmarker',
            popupAnchor: [0, 0],
            iconSize: [40, 30],
          })}
          zIndexOffset={-10}
        >
          <Tooltip>
            <div className="text-sm font-bold">
              {tSafe(
                'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripDetailMap.markers.journey-end',
                {
                  defaultValue: 'Journey End',
                },
              )}
            </div>
          </Tooltip>
        </Marker>
      ) : null}

      {startLocation ? (
        <Marker
          icon={L.divIcon({
            html: renderToString(
              <div className="-mt-4 -ml-1">
                <Icon name={'tripStartMarker'} className="text-success" />
              </div>,
            ),
            className: 'eventmarker',
            popupAnchor: [0, 0],
            iconSize: [40, 30],
          })}
          key="start"
          position={[markerStartLocation.lat, markerStartLocation.lng]}
          zIndexOffset={-10}
        >
          <Tooltip>
            <div className="text-sm font-bold">
              {tSafe(
                'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripDetailMap.markers.journey-start',
                {
                  defaultValue: 'Journey Start',
                },
              )}
            </div>
          </Tooltip>
        </Marker>
      ) : null}

      {events
        ? tripEventMarkers(
            events,
            distanceInMiles,
            tripEventDetailFieldTranslations,
            tripEventTypeDescriptionMap,
            distanceInMiles ? mph_text : kph_text,
            distanceInMiles ? 'mph/s' : 'kmph/s',
            seconds_text,
          )
        : null}

      <Polyline positions={[mainTripPath]} lineCap="square" color="#396AE2" weight={5} />

      {selectedSegmentPositions && <Polyline positions={selectedSegmentPositions} color="yellow" weight={5} />}
    </>
  );
};
