import { useContext } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { CopyToClipboardButton } from 'atoms/CopyToClipboardButton';
import { I18nContext } from 'common/useT';
import Widget, { WidgetPageType } from 'components/Dashboard/Widget';
import { useDriverDetails } from 'components/Driver/Detail/hooks';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';

const Label = tw.span`font-bold text-md`;

const Item = ({ label, value, copyable }: { label: string; value?: string | null; copyable?: boolean }) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { unknown_text },
    },
  } = i18nContext;

  return (
    <>
      <Label>{label}</Label>

      <span className="flex px-1 py-0.5">
        {value ?? unknown_text}

        {copyable && value ? <CopyToClipboardButton value={value} className="ml-1" /> : null}
      </span>
    </>
  );
};

const Vehicle = () => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();
  const { data, loading, error } = useDriverDetails();

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { unavailable_text },
      domain: {
        common: { guid_text },
        driver: { driver_translatedWithCount },
        vehicle: {
          fields: { licencePlate_text, fuelStatus_text, fuelType_text, make_text, model_text, vin_text, year_text },
        },
        vehicleDetails: { view_vehicle_text },
        driverDetails: {
          fields: { vehicleInfo_text },
        },
      },
    },
  } = i18nContext;

  const noVehicleAssociatedText = tSafe('components.Driver.Detail.Vehicle.no-vehicle-associated', {
    defaultValue: 'No Vehicle Associated',
  });

  const [{ associations }] = data ?? [];
  const { vehicle } = associations ?? {};

  const {
    vehicle: { fuelType, id, make, model, year, licencePlate, vin },
    status: { fuel },
  } = vehicle ?? { vehicle: {}, status: {} };

  return (
    <Widget
      title={vehicleInfo_text}
      collapsible
      scrollable
      loading={loading}
      error={error}
      page={WidgetPageType.Driver}
    >
      {!associations || !vehicle ? (
        <div className="pl-2">{noVehicleAssociatedText}</div>
      ) : (
        <div className="flex flex-col gap-2 p-2">
          <div className="grid gap-x-1 items-center grid-cols-[auto,1fr]">
            <Item label={guid_text} value={id} copyable />

            <Item label={licencePlate_text} value={licencePlate} />

            <Item label={make_text} value={make?.toUpperCase()} />

            <Item label={model_text} value={model?.toUpperCase()} />

            <Item label={year_text} value={year} />

            <Item label={vin_text} value={vin} />

            <Item label={fuelType_text} value={fuelType} />

            <Item label={fuelStatus_text} value={fuel ? fuel.toString() + ' %' : unavailable_text} />
          </div>

          <div className="w-full flex-center">
            <Link to={`/${currentFleetId}/vehicles/${id}`}>
              <div className="px-1 py-0.5 w-[120px] ui-button rounded-4 border-px border-gray-600">
                {view_vehicle_text}&nbsp;&nbsp;&gt;
              </div>
            </Link>
          </div>
        </div>
      )}
    </Widget>
  );
};

export default Vehicle;
