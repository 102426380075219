import produce from 'immer';
import { useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';

import Button from 'atoms/Button';
import Modal, { useModalContext } from 'atoms/Modal';
import UserSearch from 'atoms/UserSearch';
import { I18nContext } from 'common/useT';
import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';
import CustomRecipientForm from 'components/Fleet/FleetList/Toolbar/EditSubfleetToolbarItem/RecipientManager/CustomRecipientForm';
import RecipientCard from 'components/Fleet/FleetList/Toolbar/EditSubfleetToolbarItem/RecipientManager/RecipientCard';
import { FleetNotificationChannelRecipients, FleetNotificationRecipientGroup } from 'generated/graphql';
import { values } from 'utils';

interface RecipientManagerProps {
  channelRecipientsState: FleetNotificationChannelRecipients | null | undefined;
  channelRecipients: FleetNotificationChannelRecipients | null | undefined;
  channel: 'Email' | 'SMS';
  title: string;
  onChange: (recipients: FleetNotificationChannelRecipients) => void;
  onClearAll?: () => void;
  isDefault?: boolean;
}

const allRecipientGroupTypes = values(FleetNotificationRecipientGroup);

const RecipientManager = ({
  channelRecipientsState,
  channelRecipients,
  channel,
  title,
  onChange,
  onClearAll,
}: RecipientManagerProps) => {
  const i18nContext = useContext(I18nContext);
  const modalContext = useModalContext();
  const [recipientGroups, setRecipientGroups] = useState(channelRecipientsState?.recipientGroups ?? []);
  const [customRecipients, setCustomRecipients] = useState(channelRecipientsState?.customRecipients ?? []);
  const [recipientUserIds, setRecipientUserIds] = useState(channelRecipientsState?.recipientUserIds ?? []);

  const focusedFleetTreeFleetId = useRecoilValue(focusedFleetTreeFleetIdState)!;

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      enums: { fleetNotificationRecipientGroupDescriptionMap },
      forms: {
        buttons: { add_text, clear_text },
      },
    },
  } = i18nContext;

  const existingGroupsIncludeGroup = (group: FleetNotificationRecipientGroup) =>
    !recipientGroups
      ?.filter(
        (x) => (x.canDisable && !x.disabled) || (!x.canDisable && x.mandatory) || (x.group === group && !x.disabled),
      )
      ?.map((x) => x.group)
      .includes(group);

  return (
    <div className="flex flex-col">
      <span className="text-md font-bold">{title}</span>

      <div className="flex flex-col mt-1">
        <div className="flex mb-0.5">
          {allRecipientGroupTypes.filter(existingGroupsIncludeGroup).map((x) => (
            <Button
              key={x}
              className="ml-1 border-px rounded-4 px-1 py-0.5 ui-button"
              onClick={() => {
                const updatedRecipientGroups = produce(recipientGroups, (draft) => {
                  if (draft) {
                    const existingIdx = draft.findIndex((g) => g.group === x && g.disabled);

                    if (existingIdx >= 0) {
                      const existingGroup = draft[existingIdx];
                      draft[existingIdx] = { ...existingGroup, disabled: false };
                    } else {
                      draft.push({
                        group: x,
                        mandatory: false,
                        allowOptOut: true,
                        disabled: false,
                        canDelete: true,
                        canDisable: false,
                      });
                    }
                  } else {
                    draft = [];
                    draft.push({
                      group: x,
                      mandatory: false,
                      allowOptOut: true,
                      disabled: false,
                      canDelete: true,
                      canDisable: false,
                    });
                  }
                });
                setRecipientGroups(updatedRecipientGroups);
                onChange({ customRecipients, recipientGroups: updatedRecipientGroups, recipientUserIds });
              }}
            >
              <span>{add_text}&nbsp;</span>

              <span>{fleetNotificationRecipientGroupDescriptionMap[x]}</span>
            </Button>
          ))}

          <Modal
            contentClassName="min-w-40"
            title={tSafe('components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.RecipientManager.user-search', {
              defaultValue: 'User Search',
            })}
            trigger={
              <Button className="ml-1 border-px rounded-4 px-1 py-0.5 ui-button">
                {tSafe('components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.RecipientManager.lookup-add-user', {
                  defaultValue: 'Lookup and add User',
                })}
              </Button>
            }
          >
            <UserSearch
              fleetId={focusedFleetTreeFleetId}
              onSelectUser={(user) => {
                const updatedUserIds = produce(recipientUserIds, (draft) => {
                  if (draft) {
                    const existingIdx = draft.findIndex((x) => x.userId === user.id && x.disabled);

                    if (existingIdx >= 0) {
                      const existingRecipient = draft[existingIdx];
                      draft[existingIdx] = { ...existingRecipient, disabled: false };
                    } else {
                      draft.push({
                        userId: user.id,
                        mandatory: false,
                        allowOptOut: true,
                        disabled: false,
                        canDelete: true,
                        canDisable: false,
                      });
                    }
                  } else {
                    draft = [
                      {
                        userId: user.id,
                        mandatory: false,
                        allowOptOut: true,
                        disabled: false,
                        canDelete: true,
                        canDisable: false,
                      },
                    ];
                  }
                });

                setRecipientUserIds(updatedUserIds);
                onChange({ customRecipients, recipientGroups, recipientUserIds: updatedUserIds });
              }}
            />
          </Modal>

          <Modal
            contentClassName="min-w-40"
            title={`Add custom ${channel}`}
            trigger={
              <Button className="ml-1 border-px rounded-4 px-1 py-0.5 ui-button">
                {channel === 'Email'
                  ? tSafe(
                      'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.RecipientManager.add-custom-email-recipient',
                      { defaultValue: 'Add custom email recipient' },
                    )
                  : tSafe(
                      'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.RecipientManager.add-custom-sms-recipient',
                      { defaultValue: 'Add custom SMS recipient' },
                    )}
              </Button>
            }
          >
            <div>
              <CustomRecipientForm
                channel={channel}
                onSubmit={(recipient) => {
                  const updatedRecipients = produce(customRecipients, (draft) => {
                    if (draft) {
                      const existingIdx = draft.findIndex(
                        (x) => x.recipientEmailOrPhone.toLowerCase() === recipient.toLowerCase(),
                      );

                      if (existingIdx >= 0) {
                        const existingRecipient = draft[existingIdx];
                        draft[existingIdx] = { ...existingRecipient, disabled: false };
                      } else {
                        draft.push({
                          recipientEmailOrPhone: recipient,
                          mandatory: false,
                          disabled: false,
                          canDelete: true,
                          canDisable: false,
                        });
                      }
                    } else {
                      draft = [
                        {
                          recipientEmailOrPhone: recipient,
                          mandatory: false,
                          disabled: false,
                          canDelete: true,
                          canDisable: false,
                        },
                      ];
                    }
                  });

                  setCustomRecipients(updatedRecipients);
                  onChange({ customRecipients: updatedRecipients, recipientGroups, recipientUserIds });
                }}
              />
            </div>
          </Modal>
        </div>

        <div className="flex flex-wrap bg-gray-100 p-1 rounded-8 min-h-15 w-full">
          {recipientGroups?.map((x) => (
            <RecipientCard
              key={x.group}
              recipientType={x.group}
              canDelete={channelRecipients?.recipientGroups?.find((y) => y.group === x.group)?.canDelete ?? true}
              canDisable={channelRecipients?.recipientGroups?.find((y) => y.group === x.group)?.canDisable ?? false}
              mandatory={x.mandatory}
              isDisabled={x.disabled}
              showAllowOptOut={true}
              allowOptOut={x.allowOptOut}
              onClickDelete={(recipientType) => {
                const updatedRecipientGroups = recipientGroups.filter(({ group }) => group !== recipientType);
                setRecipientGroups(updatedRecipientGroups);
                onChange({ customRecipients, recipientGroups: updatedRecipientGroups, recipientUserIds });
              }}
              onClickDisable={(isDisabled, recipientType) => {
                const groupIdxToDelete = recipientGroups.findIndex(({ group }) => group === recipientType);

                if (groupIdxToDelete >= 0) {
                  const updatedRecipientGroups = [...recipientGroups];
                  const groupToRemove = updatedRecipientGroups[groupIdxToDelete];
                  const updatedGroupToRemove = { ...groupToRemove, disabled: !isDisabled };

                  updatedRecipientGroups[groupIdxToDelete] = updatedGroupToRemove;

                  setRecipientGroups(updatedRecipientGroups);
                  onChange({ customRecipients, recipientGroups: updatedRecipientGroups, recipientUserIds });
                }
              }}
              onClickMandatory={(recipientType, isMandatory) => {
                const groupToChange = recipientGroups.find(({ group }) => group === recipientType);
                if (groupToChange) {
                  const updatedRecipientGroups = [...recipientGroups];
                  const idx = updatedRecipientGroups.findIndex(({ group }) => group === recipientType);
                  const obj = { ...groupToChange, mandatory: isMandatory };
                  updatedRecipientGroups[idx] = obj;
                  setRecipientGroups(updatedRecipientGroups);
                  onChange({ customRecipients, recipientGroups: updatedRecipientGroups, recipientUserIds });
                }
              }}
              onClickAllowOptOut={(recipientType, allowOptOut) => {
                const groupToChange = recipientGroups.find(({ group }) => group === recipientType);
                if (groupToChange) {
                  const updatedRecipientGroups = [...recipientGroups];
                  const idx = updatedRecipientGroups.findIndex(({ group }) => group === recipientType);
                  const obj = { ...groupToChange, allowOptOut };
                  updatedRecipientGroups[idx] = obj;
                  setRecipientGroups(updatedRecipientGroups);
                  onChange({ customRecipients, recipientGroups: updatedRecipientGroups, recipientUserIds });
                }
              }}
            />
          ))}

          {recipientUserIds?.map((x) => (
            <RecipientCard
              key={`userId_${x.userId}`}
              recipientType="userId"
              canDelete={channelRecipients?.recipientUserIds?.find((y) => y.userId === x.userId)?.canDelete ?? true}
              canDisable={channelRecipients?.recipientUserIds?.find((y) => y.userId === x.userId)?.canDisable ?? false}
              mandatory={x.mandatory}
              customValue={x.userId}
              isDisabled={x.disabled}
              showAllowOptOut={true}
              allowOptOut={x.allowOptOut}
              onClickDelete={(_, userId) => {
                const updatedRecipientUserIds = recipientUserIds.filter(
                  ({ userId: thisUserId }) => thisUserId !== userId,
                );
                setRecipientUserIds(updatedRecipientUserIds);
                onChange({ customRecipients, recipientGroups, recipientUserIds: updatedRecipientUserIds });
              }}
              onClickDisable={(isDisabled, _, userId) => {
                const recipientIdx = recipientUserIds.findIndex(({ userId: thisUserId }) => thisUserId === userId);

                if (recipientIdx >= 0) {
                  const updatedRecipients = [...recipientUserIds];
                  const userIdToUpdate = updatedRecipients[recipientIdx];
                  const newRecipientsToUpdate = { ...userIdToUpdate, disabled: !isDisabled };

                  updatedRecipients[recipientIdx] = newRecipientsToUpdate;

                  setRecipientUserIds(updatedRecipients);
                  onChange({ customRecipients, recipientGroups, recipientUserIds: updatedRecipients });
                }
              }}
              onClickMandatory={(_, isMandatory, userId) => {
                const recipientToChange = recipientUserIds.find(({ userId: thisUserId }) => thisUserId === userId);
                if (recipientToChange) {
                  const updatedRecipientUserIds = [
                    ...recipientUserIds.filter(({ userId: thisUserId }) => thisUserId !== userId),
                    { ...recipientToChange, mandatory: isMandatory },
                  ];
                  setRecipientUserIds(updatedRecipientUserIds);
                  onChange({ customRecipients, recipientGroups, recipientUserIds: updatedRecipientUserIds });
                }
              }}
              onClickAllowOptOut={(_, allowOptOut, userId) => {
                const recipientToChange = recipientUserIds.find(({ userId: thisUserId }) => thisUserId === userId);
                if (recipientToChange) {
                  const updatedRecipientUserIds = [
                    ...recipientUserIds.filter(({ userId: thisUserId }) => thisUserId !== userId),
                    { ...recipientToChange, allowOptOut },
                  ];
                  setRecipientUserIds(updatedRecipientUserIds);
                  onChange({ customRecipients, recipientGroups, recipientUserIds: updatedRecipientUserIds });
                }
              }}
            />
          ))}

          {customRecipients?.map((x) => (
            <RecipientCard
              key={`custom_email_${x.recipientEmailOrPhone}`}
              recipientType={channel === 'Email' ? 'customEmail' : 'customTelephone'}
              customValue={x.recipientEmailOrPhone}
              canDelete={
                channelRecipients?.customRecipients?.find((y) => y.recipientEmailOrPhone === x.recipientEmailOrPhone)
                  ?.canDelete ?? true
              }
              canDisable={
                channelRecipients?.customRecipients?.find((y) => y.recipientEmailOrPhone === x.recipientEmailOrPhone)
                  ?.canDisable ?? false
              }
              showAllowOptOut={false}
              mandatory={x.mandatory}
              isDisabled={x.disabled}
              onClickDelete={(_, customValue) => {
                const updatedCustomRecipients = customRecipients.filter(
                  ({ recipientEmailOrPhone }) => recipientEmailOrPhone !== customValue,
                );
                setCustomRecipients(updatedCustomRecipients);
                onChange({ customRecipients: updatedCustomRecipients, recipientGroups, recipientUserIds });
              }}
              onClickDisable={(isDisabled, _, customValue) => {
                const recipientIdx = customRecipients.findIndex(
                  ({ recipientEmailOrPhone }) => recipientEmailOrPhone === customValue,
                );

                if (recipientIdx >= 0) {
                  const updatedRecipients = [...customRecipients];
                  const recipientToUpdate = updatedRecipients[recipientIdx];
                  const newRecipientsToUpdate = { ...recipientToUpdate, disabled: !isDisabled };

                  updatedRecipients[recipientIdx] = newRecipientsToUpdate;

                  setCustomRecipients(updatedRecipients);
                  onChange({ customRecipients: updatedRecipients, recipientGroups, recipientUserIds });
                }
              }}
              onClickMandatory={(_, isMandatory, recipientEmailOrPhone) => {
                const recipientToChange = customRecipients.find(
                  ({ recipientEmailOrPhone: x }) => recipientEmailOrPhone === x,
                );
                if (recipientToChange) {
                  const updatedCustomRecipients = [
                    ...customRecipients.filter(({ recipientEmailOrPhone: x }) => x !== recipientEmailOrPhone),
                    { ...recipientToChange, mandatory: isMandatory },
                  ];
                  setCustomRecipients(updatedCustomRecipients);
                  onChange({ customRecipients: updatedCustomRecipients, recipientGroups, recipientUserIds });
                }
              }}
            />
          ))}
        </div>
      </div>

      {!![
        ...(channelRecipientsState?.recipientGroups?.filter((x) => !x.disabled && (x.canDelete || x.canDisable)) ?? []),
        ...(channelRecipientsState?.customRecipients?.filter((x) => !x.disabled && (x.canDelete || x.canDisable)) ??
          []),
        ...(channelRecipientsState?.recipientUserIds?.filter((x) => !x.disabled && (x.canDelete || x.canDisable)) ??
          []),
      ]?.length && (
        <Button
          onClick={() => {
            onClearAll?.();
            setRecipientGroups(
              produce(
                recipientGroups.filter((x) => !x.canDelete),
                (draft) => {
                  draft.forEach((x) => {
                    if (x.canDisable) {
                      x.disabled = true;
                    }
                  });
                },
              ),
            );
            setCustomRecipients(
              produce(
                customRecipients.filter((x) => !x.canDelete),
                (draft) => {
                  draft.forEach((x) => {
                    if (x.canDisable) {
                      x.disabled = true;
                    }
                  });
                },
              ),
            );
            setRecipientUserIds(
              produce(
                recipientUserIds.filter((x) => !x.canDelete),
                (draft) => {
                  draft.forEach((x) => {
                    if (x.canDisable) {
                      x.disabled = true;
                    }
                  });
                },
              ),
            );
          }}
          className="w-10 mt-1 text-md px-1 py-0.5 bg-gray-100 rounded-4 ui-button-dark"
        >
          {clear_text}
        </Button>
      )}

      <div className="w-full flex-center space-x-1">
        <Button
          onClick={() => modalContext?.closeModal?.()}
          className="w-10 mt-1 text-md px-1 py-0.5 bg-success text-white rounded-4 hover:brightness-90"
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default RecipientManager;
