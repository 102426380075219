import { ReactNode } from 'react';

import Button from 'atoms/Button';
import ConfirmModal, { ConfirmModalProps, useConfirmModal } from 'atoms/Modal/ConfirmModal';
import { cx } from 'utils';

interface ButtonWithConfirmModalProps extends Omit<ConfirmModalProps, 'modalProps'> {
  children: ReactNode;
  onConfirm: () => void;
  onReject?: () => void;
  className?: string;
}

const ButtonWithConfirmModal = ({
  children,
  onConfirm,
  onReject,
  className,
  confirmContent,
  confirmTitle,
  confirmButtonText,
  rejectButtonText,
  confirmContentClassName = '!max-w-1/4',
  confirmButtonContainerClassName,
  confirmYesButtonClassName = 'py-0.5 px-1 text-md border-px rounded-4 hover:bg-gray-100 text-success',
  confirmNoButtonClassName = 'py-0.5 px-1 text-md border-px rounded-4 ml-1 hover:bg-gray-100',
}: ButtonWithConfirmModalProps) => {
  const { openModal, props: modalProps } = useConfirmModal({
    title: confirmTitle,
    contentClassName: cx('max-w-50', confirmContentClassName),
    preventClose: true,
    showCloseButton: false,
  });

  const confirmProps = {
    modalProps,
    onConfirm,
    onReject,
    confirmButtonText,
    rejectButtonText,
    confirmContent,
    confirmButtonContainerClassName,
    confirmTitle,
    confirmYesButtonClassName,
    confirmNoButtonClassName,
  };

  return (
    <>
      <Button onClick={openModal} className={className}>
        {children}
      </Button>

      <ConfirmModal {...confirmProps} />
    </>
  );
};

export default ButtonWithConfirmModal;
