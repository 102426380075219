import { useContext } from 'react';

import Switch from 'atoms/Switch';
import { enabledNotificationTopicChannels } from 'common/notificationSettingsFields';
import { I18nContext } from 'common/useT';
import { NotificationType, Topic, UserNotificationChannelSettingsInput } from 'generated/graphql';

interface TableRowProps {
  data: UserNotificationChannelSettingsInput;
  eventName: string;
  topic: Topic;
  onChangeEmailEnabled: (value: boolean) => void;
  onChangeSmsEnabled: (value: boolean) => void;
  onChangePortalEnabled: (value: boolean) => void;
}

const TableRow = ({
  data,
  eventName,
  topic,
  onChangeEmailEnabled,
  onChangeSmsEnabled,
  onChangePortalEnabled,
}: TableRowProps) => {
  const i18nContext = useContext(I18nContext);
  const { email, portal, sms } = data;
  const enabledChannels = enabledNotificationTopicChannels[topic];
  const smsAvailable = enabledChannels?.includes(NotificationType.Sms);
  const emailAvailable = enabledChannels?.includes(NotificationType.Email);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { unavailable_text },
    },
  } = i18nContext;

  return (
    <tr key={eventName} className="border-b-px border-gray-300">
      <td>{eventName}</td>

      <td className="py-0.5">
        <Switch onChange={onChangePortalEnabled} initialValue={portal} />
      </td>

      <td>
        {emailAvailable ? (
          <Switch onChange={onChangeEmailEnabled} initialValue={email} />
        ) : (
          <span>{unavailable_text}</span>
        )}
      </td>

      <td>
        {smsAvailable ? <Switch onChange={onChangeSmsEnabled} initialValue={sms} /> : <span>{unavailable_text}</span>}
      </td>
    </tr>
  );
};

export default TableRow;
