import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { SelectOption } from 'types';
import { AggregateListRow } from 'types/settings';
import { ListColumnId, UserListColumnId } from 'generated/graphql';

const multiSelectFilter = <T,>(
  id: ListColumnId | UserListColumnId,
  items: SelectOption<T>[],
  options?: {
    searchable?: boolean;
    useTableFiltering?: boolean;
  },
) => {
  const Component = ({ column: { meta, id } }: { column: ColumnDef<AggregateListRow> }) => {
    const history = useHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setFilter = useMemo(() => meta?.filterComponent, []);

    const urlItems = useMemo(() => {
      if (!id) return;

      return (
        new URLSearchParams(history.location.search)
          .get(id)
          ?.split(',')
          ?.map((x) => items.find((item) => item.value === x)!)
          .filter((x) => x) ?? []
      );
    }, [history.location.search, id]);

    const { getProps, setSelectedItems } = useDropdownSelect(items, {
      multiselect: true,
      initialItems: urlItems as typeof urlItems,

      onSelect: (items) => {
        if (!id) return;

        const params = new URLSearchParams(history.location.search);

        if (items.length) {
          params.set(id, items.map((x) => x.value).join(','));
        } else {
          params.delete(id);
        }

        history.push({ pathname: history.location.pathname, search: params.toString() });
      },
      searchable: options?.searchable,
    });

    useEffect(() => {
      setSelectedItems(urlItems ?? []);
      if (urlItems && options?.useTableFiltering) meta?.filterComponent?.(urlItems?.map((x) => x.label));
    }, [urlItems, setFilter, setSelectedItems]);

    return <DropdownSelect {...getProps()} className="w-full text-left" />;
  };

  return <Component column={{ id }} />;
};

export default multiSelectFilter;
