import { QueryHookOptions } from '@apollo/client';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { defaultDashboardTimePeriod } from 'common/constants';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import {
  GetFleetDriversWithVehicleIdsQuery,
  GetVehicleDoc,
  GetVehicleQuery,
  GetFleetDriversWithVehicleIdsDoc,
} from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

export const useCurrentVehicleId = () =>
  useRouteMatch<{ vehicleId: string }>('/:fleetId/vehicles/:vehicleId')?.params?.vehicleId?.toLowerCase();

type QueryOptions = QueryHookOptions<GetVehicleQuery>;

export const useVehicleDetails = ({
  fetchPolicy = 'cache-only',
  nextFetchPolicy: _,
  overrideVehicleId,
  ...rest
}: Omit<QueryOptions, 'variables'> & { overrideVehicleId?: string } = {}) => {
  const currVehicleId = useCurrentVehicleId();

  return useQ(GetVehicleDoc, {
    variables: {
      vehicleIds: overrideVehicleId ?? currVehicleId!,
      period: defaultDashboardTimePeriod,
    },
    fetchPolicy,
    ...rest,
  });
};

export const useAvailableDrivers = (
  fleetId?: string,
  {
    fetchPolicy = 'cache-only',
    nextFetchPolicy: _,
    ...rest
  }: Omit<QueryHookOptions<GetFleetDriversWithVehicleIdsQuery>, 'variables'> = {},
) => {
  const currentFleetId = useCurrentFleetId();
  const fleetIds = fleetId ?? currentFleetId;
  const { data: drivers } = useQ(GetFleetDriversWithVehicleIdsDoc, {
    variables: { fleetIds: fleetIds },
    fetchPolicy,
    ...rest,
  });

  const availableDrivers = useMemo(() => drivers?.filter((driver) => !driver.associations?.vehicleId), [drivers]);

  return availableDrivers;
};
