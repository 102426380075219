import { ReactNode } from 'react';

interface SettingProps {
  label: string;
  children: ReactNode;
}

const Setting = ({ label, children }: SettingProps) => (
  <div className="flex items-center justify-between space-x-2 capitalize">
    <h4>{label}:</h4>

    <span className="w-10">{children}</span>
  </div>
);

export default Setting;
