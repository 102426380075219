import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { selectedVehicleState } from 'components/Live/state';
import InfoSummary from 'components/Live/VehicleSidebar/InfoSummary';

const VehicleSidebar = () => {
  const [selectedVehicle, setSelectedVehicle] = useRecoilState(selectedVehicleState);

  if (!selectedVehicle) return null;

  return (
    <div className="relative p-1 w-40 bg-gray-100 shadow-xl">
      <Button className="absolute left-1 top-1 ui-button-dark" aria-label="Close Vehicle Summary">
        <IonIcon name="closeOutline" className="text-2xl" onClick={() => setSelectedVehicle(undefined)} />
      </Button>

      <InfoSummary />
    </div>
  );
};

export default VehicleSidebar;
