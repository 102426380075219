import { useRecoilValue } from 'recoil';

import Icon from 'atoms/Icon';
import Modal from 'atoms/Modal';
import { DrivingStatsHistogram } from 'components/Charts/DrivingStatsHistogram';
import { Tile } from 'components/Driver/Detail/DrivingStats/TileGroup';
import { driverDetailStatisticsPeriodState } from 'components/Driver/state';
import { cx } from 'utils';

interface StatsTileProps extends Tile {
  selected?: boolean;
  className?: string;
  valueClassName?: string;
  defaultIconClassName?: string;
  showZeroValues?: boolean;
  count?: boolean;
  periodSource?: 'driverStatsPeriod';
}

export const StatsTile = ({
  name,
  description,
  value,
  histogramData,
  icon,
  selected,
  className,
  valueClassName,
  defaultIconClassName,
  showZeroValues,
  count,
}: StatsTileProps) => {
  const driverStatsPeriod = useRecoilValue(driverDetailStatisticsPeriodState);

  if (!showZeroValues && !value) return null;

  return (
    <Modal
      key={name}
      title={description}
      contentClassName="w-1/2 h-1/2 text-md overflow-y-hidden"
      trigger={
        <div
          className={cx(
            'h-11/12 flex items-center border rounded-8 border-dark-gray/20 pl-2 space-x-2 ui-button',
            className,
            selected && 'bg-gray-100',
            !value && 'text-dark-gray opacity-50',
          )}
        >
          <Icon
            name={typeof icon === 'string' ? icon : icon.name}
            className={`!w-4 py-1.5 flex-shrink-0 ${defaultIconClassName} ${
              typeof icon === 'string' ? '' : icon.className
            }`}
            aria-label={description}
          />

          <div className="flex flex-1 flex-col justify-center leading-7 space-y-0.5 break-word">
            <div className="text-md">{description}</div>

            {!count && <div className={`text-sm ${valueClassName}`}>{value}</div>}
          </div>

          {count && (
            <div
              className={`flex-center flex-shrink-0 w-5 h-3/4 text-3xl border-l border-dark-gray/20 ${valueClassName}`}
            >
              {value}
            </div>
          )}
        </div>
      }
    >
      <DrivingStatsHistogram
        period={driverStatsPeriod}
        histogramData={histogramData}
        description={description}
        className="flex-1 p-2 w-full h-full"
      />
    </Modal>
  );
};
