import { useMutation } from '@apollo/client';
import * as Menu from '@radix-ui/react-dropdown-menu';
import { compareAsc, parseISO } from 'date-fns';
import { useContext, useEffect, useState } from 'react';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { CenteredSpinner } from 'atoms/Spinner';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import NotificationItem from 'components/Navbar/Notifications/NotificationItem';
import { GetPortalNotificationsDoc, MarkNotificationsAsReadDoc } from 'generated/graphql';
import { cx } from 'utils';
import { useQ } from 'utils/apolloClient';

const Notifications = () => {
  const i18nContext = useContext(I18nContext);
  const { data, loading, fetchMore } = useQ(GetPortalNotificationsDoc, {
    variables: { offset: 0, limit: 10 },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
  const [countUnread, setCountUnread] = useState(0);
  const [sortByNewestFirst] = useState(true);
  const [markNotificationsAsRead] = useMutation(MarkNotificationsAsReadDoc);

  const onLoadMore = () => {
    fetchMore({
      variables: { offset: data?.notifications.length },
    });
  };

  useEffect(() => {
    if (data) {
      markNotificationsAsRead({
        variables: { ids: data.notifications.map((x) => x.id) },
      }).then(({ data: updatedData }) => {
        if (updatedData) {
          setCountUnread(updatedData.markPortalNotificationsAsRead.count);
        }
      });
      setCountUnread(data.count);
    }
  }, [data, markNotificationsAsRead]);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      domain: {
        notification: { notification_translatedWithCount },
      },
      pagination: { load_more },
    },
  } = i18nContext;

  return (
    <Menu.Root>
      <Menu.Trigger className="border-0 hover:bg-navbarHover px-2">
        <div className="relative w-3 h-3">
          {countUnread !== 0 && (
            <span
              className={cx(
                'absolute -mt-0.5 -mr-0.5 right-0 h-2 text-white text-sm font-normal bg-error rounded-8 flex-center z-50',
                countUnread > 99 ? 'w-[2.5rem]' : 'w-2',
              )}
            >
              {countUnread < 100 ? countUnread : '99+'}
            </span>
          )}

          <div>
            <Tooltip text={notification_translatedWithCount(0)}>
              <IonIcon className="text-4xl z-40" name="notificationsOutline" />
            </Tooltip>
          </div>
        </div>
      </Menu.Trigger>

      <Menu.Content
        sideOffset={7}
        align="end"
        className="min-w-40 max-h-60 bg-white rounded-4 px-1 shadow-card text-sm overflow-auto"
      >
        <div className="flex mb-1 sticky top-0 bg-white z-999 py-1">
          <h1 className="text-lg font-bold capitalize">{notification_translatedWithCount(0)}</h1>
        </div>

        {!loading && (
          <div className="absolute right-1 top-1 z-999">
            <span>{countUnread} </span>

            <span>{tSafe('components.Navbar.Notifications.unread', { defaultValue: 'unread' })} </span>

            <span>{notification_translatedWithCount(countUnread)}</span>
          </div>
        )}

        <div>
          <div>
            {!!data?.notifications.length &&
              data?.notifications
                .map((x) => ({ ...x, time: parseISO(x.time) }))
                .sort(({ time: time1 }, { time: time2 }) =>
                  sortByNewestFirst ? compareAsc(time2, time1) : compareAsc(time1, time2),
                )
                .map((item) => (
                  <div key={item.id}>
                    <NotificationItem key={`item_${item.id}`} item={item} />
                  </div>
                ))}
          </div>

          {data && data.count > data.notifications.length && (
            <div className="py-1">
              {!loading ? (
                <div>
                  <Button
                    className="my-1 px-1 py-0.5 ui-button rounded-4 border-px border-gray-300 text-md"
                    onClick={onLoadMore}
                  >
                    {load_more}
                  </Button>
                </div>
              ) : (
                <CenteredSpinner />
              )}
            </div>
          )}
        </div>
      </Menu.Content>
    </Menu.Root>
  );
};

export default Notifications;
