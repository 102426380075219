import { useMutation } from '@apollo/client';
import { useContext, useMemo } from 'react';

import Pagination from 'atoms/Pagination';
import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import {
  CurrencyUnit,
  DeleteReportDoc,
  DistanceUnit,
  GetReportNotificationsDoc,
  GetReportsDoc,
  Report,
  ReportPeriod,
  ReportSchedule,
  ReportType,
  UpdateReportDoc,
  VolumeUnit,
  WeeklyReport,
} from 'generated/graphql';
import { Result } from 'types';
import { cx } from 'utils';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ReportingColumnId, ReportingListRow } from 'types/settings';
import { LicencePlate } from 'atoms/LicencePlate';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import UpdateFrequencyForm from './UpdateFrequencyForm';
import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import ButtonWithConfirmModal from 'atoms/ButtonWithConfirmModal';
import Tooltip from 'atoms/Tooltip';

const ordinal = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

interface ReportTableProps {
  data: Result<typeof GetReportsDoc> | Result<typeof GetReportNotificationsDoc>; // TODO: type this to the return type of the query
  isNotifications: boolean;
  editMode: boolean;
}

const useColumns = (isEditMode: boolean, isNotification: boolean) => {
  const i18nContext = useContext(I18nContext);
  const accessibleFleetMap = useAccessibleFleetMap();
  const [deleteReportMutation] = useMutation(DeleteReportDoc);
  const [updateReportMutation] = useMutation(UpdateReportDoc);
  const {
    currencyUnit,
    dieselPricePerLitre,
    idleTimeAsPercentage,
    distanceInMiles,
    petrolPricePerLitre,
    volumeInGallons,
  } = useSettings();

  const memoisedColumns = useMemo(() => {
    const deleteReport = (id: string) =>
      deleteReportMutation({
        variables: { id },
        update: (cache, { data }) => {
          if (!data) return;

          cache.evict({ id: cache.identify(data.deleteReport) });
          cache.gc();
        },
      });

    const updateReport = (reportId: string, report: Omit<Report, 'driver' | 'vehicle' | 'createdAt'>) => {
      updateReportMutation({
        variables: {
          id: reportId,
          report: {
            fleetIds: report.fleetIds,
            type: report.type,
            schedule: report.schedule,
            dayOfMonth: report.dayOfMonth,
            dayOfWeek: report.dayOfWeek,
            dateFrom: report.dateFrom,
            dateRange: report.dateRange,
            dateTo: report.dateTo,
          },
        },
      });
    };

    if (i18nContext) {
      const {
        commonTranslations: {
          enums: {
            reportPeriodDescriptionMap,
            reportScheduleDescriptionMap,
            reportTypeDescriptionMap,
            daysOfWeekDescriptionMap,
          },
        },
        tSafe,
      } = i18nContext;

      const columnHelper = createColumnHelper<ReportingListRow>();

      const columns = [
        columnHelper.accessor<ReportingColumnId, string>('type', {
          cell: (props) => reportTypeDescriptionMap[props.getValue() as ReportType] ?? 'billo',
          id: 'type',
          header: () => tSafe('common.domain.report.fields.reportType', { defaultValue: 'Report type' }),
          size: 10,
        }),
        columnHelper.accessor<ReportingColumnId, string[]>('fleetIds', {
          cell: (props) => accessibleFleetMap[props.getValue()[0] ?? '']?.name,
          id: 'fleetIds',
          header: () => tSafe('common.domain.fleet.fleets', { defaultValue: 'Fleets' }),
          size: 120,
        }),
        columnHelper.accessor<ReportingColumnId, string>('dateRange', {
          cell: (props) =>
            props.getValue() ? (
              <span>{reportPeriodDescriptionMap[props.getValue() as ReportPeriod]}</span>
            ) : (
              <span>{`${props.row.original.dateFrom} to ${props.row.original.dateTo}`}</span>
            ),
          id: 'dateRange',
          header: () => tSafe('common.domain.report.fields.dateRange', { defaultValue: 'Date Range' }),
          size: 120,
        }),
        columnHelper.accessor<ReportingColumnId, string>('driverName', {
          cell: (props) => props.getValue(),
          id: 'driverName',
          header: () => 'Driver',
          size: 120,
        }),
        columnHelper.accessor<ReportingColumnId, string>('licencePlate', {
          cell: (props) =>
            props.getValue() ? <LicencePlate licenceNumber={props.getValue()} textClass="text-sm" /> : null,
          id: 'licencePlate',
          header: () => tSafe('common.domain.vehicle.vehicles_one', { defaultValue: 'Vehicle' }),
          size: 120,
        }),
        columnHelper.accessor<ReportingColumnId, string>('schedule', {
          cell: (props) => {
            if (isEditMode && !isNotification) {
              return <UpdateFrequencyForm reportId={props.row.original.id} initialState={props.row.original} />;
            }

            if (props.getValue() === ReportSchedule.Once) {
              return reportScheduleDescriptionMap[props.getValue() as ReportSchedule];
            }

            return (
              <>
                {`${reportScheduleDescriptionMap[props.getValue() as ReportSchedule]} ${
                  props.getValue() === ReportSchedule.Weekly && props.row.original.dayOfWeek
                    ? `on ${daysOfWeekDescriptionMap[props.row.original.dayOfWeek]}`
                    : `on the ${props.row.original.dayOfMonth ? ordinal(props.row.original.dayOfMonth) : ''}`
                }`}
              </>
            );
          },
          id: 'schedule',
          header: () => tSafe('common.domain.report.fields.schedule', { defaultValue: 'Frequency' }),
          size: 120,
        }),
        columnHelper.accessor<ReportingColumnId, string>('createdAt', {
          cell: (props) => format(parseISO(props.getValue()), 'PPPPp') ?? null,
          id: 'createdAt',
          header: () => 'Created',
          size: 120,
        }),
        columnHelper.accessor<ReportingColumnId, string>('reportSentAt', {
          cell: (props) =>
            props.getValue() ? format(parseISO(props.getValue()), 'dd/MM/yyyy hh:mm:ss a') : 'In Progress',
          id: 'reportSentAt',
          header: () => tSafe('common.domain.report.fields.reportSentAt', { defaultValue: 'Last Run' }),
          size: 120,
        }),
        columnHelper.accessor<ReportingColumnId, string>('id', {
          cell: (props) => {
            if (isNotification) return null;

            const settingsIdleTime = idleTimeAsPercentage ? '%' : 'h';
            const settingsDistanceUnit = distanceInMiles ? DistanceUnit.Miles : DistanceUnit.Kilometres;
            const settingsVolumeUnit = volumeInGallons ? VolumeUnit.Gallon : VolumeUnit.Litre;
            const settingsChanged =
              currencyUnit !== props.row.original.currencyUnit ||
              dieselPricePerLitre !== props.row.original.dieselPrice ||
              petrolPricePerLitre !== props.row.original.petrolPrice ||
              settingsIdleTime !== props.row.original.idleTime ||
              settingsDistanceUnit !== props.row.original.distanceUnit ||
              settingsVolumeUnit !== props.row.original.volumeUnit;

            if (isEditMode) {
              return (
                <div className="text-right mr-1">
                  {settingsChanged && (
                    <Button
                      aria-label={tSafe('components.Reporting.resync-report', {
                        defaultValue: 'Re-sync report',
                      })}
                      title={tSafe('components.Reporting.resync-report', {
                        defaultValue: 'Re-sync report',
                      })}
                      onClick={() => updateReport(props.row.original.id, props.row.original)}
                    >
                      <IonIcon name="syncOutline" />
                    </Button>
                  )}

                  <ButtonWithConfirmModal
                    className="ml-1"
                    confirmTitle={tSafe('components.Reporting.delete-report.title', {
                      defaultValue: 'Confirm Delete Report',
                    })}
                    confirmContent={tSafe('components.Reporting.delete-report.content', {
                      defaultValue: 'Are you sure you would like to delete this report?',
                    })}
                    onConfirm={() => deleteReport(props.row.original.id)}
                  >
                    <IonIcon name="trashOutline" />
                  </ButtonWithConfirmModal>
                </div>
              );
            }

            if (settingsChanged) {
              return (
                <div className="text-right mr-1">
                  <Tooltip text="This report is out of sync with your settings!">
                    <IonIcon name="warningOutline" className="bg-yellow" />
                  </Tooltip>
                </div>
              );
            }

            return null;
          },
          id: 'id',
          header: () => 'Last Run',
          size: 120,
        }),
      ];
      return columns;
    } else {
      return [];
    }
  }, [
    accessibleFleetMap,
    currencyUnit,
    deleteReportMutation,
    dieselPricePerLitre,
    distanceInMiles,
    idleTimeAsPercentage,
    isNotification,
    petrolPricePerLitre,
    volumeInGallons,
    updateReportMutation,
    i18nContext,
  ]);

  return memoisedColumns;
};

const ReportTable = ({ data, isNotifications, editMode }: ReportTableProps) => {
  const table = useReactTable({
    columns: useColumns(editMode, isNotifications),
    data: data.map((x) => ({
      id: x.id ?? '',
      type: x.type ?? '',
      fleetIds: x.fleetIds ?? [''],
      currencyUnit: x.currencyUnit ?? CurrencyUnit.Gbp,
      dateRange: x.dateRange ?? ReportPeriod.P30d,
      dateFrom: x.dateFrom ?? '',
      dateTo: x.dateTo ?? '',
      dayOfWeek: x.dayOfWeek ?? WeeklyReport.Mon,
      dayOfMonth: x.dayOfMonth ?? 1,
      distanceUnit: x.distanceUnit ?? DistanceUnit.Miles,
      dieselPrice: x.dieselPrice ?? 0,
      driverName: x.driver?.user.name ?? '',
      idleTime: x.idleTime ?? '',
      licencePlate: x.vehicle?.vehicle.licencePlate ?? '',
      petrolPrice: x.petrolPrice ?? 0,
      schedule: x.schedule ?? '',
      createdAt: x.createdAt ?? '',
      sentAt: x.reportSentAt ?? '',
      volumeUnit: x.volumeUnit ?? VolumeUnit.Litre,
    })),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const { getHeaderGroups, getRow, setPageIndex, getPageCount } = table;

  return (
    <table>
      <thead>
        {getHeaderGroups().map((group) => (
          <tr className="border-b-px border-gray-300">
            {group.headers.map((column) => (
              <th className={cx('px-1 py-1 font-bold text-left text-md')}>
                {flexRender(column.column.columnDef.header, column.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table.getRowModel().rows.map((row) => {
          getRow(row.id);

          return (
            <tr className="odd:bg-gray-300/30 text-md">
              {row.getVisibleCells().map((cell) => (
                <td className={cx('px-1 py-1')}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          );
        })}
      </tbody>

      <tfoot>
        <tr className="border-t-px border-gray-300 ">
          <td colSpan={9}>
            <div className="flex w-full justify-end">
              <Pagination onChange={setPageIndex} pageCount={!!getPageCount() ? getPageCount() : 1} showCounter />
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default ReportTable;
