import { ReactNode } from 'react';
import { theme } from 'twin.macro';

import IonIcon from 'atoms/IonIcon';
import Popover from 'atoms/Popover';

interface VehicleListEventCountProps {
  label: string;
  count?: number | null;
  children: ReactNode;
  bgColor?: string;
}

const CountWithDetails = ({ label, count, children, bgColor = theme`colors.error` }: VehicleListEventCountProps) => {
  if (!count) {
    return <div className="w-3 h-3 text-white text-md leading-none bg-success rounded-4 flex-center">0</div>;
  }

  return (
    <Popover content={children} label={label} asChild>
      <div className="relative w-3 h-3 text-white text-md leading-none rounded-4" style={{ backgroundColor: bgColor }}>
        <span className="absolute left-1/2 top-2/5 -translate-x-1/2 -translate-y-1/2">{count ?? 0}</span>

        <span className="absolute bottom-1/4 left-1/2 -translate-x-1/2 translate-y-1/2">
          <IonIcon name="chevronDownOutline" />
        </span>
      </div>
    </Popover>
  );
};

export default CountWithDetails;
