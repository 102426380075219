import { ComponentType } from 'react';
import { Layout } from 'react-grid-layout';

import * as BatteryEventCountWidget from 'components/Dashboard/BatteryEventCountWidget';
import * as DashboardLightEventCountWidget from 'components/Dashboard/DashboardLightEventCountWidget';
import * as DriverEventCountWidget from 'components/Dashboard/DriverEventCountWidget';
import * as DtcEventCountWidget from 'components/Dashboard/DtcEventCountWidget';
import * as EcoScoreWidget from 'components/Dashboard/EcoScoreWidget';
import * as FleetDrivingStatsWidget from 'components/Dashboard/FleetDrivingStatsWidget';
import * as IdleTimeLeaderboardWidget from 'components/Dashboard/IdleTimeLeaderboardWidget';
import * as LeaderboardWidget from 'components/Dashboard/LeaderboardWidget';
import * as UtilisationWidget from 'components/Dashboard/UtilisationWidget';
import { DashboardWidget } from 'types/widgets';

const maxH = 10;

const widgetMap: Record<DashboardWidget, Partial<Layout> & { component: ComponentType }> = {
  leaderboardWidget: {
    component: LeaderboardWidget.default,
    minH: 3,
    maxH,
  },
  idleTimeLeaderboardWidget: {
    component: IdleTimeLeaderboardWidget.default,
    minH: 3,
    maxH,
  },
  batteryEventCountWidget: {
    component: BatteryEventCountWidget.default,
    minH: 3,
    maxH,
  },
  dashboardLightEventCountWidget: {
    component: DashboardLightEventCountWidget.default,
    minH: 3,
    maxH: 15,
  },
  driverEventCountWidget: {
    component: DriverEventCountWidget.default,
    minH: 3,
    maxH,
  },
  fleetDrivingStatsWidget: {
    component: FleetDrivingStatsWidget.default,
    minH: 7,
    maxH: 15,
  },
  dtcEventCountWidget: {
    component: DtcEventCountWidget.default,
    minH: 3,
    maxH,
  },
  ecoScoreWidget: {
    component: EcoScoreWidget.default,
    minH: 4,
    maxH,
  },
  utilisationWidget: {
    component: UtilisationWidget.default,
    minH: 4,
    maxH,
  },
};

export default widgetMap;
