import { useMutation } from '@apollo/client';
import { ReactNode, useContext } from 'react';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { WidgetContext } from 'components/Dashboard/Widget';
import useSettings from 'components/Settings/useSettings';
import { SetWidgetHiddenItemsDoc } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { cx } from 'utils';

interface WidgetItemProps {
  name: string;
  children: ReactNode;
  className?: string;
}

const WidgetItem = ({ name, children, className }: WidgetItemProps) => {
  const { isEditMode: showAll, widgetKey } = useContext(WidgetContext);
  const settings = useSettings();
  const [setHiddenItems] = useMutation(SetWidgetHiddenItemsDoc);

  const { hiddenItems } = settings.dashboardWidgetSettings[widgetKey as DashboardWidget] ?? [];

  const isHidden = hiddenItems?.includes(name);

  const toggleItem = (key: string) => {
    const updatedHiddenItems = isHidden ? hiddenItems.filter((item) => item !== key) : [...hiddenItems, key];

    setHiddenItems({ variables: { widgetName: widgetKey, hiddenItems: updatedHiddenItems } });
  };

  if (!showAll && isHidden) return null;

  if (!showAll)
    return (
      <span className={className} data-testid="widget-item">
        {children}
      </span>
    );

  return (
    <div className={cx('relative', className, isHidden && 'opacity-40')} data-testid="widget-item">
      {children}

      <Button className="absolute right-1.5 top-1 text-lg ui-button" onClick={() => toggleItem(name)}>
        <IonIcon name={isHidden ? 'eyeOutline' : 'eyeOffOutline'} />
      </Button>
    </div>
  );
};

export default WidgetItem;
