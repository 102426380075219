import DateFilter from 'components/Dashboard/DateFilter';
import GeofenceBreachList from 'components/Live/Geofence/GeofenceSidebar/GeofenceBreaches/GeofenceBreachList';
import GeofenceBreachSearch from 'components/Live/Geofence/GeofenceSidebar/GeofenceBreaches/GeofenceBreachSearch';
import GeofenceBreachTypeFilter from 'components/Live/Geofence/GeofenceSidebar/GeofenceBreaches/GeofenceBreachTypeFilter';

const GeofenceBreaches = () => {
  return (
    <div className="flex flex-col">
      <GeofenceBreachSearch />

      <div className="flex flex-row justify-between items-end mb-0.5">
        <GeofenceBreachTypeFilter />

        <DateFilter />
      </div>

      <GeofenceBreachList />
    </div>
  );
};

export default GeofenceBreaches;
