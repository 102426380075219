import produce from 'immer';
import { useForm } from 'react-hook-form';

import Button from 'atoms/Button';
import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import Icon from 'atoms/Icon';
import { useModalContext } from 'atoms/Modal';
import { useT } from 'common/useT';
import { Vehicle, FuelType } from 'generated/graphql';
import { SelectOption } from 'types';
import { values } from 'utils';

export type VehicleEditFormData = Pick<Vehicle, 'make' | 'model' | 'year' | 'licencePlate' | 'vin' | 'seedOdo'> & {
  fuelType: FuelType;
  externalId?: string;
};

const fuelTypes = values(FuelType).map((item) => ({ label: item, value: item }));

interface VehicleEditFormProps {
  initialState?: VehicleEditFormData;
  onSubmit: (vehicle: VehicleEditFormData) => void;
  onCancel?: () => void;
  creationMode?: boolean;
  hideButtons?: boolean;
}

export const VehicleEditForm = ({
  initialState,
  onSubmit,
  onCancel,
  creationMode = false,
  hideButtons = false,
}: VehicleEditFormProps) => {
  const {
    commonTranslations: {
      domain: {
        vehicle: {
          fields: { make_text, model_text, year_text, licencePlate_text, vin_text, fuelType_text },
        },
      },
      forms: {
        buttons: { cancel_text, save_text },
        ensure_all_required_fields_filled_text,
        required_text,
      },
    },
  } = useT();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, dirtyFields, isDirty },
  } = useForm<VehicleEditFormData>({
    defaultValues: { fuelType: initialState?.fuelType ?? FuelType.Diesel },
    mode: 'onTouched',
  });

  const modalContext = useModalContext();

  const fieldLabels: { key: keyof VehicleEditFormData; label: string; required?: boolean }[] = [
    {
      key: 'make',
      label: make_text,
      required: true,
    },
    {
      key: 'model',
      label: model_text,
      required: true,
    },
    {
      key: 'year',
      label: year_text,
      required: true,
    },
    {
      key: 'licencePlate',
      label: licencePlate_text,
      required: true,
    },
    {
      key: 'vin',
      label: vin_text,
    },
  ];
  const fields = produce(fieldLabels, (fields) => {
    if (creationMode) {
      fields.push({ key: 'externalId', label: 'Device Serial Number', required: true });
    }
  });

  const { getProps } = useDropdownSelect(fuelTypes, {
    initialItem: fuelTypes.find((type) => type.value === watch('fuelType'))!,
    onSelect: (item: SelectOption) => setValue('fuelType', item.value as FuelType),
    buttonIcon: <Icon name="fuelconsumption" className="!h-2" />,
  });

  return (
    <div className="flex flex-col w-full text-md rounded-8">
      {isDirty && !isValid && <div className="flex text-error">{ensure_all_required_fields_filled_text}</div>}

      <section about="Vehicle Details">
        <form onSubmit={(e) => e.preventDefault()} className="grid gap-1 grid-cols-2 items-start my-2 p-1">
          {fields.map(({ key, label, required }) => (
            <div className="flex flex-col" key={key}>
              <label className="font-bold">
                {label}

                {required ? <span className="ml-0.5 text-error font-bold">*</span> : null}
              </label>

              <input
                defaultValue={initialState?.[key] ?? ''}
                className="p-0.5 w-full text-lg  rounded-4 outline outline-1 outline-dark-gray hover:outline-2"
                {...register(key, { required })}
              />
            </div>
          ))}

          <div className="flex flex-col">
            <label className="font-bold">
              <span>{fuelType_text}</span>

              <span className="ml-0.5 text-error font-bold">*</span>
            </label>

            <DropdownSelect {...getProps()} className="!w-full" />
          </div>
        </form>

        <div className="flex justify-end -mt-2 font-light text-sm text-gray-400">* {required_text}</div>

        {!hideButtons && (
          <div className="flex gap-1 justify-center my-3 w-full">
            <Button
              className="px-2 py-0.5 hover:bg-gray-100 border-px rounded-4"
              onClick={() => {
                modalContext?.closeModal?.();
                onCancel?.();
              }}
            >
              {cancel_text}
            </Button>

            {isDirty && (
              <Button
                className="px-2 py-0.5 bg-navbar hover:bg-navbarHover border-px rounded-4"
                onClick={() => {
                  if (isValid && Object.keys(dirtyFields).length) {
                    handleSubmit(onSubmit)();
                  }
                }}
              >
                {save_text}
              </Button>
            )}
          </div>
        )}
      </section>
    </div>
  );
};
