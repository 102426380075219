// eslint-disable-next-line import/no-duplicates

import { useContext, useEffect, useState } from 'react';

import Spinner from 'atoms/Spinner';
import { reverseGeocodeLookup } from 'common/geocodingApi';
import { I18nContext } from 'common/useT';
import { MiddleTripInfoPanel } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/TripInfoPanel/MiddleTripInfoPanel';
import { StartEndTripInfoPanel } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/TripInfoPanel/StartEndTripInfoPanel';
import { GetTripDoc } from 'generated/graphql';
import { NominatimReverseGeocodingResult } from 'types/geocoding';
import { useQ } from 'utils/apolloClient';

interface TripInfoPanelProps {
  id: string;
}

const formatAddress = (addressResult: NominatimReverseGeocodingResult) => {
  const {
    address: { road, town, city, country, postcode },
  } = addressResult;
  return [road, town, city, country, postcode].filter((x) => x).join(', ');
};

export const TripInfoPanel = ({ id }: TripInfoPanelProps) => {
  const i18nContext = useContext(I18nContext);
  const [tripStartAddress, setTripStartAddress] = useState<string>();
  const [tripEndAddress, setTripEndAddress] = useState<string>();
  const {
    loading,
    error,
    data: trip,
  } = useQ(GetTripDoc, {
    variables: {
      tripId: id!,
    },
  });

  useEffect(() => {
    const { startLocation, endLocation } = trip ?? {};

    setTripStartAddress(undefined);
    setTripEndAddress(undefined);

    if (!startLocation || !endLocation) return;

    const fetchAddress = async () => {
      const [startAddress, endAddress] = await Promise.all([
        reverseGeocodeLookup(startLocation),
        reverseGeocodeLookup(endLocation),
      ]);
      setTripStartAddress(formatAddress(startAddress));
      setTripEndAddress(formatAddress(endAddress));
    };
    fetchAddress();
  }, [trip]);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { failed_to_fetch_data_text },
    },
  } = i18nContext;

  if (error) return <div>{failed_to_fetch_data_text}</div>;
  if (loading) return <Spinner />;
  if (!trip) return null;

  const { endFuel, endTime, startFuel, startTime, startLocation, endLocation, tripEnded } = trip;

  return (
    <div>
      <div className="p-1 bg-gray-100 rounded-16">
        <span className="font-bold">
          {tSafe('components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripInfoPanel.start-trip', {
            defaultValue: 'Start Trip',
          })}
        </span>

        <StartEndTripInfoPanel time={startTime} address={tripStartAddress} location={startLocation} fuel={startFuel} />
      </div>

      <div className="flex flex-col items-center">
        <div className="w-2 h-1 bg-gray-100 rounded-b-16"></div>

        <div className="mt-1 w-2 h-2 bg-gray-100 rounded-16"></div>

        <div className="mt-1 w-2 h-1 bg-gray-100 rounded-t-16"></div>

        <div className="p-1 bg-gray-100 rounded-16">
          {tripEnded ? (
            <MiddleTripInfoPanel tripId={id} />
          ) : (
            <span>
              {tSafe(
                'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripInfoPanel.trip-in-progress',
                {
                  defaultValue: 'Trip in progress...',
                },
              )}
            </span>
          )}
        </div>

        <div className="w-2 h-1 bg-gray-100 rounded-b-16"></div>

        <div className="mt-1 w-2 h-2 bg-gray-100 rounded-16"></div>

        <div className="mt-1 w-2 h-1 bg-gray-100 rounded-t-16"></div>
      </div>

      <div className="p-1 bg-gray-100 rounded-16">
        {tripEnded ? (
          <>
            <span className="font-bold">
              {tSafe('components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripInfoPanel.end-trip', {
                defaultValue: 'End Trip',
              })}
            </span>

            <StartEndTripInfoPanel time={endTime} address={tripEndAddress} location={endLocation} fuel={endFuel} />
          </>
        ) : (
          <span>
            {tSafe(
              'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripInfoPanel.trip-not-yet-completed',
              {
                defaultValue: 'Trip not yet completed',
              },
            )}
          </span>
        )}
      </div>
    </div>
  );
};
