import { useContext, useEffect, useState } from 'react';

import Button from 'atoms/Button';
import FleetSearch from 'atoms/FleetSearch';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';

interface MultipleFleetSelectorWithSearchProps {
  selectedFleetId?: string;
  onChange: (fleetIds: string[]) => void;
  initialValue?: Set<string>;
  allowMultiple?: boolean;
}

const MultipleFleetSelectorWithSearch = ({
  onChange,
  initialValue,
  allowMultiple = false,
}: MultipleFleetSelectorWithSearchProps) => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();
  const fleetMap = useAccessibleFleetMap();
  const [, setTargetFleetId] = useState(currentFleetId);
  const [selectedFleetIds, setSelectedFleetIds] = useState(initialValue ?? new Set<string>());

  useEffect(() => {
    onChange(Array.from(selectedFleetIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFleetIds]);

  const addFleet = (fleetId: string) => {
    if (allowMultiple) {
      setSelectedFleetIds((prevState) => new Set(prevState).add(fleetId));
      return;
    }
    setTargetFleetId(fleetId);
    setSelectedFleetIds(new Set([fleetId]));
  };

  const removeFleet = (id: string) => {
    setSelectedFleetIds((prevState) => {
      const next = new Set(prevState);
      next.delete(id);

      return next;
    });
  };

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-start justify-between my-1">
        <FleetSearch
          className="relative"
          onFleetSelect={(fleetId) => {
            addFleet(fleetId);
          }}
        />
      </div>

      <div className="w-full border rounded-4 min-h-7 p-1">
        {!selectedFleetIds.size && (
          <div className="flex-center min-h-8">
            <h4>
              {tSafe('components.User.UserList.MultipleFleetSelectorWithSearch.no-fleets-assigned', {
                defaultValue: 'No fleets assigned',
              })}
            </h4>
          </div>
        )}

        {Array.from(selectedFleetIds).map((id) => (
          <div key={id} className="flex gap-0.5 p-1">
            <Button className="text-error text-lg" onClick={() => removeFleet(id)}>
              <IonIcon name="closeOutline" />
            </Button>

            {fleetMap[id]?.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipleFleetSelectorWithSearch;
