import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import { I18nContext } from 'common/useT';
import AssociationList from 'components/Live/Geofence/GeofenceSidebar/GeofenceSettings/AssociationList';
import AssociationSearch from 'components/Live/Geofence/GeofenceSidebar/GeofenceSettings/AssociationSearch';
import useCreateGeofenceMutation from 'components/Live/Geofence/useCreateGeofenceMutation';
import { selectedGeofenceState } from 'components/Live/state';
import useRole from 'components/User/useRole';
import { GeofenceColor, UpdateGeofenceDoc } from 'generated/graphql';
import { entries, omit } from 'utils';

export const geofenceColorMap: Record<GeofenceColor, string> = {
  [GeofenceColor.Blue]: '#3842A7',
  [GeofenceColor.Gray]: '#838B97',
  [GeofenceColor.Brown]: '#A78F0F',
  [GeofenceColor.Purple]: '#8D51D1',
  [GeofenceColor.Green]: '#0DC750',
};

const GeofenceSettings = () => {
  const i18nContext = useContext(I18nContext);
  const { isManager } = useRole();
  const [updateGeofence] = useMutation(UpdateGeofenceDoc);
  const [selectedGeofence, setSelectedGeofence] = useRecoilState(selectedGeofenceState);
  const [createGeofence] = useCreateGeofenceMutation();

  if (!selectedGeofence || !i18nContext) return null;

  const {
    commonTranslations: {
      domain: {
        geofence: {
          fields: { color_text },
        },
        association: { associations_text },
      },
      forms: {
        buttons: { cancel_text, save_text },
      },
    },
  } = i18nContext;

  const onCreateGeofence = () => {
    if (!selectedGeofence.name) return;

    createGeofence(omit(selectedGeofence, '__typename', 'fleetIds', 'vehicleIds'));

    setSelectedGeofence(undefined);
  };

  const onUpdateGeofence = () => {
    if (!selectedGeofence.name) return;

    const { id, ...geofenceInput } = omit(selectedGeofence, '__typename', 'fleetIds', 'vehicleIds');

    updateGeofence({
      variables: { id: id!, geofence: geofenceInput },
      optimisticResponse: { updateGeofence: { __typename: 'Geofence', id: id!, ...selectedGeofence } },
    });

    setSelectedGeofence(undefined);
  };

  return (
    <>
      {isManager && (
        <div className="space-y-1">
          <h3 className="text-md font-bold">{color_text}</h3>

          <div className="grid gap-0.5 self-center justify-center grid-cols-[repeat(4,2rem)] auto-rows-[2rem]">
            {entries(geofenceColorMap).map(([name, color]) => (
              <div
                key={color}
                onClick={() => setSelectedGeofence({ ...selectedGeofence, color: name })}
                className="w-full h-full"
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
        </div>
      )}

      {selectedGeofence.id && (
        <div className="space-y-1">
          <h3 className="text-md font-bold">{associations_text}</h3>

          {isManager && <AssociationSearch />}

          <AssociationList />
        </div>
      )}

      <div className="space-x-2 flex-center mt-2">
        <Button className="px-1 py-0.5 border rounded-4 ui-button-dark" onClick={() => setSelectedGeofence(undefined)}>
          {cancel_text}
        </Button>

        {isManager && (
          <Button
            className="px-1 py-0.5 text-success border border-success rounded-4 ui-button-dark"
            onClick={selectedGeofence.id ? onUpdateGeofence : onCreateGeofence}
            disabled={!selectedGeofence.name.length}
          >
            {save_text}
          </Button>
        )}
      </div>
    </>
  );
};

export default GeofenceSettings;
