import { useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { mapState, selectedGeofenceState, selectedVehicleState } from 'components/Live/state';
import { Geofence, GetFleetGeofencesDoc, GetVehicleGeofencesDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

import { GeofenceCard } from './GeofenceCard';

const GeofenceList = () => {
  const i18nContext = useContext(I18nContext);
  const selectedVehicle = useRecoilValue(selectedVehicleState)!;
  const setSelectedGeofence = useSetRecoilState(selectedGeofenceState);
  const currentMapState = useRecoilValue(mapState);
  const fleetId = useCurrentFleetId();
  const { data: fleetGeofences } = useQ(GetFleetGeofencesDoc, {
    variables: {
      fleetId,
      withSubfleets: false,
      withParentFleets: true,
    },
  });
  const { data: vehicleGeofences } = useQ(GetVehicleGeofencesDoc, {
    variables: {
      vehicleId: selectedVehicle.vehicleId,
    },
  });

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const showSelectedGeofence = (geofence: Geofence) => {
    setSelectedGeofence(geofence);
    currentMapState?.flyTo(geofence.coords, 13, { animate: false });
  };

  return (
    <div className="flex flex-col p-0.5 overflow-auto">
      <div className="mb-2 mt-1">
        <h4 className="text-lg font-bold">
          {tSafe('components.Live.VehicleSidebar.GeofenceList.fleet-geofences', { defaultValue: 'Fleet Geofences' })}
        </h4>

        {fleetGeofences?.map((geofence) => (
          <GeofenceCard key={geofence.id} geofence={geofence} onClick={showSelectedGeofence} />
        ))}
      </div>

      <div className="mb-1">
        <h4 className="text-lg font-bold">
          {tSafe('components.Live.VehicleSidebar.GeofenceList.vehicle-geofences', {
            defaultValue: 'Vehicle Geofences',
          })}
        </h4>

        {vehicleGeofences?.map((geofence) => (
          <GeofenceCard key={geofence.id} geofence={geofence} onClick={showSelectedGeofence} />
        ))}
      </div>
    </div>
  );
};

export default GeofenceList;
