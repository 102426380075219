import { useContext } from 'react';
import { useSetRecoilState } from 'recoil';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { geofenceNotificationsListState } from 'components/Live/state';

interface FilterVehicleProps {
  vehicle: { vehicleId: string; licencePlate: string; driverName: string };
}

const FilterVehicle = ({ vehicle }: FilterVehicleProps) => {
  const i18nContext = useContext(I18nContext);
  const setNotificationsListState = useSetRecoilState(geofenceNotificationsListState);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      forms: {
        buttons: { delete_text },
      },
    },
  } = i18nContext;

  const deleteFilter = () => {
    setNotificationsListState((prevState) => ({
      ...prevState,
      offset: 0,
      filterVehicles: prevState.filterVehicles.filter((x) => x.vehicleId !== vehicle.vehicleId),
    }));
  };
  return (
    <Button onClick={deleteFilter} className="" aria-label={delete_text}>
      <div className="group cursor-pointer relative flex flex-col py-0.5 px-1 border-px rounded-4 mr-0.5 bg-white text-sm">
        <IonIcon
          name="closeOutline"
          className="text-error text-xl opacity-0 group-hover:opacity-100 bg-white rounded-full absolute right-0.5 top-0.5"
        />

        <div className="">{vehicle.driverName}</div>

        <div className="font-bold">{vehicle.licencePlate}</div>
      </div>
    </Button>
  );
};

export default FilterVehicle;
