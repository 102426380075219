import { useContext } from 'react';

import Button from 'atoms/Button';
import Modal, { ModalProps, useModal } from 'atoms/Modal';
import { I18nContext } from 'common/useT';

export interface ConfirmModalProps {
  modalProps: ModalProps;
  confirmContent?: string | JSX.Element;
  confirmButtonText?: string;
  rejectButtonText?: string;
  onConfirm: () => void;
  onReject?: () => void;
  confirmTitle?: string;
  confirmContentClassName?: string;
  confirmButtonContainerClassName?: string;
  confirmYesButtonClassName?: string;
  confirmNoButtonClassName?: string;
}

const ConfirmModal = ({
  modalProps = { preventClose: true },
  confirmContent,
  confirmButtonText,
  rejectButtonText,
  onConfirm,
  onReject,
  confirmTitle = modalProps.title,
  confirmContentClassName = 'flex flex-col',
  confirmButtonContainerClassName = 'flex-center space-x-2 mt-2',
  confirmYesButtonClassName,
  confirmNoButtonClassName,
}: ConfirmModalProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      forms: {
        buttons: { confirm_text, cancel_text },
      },
    },
  } = i18nContext;
  return (
    <Modal {...modalProps} title={confirmTitle}>
      <div className={confirmContentClassName}>
        {confirmContent ?? <span>{tSafe('atoms.Modal.ConfirmModal.content', { defaultValue: 'Are you sure?' })}</span>}

        <div className={confirmButtonContainerClassName}>
          <Button
            className={confirmYesButtonClassName}
            onClick={() => {
              onConfirm();
              modalProps.setIsOpen?.(false);
            }}
          >
            {confirmButtonText ?? confirm_text}
          </Button>

          <Button
            className={confirmNoButtonClassName}
            onClick={() => {
              onReject?.();
              modalProps.setIsOpen?.(false);
            }}
          >
            {rejectButtonText ?? cancel_text}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const useConfirmModal = (opts?: Omit<ModalProps, 'children' | 'isOpen' | 'setIsOpen'>) => {
  const i18nContext = useContext(I18nContext);

  const { tSafe } = i18nContext ?? {};

  return useModal(
    opts ?? {
      title: tSafe && tSafe('atoms.Modal.ConfirmModal.title', { defaultValue: 'Confirm' }),
      preventClose: true,
    },
  );
};

export default ConfirmModal;
