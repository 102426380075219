import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useSetRecoilState } from 'recoil';

import { selectedGeofenceState } from 'components/Live/state';
import { GeofenceColor } from 'generated/graphql';

const useNewGeofenceHandler = () => {
  const map = useMap();
  const setSelectedGeofence = useSetRecoilState(selectedGeofenceState);

  useEffect(() => {
    // https://github.com/geoman-io/leaflet-geoman
    map.on('pm:create', ({ layer: circle }: { layer: L.Circle }) => {
      setSelectedGeofence({
        name: '',
        color: GeofenceColor.Gray,
        coords: { lat: circle.getLatLng().lat, lng: circle.getLatLng().lng },
        radius: circle.getRadius(),
        fleetIds: [],
        vehicleIds: [],
      });

      circle.remove();
    });
  }, [map, setSelectedGeofence]);
};

export default useNewGeofenceHandler;
