import * as battery from './battery';
import * as dashboardLightIcons from './dashboardLightIcons';
import * as device from './device';
import * as generic from './generic';
import * as logo from './logo';
import * as map from './map';
import * as service from './service';
import * as trips from './trips';
import * as uncategorised from './uncategorised';

const icons = {
  ...battery,
  ...device,
  ...dashboardLightIcons,
  ...generic,
  ...map,
  ...service,
  ...trips,
  ...uncategorised,
  ...logo,
};

export default icons;
export type IconName = keyof typeof icons;
