import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import WidgetTileGroup from 'atoms/WidgetTile/WidgetTileGroup';
import { dashboardLightsIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { EntityAssociation, GetDashboardLightEventCountDoc } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';

const DashboardLightEventCountWidget = () => {
  const i18nContext = useContext(I18nContext);

  const currentFleetId = useCurrentFleetId();
  const { loading, error, data } = useQ(GetDashboardLightEventCountDoc, {
    variables: { fleetId: currentFleetId },
  });
  const history = useHistory();

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardLightMessageTypeDescriptionMap, dashboardWidgetDescriptionMap },
    },
  } = i18nContext;

  const tiles = data?.map(({ type, description, count }) => ({
    name: type,
    description: description.length ? description : dashboardLightMessageTypeDescriptionMap[type],
    value: count,
    icon: dashboardLightsIconMap[type],
    onClick: () => {
      history.push(
        `/${currentFleetId}/list-view?${new URLSearchParams({
          dashboardLights: type,
        })}`,
        { associationState: EntityAssociation.AllVehicles, defaultList: true },
      );
    },
  }));

  return (
    <Widget
      title={dashboardWidgetDescriptionMap[DashboardWidget.dashboardLightEventCountWidget]}
      loading={loading}
      error={error}
    >
      <WidgetTileGroup tiles={tiles ?? []} valueClassName="text-error" count />
    </Widget>
  );
};

export default DashboardLightEventCountWidget;
