import { useMutation } from '@apollo/client';

import EditableLabel, { useEditableLabel } from 'atoms/EditableLabel';
import useUser from 'components/User/useUser';
import { UpdateUserDoc } from 'generated/graphql';
import { ClassName, Result } from 'types';
import { getError } from 'utils/apolloClient';
import { errorToast } from 'utils/toasts';

interface UserDetailsEditableLabelProps extends ClassName {
  field: keyof Pick<Result<typeof UpdateUserDoc>, 'email' | 'name' | 'phoneNumber'>;
}

const UserDetailsEditableLabel = ({ field, className }: UserDetailsEditableLabelProps) => {
  const user = useUser();

  const onChange = (value: string) => {
    updateUser({
      variables: { id: user.id, [field]: value },
    });
  };

  const { reset, getProps } = useEditableLabel({ initialValue: user[field] ?? '', onChange });

  const [updateUser, { loading }] = useMutation(UpdateUserDoc, {
    onError: (error) => {
      const err = getError(error);

      if (err?.message) {
        errorToast(err?.message);
      }

      reset();
    },
  });

  return <EditableLabel {...getProps()} className={className} inputClassName="px-1 py-0.5" isLoading={loading} />;
};

export default UserDetailsEditableLabel;
