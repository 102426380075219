import { Layouts } from 'react-grid-layout';

import widgetMap from 'components/Driver/Detail/widgetMap';
import { GridBreakpoints, SerializedGridLayout } from 'types/gridLayout';
import { DriverPageWidgetSettings } from 'types/settings';
import { DriverDetailPageWidget } from 'types/widgets';

export const driverPageWidgetHeightMap: Record<DriverDetailPageWidget, number> = {
  [DriverDetailPageWidget.ecoScoreWidget]: 5,
  [DriverDetailPageWidget.drivingStatsWidget]: 5,
  [DriverDetailPageWidget.vehicleInfoWidget]: 7,
  [DriverDetailPageWidget.driverLastKnownLocationWidget]: 4,
};

export const serializedLayoutToLayout = ({
  widgetSettings,
  collapsedWidgets,
}: {
  widgetSettings: DriverPageWidgetSettings;
  collapsedWidgets: string[];
}) =>
  Object.entries(widgetSettings).reduce((acc, [key, { layout }]) => {
    Object.entries(layout).forEach(([breakpoint, area]) => {
      acc[breakpoint] ??= [];

      if (!widgetMap[key]) return;

      const { component: _, ...defaultLayout } = widgetMap[key];

      acc[breakpoint].push({
        i: key,
        ...defaultLayout,
        ...area,
        h: collapsedWidgets.includes(key) ? 1 : driverPageWidgetHeightMap[key as DriverDetailPageWidget]!,
      });
    });

    return acc;
  }, {} as Layouts);

export const layoutToSerializedLayout = (layout: Layouts) =>
  Object.entries(layout).reduce((acc, [breakpoint, layout]) => {
    layout.forEach((widget) => {
      acc[widget.i] ??= {} as SerializedGridLayout[string];
      acc[widget.i][breakpoint as GridBreakpoints] = { x: widget.x, y: widget.y, w: widget.w, h: widget.h };
    });

    return acc;
  }, {} as SerializedGridLayout);
