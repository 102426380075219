// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB } from 'date-fns/locale';
import { useContext } from 'react';
import { useSetRecoilState } from 'recoil';

import Icon from 'atoms/Icon';
import { I18nContext } from 'common/useT';
import { activityHistorySelectedTripId } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/state';
import {
  DateTimeColumn,
  EventDetailContainer,
  EventTypeColumn,
  IconColumn,
  TripAdditionalColumn,
} from 'components/ActivityHistoryList/styledComponents';
import useSettings from 'components/Settings/useSettings';
import {
  DriverGeofenceBreachEvent,
  DriverTripEvent,
  VehicleGeofenceBreachEvent,
  VehicleTripEvent,
} from 'generated/graphql';
import { formatTime, kmToMi } from 'utils';

interface TripEventRowItemProps {
  event: Partial<VehicleTripEvent | DriverTripEvent> & {
    geofenceNotifications: Partial<VehicleGeofenceBreachEvent | DriverGeofenceBreachEvent>[];
  };
  time: string;
}

export const TripEventRowItem = ({ event, time }: TripEventRowItemProps) => {
  const i18nContext = useContext(I18nContext);
  const setTripId = useSetRecoilState(activityHistorySelectedTripId);
  const { distanceInMiles, idleTimeAsPercentage } = useSettings();
  const { duration, distance, idleTime } = event;

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      uom: { mi_text, km_text },
      domain: {
        trip: { trip_text },
        tripRecord: {
          fields: { duration_text, distance_text },
        },
      },
    },
  } = i18nContext;

  return (
    <EventDetailContainer onClick={() => setTripId(event.messageId)}>
      <DateTimeColumn>{format(parseISO(time), 'iii PPp', { locale: enGB })}</DateTimeColumn>

      <EventTypeColumn>{trip_text}</EventTypeColumn>

      <IconColumn>
        <Icon name="tripcount" className="!w-3" />
      </IconColumn>

      <TripAdditionalColumn>
        {duration ? (
          <>
            <div>{duration_text}</div>

            <div>{formatTime(duration)}</div>
          </>
        ) : (
          <div>
            {tSafe('components.ActivityHistoryList.ListRowItems.TripEventRowItem.trip-in-progress', {
              defaultValue: 'Trip In Progress',
            })}
          </div>
        )}
      </TripAdditionalColumn>

      <TripAdditionalColumn>
        <>
          <div>{distance_text}</div>

          <div>
            {distance && Math.round(distanceInMiles ? kmToMi(distance / 1000) : distance / 1000)}

            {distanceInMiles ? ` ${mi_text}` : ` ${km_text}`}
          </div>
        </>
      </TripAdditionalColumn>

      <TripAdditionalColumn>
        <>
          <div>Idle Time</div>

          <div>
            {idleTime
              ? idleTimeAsPercentage
                ? Math.round(duration ? (idleTime / duration) * 100 : 0)
                : formatTime(idleTime)
              : idleTimeAsPercentage
              ? '0%'
              : '0 sec'}

            {idleTime && idleTimeAsPercentage ? '%' : ''}
          </div>
        </>
      </TripAdditionalColumn>
    </EventDetailContainer>
  );
};
