import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';

import CurrencyDropdown from 'atoms/CurrencyDropdown';
import NumericInputField from 'atoms/NumericInputField';
import { I18nContext } from 'common/useT';
import Setting from 'components/Settings/Setting';
import SettingGroup from 'components/Settings/SettingGroup';
import useSettings from 'components/Settings/useSettings';
import { CurrencyUnit, UpdateSettingsDoc } from 'generated/graphql';

const FuelPriceSettings = () => {
  const i18nContext = useContext(I18nContext);
  const [updateSettings] = useMutation(UpdateSettingsDoc);
  const settings = useSettings();
  const [dieselPriceValidationError, setDieselPriceValidationError] = useState<string>();
  const [petrolPriceValidationError, setPetrolPriceValidationError] = useState<string>();

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      domain: {
        settings: {
          fields: { dieselPricePerLitre_text, petrolPricePerLitre_text, currencyUnit_text },
        },
      },
    },
  } = i18nContext;

  return (
    <SettingGroup title={tSafe('components.Settings.FuelPriceSettings.fuel-prices', { defaultValue: 'Fuel Prices' })}>
      <div className="flex flex-col gap-2">
        <Setting label={currencyUnit_text}>
          <CurrencyDropdown
            className="text-sm"
            onSelect={(option) => updateSettings({ variables: { currencyUnit: option.value as CurrencyUnit } })}
            currentValue={settings.currencyUnit}
          />
        </Setting>

        <Setting label={dieselPricePerLitre_text}>
          {dieselPriceValidationError && <span className="text-error">{dieselPriceValidationError}</span>}

          <NumericInputField
            className="text-sm w-10 pl-1"
            value={settings.dieselPricePerLitre}
            ariaLabel={dieselPricePerLitre_text}
            onValidate={(isValid, updatedValueIfValid, error) => {
              if (isValid) {
                setDieselPriceValidationError(undefined);
                updateSettings({ variables: { dieselPricePerLitre: updatedValueIfValid } });
              } else {
                setDieselPriceValidationError(error?.message);
              }
            }}
          />
        </Setting>

        <Setting label={petrolPricePerLitre_text}>
          {petrolPriceValidationError && <span className="text-error">{petrolPriceValidationError}</span>}

          <NumericInputField
            className="text-sm w-10 pl-1"
            value={settings.petrolPricePerLitre}
            onChange={(value) => updateSettings({ variables: { petrolPricePerLitre: value } })}
            ariaLabel={petrolPricePerLitre_text}
            onValidate={(isValid, updatedValueIfValid, error) => {
              if (isValid) {
                setPetrolPriceValidationError(undefined);
                updateSettings({ variables: { petrolPricePerLitre: updatedValueIfValid } });
              } else {
                setPetrolPriceValidationError(error?.message);
              }
            }}
          />
        </Setting>
      </div>
    </SettingGroup>
  );
};

export default FuelPriceSettings;
