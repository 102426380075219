import { useRecoilValue } from 'recoil';

import GeofenceLayer from 'components/Live/Geofence/GeofenceLayer';
import SelectedGeofenceCircle from 'components/Live/Geofence/SelectedGeofenceCircle';
import useNewGeofenceHandler from 'components/Live/Geofence/useNewGeofenceHandler';
import { liveSettingsState } from 'components/Live/state';

const Goefence = () => {
  useNewGeofenceHandler();
  const { fleetGeofenceDisplayMode } = useRecoilValue(liveSettingsState);

  return (
    <>
      {fleetGeofenceDisplayMode !== 'none' ? <GeofenceLayer /> : null}

      <SelectedGeofenceCircle />
    </>
  );
};

export default Goefence;
