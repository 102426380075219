import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { driverEventsIconMap, driverEventsIconStyleMap } from 'common/driverEvents';
import { I18nContext } from 'common/useT';
import { useDriverDetails } from 'components/Driver/Detail/hooks';
import { DriverEventType } from 'generated/graphql';
import { cx } from 'utils';

export const Status = () => {
  const i18nContext = useContext(I18nContext);
  const { data, loading, error } = useDriverDetails();

  if (loading || !i18nContext) return null;

  const {
    commonTranslations: {
      errors: { error_text, entity_not_found_tfn },
      domain: {
        driver: { driver_translatedWithCount },
      },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;
  if (!data) return <div>{entity_not_found_tfn(driver_translatedWithCount(1))}</div>;

  const [
    {
      statistics: { impactEventCounts: driverEventCounts },
    },
  ] = data;

  return (
    <div className="bg-white">
      {driverEventCounts
        .filter((x) => x.type !== DriverEventType.Unplug && x.type !== DriverEventType.NotReporting && x.count > 0)
        .map((eventCount) => (
          <div key={eventCount.type} className="flex w-10 h-5 bg-gray-100 border-px rounded-[10%]">
            <div className="flex justify-center w-3/5 border-r-px">
              <Icon
                className={cx('!w-4', driverEventsIconStyleMap[eventCount.type])}
                name={driverEventsIconMap[eventCount.type]}
              />
            </div>

            <div className="flex justify-center w-2/5 text-4xl">{eventCount.count}</div>
          </div>
        ))}
    </div>
  );
};
