import { useCallback, useContext, useMemo, useState } from 'react';

import IonIcon from 'atoms/IonIcon';
import { useModalContext } from 'atoms/Modal';
import { CenteredSpinner } from 'atoms/Spinner';
import useUserSearchSource, { MemoizedUser } from 'atoms/UserSearch/useUserSearchSource';
import { I18nContext } from 'common/useT';
import { GetUserSearchDoc } from 'generated/graphql';
import { cx } from 'utils';
import { useQ } from 'utils/apolloClient';

interface UserSearchProps {
  onSelectUser?: (user: MemoizedUser) => void;
  fleetId: string;
  searchInputClassName?: string;
  searchResultsClassName?: string;
}

const UserSearch = ({ onSelectUser, fleetId, searchInputClassName, searchResultsClassName }: UserSearchProps) => {
  const i18nContext = useContext(I18nContext);
  const [searchTerm, setSearchTerm] = useState('');
  const resetSearchTerm = useCallback(() => setSearchTerm(''), []);
  const modalContext = useModalContext();

  const { data, loading } = useQ(GetUserSearchDoc, {
    variables: { fleetId },
    fetchPolicy: 'cache-first',
  });

  const users = useMemo(
    () =>
      data?.map(({ id, email, name }) => ({
        id,
        email,
        name,
      })) ?? [],
    [data],
  );

  const results = [
    ...useUserSearchSource(users, searchTerm, (id) => {
      resetSearchTerm();
      onSelectUser?.(id);
      modalContext?.closeModal();
    }),
  ]
    .sort((a, b) => b.score - a.score)
    .map(({ jsx }) => jsx);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { loading_text },
    },
  } = i18nContext;

  if (loading)
    return (
      <>
        <div className="flex-center capitalize text-md">{loading_text}</div>

        <div className="w-full flex ml-4!h-3 text-sm">
          <CenteredSpinner />
        </div>
      </>
    );

  return (
    <div className="relative flex my-0.5 p-0.5 bg-white border-px border-gray-400 rounded-4 ">
      <input
        className={cx('pr-1 w-full bg-white focus:outline-none', searchInputClassName)}
        placeholder={tSafe('atoms.UserSearch.search-user-by-name', { defaultValue: 'Search for user by name' })}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <div className="text-2xl" aria-label="Search">
        <IonIcon name="searchOutline" />
      </div>

      <ul
        className={cx(
          'absolute left-0 right-0 text-sm top-[calc(100%+.5rem)] children:focus:outline-none z-999',
          searchResultsClassName,
        )}
      >
        {searchTerm && !!results?.length && (
          <div className="flex flex-col bg-gray-100 border rounded-8 overflow-hidden">
            <span className="max-h-80 overflow-auto">{results}</span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default UserSearch;
