import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import Icon from 'atoms/Icon';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { selectedGeofenceState } from 'components/Live/state';
import { DisassociateGeofenceFromFleetDoc, Geofence, GetFleetDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

interface FleetAssociationProps {
  fleetId: string;
}

const FleetAssociation = ({ fleetId }: FleetAssociationProps) => {
  const i18nContext = useContext(I18nContext);
  const [selectedGeofence, setSelectedGeofence] = useRecoilState(selectedGeofenceState);
  const { data: fleet } = useQ(GetFleetDoc, { variables: { fleetId } });
  const [disassociateFleet] = useMutation(DisassociateGeofenceFromFleetDoc);

  if (!fleet || !i18nContext) return null;

  const {
    commonTranslations: {
      domain: {
        fleet: { fleet_text },
      },
    },
  } = i18nContext;

  const onClick = () => {
    if (!selectedGeofence?.id) return;

    const updatedSelectedGeofence: typeof selectedGeofence = {
      ...selectedGeofence,
      fleetIds: selectedGeofence.fleetIds.filter((id) => id !== fleetId),
    };

    setSelectedGeofence(updatedSelectedGeofence);

    disassociateFleet({
      variables: { geofenceId: selectedGeofence.id, fleetId },
      optimisticResponse: { disassociateGeofenceFromFleet: updatedSelectedGeofence as Geofence },
    });
  };

  return (
    <div className="p-1 min-h-5 border rounded-2 space-x-1 flex-center">
      <Icon name="fleet" className="mr-1 !w-3" aria-label={fleet_text} />

      <h4 className="flex-1 text-sm font-bold">{fleet.name}</h4>

      <Button aria-label="unlink" onClick={onClick}>
        <IonIcon name="unlinkOutline" className="text-xl" />
      </Button>
    </div>
  );
};

export default FleetAssociation;
