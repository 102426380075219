import AddUserToolbarItem from 'components/User/UserList/UserListToolbar/AddUserToolbarItem';
import UserListEditModeToolbarItem from 'components/User/UserList/UserListToolbar/UserListEditModeToolbarItem';
import useRole from 'components/User/useRole';

const UserListToolbar = () => {
  const { isManager } = useRole();
  return (
    <>
      {isManager && <AddUserToolbarItem />}

      <UserListEditModeToolbarItem />
    </>
  );
};

export default UserListToolbar;
