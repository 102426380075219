import { QueryLazyOptions } from '@apollo/client';
import { useEffect } from 'react';
import { Marker } from 'react-leaflet';
import { useRecoilState, useRecoilValue } from 'recoil';

import { VehicleMarker } from 'atoms/LeafletMapIcons/VehicleMarker';
import {
  selectedGeofenceState,
  selectedVehicleState,
  showSelectedGeofenceVehiclesOnlyState,
} from 'components/Live/state';
import useAccessibleFleetMap, { getChildFleetIdsFromFleetMap } from 'components/User/useAccessibleFleetMap';
import { Exact, VehicleLastKnownLocation } from 'generated/graphql';

interface VehicleMarkersProps {
  vehicles: VehicleLastKnownLocation[] | undefined;
  loadTrips: (
    options?:
      | QueryLazyOptions<
          Exact<{
            vehicleId: string;
          }>
        >
      | undefined,
  ) => void;
  stopPollingTripsData: () => void;
}

export const VehicleMarkers = ({ vehicles, loadTrips, stopPollingTripsData }: VehicleMarkersProps) => {
  const fleetMap = useAccessibleFleetMap();
  const [selectedVehicle, setSelectedVehicle] = useRecoilState(selectedVehicleState);
  const selectedGeofence = useRecoilValue(selectedGeofenceState);
  const showSelectedGeofenceVehiclesOnly = useRecoilValue(showSelectedGeofenceVehiclesOnlyState);

  useEffect(() => {
    if (!selectedVehicle) stopPollingTripsData();
  });

  if (!vehicles || !fleetMap) return null;

  const markers = vehicles
    .filter(({ fleetIds: [fleetId], vehicleId }) => {
      if (selectedGeofence && showSelectedGeofenceVehiclesOnly) {
        const { fleetIds: geofenceFleetIds, vehicleIds } = selectedGeofence;
        const childFleetIds = new Set([
          ...geofenceFleetIds,
          ...geofenceFleetIds.flatMap((x) => getChildFleetIdsFromFleetMap(x, fleetMap)),
        ]);

        if (selectedGeofence.fleetIds.length && childFleetIds.has(fleetId)) {
          return true;
        }

        if (selectedGeofence.vehicleIds.length && vehicleIds.includes(vehicleId)) {
          return true;
        }

        return false;
      }
      return true;
    })
    .filter(({ lastKnownLocation }) => {
      if (!lastKnownLocation) return false;
      if (lastKnownLocation.lat === 0 && lastKnownLocation.lng === 0) return false;
      return true;
    })
    .map(({ driverName, driverId, licencePlate, vehicleId, tripEnded, lastKnownLocation }) => (
      <Marker
        key={vehicleId}
        position={[lastKnownLocation!.lat, lastKnownLocation!.lng]}
        icon={VehicleMarker({
          driverName: driverName,
          licencePlate: licencePlate,
          tripEnded: tripEnded,
          isSelected: selectedVehicle?.vehicleId === vehicleId,
        })}
        eventHandlers={{
          click: () => {
            setSelectedVehicle({
              vehicleId,
              driverId,
              driverName,
              licencePlate,
              tripEnded,
            });
            loadTrips({ variables: { vehicleId: vehicleId } });
          },
        }}
      />
    ));

  return <>{markers}</>;
};
