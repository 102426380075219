import { useContext } from 'react';

import { I18nContext } from 'common/useT';

import IonIcon from './IonIcon';

export enum ResponseType {
  NoFleetData,
  NotFound,
  FailedToFetch,
}

export enum SeverityLevel {
  Message,
  Warning,
  Error,
}

const NoDataResponse = ({
  level = SeverityLevel.Message,
  reason,
}: {
  level?: SeverityLevel;
  reason?: ResponseType;
}) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      errors: { failed_to_fetch_data_text, not_found_error_text, no_data_fleet_text },
    },
  } = i18nContext;

  const messageMap: Record<ResponseType, string> = {
    [ResponseType.NotFound]: not_found_error_text,
    [ResponseType.NoFleetData]: no_data_fleet_text,
    [ResponseType.FailedToFetch]: failed_to_fetch_data_text,
  };

  return (
    <div className="flex-center h-full items-center">
      {level === SeverityLevel.Error && <IonIcon name="warningOutline" className="text-lg text-error" />}

      <h3 className="flex-center text-gray-400 text-sm">{messageMap[reason ?? ResponseType.FailedToFetch]}</h3>
    </div>
  );
};

export default NoDataResponse;
