import { CellContext } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { AggregateListRow } from 'types/settings';

const LicencePlateCell = ({ cell }: { cell: CellContext<AggregateListRow, string> }) => {
  const currentFleetId = useCurrentFleetId();

  return (
    <Link
      to={`/${currentFleetId}/vehicles/${cell.row.original.vehicleId}`}
      className="text-left text-hover hover:underline uppercase"
    >
      {cell.getValue<string>() ?? '-----'}
    </Link>
  );
};

export default LicencePlateCell;
