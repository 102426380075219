import { useContext } from 'react';

import { I18nContext } from 'common/useT';

export interface ActivityHistoryPaginationProps {
  currentPageOffsetState: number[];
  setCurrentPageOffsetState: (currState: number[]) => void;
  nextPageOffset: number;
  showPaginationForwards: boolean;
  showPaginationBack: boolean;
  onNext?: () => void;
  onPrev?: () => void;
}

export const Pagination = ({
  currentPageOffsetState,
  setCurrentPageOffsetState,
  nextPageOffset,
  showPaginationForwards,
  showPaginationBack,
  onNext,
  onPrev,
}: ActivityHistoryPaginationProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      pagination: { next_page_text, previous_page_text },
    },
  } = i18nContext;

  return (
    <div className="m-1 w-full justify-between relative">
      {showPaginationBack ? (
        <div
          onClick={() => {
            const newOffsets = [...currentPageOffsetState.slice(0, -1)];

            setCurrentPageOffsetState(newOffsets);

            onPrev?.();
          }}
          className="absolute left-0 font-bold cursor-pointer ui-button bg-white p-2"
        >
          &lt; {previous_page_text}
        </div>
      ) : null}

      {showPaginationForwards ? (
        <div
          onClick={() => {
            const newOffsets = [...currentPageOffsetState, nextPageOffset];

            setCurrentPageOffsetState(newOffsets);

            onNext?.();
          }}
          className="absolute font-bold right-0 cursor-pointer ui-button bg-white p-2"
        >
          {next_page_text} &gt;
        </div>
      ) : null}
    </div>
  );
};
