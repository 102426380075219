import { ApolloCache } from '@apollo/client';

import { VehicleDeviceAssociationMutation, GetVehicleDoc } from 'generated/graphql';
import { Result } from 'types';

export const updateVehicleDeviceAssociationReference = (
  cache: ApolloCache<any>,
  data: VehicleDeviceAssociationMutation | null | undefined,
  vehicleDetails: Result<typeof GetVehicleDoc>[0],
) => {
  if (data) {
    cache.modify({
      id: cache.identify(vehicleDetails),
      fields: {
        associations(_, { toReference }) {
          return toReference(`Association:{"deviceId":"${data.vehicleDeviceAssociation.deviceId}"}`);
        },
      },
      broadcast: true,
    });
  }
};
