import { Circle } from 'react-leaflet';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { geofenceColorMap } from 'components/Live/Geofence/GeofenceSidebar/GeofenceSettings';
import {
  geofenceNotificationsListState,
  liveSettingsState,
  selectedGeofenceState,
  selectedVehicleState,
} from 'components/Live/state';
import { GetFleetGeofencesDoc, GetVehicleGeofencesDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

const GeofenceLayer = () => {
  const fleetId = useCurrentFleetId();
  const [selectedGeofence, setSelectedGeofence] = useRecoilState(selectedGeofenceState);
  const resetGeofenceNotificationsListState = useResetRecoilState(geofenceNotificationsListState);
  const selectedVehicleId = useRecoilValue(selectedVehicleState)?.vehicleId;
  const showAllGeofences = useRecoilValue(liveSettingsState).fleetGeofenceDisplayMode === 'all';

  const { data: fleetGeofences = [] } = useQ(GetFleetGeofencesDoc, {
    variables: {
      fleetId,
      withSubfleets: showAllGeofences,
      withParentFleets: showAllGeofences,
    },
  });

  const { data: vehicleGeofences = [] } = useQ(GetVehicleGeofencesDoc, {
    skip: !selectedVehicleId,
    variables: { vehicleId: selectedVehicleId! },
  });

  const geofences = [...fleetGeofences, ...vehicleGeofences];

  return (
    <>
      {geofences.map(({ id, color, coords: { lat, lng }, radius, ...rest }) =>
        id === selectedGeofence?.id ? null : (
          <Circle
            key={id}
            center={[lat, lng]}
            radius={radius}
            pathOptions={{ color: geofenceColorMap[color], fillColor: geofenceColorMap[color] }}
            eventHandlers={{
              click: () => {
                setSelectedGeofence({ id, coords: { lat, lng }, radius, color, ...rest });
                resetGeofenceNotificationsListState();
              },
            }}
          />
        ),
      )}
    </>
  );
};

export default GeofenceLayer;
