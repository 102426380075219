import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import Icon from 'atoms/Icon';
import useSearchSource, { SearchSourceComponent } from 'common/useSearchSource';
import { selectedGeofenceState } from 'components/Live/state';
import useUser from 'components/User/useUser';
import { AssociateGeofenceWithFleetDoc } from 'generated/graphql';

const SearchFleetItem: SearchSourceComponent = ({ id, innerHtml, onSelect }) => (
  <li
    className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
    onClick={() => onSelect?.(id)}
  >
    <Icon name="fleet" className="mr-1 !w-2" />

    <div className="flex flex-col">
      <span className="text-md" dangerouslySetInnerHTML={{ __html: innerHtml }} />
    </div>
  </li>
);

const useFleetSearchSource = (searchTerm: string, onSelect: () => void) => {
  const [selectedGeofence, setSelectedGeofence] = useRecoilState(selectedGeofenceState);

  const { accessibleFleets } = useUser();
  const fleets = useMemo(
    () => accessibleFleets?.filter((fleet) => !selectedGeofence?.fleetIds.includes(fleet.id)),
    [accessibleFleets, selectedGeofence?.fleetIds],
  );

  const [associateWithFleet] = useMutation(AssociateGeofenceWithFleetDoc);

  const selectHandler = useCallback(
    (fleet: NonNullable<typeof fleets>[0]) => {
      if (!selectedGeofence?.id) return;

      const updatedSelectedGeofence: typeof selectedGeofence = {
        ...selectedGeofence,
        fleetIds: [...selectedGeofence.fleetIds, fleet.id],
      };

      setSelectedGeofence(updatedSelectedGeofence);

      associateWithFleet({
        variables: { geofenceId: selectedGeofence!.id!, fleetId: fleet.id },
        optimisticResponse: {
          associateGeofenceWithFleet: { __typename: 'Geofence', id: selectedGeofence.id, ...updatedSelectedGeofence },
        },
      });

      onSelect?.();
    },
    [associateWithFleet, onSelect, selectedGeofence, setSelectedGeofence],
  );

  return useSearchSource({
    memoizedData: fleets,
    searchKey: 'name',
    searchTerm,
    Component: SearchFleetItem,
    onSelect: selectHandler,
  });
};

export default useFleetSearchSource;
