import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { currencySymbolMap } from 'common/descriptionMaps';
import { CurrencyUnit, Maybe } from 'generated/graphql';
import { SelectOption } from 'types';
import { values } from 'utils';

const currencyUnitOptions = values(CurrencyUnit).map((x) => ({ label: currencySymbolMap[x], value: x }));

const CurrencyDropdown = ({
  currentValue,
  onSelect,
  className = '!w-full text-lg',
}: {
  currentValue?: Maybe<CurrencyUnit>;
  onSelect: (item: SelectOption) => void;
  className?: string;
}) => {
  const { getProps } = useDropdownSelect(currencyUnitOptions, {
    initialItem: currencyUnitOptions.find((option) => option.value === currentValue)!,
    onSelect,
  });

  return <DropdownSelect {...getProps()} className={className} />;
};

export default CurrencyDropdown;
