import { useMutation } from '@apollo/client';
import { DetailedHTMLProps, HTMLAttributes, useContext, useState } from 'react';

import IonIcon from 'atoms/IonIcon';
import Modal, { useModal } from 'atoms/Modal';
import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import UserEditForm, { UserEditFormData } from 'components/User/UserList/UserEditForm';
import { ApiError, CreateUserDoc, GetUsersDoc } from 'generated/graphql';
import { getError } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';

const Trigger = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div {...props} className="hover:bg-gray-300">
      <span className="text-sm space-x-0.5 flex-center">
        <IonIcon className="text-lg" name="addCircleOutline" />

        <span>
          {tSafe('components.User.UserList.UserListToolbar.AddUserToolbarItem.create-user', {
            defaultValue: 'Create User',
          })}
        </span>
      </span>
    </div>
  );
};

const AddUserToolbarItem = () => {
  const { tSafe } = useContext(I18nContext) ?? {};
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const currentFleetId = useCurrentFleetId();
  const { closeModal, props: modalProps } = useModal({
    title:
      tSafe &&
      tSafe('components.User.UserList.UserListToolbar.AddUserToolbarItem.create-user', {
        defaultValue: 'Create User',
      }),
  });

  const [createUser, { client }] = useMutation(CreateUserDoc, {
    onCompleted: (mutationRes) => {
      client.cache.updateQuery({ query: GetUsersDoc, variables: { fleetId: currentFleetId } }, (data) => {
        const currUsers = data?.users ?? [];
        const index = currUsers.findIndex((x) => x.id === mutationRes.createUser.id);

        if (index !== -1) {
          const newList = [...currUsers];
          newList[index] = mutationRes.createUser;

          return {
            users: [...newList],
          };
        }

        return {
          users: [mutationRes.createUser, ...currUsers],
        };
      });

      if (tSafe) {
        successToast(
          tSafe('components.User.UserList.UserListToolbar.AddUserToolbarItem.success-toast', {
            defaultValue: 'The user has been successfully created',
          }),
        );
      }

      setUploadInProgress(false);
      closeModal();
    },
    onError: (rawError) => {
      setUploadInProgress(false);
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });

  const onSubmit = (user: UserEditFormData) => {
    setUploadInProgress(true);

    createUser({
      variables: {
        ...user,
        skipLoginEmail: !user.sendPasswordEmail,
      },
    });
  };
  return (
    <Modal {...modalProps} trigger={<Trigger />} contentClassName="w-80" preventClose>
      {uploadInProgress && (
        <div className="flex items-center">
          <CenteredSpinner className="z-700" />
        </div>
      )}

      <UserEditForm onSubmit={onSubmit} creationMode />
    </Modal>
  );
};

export default AddUserToolbarItem;
