import { useState } from 'react';

import { cx } from 'utils';

export enum ProgressBarUnitType {
  Percentage = 'Percentage',
  Degrees = 'Degrees',
}
export const ProgressBarUnitMap: Record<ProgressBarUnitType, string> = {
  Percentage: '%',
  Degrees: '°C',
};

interface ProgressBarProps {
  displayValue?: boolean;
  value?: number;
  color?: string;
}
const ProgressBar = ({ value = 0, displayValue, color = 'bg-success' }: ProgressBarProps) => {
  const [loadValue, setLoadValue] = useState(0);
  // Animate the bar
  setTimeout(() => setLoadValue(value), 0);

  return (
    <div className="relative">
      {displayValue && <div className="h-2.5 absolute justify-center flex w-full items-center">{`${value}%`}</div>}

      <div className={cx('rounded-24 bg-gray-300', displayValue ? 'h-2.5' : 'h-1')}>
        <div
          className={cx(`h-full ease-in-out	duration-1000 flex rounded-24`, color)}
          style={{ width: `${loadValue}%` }}
        />
      </div>
    </div>
  );
};
export default ProgressBar;
