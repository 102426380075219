import { useContext, useEffect, useState } from 'react';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { ClassName } from 'types';
import { cx } from 'utils';

interface PaginationProps {
  pageCount: number;
  initialPage?: number;
  onChange: (pageIndex: number) => void;
  showCounter?: boolean;
  prevLabel?: string;
  nextLabel?: string;
  vertical?: boolean;
}

const Pagination = ({
  pageCount,
  initialPage = 0,
  onChange,
  showCounter,
  prevLabel,
  nextLabel,
  className,
  vertical,
}: PaginationProps & ClassName) => {
  const i18nContext = useContext(I18nContext);
  const [pageIndex, setPageIndex] = useState(initialPage);

  useEffect(() => {
    onChange(pageIndex);
  }, [onChange, pageIndex]);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      pagination: { current_page_position_tfn },
    },
  } = i18nContext;

  const defaultButtonClasses = cx(
    vertical ? 'flex items-center gap-0.5 font-bold group' : 'flex-center flex-col px-2 py-1',
  );

  return (
    <div className={cx(`flex-center`, className)}>
      <div className="flex-center gap-3">
        <Button
          onClick={() => setPageIndex(pageIndex - 1)}
          disabled={pageIndex <= 0}
          className={cx(defaultButtonClasses, vertical && '')}
        >
          <IonIcon
            name="chevronForwardOutline"
            className={cx('rotate-180', vertical && 'transition-all group-hover:-translate-x-0.5')}
          />

          <span>{prevLabel}</span>
        </Button>

        {showCounter && <div className="text-sm">{current_page_position_tfn(pageIndex + 1, pageCount)}</div>}

        <Button
          onClick={() => setPageIndex(pageIndex + 1)}
          disabled={pageIndex >= pageCount - 1}
          className={cx(defaultButtonClasses, vertical && 'self-end')}
        >
          <IonIcon
            name="chevronForwardOutline"
            className={cx(vertical && 'transition-all order-1 group-hover:translate-x-0.5')}
          />

          <span>{nextLabel}</span>
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
