import { useMutation } from '@apollo/client';
import { PropsWithChildren, useContext } from 'react';
import { HeaderProps } from 'react-table';

import TableHeader from 'atoms/react-table/TableHeader';
import { WidgetContext } from 'components/Dashboard/Widget';
import useSettings from 'components/Settings/useSettings';
import { SetWidgetHiddenItemsDoc } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';

interface LeaderboardTableHeaderProps extends PropsWithChildren<HeaderProps<object>> {
  text: string;
  alignment?: 'left' | 'center' | 'right';
}

const LeaderboardTableHeader = ({ text, alignment, ...props }: LeaderboardTableHeaderProps) => {
  const { isEditMode, widgetKey } = useContext(WidgetContext);
  const settings = useSettings();
  const [setHiddenItems] = useMutation(SetWidgetHiddenItemsDoc);

  const onChange = (hiddenColumns: string[]) =>
    setHiddenItems({ variables: { widgetName: widgetKey, hiddenItems: hiddenColumns } });

  const { hiddenItems } = settings.dashboardWidgetSettings[widgetKey as DashboardWidget] ?? [];

  return (
    <TableHeader
      {...props}
      text={text}
      alignment={alignment}
      isEditMode={isEditMode}
      hiddenColumns={hiddenItems}
      onChange={onChange}
    />
  );
};

export default LeaderboardTableHeader;
