import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { debounce, escapeRegExp } from 'lodash';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { Exact, Query } from 'generated/graphql';
import { cx } from 'utils';
import { TypedDocumentNode, useLazyQuery } from '@apollo/client';

export interface SearchProps {
  searchQueryDoc: TypedDocumentNode<
    Partial<Query>,
    Exact<{
      searchTerm: string;
      fleetId: string;
    }>
  >;
  placeholder: string;
  key: 'driverSearch' | 'vehicleSearch';
  highlightKey: 'name' | 'licencePlate';
  iconName: 'personCircleOutline' | 'carSportOutline';
}

const Search = ({
  fleetId,
  onSelect,
  props,
}: {
  fleetId?: string;
  onSelect?: (id: string) => void;
  props: SearchProps;
}) => {
  const i18nContext = useContext(I18nContext);
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const currentFleetId = useCurrentFleetId();
  const fleetIdToSearch = fleetId ?? currentFleetId;
  const { highlightKey, iconName, key, placeholder, searchQueryDoc } = props;

  const [search, { data, loading }] = useLazyQuery(searchQueryDoc, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (searchTerm: string) => {
          searchTerm.length && search({ variables: { searchTerm, fleetId: fleetIdToSearch } });
        },
        600,
        { leading: true },
      ),
    [],
  );

  const updateValue = useCallback(
    (value: string) => {
      setSearchTerm(value);
      debouncedSearch(value);
    },
    [searchTerm],
  );

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const highlightText = (input: string, text: string) => {
    return input.replace(new RegExp(escapeRegExp(text), 'gi'), `<strong>$&</strong>`);
  };

  const renderResults = () => {
    if (loading) {
      return (
        <li className="flex px-2 py-1 text-left">
          {tSafe('atoms.search.driver-or-vehicle.fetching-results', {
            defaultValue: 'Fetching results...',
          })}
        </li>
      );
    }

    const results = data?.[key];

    if (results?.length) {
      return results.map((x: any) => (
        <li
          className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
          onClick={() => onSelect?.(x.id)}
          key={x.id}
        >
          <IonIcon name={iconName} className="mr-1 text-3xl" />
          <div className="flex flex-col">
            <span
              className="text-md"
              dangerouslySetInnerHTML={{
                __html: highlightText(x[highlightKey], searchTerm),
              }}
            />
          </div>
        </li>
      ));
    }

    return (
      <li className="flex px-2 py-1 text-left">
        {tSafe('atoms.search.driver-or-vehicle.no-results', { defaultValue: 'No results' })}
      </li>
    );
  };

  return (
    <div className="relative flex my-0.5 p-0.5 bg-white border-px border-gray-400 rounded-4">
      <input
        className={cx('pr-1 w-full bg-white focus:outline-none')}
        value={searchTerm}
        onChange={(e) => updateValue(e.target.value)}
        placeholder={placeholder}
        onBlur={() => setTimeout(() => setSearchTerm(''), 180)}
        ref={inputRef}
      />
      <div className="text-2xl" aria-label="Search">
        <IonIcon name="searchOutline" />
      </div>

      <ul className="absolute z-600 left-0 right-0 text-sm top-[calc(100%+.5rem)] children:focus:outline-none">
        {searchTerm && (
          <div className="flex flex-col bg-gray-100 border border-gray-400 rounded-8 overflow-hidden">
            <span className="max-h-20 overflow-auto">{renderResults()}</span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default Search;
