import FuelPriceSettings from 'components/Settings/FuelPriceSettings';
import Preferences from 'components/Settings/Preferences';
import UserDetails from 'components/Settings/UserDetails';
import UserNotificationSettings from 'components/Settings/UserNotificationSettings';

const Settings = () => (
  <div className="flex flex-col">
    <div className="grid gap-1 grid-rows-1 p-2 auto-rows-fr grid-cols-[repeat(auto-fill,minmax(30rem,1fr))]">
      <UserDetails />

      <Preferences />

      <FuelPriceSettings />
    </div>

    <div className="grid gap-1 grid-rows-1 p-2 auto-rows-fr grid-cols-[repeat(auto-fill,minmax(60rem,1fr))]">
      <UserNotificationSettings />
    </div>
  </div>
);

export default Settings;
