import * as Menu from '@radix-ui/react-dropdown-menu';
import { Link } from 'react-router-dom';

import IonIcon, { IonIconName } from 'atoms/IonIcon';
import { cx } from 'utils';

interface ProfileMenuLinkProps {
  to?: string;
  icon: IonIconName;
  label: string;
  onClick?: () => void;
  className?: string;
}

const ProfileMenuLink = ({ to = '#', icon, label, onClick, className }: ProfileMenuLinkProps) => (
  <Link to={to} onClick={onClick}>
    <Menu.Item
      className={cx(
        'flex relative items-center leading-none hover:bg-hover hover:text-white select-none rounded-4 h-4 p-0.5 pl-4',
        className,
      )}
      aria-label={label}
    >
      <div className="absolute left-0 w-4 inline-flex items-center justify-center">
        <IonIcon name={icon} className="text-lg" />
      </div>

      <span>{label}</span>
    </Menu.Item>
  </Link>
);

export default ProfileMenuLink;
