import { add, addSeconds, differenceInSeconds, format, parseISO } from 'date-fns';
import { Map } from 'leaflet';
import { useContext, useState } from 'react';
import {
  Bar,
  Cell,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { theme } from 'twin.macro';

import Switch from 'atoms/Switch';
import { I18nContext } from 'common/useT';
import {
  activityHistoryClickedSegment,
  activityHistoryHoveredSegment,
  streetViewOnHoverAtom,
} from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/state';
import useSettings from 'components/Settings/useSettings';
import { LatLng, Maybe, TripEvent, TripRouteGeometryDetail } from 'generated/graphql';
import { kmToMi } from 'utils';

const ChartWrapper = styled.div`
  .recharts-surface {
    overflow: visible;
  }
`;

interface TripSpeedHistogramProps {
  geometryDetail: Maybe<TripRouteGeometryDetail[] | undefined>;
  mapInstance: Map | undefined;
  events: TripEvent[] | undefined | null;
  tripStartTime: string;
}

export interface HistogramBarSegment {
  averageSpeed: number;
  maxSpeed: number;
  minSpeed: number;
  timeFrom: Date;
  timeTo?: Date;
  interval: number;
  segmentGeometry: LatLng[];
}

const formatSecondsAsTime = (tick: string, tripStartTime: string, displayWallClockTime: boolean) => {
  if (displayWallClockTime) return format(add(parseISO(tripStartTime), { seconds: parseInt(tick) }), 'HH:mm:ss');

  const digitFormat = (n: number) => n.toLocaleString('en-GB', { minimumIntegerDigits: 2 });
  const time = parseInt(tick);
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  return `${hours > 0 ? digitFormat(hours) : ''}${hours > 0 ? ':' : ''}${digitFormat(minutes)}:${digitFormat(seconds)}`;
};

export const TripSpeedHistogram = ({ geometryDetail, mapInstance, events, tripStartTime }: TripSpeedHistogramProps) => {
  const i18nContext = useContext(I18nContext);
  const { distanceInMiles } = useSettings();
  const [activeIndex, setActiveIndex] = useState<number>();
  const [displayWallClockTime, setDisplayWallClockTime] = useState(true);
  const setHoveredSegment = useSetRecoilState(activityHistoryHoveredSegment);
  const setClickedSegment = useSetRecoilState(activityHistoryClickedSegment);
  const [streetViewOnHover, setStreetViewOnHover] = useRecoilState(streetViewOnHoverAtom);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { unavailable_text },
      uom: { kph_text, mph_text },
      domain: {
        tripRecord: {
          fields: { avgSpeed_text, maxSpeed_text, minSpeed_text },
        },
      },
    },
  } = i18nContext;

  if (!geometryDetail?.length) return <div>{unavailable_text}</div>;

  const description = distanceInMiles
    ? tSafe('components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripSpeedHistogram.speed-mph', {
        defaultValue: 'Speed mph',
      })
    : tSafe('components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripSpeedHistogram.speed-kmph', {
        defaultValue: 'Speed km/h',
      });

  const [{ time: initialTime }] = geometryDetail;
  const { time: timeLastItem } = geometryDetail[geometryDetail.length - 1];

  const firstItemTime = parseISO(initialTime);
  const lastItemTime = parseISO(timeLastItem);
  const timeDifferenceInSeconds = differenceInSeconds(lastItemTime, firstItemTime);
  const numberOfTimeSegments = Math.min(60, Math.ceil(timeDifferenceInSeconds / 5));
  const segmentTimeIntervalInSeconds = Math.round(timeDifferenceInSeconds / numberOfTimeSegments);
  const timeSegments = [...Array(numberOfTimeSegments)].map((_, i) =>
    addSeconds(firstItemTime, i * segmentTimeIntervalInSeconds),
  );

  const speedData = geometryDetail.map(({ speed, time, location }) => ({
    speed: distanceInMiles && speed ? kmToMi(speed) : speed,
    time: parseISO(time),
    location,
  }));

  type TimeSegment = {
    segmentTimeStart: Date;
    segmentTimeEnd?: Date;
    speeds: number[];
    segmentStartTimeInSeconds: number;
    segmentGeometry: LatLng[];
  };

  const timeSegmentedData = timeSegments.reduce((acc, timeFrom, idx) => {
    const timeTo = timeSegments[idx + 1] ? timeSegments[idx + 1] : undefined;
    const filteredData = speedData.filter((item) => {
      return item.time.getTime() >= timeFrom.getTime() && (timeTo ? item.time.getTime() < timeTo.getTime() : true);
    });

    acc.push({
      segmentTimeStart: timeFrom,
      segmentTimeEnd: timeTo,
      speeds: filteredData ? filteredData.map(({ speed }) => speed ?? 0) : [],
      segmentStartTimeInSeconds: segmentTimeIntervalInSeconds * idx,
      segmentGeometry: filteredData.map((item) => item.location),
    });

    return acc;
  }, [] as TimeSegment[]);

  const histogramData: HistogramBarSegment[] = timeSegmentedData.map(
    ({ segmentTimeStart, segmentStartTimeInSeconds: interval, speeds, segmentTimeEnd, segmentGeometry }) => ({
      averageSpeed: Math.round(speeds.reduce((a, b) => a + b, 0) / speeds.length) || 0,
      maxSpeed: speeds.length ? Math.round(Math.max(...speeds)) : 0,
      minSpeed: speeds.length ? Math.round(Math.min(...speeds)) : 0,
      timeFrom: segmentTimeStart,
      timeTo: segmentTimeEnd,
      interval,
      segmentGeometry,
    }),
  );

  if (!histogramData.map((item) => item.averageSpeed).length)
    return (
      <div>
        {tSafe('components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripSpeedHistogram.no-data', {
          defaultValue: 'No Speed Data Available',
        })}
      </div>
    );

  const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
    const formatLabel = (item: Payload<ValueType, NameType>) => {
      const { value, name } = item;
      switch (name) {
        case avgSpeed_text:
          return (
            <p key="avSpeed" className="label">
              {tSafe(
                'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripSpeedHistogram.tooltip.average-speed',
                {
                  defaultValue: 'Average Speed : {{value}} {{unit}}',
                  value,
                  unit: distanceInMiles ? mph_text : kph_text,
                },
              )}
            </p>
          ); //
        case maxSpeed_text:
          return (
            <p key="maxSpeed" className="label">
              {tSafe(
                'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripSpeedHistogram.tooltip.max-speed',
                {
                  defaultValue: 'Max Speed : {{value}} {{unit}}',
                  value,
                  unit: distanceInMiles ? mph_text : kph_text,
                },
              )}
            </p>
          );
        case minSpeed_text:
          return (
            <p key="minSpeed" className="label">
              {tSafe(
                'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripSpeedHistogram.tooltip.min-speed',
                {
                  defaultValue: 'Min Speed : {{value}} {{unit}}',
                  value,
                  unit: distanceInMiles ? mph_text : kph_text,
                },
              )}
            </p>
          );
        default:
          return null;
      }
    };
    if (active) {
      return (
        <div className="p-1 bg-white border-px rounded-2">
          <p>
            <span>
              {tSafe(
                'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripSpeedHistogram.segment-start-time',
                {
                  defaultValue: 'Segment Start Time:',
                },
              )}
            </span>

            <span>{formatSecondsAsTime(label, tripStartTime, displayWallClockTime)}</span>
          </p>

          {payload?.map((item) => formatLabel(item))}
        </div>
      );
    }

    return null;
  };

  const setActiveBarIndex = (_: HistogramBarSegment, idx: number) => setActiveIndex(idx);

  const setMapPolyLineLocations = (data: HistogramBarSegment) => {
    setHoveredSegment(data);

    if (mapInstance && data.segmentGeometry.length) {
      mapInstance.flyTo(data.segmentGeometry[0], undefined, { animate: false });
    }
  };

  const tripEventTimes = events ? events.map((item) => parseISO(item.time)) : ([] as Date[]);
  const barName = avgSpeed_text;
  const minSpeedLineName = minSpeed_text;
  const maxSpeedLineName = maxSpeed_text;

  return (
    <div className="p-2 w-full flex flex-col relative">
      <ChartWrapper className="flex-center h-30">
        <ResponsiveContainer width="90%" height="100%">
          <ComposedChart data={histogramData}>
            <XAxis
              tickMargin={2}
              height={50}
              minTickGap={20}
              dataKey="interval"
              ticks={histogramData.map((data) => data.interval)}
              tickFormatter={(tick) => formatSecondsAsTime(tick, tripStartTime, displayWallClockTime)}
            >
              <Label fill={theme`colors.dark-gray`} dy={10}>
                {tSafe(
                  'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripSpeedHistogram.chart.x-axis.label',
                  {
                    defaultValue: 'Journey Time Start -&gt; End',
                  },
                )}
              </Label>
            </XAxis>

            <YAxis width={40}>
              <Label angle={-90} fill={theme`colors.dark-gray`} dx={-20}>
                {description}
              </Label>
            </YAxis>

            <Tooltip content={<CustomTooltip />} cursor={{ fill: 'red' }} />

            <Bar
              name={barName}
              dataKey="averageSpeed"
              fill={theme`colors.secondary`}
              onMouseOver={(data: HistogramBarSegment, idx: number) => {
                setMapPolyLineLocations(data);
                setActiveBarIndex(data, idx);
              }}
              onClick={setClickedSegment}
            >
              {histogramData.map(({ timeFrom, timeTo }, idx) => {
                const tripEventFallWithinRange = tripEventTimes.some((eventTime) =>
                  eventTime.getTime() >= timeFrom.getTime() && timeTo ? eventTime.getTime() < timeTo.getTime() : false,
                );
                const defaultColor = tripEventFallWithinRange ? theme`colors.error` : theme`colors.secondary`;

                return <Cell cursor="pointer" fill={idx === activeIndex ? 'blue' : defaultColor} key={`cell-${idx}`} />;
              })}
            </Bar>

            <Line name={minSpeedLineName} dataKey="minSpeed" stroke="green" dot={false} />

            <Line name={maxSpeedLineName} dataKey="maxSpeed" stroke={theme`colors.error`} dot={false} />

            <Legend wrapperStyle={{ bottom: -20, left: 25 }} />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartWrapper>

      <div className="z-10 absolute bottom-3 left-3 flex items-center gap-1">
        <span>
          {tSafe(
            'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripSpeedHistogram.chart.street-view-hover',
            {
              defaultValue: 'Street View on hover',
            },
          )}
        </span>

        <Switch onChange={(state) => setStreetViewOnHover(state)} initialValue={streetViewOnHover} />
      </div>

      <div className="z-10 absolute bottom-3 right-3 flex items-center gap-1">
        <span>
          {tSafe(
            'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripSpeedHistogram.chart.relative-time-switch',
            {
              defaultValue: 'Relative time',
            },
          )}
        </span>

        <Switch onChange={(state) => setDisplayWallClockTime(!state)} />
      </div>
    </div>
  );
};
