import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { useFleetTreeContext } from 'components/Fleet/FleetList/FleetTree';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';

interface FleetExpandButtonProps {
  fleetId: string;
}

const FleetExpandButton = ({ fleetId }: FleetExpandButtonProps) => {
  const fleetMap = useAccessibleFleetMap();
  const { isExpanded, onToggle } = useFleetTreeContext()[fleetId] ?? {};

  if (!fleetMap[fleetId]?.childrenIds.length) return null;

  return (
    <Button
      className="flex-center text-primary rounded-4 p-[0.2rem] m-auto bg-gray-100 border-2 border-primary"
      onClick={onToggle}
    >
      <span className="text-md">
        {isExpanded ? <IonIcon name="chevronDownOutline"  /> :  <IonIcon name="chevronUpOutline" />}
      </span>

      {fleetMap[fleetId]?.childrenIds.length}
    </Button>
  );
};

export default FleetExpandButton;
