import { useDraggable } from '@dnd-kit/core';

export const Draggable = ({ id, children }: { id: string; children: JSX.Element }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
  });
  return (
    <button ref={setNodeRef} className="pointer-grab" {...listeners} {...attributes}>
      {children}
    </button>
  );
};
