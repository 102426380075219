import { useMutation } from '@apollo/client';
import { useContext, useMemo, useState } from 'react';
import { NIL } from 'uuid';

import Button from 'atoms/Button';
import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { LicencePlate } from 'atoms/LicencePlate';
import BlockingModal from 'atoms/Modal/BlockingModal';
import ConfirmModal, { useConfirmModal } from 'atoms/Modal/ConfirmModal';
import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { useAvailableVehicles, useDriverDetails } from 'components/Driver/Detail/hooks';
import { UpdateAssociationDoc, UpdateUserDoc, VehicleDetails } from 'generated/graphql';
import { SelectOption } from 'types';
import { isDefined } from 'utils';

interface DropdownSelectWrapperProps {
  items: SelectOption[];
  onSelect: (item: string) => void;
  initialItem?: SelectOption;
}

const DropdownSelectWrapper = ({ items, onSelect, initialItem }: DropdownSelectWrapperProps) => {
  const { getProps } = useDropdownSelect(items, {
    onSelect: (item: SelectOption) => onSelect(item.value),
    searchable: true,
    initialItem,
  });

  return <DropdownSelect {...getProps()} className="!w-30" />;
};

interface VehicleSelectorProps {
  currentVehicle?: Omit<
    VehicleDetails,
    | 'associations'
    | 'trips'
    | 'activity'
    | 'statistics'
    | 'fleetIds'
    | 'exceptions'
    | 'geofences'
    | 'tripStatistics'
    | 'status'
    | 'batteryStatus'
    | 'activeDtcs'
    | 'activeDashboardLights'
    | 'lastKnownLocation'
  > | null;
  cancelEditMode: () => void;
}

const VehicleSelector = ({ cancelEditMode, currentVehicle }: VehicleSelectorProps) => {
  const i18nContext = useContext(I18nContext);
  const { tSafe } = i18nContext ?? {};
  const { data: drivers, loading, error } = useDriverDetails();
  const fleetId = drivers?.[0].user.accessibleFleets?.[0].id;
  const unassignedVehicles = useAvailableVehicles(fleetId, { fetchPolicy: 'network-only' });
  const [selectedVehicleId, setSelectedVehicleId] = useState<string>();
  const { make, model, year, fuelType, id: currentVehicleId, licencePlate } = currentVehicle?.vehicle ?? {};

  const currentVehicleSelectOption = useMemo(
    () =>
      currentVehicle
        ? {
            label: `${make?.toUpperCase()} ${model?.toUpperCase()} (${year} ${fuelType?.toUpperCase()}) ${licencePlate}`,
            value: currentVehicleId!,
          }
        : undefined,
    [currentVehicle, make, model, year, fuelType, currentVehicleId, licencePlate],
  );

  const vehicleSelectOptions = useMemo((): SelectOption[] => {
    if (!tSafe || !unassignedVehicles) return [];

    // const availablevehicles = vehicles
    //   ? vehicles.map(x => x.vehicle ? ({ vehicle: { id, make, model, licencePlate, year, fuelType } }) => ({
    //       label: `${make?.toUpperCase()} ${model?.toUpperCase()} (${year} ${fuelType?.toUpperCase()}) ${licencePlate}`,
    //       value: id,
    //     }))
    //   : [];

    const availableVehicleOptions = unassignedVehicles
      .map((x) =>
        x.vehicle
          ? {
              label: `${x.vehicle.make?.toUpperCase()} ${x.vehicle.model?.toUpperCase()} (${
                x.vehicle.year
              } ${x.vehicle.fuelType?.toUpperCase()}) ${x.vehicle.licencePlate}`,
              value: x.vehicle.id,
            }
          : undefined,
      )
      .filter(isDefined);
    const unassignSelectOption = {
      label: tSafe('components.Driver.Detail.Header.VehicleInfo.VehicleSelector.no-vehicle-option', {
        defaultValue: 'No vehicle',
      }),
      value: NIL,
    };

    if (!currentVehicleSelectOption) return availableVehicleOptions;

    return [currentVehicleSelectOption, unassignSelectOption, ...availableVehicleOptions];
  }, [unassignedVehicles, tSafe, currentVehicleSelectOption]);

  const [updateAssociation, { loading: updateAssociationMutationLoading }] = useMutation(UpdateAssociationDoc);
  const [updateUser, { loading: updateUserLoading }] = useMutation(UpdateUserDoc);

  const { openModal, props } = useConfirmModal();

  const spinner = (
    <div className="flex-center">
      <div className="ml-4">
        <Spinner className="!text-[0.4em]" />
      </div>
    </div>
  );

  const driverDetails = drivers?.[0];
  const selectedVehicle = unassignedVehicles?.find((x) => (x.vehicle ? x.vehicle.id === selectedVehicleId : false));

  if (loading || !unassignedVehicles || !i18nContext || !tSafe) return spinner;

  const {
    commonTranslations: {
      forms: {
        buttons: { cancel_text },
      },
      errors: { error_text, entity_not_found_tfn },
      domain: {
        driver: { driver_translatedWithCount },
      },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  if (!driverDetails) return <div className="text-xl">{entity_not_found_tfn(driver_translatedWithCount(1))}</div>;

  const onSelectVehicle = (vehicleId: string) => {
    setSelectedVehicleId(vehicleId);
    if (vehicleId !== currentVehicleId) {
      openModal();
    }
  };

  const onConfirm = (vehicleId?: string) => {
    if (!vehicleId) return;

    if (vehicleId === NIL) {
      unassignVehicle();
    } else {
      assignVehicle(vehicleId);
    }
  };

  const unassignVehicle = () => {
    const { deviceId } = driverDetails.associations ?? {};

    if (!deviceId) return;

    updateAssociation({
      variables: { deviceId: deviceId!, driverId: NIL, withDriverDetailsAssociations: true },
    }).finally(cancelEditMode);
  };

  const assignVehicle = (vehicleId: string) => {
    const selectedVehicleDeviceId = selectedVehicle?.device?.id;
    const selectedVehicleDetail = selectedVehicle?.vehicle;

    if (!selectedVehicleDeviceId || vehicleId === currentVehicleId || !selectedVehicleDetail) return;

    updateAssociation({
      variables: { deviceId: selectedVehicleDeviceId, driverId: driverDetails.id, withDriverDetailsAssociations: true },
    })
      .then(() =>
        updateUser({
          variables: { id: driverDetails.user.id, fleetIds: selectedVehicle?.device?.primaryFleetId },
        }),
      )
      .finally(cancelEditMode);
  };

  const confirmText =
    selectedVehicle?.vehicle && selectedVehicleId !== NIL
      ? tSafe('components.Driver.Detail.Header.VehicleInfo.VehicleSelector.are-you-sure-to-reassign-to-vehicle', {
          defaultValue: "Are you sure you'd like to reassign this driver to {{licencePlate}}?",
          licencePlate: selectedVehicle.vehicle.licencePlate,
        })
      : tSafe(
          'components.Driver.Detail.Header.VehicleInfo.VehicleSelector.are-you-sure-to-unassign-vehicle-from-driver',
          {
            defaultValue: "Are you sure you'd like to unassign a vehicle from this driver?",
          },
        );

  return (
    <>
      <ConfirmModal
        modalProps={props}
        confirmContent={<span className="max-w-40 mb-1 text-md">{confirmText}</span>}
        onConfirm={() => onConfirm(selectedVehicleId)}
      />

      <div className="flex-center">
        {updateAssociationMutationLoading || updateUserLoading ? (
          <div className="flex-center text-md">
            <BlockingModal />

            {selectedVehicleId !== NIL ? (
              <>
                <span>
                  {tSafe('components.Driver.Detail.Header.VehicleInfo.VehicleSelector.updating-vehicle-to', {
                    defaultValue: 'updating vehicle to',
                  })}
                </span>

                <LicencePlate licenceNumber={selectedVehicle?.vehicle?.licencePlate!} textClass="text-sm" />

                <span>
                  {tSafe('components.Driver.Detail.Header.VehicleInfo.VehicleSelector.this-may-take-a-minute', {
                    defaultValue: '... this may take a minute',
                  })}
                </span>
              </>
            ) : (
              <span>
                {tSafe(
                  'components.Driver.Detail.Header.VehicleInfo.VehicleSelector.unassigning-vehicle-may-take-a-minute',
                  {
                    defaultValue: 'unassigning vehicle... this may take a minute',
                  },
                )}
              </span>
            )}

            <div className="ml-4">
              <Spinner className="!text-[0.5em]" />
            </div>
          </div>
        ) : (
          <>
            <DropdownSelectWrapper
              items={vehicleSelectOptions}
              onSelect={onSelectVehicle}
              initialItem={currentVehicleSelectOption}
            />

            <span className="ml-1">
              <Button className="text-sm" onClick={cancelEditMode}>
                {cancel_text}
              </Button>
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default VehicleSelector;
