import Icon from 'atoms/Icon';
import { IconName } from 'atoms/Icon/icons';

interface StatusItemProps {
  description: string;
  value?: string | number;
  iconName?: IconName;
  className?: string;
  iconClassName?: string;
}

export const StatusItem = ({ description, value, iconName, className, iconClassName }: StatusItemProps) => {
  return (
    <div className={`flex items-center justify-start h-3 mb-1 ${className}`}>
      {iconName ? (
        <div className="mx-1 min-w-4">
          <Icon name={iconName} className={`${iconClassName}`} />
        </div>
      ) : null}

      <span className="ml-1 min-w-15 font-bold">{description}</span>

      <span>{value}</span>
    </div>
  );
};
