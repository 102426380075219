import { useContext } from 'react';

import { I18nContext } from 'common/useT';
import FleetHierarchy, { FleetHierarchyMode } from 'components/Fleet/FleetList';

const FleetManagementPage = () => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="flex flex-col h-full">
      <h1 className="text-md font-bold p-2">
        {tSafe('components.Fleet.FleetManagementPage.title', { defaultValue: 'Fleet Management' })}
      </h1>

      <div className="flex-1 flex-center overflow-hidden">
        <FleetHierarchy mode={FleetHierarchyMode.MANAGEMENT} />
      </div>
    </div>
  );
};

export default FleetManagementPage;
