import { useContext } from 'react';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { GetVehicleCardDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

interface VehicleCardProps {
  id: string;
  onClickDelete: () => void;
  vehicleData?: { id: string; licencePlate: string };
}

const VehicleCard = ({ id, onClickDelete }: VehicleCardProps) => {
  const i18nContext = useContext(I18nContext);
  const { data, loading, error } = useQ(GetVehicleCardDoc, { variables: { vehicleId: id } });

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { error_text },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;
  if (loading) return <CenteredSpinner className="text-[8px]" />;
  if (!data) return null;

  const [{ vehicle }] = data;

  return (
    <div className="relative flex items-center px-1 py0.5 border-px rounded-4 w-[120px] font-bold">
      <Button
        className="absolute right-1 ui-button"
        onClick={onClickDelete}
        aria-label={tSafe('atoms.VehicleSearch.VehicleSelectorWithSearch.VehicleCard.remove-vehicle', {
          defaultValue: 'Remove vehicle',
        })}
      >
        <IonIcon name="closeOutline" className="text-error text-lg" />
      </Button>

      {vehicle.licencePlate}
    </div>
  );
};

export default VehicleCard;
