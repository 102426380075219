import { useCallback, useContext, useState } from 'react';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { cx } from 'utils';

import useFleetTreeSearchSource from './useFleetSearchSource';

interface FleetSearchProps {
  className?: string;
  onFleetSelect?: (id: string) => void;
}

const FleetSearch = ({ className, onFleetSelect }: FleetSearchProps) => {
  const i18nContext = useContext(I18nContext);
  const [searchTerm, setSearchTerm] = useState('');
  const resetSearch = useCallback(() => setSearchTerm(''), []);

  const results = [
    ...useFleetTreeSearchSource(searchTerm, (id) => {
      onFleetSelect && onFleetSelect(id);
      resetSearch();
    }),
  ]
    .sort((a, b) => b.score - a.score)
    .map(({ jsx }) => jsx);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className={cx(className, 'flex w-full')}>
      <div className="flex w-60 bg-gray-100 border rounded-4 overflow-hidden">
        <input
          className="flex-1 pl-2 pr-1 min-h-3.5 text-md bg-gray-100 focus:outline-none"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={tSafe('atoms.FleetSearch.search-by-fleet-name', { defaultValue: 'Search by Fleet name' })}
        />

        <span className="flex-shrink-0 pr-1 text-lg flex-center" aria-label="Search">
          <IonIcon name="searchOutline" />
        </span>
      </div>

      <ul className="absolute flex-center z-500 w-full left-0 right-0 text-sm top-[calc(100%+.5rem)] children:focus:outline-none">
        {searchTerm && !!results?.length && (
          <div className="flex w-60 flex-col bg-gray-100 border rounded-8 overflow-hidden">
            <span className="max-h-80 overflow-auto">{results}</span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default FleetSearch;
