import styled from 'styled-components';

import { ClassName } from 'types';

const StyledSpinner = styled.div`
  transform: translateY(-100%);

  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: load 1.8s infinite ease-in-out;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  & {
    animation-delay: -0.16s;
  }
  &:after {
    left: 3.5em;
  }

  @keyframes load {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`;

const Spinner = ({ className }: ClassName) => <StyledSpinner className={`text-yellow ${className}`} />;

export const CenteredSpinner = ({ className }: ClassName) => (
  <div className="flex-center w-full h-full">
    <Spinner className={className} />
  </div>
);

export default Spinner;
