import { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { CellProps, Column, useTable } from 'react-table';

import { getHeaderRenderer } from 'atoms/react-table/TableHeader';
import Separator from 'atoms/Separator';
import { I18nContext } from 'common/useT';
import { WidgetContext } from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useSettings from 'components/Settings/useSettings';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import { GetLeaderboardDoc } from 'generated/graphql';
import { Result } from 'types';
import { IdleLeaderboardRecord } from 'types/gridLayout';
import { DashboardWidget } from 'types/widgets';
import { cx } from 'utils';

import IdleLeaderboardTableHeader from './IdleLeaderboardTableHeader';

type LeaderboardItem = Result<typeof GetLeaderboardDoc>[0];

export type LeaderboardCell<T> = PropsWithChildren<CellProps<LeaderboardItem, T>>;

interface IdleLeaderboardTableProps {
  leaders: IdleLeaderboardRecord[];
  className?: string;
}

const CenterCell = (cell: LeaderboardCell<number>) => <div className="text-center">{cell.value}</div>;

const header = getHeaderRenderer(IdleLeaderboardTableHeader);

const useColumns = () => {
  const i18nContext = useContext(I18nContext);
  const { idleTimeAsPercentage } = useSettings();
  const currentFleetId = useCurrentFleetId();
  const fleetMap = useAccessibleFleetMap();

  const { tSafe } = i18nContext ?? {};

  const columns: Column<IdleLeaderboardTableProps['leaders'][number]>[] = useMemo(
    () =>
      tSafe
        ? [
            {
              accessor: 'ranking',
              Header: header('#', { alignment: 'right' }),
              Cell: (row) => <div className="pl-1 text-right font-semibold">{row.value}.</div>,
            },
            {
              accessor: 'driverName',
              Header: header(
                tSafe('components.Dashboard.IdleLeaderboardWidget.table.headers.name', { defaultValue: 'Name' }),
                {
                  alignment: 'left',
                },
              ),
              Cell: CenterCell,
            },
            {
              accessor: 'fleetId',
              Header: header(tSafe('common.domain.fleet.fleets_one', { defaultValue: 'Fleet' })),
              Cell: ({ value }: LeaderboardCell<string>) => <div className="text-center">{fleetMap[value]?.name}</div>,
            },
            {
              accessor: idleTimeAsPercentage ? 'idleTimePercentage' : 'idleTimeInHours',
              Header: header(
                tSafe('components.Dashboard.IdleLeaderboardWidget.table.headers.idle-time', {
                  unit: idleTimeAsPercentage ? '%' : 'h',
                  defaultValue: 'Idle ({{unit}})',
                }),
              ),
              Cell: CenterCell,
            },
            {
              accessor: 'longIdlingEventCount',
              Header: header(
                tSafe('components.Dashboard.IdleLeaderboardWidget.table.headers.long-idling', {
                  defaultValue: 'Long idling',
                }),
              ),
              Cell: CenterCell,
            },
            {
              accessor: 'idlingScore',
              Header: header(
                tSafe('components.Dashboard.IdleLeaderboardWidget.table.headers.idling-score', {
                  defaultValue: 'Idling score',
                }),
              ),
              Cell: CenterCell,
            },
          ]
        : [],
    [idleTimeAsPercentage, fleetMap, tSafe],
  );

  return columns;
};

const IdleLeaderboardTable = ({ className, leaders }: IdleLeaderboardTableProps) => {
  const { isEditMode, widgetKey } = useContext(WidgetContext);
  const settings = useSettings();
  const { hiddenItems } = settings.dashboardWidgetSettings[widgetKey as DashboardWidget] ?? [];
  const currentFleetId = useCurrentFleetId();
  const history = useHistory();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable({
    columns: useColumns(),
    data: leaders,
  });

  useEffect(() => {
    isEditMode ? setHiddenColumns([]) : setHiddenColumns(hiddenItems);
  }, [hiddenItems, isEditMode, setHiddenColumns]);

  return (
    <table {...getTableProps()} className={className}>
      <thead>
        {headerGroups.map((group) => (
          <tr {...group.getHeaderGroupProps()}>
            {group.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className={cx('px-1 py-1 text-sm font-normal', hiddenItems?.includes(column.id) && 'opacity-40')}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        <tr>
          <td>
            <Separator absolute />
          </td>
        </tr>

        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr
              {...row.getRowProps()}
              className="cursor-pointer hover:bg-gray-100"
              onClick={() => history.push(`/${currentFleetId}/drivers/${row.original.driverId}`)}
            >
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={cx('px-1 py-1 text-sm', hiddenItems?.includes(cell.column.id) && 'opacity-40')}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default IdleLeaderboardTable;
