import L, { Map } from 'leaflet';
import { useContext, useEffect, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Pagination from 'atoms/Pagination';
import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import {
  activityHistoryClickedSegment,
  activityHistoryHoveredSegment,
  activityHistorySelectedTripId,
  activityHistoryTripIds,
} from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/state';
import StreetView from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/StreetView';
import { TripDetailMap } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/TripDetailMap';
import { TripInfoPanel } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/TripInfoPanel';
import { TripSpeedHistogram } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/TripSpeedHistogram';
import { GetTripDoc } from 'generated/graphql';
import { cx } from 'utils';
import { useQ } from 'utils/apolloClient';

export const TripEventDetail = () => {
  const i18nContext = useContext(I18nContext);
  const [isShowStreeView, setIsShowStreeView] = useState(false);
  const [mapInstance, setMapInstance] = useState<Map>();
  const tripIds = useRecoilValue(activityHistoryTripIds);
  const [tripId, setTripId] = useRecoilState(activityHistorySelectedTripId);
  const {
    loading,
    error,
    data: trip,
  } = useQ(GetTripDoc, {
    skip: !tripId,
    variables: { tripId: tripId! },
    onCompleted: ({ trip: { geometryDetail } }) => {
      const latLngKeys = new Set();
      const uniqueLatLngs = (geometryDetail ?? []).filter(({ location: { lat, lng } }) => {
        var key = `${lat}${lng}`;

        return !latLngKeys.has(key) && latLngKeys.add(key);
      });

      if (uniqueLatLngs.length > 1) {
        mapInstance?.fitBounds(L.latLngBounds(uniqueLatLngs?.map(({ location }) => location)), {
          padding: [50, 50],
        });
        mapInstance?.invalidateSize();
      }
    },
  });
  const setHoveredSegment = useSetRecoilState(activityHistoryHoveredSegment);
  const setClickedSegment = useSetRecoilState(activityHistoryClickedSegment);

  useEffect(() => {
    setHoveredSegment(undefined);
    setClickedSegment(undefined);
  }, [setClickedSegment, setHoveredSegment, tripId]);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { error_text },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  if (!tripId || loading || !trip)
    return (
      <div className="flex-center h-full">
        <Spinner className="text-lg" />
      </div>
    );

  const { startLocation, geometryDetail, events, vehicleId, startTime } = trip;

  const mapLocation: [number, number] = startLocation ? [startLocation.lat, startLocation.lng] : [53.505, -0.09];

  const prevTripText = tSafe('components.ActivityHistoryList.EventTypes.Details.TripEventDetail.previous-trip', {
    defaultValue: 'Previous Trip',
  });
  const nextTripText = tSafe('components.ActivityHistoryList.EventTypes.Details.TripEventDetail.next-trip', {
    defaultValue: 'Next Trip',
  });

  return (
    <div className="flex gap-4 h-full p-2">
      <div className="w-30 overflow-y-auto">
        <Pagination
          className="absolute top-3 right-6"
          pageCount={tripIds.length}
          initialPage={tripIds.findIndex((id) => id === tripId)}
          onChange={(i) => setTripId(tripIds[i])}
          prevLabel={prevTripText}
          nextLabel={nextTripText}
          vertical
        />

        <TripInfoPanel id={tripId} />
      </div>

      <div className="flex-1 flex flex-col mb-2 gap-1 items-center">
        <div className="flex-1 flex w-full rounded-8 overflow-hidden relative">
          <MapContainer
            center={mapLocation}
            zoom={13}
            scrollWheelZoom={true}
            whenCreated={setMapInstance}
            className="flex-1"
            tap={false} // see issue https://stackoverflow.com/questions/65369083/popup-does-not-open-when-clicking-on-marker-safari
          >
            <TripDetailMap tripId={tripId} vehicleId={vehicleId!} />
          </MapContainer>

          {isShowStreeView && (
            <div className="flex-1">
              <StreetView />
            </div>
          )}

          <div
            className={cx(
              'absolute top-1/2 right-0 bg-gray-100 py-2 rounded-l-16 z-500 -translate-y-1/2 cursor-pointer border-l border-black/30',
              'text-black text-sm select-none uppercase [text-orientation:upright] [writing-mode:tb] whitespace-nowrap',
              isShowStreeView && 'right-1/2',
            )}
            role="button"
            onClick={() => setIsShowStreeView(!isShowStreeView)}
          >
            {tSafe('components.ActivityHistoryList.EventTypes.Details.TripEventDetail.street-view', {
              defaultValue: 'Street View',
            })}
          </div>
        </div>

        <TripSpeedHistogram
          geometryDetail={geometryDetail}
          mapInstance={mapInstance}
          events={events}
          tripStartTime={startTime}
        />
      </div>
    </div>
  );
};
