import { GetDriverSearchDoc } from 'generated/graphql';
import Search, { SearchProps } from 'atoms/Search';
import { useContext } from 'react';
import { I18nContext } from 'common/useT';

const DriverSearch = ({ fleetId, onSelect }: { fleetId?: string; onSelect?: (id: string) => void }) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const driverSearchConfig: SearchProps = {
    key: 'driverSearch',
    searchQueryDoc: GetDriverSearchDoc,
    placeholder: tSafe('atoms.DriverSearch.search-driver-by-name', {
      defaultValue: 'Search for driver by name',
    }),
    highlightKey: 'name',
    iconName: 'personCircleOutline',
  };

  return <Search fleetId={fleetId} onSelect={onSelect} props={driverSearchConfig} />;
};

export default DriverSearch;
