import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { debounce, escapeRegExp } from 'lodash';

import IonIcon from 'atoms/IonIcon';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetDriverVehicleSearchDoc } from 'generated/graphql';
import { cx } from 'utils';
import { useLazyQuery } from '@apollo/client';

const DriverVehicleSearch = ({ fleetId, onSelect }: { fleetId?: string; onSelect?: (id: string) => void }) => {
  const i18nContext = useContext(I18nContext);
  const [searchTerm, setSearchTerm] = useState('');
  const resetSearch = useCallback(() => setSearchTerm(''), []);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const currentFleetId = useCurrentFleetId();
  const fleetIdToSearch = fleetId ?? currentFleetId;
  const history = useHistory();

  useEffect(() => {
    if (inputRef.current && isFocused) {
      inputRef.current.focus();
    }
    if (!showSearchBar || !isFocused) {
      resetSearch();
    }
  }, [isFocused, resetSearch, showSearchBar]);

  const [search, { data, loading }] = useLazyQuery(GetDriverVehicleSearchDoc, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });
  const {
    driverVehicleSearch: { drivers, vehicles },
  } = data ?? { driverVehicleSearch: { drivers: [], vehicles: [] } };

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (searchTerm: string) => {
          searchTerm.length && search({ variables: { searchTerm, fleetId: fleetIdToSearch } });
        },
        600,
        { leading: true },
      ),
    [],
  );

  const updateValue = useCallback(
    (value: string) => {
      setSearchTerm(value);
      debouncedSearch(value);
    },
    [searchTerm],
  );

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const highlightText = (input: string, text: string) => {
    return input.replace(new RegExp(escapeRegExp(text), 'gi'), `<strong>$&</strong>`);
  };

  const renderResults = () => {
    const allResults = [...drivers, ...vehicles];

    if (loading) {
      return (
        <li className="flex px-2 py-1 text-left">
          {tSafe('atoms.search.driver-or-vehicle.fetching-results', {
            defaultValue: 'Fetching results...',
          })}
        </li>
      );
    }
    if (!allResults.length) {
      return (
        <li className="flex px-2 py-1 text-left">
          {tSafe('atoms.search.driver-or-vehicle.no-results', {
            defaultValue: 'No results',
          })}
        </li>
      );
    }
    return [
      vehicles.map((x) => (
        <li
          className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
          onClick={() => {
            onSelect?.(x.id);
            history.push(`/${fleetIdToSearch}/vehicles/${x.id}`);
          }}
        >
          <IonIcon name="carSportOutline" className="mr-1 text-3xl" />

          <div className="flex flex-col">
            <span
              className="text-md uppercase"
              dangerouslySetInnerHTML={{ __html: highlightText(x.licencePlate, searchTerm) }}
            />
          </div>
        </li>
      )),
      drivers.map((x) => (
        <li
          className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
          onClick={() => {
            onSelect?.(x.id);
            history.push(`/${fleetIdToSearch}/drivers/${x.id}`);
          }}
        >
          <IonIcon name="personCircleOutline" className="mr-1 text-3xl" />

          <div className="flex flex-col">
            <span className="text-md" dangerouslySetInnerHTML={{ __html: highlightText(x.name, searchTerm) }} />
          </div>
        </li>
      )),
    ];
  };

  return (
    <div
      className={cx(
        'flex min-w-8 items-center relative transition-opacity ease-in duration-700 hover:bg-navbarHover cursor-pointer',
      )}
      onClick={() => {
        setShowSearchBar(true);
        setIsFocused(true);
      }}
    >
      <div className="flex absolute right-0 mr-2 max-h-4">
        <input
          className={cx(
            'ease-in-out duration-300 outline-none px-2 rounded-l-24 min-h-4 text-md font-thin bg-white',
            showSearchBar ? 'w-40' : 'w-4 bg-navbar pointer-event-auto -z-10',
          )}
          value={searchTerm}
          onChange={(e) => {
            updateValue(e.target.value);
          }}
          placeholder={tSafe('atoms.search.driver-or-vehicle.by.driver-name-or-vehicle-reg', {
            defaultValue: 'Search for a Driver name or Vehicle registration',
          })}
          ref={inputRef}
          onBlur={() => {
            setTimeout(() => {
              setIsFocused(false);
              setShowSearchBar(false);
            }, 180);
          }}
        />

        <>
          <button
            className={cx(
              'duration-300 pr-1 text-2xl flex-center cursor-pointer',
              showSearchBar ? 'bg-white rounded-r-24' : 'bg-inherit',
            )}
            aria-label="Search"
          >
            <Tooltip text="Search">
              <IonIcon name="searchOutline" />
            </Tooltip>
          </button>

          <ul className="absolute z-999 left-0 right-0 text-sm top-[calc(100%+.5rem)] children:focus:outline-none">
            {showSearchBar && searchTerm && (
              <div className="flex flex-col bg-gray-100 border border-gray-400 rounded-8 overflow-hidden">
                <span className="max-h-80 overflow-auto">{renderResults()}</span>
              </div>
            )}
          </ul>
        </>
      </div>
    </div>
  );
};

export default DriverVehicleSearch;
