import { useContext } from 'react';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import Modal from 'atoms/Modal';
import Switch from 'atoms/Switch';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import RecipientManager from 'components/Fleet/FleetList/Toolbar/EditSubfleetToolbarItem/RecipientManager';
import { CascadedFleetNotificationChannels, FleetNotificationChannelRecipients, Topic } from 'generated/graphql';
import { cx } from 'utils';

interface TableRowProps {
  channels: CascadedFleetNotificationChannels;
  data: CascadedFleetNotificationChannels | undefined;
  topic: Topic;
  topicName: string;
  onChangePortalEnabled: (value: boolean) => void;
  onChangeEmailEnabled: (value: boolean) => void;
  onChangeEmailRecipients: (recipients: FleetNotificationChannelRecipients) => void;
  onClearAllEmailRecipients: () => void;
  onChangeSmsEnabled: (value: boolean) => void;
  onChangeSmsRecipients: (recipients: FleetNotificationChannelRecipients) => void;
  onClearAllSmsRecipients: () => void;
}

const TableRow = ({
  channels,
  data,
  topicName,
  onChangePortalEnabled,
  onChangeEmailEnabled,
  onChangeEmailRecipients,
  onClearAllEmailRecipients,
  onChangeSmsEnabled,
  onChangeSmsRecipients,
  onClearAllSmsRecipients,
}: TableRowProps) => {
  const i18nContext = useContext(I18nContext);
  const { email, portal, sms } = channels;

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { edit_text },
    },
  } = i18nContext;

  const set_custom_recipients_text = tSafe(
    'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.TableRow.set-custom-recipients',
    { defaultValue: 'Set custom recipients' },
  );

  const visibleEmailRecipientGroups = [
    ...(data?.email.recipients?.recipientGroups?.filter((x) => !x.disabled) ?? []),
    ...(data?.email.recipients?.recipientUserIds?.filter((x) => !x.disabled) ?? []),
    ...(data?.email.recipients?.customRecipients?.filter((x) => !x.disabled) ?? []),
  ];
  const visibleSMSRecipientGroups = [
    ...(data?.sms.recipients?.recipientGroups?.filter((x) => !x.disabled) ?? []),
    ...(data?.sms.recipients?.recipientUserIds?.filter((x) => !x.disabled) ?? []),
    ...(data?.sms.recipients?.customRecipients?.filter((x) => !x.disabled) ?? []),
  ];

  return (
    <tr className="border-b-px border-gray-300">
      <td className="text-[12px]">{topicName}</td>

      <td className="text-center py-0.5 bg-gray-100">
        <Switch onChange={onChangePortalEnabled} initialValue={portal} />
      </td>

      {email.featureSupported ? (
        <>
          <td className="text-center relative" colSpan={2}>
            <Switch
              onChange={onChangeEmailEnabled}
              initialValue={email.enabled}
              disabled={!email.available || !email.canDisable}
            />

            <Modal
              contentClassName="w-full h-1/2"
              trigger={
                <Tooltip text={data?.email?.recipients ? 'Edit custom recipients' : set_custom_recipients_text}>
                  <Button
                    disabled={!email.available}
                    className={cx('border-px rounded-full p-0.5 absolute right-6 bottom-0.5 ui-button')}
                  >
                    <IonIcon name="peopleOutline" className="text-lg" />
                  </Button>
                </Tooltip>
              }
            >
              <RecipientManager
                title={tSafe(
                  'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.TableRow.email-recipients-for-topicName',
                  { defaultValue: 'Email recipients for {{topicName}} topic', topicName },
                )}
                channelRecipientsState={data?.email?.recipients}
                channelRecipients={email.recipients}
                channel={'Email'}
                onChange={onChangeEmailRecipients}
                onClearAll={onClearAllEmailRecipients}
              />
            </Modal>

            {!!visibleEmailRecipientGroups.length && (
              <div className="absolute -mt-0.5 -mr-0.5 right-5 top-2 h-2 w-2 text-white text-sm font-normal bg-error rounded-full flex-center">
                {visibleEmailRecipientGroups.length}
              </div>
            )}
          </td>
        </>
      ) : (
        <td className="text-center" colSpan={2}></td>
      )}

      {sms.featureSupported ? (
        <>
          <td className="text-center bg-gray-100">
            <Switch
              onChange={onChangeSmsEnabled}
              initialValue={sms.enabled}
              disabled={!sms.available || !sms.canDisable}
            />
          </td>

          <td className="text-center bg-gray-100 relative align-top">
            <Modal
              contentClassName="w-full h-1/2"
              trigger={
                <Button
                  disabled={!sms.available}
                  className={cx('border-px rounded-full p-0.5 absolute right-6 bottom-0.5 ui-button')}
                >
                  <IonIcon name="peopleOutline" className="text-lg" />
                </Button>
              }
            >
              <RecipientManager
                title={tSafe(
                  'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.TableRow.sms-recipients-for-topicName',
                  { defaultValue: 'SMS recipients for {{topicName}} topic', topicName },
                )}
                channelRecipientsState={data?.sms?.recipients}
                channelRecipients={sms.recipients}
                channel={'SMS'}
                onChange={onChangeSmsRecipients}
                onClearAll={onClearAllSmsRecipients}
              />
            </Modal>

            {!!visibleSMSRecipientGroups.length && (
              <div className="absolute -mt-0.5 -mr-0.5 right-5 top-2 h-2 w-2 text-white text-sm font-normal bg-error rounded-full flex-center">
                {visibleSMSRecipientGroups.length}
              </div>
            )}
          </td>
        </>
      ) : (
        <td className="bg-gray-100 text-center" colSpan={2}></td>
      )}
    </tr>
  );
};

export default TableRow;
