import useUser from 'components/User/useUser';
import { UserRole } from 'generated/graphql';

const useRole = () => {
  const user = useUser();

  const isSuperAdmin = user.role === UserRole.Superadmin;
  const isAdmin = isSuperAdmin || user.role === UserRole.Admin;
  const isManager = isAdmin || user.role === UserRole.Manager;
  const isViewer = isManager || user.role === UserRole.Viewer;
  const isDriver = !!user.driverId;

  return {
    isSuperAdmin,
    isAdmin,
    isManager,
    isViewer,
    isDriver,
    role: user.role,
  };
};

export default useRole;
