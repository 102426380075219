import { useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { mapState, selectedGeofenceState } from 'components/Live/state';

const AddGeofenceToolbarItem = () => {
  const i18nContext = useContext(I18nContext);
  const map = useRecoilValue(mapState);
  const setSelectedGeofence = useSetRecoilState(selectedGeofenceState);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <>
      <Button
        className="text-sm space-x-0.5 flex-center hover:bg-gray-300"
        onClick={() => {
          map?.pm.enableDraw('Circle');
          setSelectedGeofence(undefined);
        }}
      >
        <IonIcon className="text-lg" name="addCircleOutline" />

        <span>
          {tSafe('components.Live.Geofence.AddGeofenceToolbarItem.add-geofence', { defaultValue: 'Add Geofence' })}
        </span>
      </Button>
    </>
  );
};

export default AddGeofenceToolbarItem;
