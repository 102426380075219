import { useDroppable } from '@dnd-kit/core';

import { cx } from 'utils';

export const Droppable = ({ id, children }: { id: string; children?: JSX.Element }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      style={{ borderColor: 'blue' }}
      className={cx(isOver && 'border-l-2 ', 'absolute w-[150px] h-[600px] opacity-40 z-600 top-0 pointer-events-none')}
    >
      {children}
    </div>
  );
};
