import { CopyToClipboardButton } from 'atoms/CopyToClipboardButton';
import Icon from 'atoms/Icon';
import { IconName } from 'atoms/Icon/icons';
import { cx } from 'utils';

interface InfoPanelItemProps {
  description: string;
  value?: string | number;
  iconName?: IconName;
  iconClass?: string;
  minWidthClassName?: string;
  showCopyToClipboard?: boolean;
  unitOfMeasure?: string;
}

export const InfoPanelItem = ({
  description,
  value,
  iconName,
  iconClass,
  unitOfMeasure,
  minWidthClassName = 'min-w-15',
  showCopyToClipboard = false,
}: InfoPanelItemProps) => {
  return (
    <div className="relative flex items-center">
      {iconName && (
        <span className={cx('mx-1 text-center', iconClass)}>
          <Icon name={iconName} />
        </span>
      )}

      <span className={cx('font-bold', minWidthClassName)}>{description}</span>

      <span>{value}</span>

      {unitOfMeasure && <span className="ml-0.5">{unitOfMeasure}</span>}

      {showCopyToClipboard && value && <CopyToClipboardButton value={value} className="ml-2" />}
    </div>
  );
};
