// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB } from 'date-fns/locale';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { InfoPanel } from 'atoms/InfoPanel';
import { InfoPanelItem } from 'atoms/InfoPanel/InfoPanelItem';
import { dtcIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { DtcEvent } from 'generated/graphql';
import { kmToMi } from 'utils';

interface DtcEventDetailProps {
  event: Partial<DtcEvent>;
}

export const DtcEventDetail = ({ event }: DtcEventDetailProps) => {
  const i18nContext = useContext(I18nContext);
  const { distanceInMiles } = useSettings();

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { unavailable_text, time_text, description_text },
      domain: {
        common: { odometer_text, recommendation_text, extended_text },
        dtc: {
          fields: { code_text, status_text, dtcType_text },
        },
      },
      uom: { miles_text, km_text },
      enums: { dtcEventTypeDescriptionMap, dtcStatusDescriptionMap },
    },
  } = i18nContext;

  const { code, description, dtcType, extended, recommendation, dtcStatus: status, time, odometer } = event;
  const odometerInKm = odometer ? odometer / 1000 : undefined;
  const odometerConverted = distanceInMiles && odometerInKm ? kmToMi(odometerInKm) : odometerInKm;
  const odometerRounded = odometerConverted ? Math.round(odometerConverted) : undefined;
  const odometerUnit = distanceInMiles ? miles_text : km_text;

  return (
    <div className="mt-2">
      <InfoPanel>
        <Icon name={dtcIconMap[dtcType!]} className="mb-2 !w-5" />

        <InfoPanelItem
          description={dtcType_text}
          value={dtcType ? dtcEventTypeDescriptionMap[dtcType] : unavailable_text}
        />

        <InfoPanelItem description={time_text} value={format(Date.parse(time), 'PPPPp', { locale: enGB })} />

        <InfoPanelItem description={status_text} value={status ? dtcStatusDescriptionMap[status] : unavailable_text} />

        <InfoPanelItem description={code_text} value={code} showCopyToClipboard />

        <InfoPanelItem description={description_text} value={description} showCopyToClipboard />

        <InfoPanelItem description={extended_text} value={extended} />

        <InfoPanelItem description={recommendation_text} value={recommendation} />

        <InfoPanelItem
          description={odometer_text}
          value={odometerRounded}
          unitOfMeasure={odometerRounded ? odometerUnit : unavailable_text}
        />
      </InfoPanel>
    </div>
  );
};
