import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';

import Button from 'atoms/Button';
import EditableLabel, { useEditableLabel } from 'atoms/EditableLabel';
import Modal, { useModal } from 'atoms/Modal';
import { updateVehicleDeviceAssociationReference } from 'common/cacheHelpers';
import { I18nContext } from 'common/useT';
import { useCurrentVehicleId, useVehicleDetails } from 'components/Vehicle/Detail/hooks';
import { ApiError, VehicleDeviceAssociationDoc } from 'generated/graphql';
import { ClassName } from 'types';
import { getError } from 'utils/apolloClient';
import ExistingVehicleDetails from 'components/List/ListView/ListViewToolbar/ExistingVehicleDetails';

interface EditableExternalIdLabelProps extends ClassName {
  externalId: string;
}

const EditableExternalIdLabel = ({ externalId, className }: EditableExternalIdLabelProps) => {
  const i18nContext = useContext(I18nContext);
  const vehicleId = useCurrentVehicleId()!;
  const [existingVehicleId, setExistingVehicleId] = useState<string>();
  const [useDriverFromExistingAssociation, setUseDriverFromExistingAssociation] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [validationMessage, setValidationMessage] = useState<string>();
  const { data: [vehicleDetails] = [] } = useVehicleDetails();
  const { openModal, closeModal, props: modalProps } = useModal({});
  const [updateVehicleAssociationMutation, { loading }] = useMutation(VehicleDeviceAssociationDoc, {
    onCompleted: () => {
      setUseDriverFromExistingAssociation(false);
      setExistingVehicleId(undefined);
      setErrorMessage(undefined);
    },
    onError: (apolloError) => {
      const error = getError(apolloError);

      if (error?.extensions.vehicleId) {
        setExistingVehicleId(error.extensions.vehicleId);
      } else {
        setErrorMessage(error?.message);
        reset();
      }
      openModal();

      if (error?.type === ApiError.DeviceNotAvailable) {
        reset();
      }
    },
    update: (cache, { data }) => updateVehicleDeviceAssociationReference(cache, data, vehicleDetails),
  });

  const onChange = (targetExternalId: string) => {
    setValidationMessage(undefined);

    if (!targetExternalId.length) {
      setValidationMessage(
        tSafe('components.Vehicle.Detail.DeviceInfo.EditableExternalIdLabel.use-button-to-remove-device', {
          defaultValue: 'Please use the button to remove the device. Or, specify a valid device serial number.',
        }),
      );
    }

    if (!targetExternalId.length || targetExternalId === externalId) {
      return;
    }

    closeModal();

    updateVehicleAssociationMutation({
      variables: {
        externalId: targetExternalId,
        vehicleId,
        overrideExistingAssociation: !!existingVehicleId,
        useDriverFromExistingAssociation: useDriverFromExistingAssociation,
      },
    });
  };

  const { reset, getProps, value: targetExternalId } = useEditableLabel({ initialValue: externalId, onChange });

  const onChangeUseDriverCheckbox = (checked: boolean) => setUseDriverFromExistingAssociation(checked);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { error_text },
      general: { success_text },
    },
  } = i18nContext;

  return (
    <>
      <Modal
        contentClassName="w-60"
        {...modalProps}
        title={
          existingVehicleId
            ? tSafe(
                'components.Vehicle.Detail.DeviceInfo.EditableExternalIdLabel.device-already-associated-with-vehicle',
                { defaultValue: 'Device is already associated with a vehicle' },
              )
            : errorMessage
            ? error_text
            : success_text
        }
      >
        {existingVehicleId ? (
          <>
            <ExistingVehicleDetails
              vehicleId={existingVehicleId}
              onChangeDriverCheckbox={onChangeUseDriverCheckbox}
              creationMode={true}
            />

            <Button
              onClick={() => {
                closeModal();
                onChange(targetExternalId);
              }}
              className="mt-1 border-px bg-success text-white rounded-2 px-1"
            >
              Proceed
            </Button>
          </>
        ) : errorMessage ? (
          <span className="text-error text-md">{errorMessage}</span>
        ) : (
          <span className="text-md">
            {tSafe('components.Vehicle.Detail.DeviceInfo.EditableExternalIdLabel.device-successfully-reassigned', {
              defaultValue: 'Device successfully re-assigned!',
            })}
          </span>
        )}
      </Modal>

      {validationMessage && <span className="text-error">{validationMessage}</span>}

      <EditableLabel
        {...getProps()}
        className={className}
        inputClassName="px-1 py-0.5 w-[calc(15ch+2rem)]"
        isLoading={loading}
      />
    </>
  );
};

export default EditableExternalIdLabel;
