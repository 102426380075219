import { useMutation } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { NIL } from 'uuid';

import Button from 'atoms/Button';
import ButtonWithConfirmModal from 'atoms/ButtonWithConfirmModal';
import BlockingModal from 'atoms/Modal/BlockingModal';
import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { useDriverDetails } from 'components/Driver/Detail/hooks';
import { FleetSelectorOptions } from 'components/FleetSelector/FleetSelectorWithOptions';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import { UpdateAssociationDoc, UpdateUserDoc } from 'generated/graphql';

interface MoveDriverFleetOptionsProps extends FleetSelectorOptions {}

const MoveDriverFleetOptions = ({ targetFleetId, resetTarget, setIsLoading }: MoveDriverFleetOptionsProps) => {
  const i18nContext = useContext(I18nContext);
  const driver = useDriverDetails().data?.[0];
  const fleetMap = useAccessibleFleetMap();
  const targetFleetName = fleetMap[targetFleetId]!.name;

  const [updateAssociationMutation, { loading: updateAssociationMutationLoading }] = useMutation(UpdateAssociationDoc);
  const [updateUser, { loading: updateUserLoading }] = useMutation(UpdateUserDoc);

  useEffect(() => {
    setIsLoading?.(updateAssociationMutationLoading || updateUserLoading);
  }, [updateAssociationMutationLoading, updateUserLoading, setIsLoading]);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      forms: {
        buttons: { cancel_text },
      },
    },
  } = i18nContext;

  if (updateAssociationMutationLoading || updateUserLoading) {
    return (
      <div className="flex text-sm items-center mt-1">
        <BlockingModal />

        <div className="mr-4">
          {tSafe('components.Driver.Detail.Edit.DriverFleetSelector.MoveDriverFleetOptions.updating', {
            targetFleetName,
            defaultValue:
              'Updating the driver fleet to {{targetFleetName}}... Please be patient and do not navigate away! This may take upto a minute.',
          })}
        </div>

        <div className="ml-1">
          <Spinner className="!text-[0.5em]" />
        </div>
      </div>
    );
  }

  const { deviceId, vehicleId } = driver?.associations ?? { deviceId: undefined, vehicleId: undefined };

  const updateAssociationFleetAndCache = () =>
    updateAssociationMutation({
      variables: { deviceId: deviceId!, fleetId: targetFleetId, withDriverDetailsAssociations: true },
    });

  const updateDriverFleet = () => updateUser({ variables: { id: driver!.user.id, fleetIds: targetFleetId } });

  const changeDriverFleetOnly = () => {
    updateDriverFleet().then(resetTarget);
  };

  const moveBothDriverAndVehicleToTargetFleet = () => {
    updateDriverFleet().then(updateAssociationFleetAndCache).then(resetTarget);
  };

  const unassignVehicleFromDriver = () => {
    updateAssociationMutation({
      variables: { deviceId: deviceId!, driverId: NIL, withDriverDetailsAssociations: true },
    })
      .then(updateDriverFleet)
      .then(resetTarget);
  };

  const cancelButton = (
    <Button onClick={resetTarget} className="px-1 bg-gray-100 border-px rounded-2 mr-1">
      {cancel_text}
    </Button>
  );

  if (deviceId && vehicleId) {
    return (
      <div className="flex mt-1">
        {cancelButton}

        <ButtonWithConfirmModal
          onConfirm={moveBothDriverAndVehicleToTargetFleet}
          className="px-1 bg-gray-100 border-px rounded-2 mr-1"
          confirmContent={tSafe(
            'components.Driver.Detail.Edit.DriverFleetSelector.MoveDriverFleetOptions.confirm-modal.confirm-move-driver-and-vehicle',
            {
              targetFleetName,
              defaultValue:
                'Please confirm that you wish to move both driver and vehicle to the {{targetFleetName}} fleet',
            },
          )}
        >
          {tSafe(
            'components.Driver.Detail.Edit.DriverFleetSelector.MoveDriverFleetOptions.confirm-modal.button.move-driver-and-vehicle',
            {
              targetFleetName,
              defaultValue: 'Move both driver and vehicle to {{targetFleetName}}',
            },
          )}
        </ButtonWithConfirmModal>

        <ButtonWithConfirmModal
          onConfirm={unassignVehicleFromDriver}
          confirmContent={tSafe(
            'components.Driver.Detail.Edit.DriverFleetSelector.MoveDriverFleetOptions.confirm-modal.confirm-unassign-vehicle-and-move-driver',
            {
              targetFleetName,
              defaultValue:
                'Please confirm that you wish to unassign the vehicle and move the driver to the {{targetFleetName}} fleet',
            },
          )}
          className="px-1 bg-gray-100 border-px rounded-2"
        >
          {tSafe(
            'components.Driver.Detail.Edit.DriverFleetSelector.MoveDriverFleetOptions.confirm-modal.button.unassign-vehicle-and-move-driver',
            {
              targetFleetName,
              defaultValue: 'Unassign vehicle and move driver to {{targetFleetName}}',
            },
          )}
        </ButtonWithConfirmModal>
      </div>
    );
  }

  return (
    <>
      <div className="my-1">
        {cancelButton}

        <ButtonWithConfirmModal
          onConfirm={changeDriverFleetOnly}
          confirmContent={tSafe(
            'components.Driver.Detail.Edit.DriverFleetSelector.MoveDriverFleetOptions.confirm-modal.confirm-move-driver',
            {
              targetFleetName,
              defaultValue: 'Please confirm that you wish to move the driver to {{targetFleetName}}',
            },
          )}
          className="px-1 bg-gray-100 border-px rounded-2"
        >
          {tSafe(
            'components.Driver.Detail.Edit.DriverFleetSelector.MoveDriverFleetOptions.confirm-modal.button.move-driver',
            {
              targetFleetName,
              defaultValue: 'Move driver to {{targetFleetName}}',
            },
          )}
        </ButtonWithConfirmModal>
      </div>
    </>
  );
};

export default MoveDriverFleetOptions;
