import { useState } from 'react';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import Spinner from 'atoms/Spinner';
import { ClassName } from 'types';
import { cx } from 'utils';

interface PropGetter {
  isInEditMode: boolean;
  setIsInEditMode: (state: boolean) => void;
  value: string;
  setValue: (value: string) => void;
  onChange: (value: string) => void;
}

export const useEditableLabel = ({
  initialValue,
  onChange,
}: {
  initialValue: string;
  onChange: (value: string) => void;
}) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [value, setValue] = useState(initialValue);

  const props = {
    isInEditMode,
    setIsInEditMode,
    value,
    setValue,
    onChange: (value: string) => value !== initialValue && onChange(value),
  };

  return {
    reset: () => setValue(initialValue),
    ...props,
    getProps: () => props,
  };
};

interface EditableLabelProps extends PropGetter, ClassName {
  isLoading?: boolean;
  inputClassName?: string;
}

const EditableLabel = ({
  value,
  isInEditMode,
  onChange,
  setValue,
  setIsInEditMode,
  isLoading = false,
  className,
  inputClassName,
}: EditableLabelProps) => {
  const submit = () => {
    setIsInEditMode(false);
    onChange(value);
  };

  return (
    <div className={cx('flex gap-1 max-w-full', className)}>
      <span className="relative text-ellipsis whitespace-nowrap overflow-hidden">
        {isInEditMode ? (
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && submit()}
            className={cx(
              'relative border-b bg-transparent focus:outline-none w-full px-0.5',
              inputClassName,
              isInEditMode ? 'border-gray-400' : 'border-transparent',
              isLoading && 'opacity-5',
            )}
            readOnly={!isInEditMode}
          />
        ) : (
          <span>{value}</span>
        )}

        {isLoading && (
          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <Spinner className="!text-[0.5em]" />
          </span>
        )}
      </span>

      <Button
        className={cx(isLoading && '!opacity-5')}
        onClick={isInEditMode ? submit : () => setIsInEditMode(true)}
        disabled={isLoading}
      >
        <IonIcon name={isInEditMode ? 'checkmarkCircleOutline' : 'pencil'} className="text-md" />
      </Button>
    </div>
  );
};

export default EditableLabel;
