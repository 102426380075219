import { WatchQueryFetchPolicy } from '@apollo/client';

import { GetTenantDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

const useTenant = ({ fetchPolicy = 'cache-only' }: { fetchPolicy?: WatchQueryFetchPolicy } = {}) => {
  const { data: tenant } = useQ(GetTenantDoc, { fetchPolicy });

  return tenant;
};

export default useTenant;
