import { CellContext } from '@tanstack/react-table';

import IonIcon from 'atoms/IonIcon';
import useAccessibleFleetMap from '../useAccessibleFleetMap';
import { UserListRow } from 'types/settings';

const UserListFleetCell = ({ cell }: { cell: CellContext<UserListRow, string[]> }) => {
  const fleetNames = cell.getValue();

  return fleetNames.length > 1 ? (
    <>
      {fleetNames.map((name) => (
        <div key={name}>{name}</div>
      ))}

      {fleetNames.length > 2 && <IonIcon name="chevronDownOutline" className="absolute right-[140px] top-1.5" />}
    </>
  ) : (
    <>
      <div className="flex h-full items-center">{fleetNames}</div>
    </>
  );
};

export default UserListFleetCell;
