import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { I18nContext } from 'common/useT';
import { geofenceNotificationsListState } from 'components/Live/state';
import { GeofenceBreachType } from 'generated/graphql';
import { SelectOption } from 'types';
import { values } from 'utils';

const GeofenceBreachTypeFilter = () => {
  const i18nContext = useContext(I18nContext);
  const [listState, setListState] = useRecoilState(geofenceNotificationsListState);

  const geofenceBreachTypeOptions = i18nContext
    ? [
        {
          label: i18nContext.tSafe(
            'components.Live.Geofence.GeofenceSidebar.GeofenceBreaches.GeofenceBreachTypeFilter.all-breach-types',
            { defaultValue: 'All Breach Types' },
          ),
          value: 'All',
        },
        ...values(GeofenceBreachType).map((item) => ({
          label: i18nContext.commonTranslations.enums.geofenceBreachTypeDescriptionMap[item],
          value: item.toString(),
        })),
      ]
    : [];

  const { getProps } = useDropdownSelect(geofenceBreachTypeOptions, {
    initialItem: geofenceBreachTypeOptions.find((x) => x.value === 'All'),
    onSelect: (item: SelectOption<GeofenceBreachType | string>) =>
      setListState({ ...listState, breachTypeFilter: item.value }),
  });

  return (
    <div className="flex mt-1 w-1/2">
      <DropdownSelect {...getProps()} className="text-md" />
    </div>
  );
};

export default GeofenceBreachTypeFilter;
