import { useRecoilValue } from 'recoil';

import FleetAssociation from 'components/Live/Geofence/GeofenceSidebar/GeofenceSettings/AssociationList/FleetAssociation';
import VehicleAssociation from 'components/Live/Geofence/GeofenceSidebar/GeofenceSettings/AssociationList/VehicleAssociation';
import { selectedGeofenceState } from 'components/Live/state';

const AssociationList = () => {
  const selectedGeofence = useRecoilValue(selectedGeofenceState)!;

  return (
    <div className="flex flex-col">
      {selectedGeofence.fleetIds.map((fleetId) => (
        <FleetAssociation key={fleetId} fleetId={fleetId} />
      ))}

      {selectedGeofence.vehicleIds.map((vehicleId) => (
        <VehicleAssociation key={vehicleId} vehicleId={vehicleId} />
      ))}
    </div>
  );
};

export default AssociationList;
