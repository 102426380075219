import { useMemo } from 'react';

import useAccessibleFleetMap, { useTopLevelFleetIds } from 'components/User/useAccessibleFleetMap';
import { FleetStatsDoc } from 'generated/graphql';
import { Result } from 'types';
import { useQ } from 'utils/apolloClient';

const useFleetStatsMap = ({ skip }: { skip?: boolean } = {}) => {
  const { data: fleetStats } = useQ(FleetStatsDoc, { skip });
  const fleetMap = useAccessibleFleetMap();
  const topLevelFleetIds = useTopLevelFleetIds();

  return useMemo(() => {
    const statsMap = fleetStats?.reduce((acc, stats) => {
      acc[stats.id] = { ...stats, cumulativeUserCount: 0, cumulativeDriverCount: 0, cumulativeVehicleCount: 0 };
      return acc;
    }, {} as Record<string, Result<typeof FleetStatsDoc>[0] & { cumulativeUserCount: number; cumulativeDriverCount: number; cumulativeVehicleCount: number }>);

    if (statsMap) {
      const countCumulative = (fleetId: string) => {
        const { id, childrenIds } = fleetMap[fleetId]!;

        const cumulativeCount = childrenIds.reduce(
          (acc, id) => {
            const { userCount, driverCount, vehicleCount } = countCumulative(id);
            acc.userCount += userCount;
            acc.driverCount += driverCount;
            acc.vehicleCount += vehicleCount;
            return acc;
          },
          {
            userCount: statsMap[id].userCount,
            driverCount: statsMap[id].driverCount,
            vehicleCount: statsMap[id].vehicleCount,
          },
        );

        statsMap[id].cumulativeUserCount = cumulativeCount.userCount;
        statsMap[id].cumulativeDriverCount = cumulativeCount.driverCount;
        statsMap[id].cumulativeVehicleCount = cumulativeCount.vehicleCount;

        return {
          userCount: statsMap[id].cumulativeUserCount,
          driverCount: statsMap[id].cumulativeDriverCount,
          vehicleCount: statsMap[id].cumulativeVehicleCount,
        };
      };

      topLevelFleetIds.forEach(countCumulative);
    }

    return statsMap;
  }, [fleetMap, fleetStats, topLevelFleetIds]);
};

export default useFleetStatsMap;
