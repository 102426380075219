import { useMutation } from '@apollo/client';
import deepEqual from 'fast-deep-equal';
import { useContext, useMemo } from 'react';
import { Layouts, Responsive as ResponsiveGridLayout, WidthProvider } from 'react-grid-layout';
import { Helmet } from 'react-helmet-async';
import { useRecoilValue } from 'recoil';

import Spinner from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { draggableHandleClass, WidgetContextProvider } from 'components/Dashboard/Widget';
import { ActivityHistoryPanel } from 'components/Driver/Detail/ActivityHistoryPanel';
import { Header } from 'components/Driver/Detail/Header';
import { useDriverDetails } from 'components/Driver/Detail/hooks';
import widgetMap from 'components/Driver/Detail/widgetMap';
import useSettings from 'components/Settings/useSettings';
import { SetDriverPageLayoutDoc } from 'generated/graphql';
import { cx, withTitle } from 'utils';

import { driverPageCollapsedWidgetsState } from './state';
import { layoutToSerializedLayout, serializedLayoutToLayout } from './utils';

const GridLayout = WidthProvider(ResponsiveGridLayout);

const DriverDetail = () => {
  const i18nContext = useContext(I18nContext);
  const { data: [driverDetails] = [], loading, error } = useDriverDetails({ fetchPolicy: 'cache-first' });
  const collapsedWidgets = useRecoilValue(driverPageCollapsedWidgetsState);

  const {
    driverPageSettings: { widgetLayout },
  } = useSettings();

  const [setDriverPageLayout] = useMutation(SetDriverPageLayoutDoc, { fetchPolicy: 'no-cache' });

  const layout = useMemo(
    () => serializedLayoutToLayout({ widgetSettings: widgetLayout, collapsedWidgets }),
    [widgetLayout, collapsedWidgets],
  );

  if (loading || !i18nContext)
    return (
      <div className="p-4 flex-center">
        <Spinner />
      </div>
    );
  const {
    commonTranslations: {
      errors: { failed_to_fetch_data_text },
    },
    tSafe,
  } = i18nContext;

  const onLayoutChange = (layout: Layouts) =>
    setDriverPageLayout({ variables: { driverPageLayout: layoutToSerializedLayout(layout) } });

  if (error || !driverDetails) return <div className="mt-4 text-md flex-center">{failed_to_fetch_data_text}</div>;

  return (
    <div className="flex h-[calc(100vh_-_110px)] max-w-[1700px] m-auto">
      <Helmet title={driverDetails.user.name ?? undefined} defer={false} />

      <div className="flex w-full overflow-hidden">
        <div className="w-[62%] relative flex flex-auto flex-wrap">
          <Header />

          <ActivityHistoryPanel />
        </div>

        <div className="w-[38%] flex-none h-full overflow-y-scroll">
          <div className="flex flex-col ml-0.5">
            <div className="flex text-md flex-col">
              <GridLayout
                className={cx('layout m-auto w-full')}
                layouts={layout}
                cols={{ sm: 1, md: 1, lg: 1, xl: 1 }}
                breakpoints={{ sm: 0, md: 750, lg: 1300, xl: 1800 }}
                rowHeight={50}
                isResizable={false}
                draggableHandle={`.${draggableHandleClass}`}
                onLayoutChange={(_, updatedLayout) => {
                  if (!deepEqual(layoutToSerializedLayout(updatedLayout), layoutToSerializedLayout(layout))) {
                    onLayoutChange(updatedLayout);
                  }
                }}
              >
                {layout?.sm?.map(({ i: widgetKey }) => {
                  const Component = widgetMap[widgetKey].component;

                  return (
                    <div key={widgetKey}>
                      <WidgetContextProvider widgetKey={widgetKey}>
                        <Component />
                      </WidgetContextProvider>
                    </div>
                  );
                })}
              </GridLayout>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTitle(DriverDetail, 'Driver');
