import fuzzysort from 'fuzzysort';
import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import { MultiSearchData } from 'components/Live/MultiSearch';
import { mapState } from 'components/Live/state';
import { VehicleLastKnownLocation } from 'generated/graphql';

const keys = ['driverName', 'licencePlate'] as const;

const useLocationResults = (
  vehicles: VehicleLastKnownLocation[],
  searchTerm: string,
  setSearchTerm: (term: string) => void,
) => {
  const map = useRecoilValue(mapState);
  const [results, setResults] = useState<MultiSearchData[]>([]);

  useMemo(
    () =>
      fuzzysort
        .goAsync(
          searchTerm,
          vehicles
            ?.filter((x) => x.lastKnownLocation?.lat && x.lastKnownLocation?.lng)
            .map((x) => ({
              vehicleId: x.vehicleId,
              licencePlate: x.licencePlate,
              driverName: x.driverName,
              lat: x.lastKnownLocation?.lat,
              lng: x.lastKnownLocation?.lng,
            })) ?? [],
          {
            keys,
            limit: 10,
            threshold: -2000,
            allowTypo: true,
          },
        )
        .then((results) => {
          return results.map((result): MultiSearchData => {
            const [formattedDriverName, formattedLicencePlate] = result.map((res) =>
              fuzzysort.highlight(res, '<b>', '</b>'),
            );
            const details = result.obj;

            const onClick = () => {
              setSearchTerm('');

              map?.flyTo([details.lat ?? 0, details.lng ?? 0], 14, {
                animate: false,
              });
            };

            return {
              score: result.score,
              jsx: (
                <li
                  key={details.vehicleId}
                  className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
                  onClick={onClick}
                >
                  <IonIcon name="carSportOutline" className="mr-1 text-3xl" />

                  <div className="flex flex-col">
                    <span
                      className="text-md"
                      dangerouslySetInnerHTML={{
                        __html: formattedDriverName ?? details.driverName ?? '',
                      }}
                    />

                    <span
                      className="text-sm"
                      dangerouslySetInnerHTML={{ __html: formattedLicencePlate ?? details.licencePlate ?? '' }}
                    />
                  </div>
                </li>
              ),
            };
          });
        })
        .then(setResults),
    [vehicles, map, searchTerm, setSearchTerm],
  );

  return results;
};

export default useLocationResults;
