import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import ButtonWithConfirmModal from 'atoms/ButtonWithConfirmModal';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';
import { ApiError, DeleteFleetDoc } from 'generated/graphql';
import { getError } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';

const DeleteFleetToolbarItem = () => {
  const i18nContext = useContext(I18nContext);
  const { tSafe } = i18nContext ?? {};
  const [focusedFleetTreeFleetId, setFocusedFleetTreeFleetId] = useRecoilState(focusedFleetTreeFleetIdState);
  const [deleteFleet] = useMutation(DeleteFleetDoc, {
    update: (cache, { data: response }) => {
      if (!response) return;

      cache.modify({
        id: cache.identify({ __typename: 'Fleet', id: response.deleteFleet.parentId }),
        fields: {
          childrenIds: (ids: string[]) => ids.filter((id) => id !== response.deleteFleet.id),
        },
      });

      cache.evict({ id: cache.identify(response.deleteFleet) });
      cache.gc();
    },
    onCompleted: () => {
      if (tSafe) {
        successToast(
          tSafe('components.Fleet.FleetList.Toolbar.DeleteFleetToolbarItem.fleet-has-been-deleted-toast', {
            defaultValue: 'The fleet has been deleted',
          }),
        );
      }
    },
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });

  const onClick = () => {
    focusedFleetTreeFleetId && deleteFleet({ variables: { id: focusedFleetTreeFleetId } });
    setFocusedFleetTreeFleetId(undefined);
  };

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      forms: {
        buttons: { delete_text },
      },
    },
  } = i18nContext;

  return (
    <div className="hover:bg-gray-300">
      <ButtonWithConfirmModal className="text-sm space-x-0.5 flex-center" onConfirm={onClick}>
        <IonIcon className="text-lg" name="trashOutline" />

        <span>{delete_text}</span>
      </ButtonWithConfirmModal>
    </div>
  );
};

export default DeleteFleetToolbarItem;
