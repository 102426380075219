import { atom } from 'recoil';

import { Period } from 'generated/graphql';
import { defaultDashboardTimePeriod } from 'common/constants';

/** @package */
export const driverListState = atom({
  key: 'driverListState',
  default: {
    onReset: () => {},
  },
});

/** @package */
export const driverDetailStatisticsPeriodState = atom<Exclude<Period, Period.Forever>>({
  key: 'driverDetailStatisticsPeriodState',
  default: defaultDashboardTimePeriod,
});
