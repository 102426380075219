import { DeferredRender } from 'atoms/DeferredRender';
import FleetTree from 'components/Fleet/FleetList/FleetTree';
import { withTitle } from 'utils';

export enum FleetHierarchyMode {
  NAVIGATION,
  SELECTION,
  MULTISELECTION,
  MANAGEMENT,
}

interface FleetHierarchyProps {
  mode?: FleetHierarchyMode;
  onFleetSelect?: (fleetId: string) => void;
}

const FleetHierarchy = ({ mode = FleetHierarchyMode.SELECTION, onFleetSelect }: FleetHierarchyProps) => {
  return (
    <div className="h-full w-full">
      <DeferredRender>
        <FleetTree mode={mode} onFleetSelect={onFleetSelect} />
      </DeferredRender>
    </div>
  );
};

export default withTitle(FleetHierarchy, 'Fleet Hierarchy');
