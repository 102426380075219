import { useContext } from 'react';

import { I18nContext } from 'common/useT';
import { DeviceConnectionStatus } from 'generated/graphql';
import { cx } from 'utils';

import Icon from './Icon';
import { IconName } from './Icon/icons';

const DeviceStatusIcon = ({ connectionStatus }: { connectionStatus: DeviceConnectionStatus }) => {
  interface IconDetails {
    name: string;
    aria: string;
    color: string;
  }

  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { deviceConnectionStatusDescriptionMap },
    },
  } = i18nContext;

  const iconMap: Record<DeviceConnectionStatus, IconDetails> = {
    [DeviceConnectionStatus.Connected]: {
      name: 'deviceConnected',
      aria: deviceConnectionStatusDescriptionMap[DeviceConnectionStatus.Connected],
      color: 'text-success',
    },
    [DeviceConnectionStatus.Disconnected]: {
      name: 'deviceDisconnected',
      aria: deviceConnectionStatusDescriptionMap[DeviceConnectionStatus.Disconnected],
      color: 'text-error',
    },
    [DeviceConnectionStatus.NeverConnected]: {
      name: 'deviceDisconnected',
      aria: deviceConnectionStatusDescriptionMap[DeviceConnectionStatus.NeverConnected],
      color: 'text-gray-300',
    },
    [DeviceConnectionStatus.NotReporting]: {
      name: 'deviceNotReporting',
      aria: deviceConnectionStatusDescriptionMap[DeviceConnectionStatus.NotReporting],
      color: 'text-error',
    },
  };
  return (
    <Icon
      name={iconMap[connectionStatus].name as IconName}
      className={cx('rounded-4 !w-4', iconMap[connectionStatus].color)}
      aria-label={iconMap[connectionStatus].aria}
    />
  );
};

export default DeviceStatusIcon;
