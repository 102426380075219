import { ComponentType } from 'react';
import { Layout } from 'react-grid-layout';

import * as DashboardLightsWidget from './DashboardLights';
import * as DeviceInfoWidget from './DeviceInfo';
import * as GeofencesWidget from './Geofences';
import * as LastKnownLocationWidget from './LastKnownLocation';
import * as VehicleLevelsWidget from './Levels';
import * as VehicleStatusWidget from './Status';
import * as TyrePressureWidget from './TyrePressure';

const maxH = 10;

const widgetMap: Record<string, Partial<Layout> & { component: ComponentType }> = {
  vehicleStatusWidget: {
    component: VehicleStatusWidget.default,
    minH: 1,
    maxH,
  },
  vehicleLastKnownLocationWidget: {
    component: LastKnownLocationWidget.default,
    minH: 1,
    maxH,
  },
  geofencesWidget: {
    component: GeofencesWidget.default,
    minH: 1,
    maxH,
  },
  vehicleLevelsWidget: {
    component: VehicleLevelsWidget.default,
    minH: 1,
    maxH,
  },
  tyrePressureWidget: {
    component: TyrePressureWidget.default,
    minH: 1,
    maxH,
  },
  dashboardLightsWidget: {
    component: DashboardLightsWidget.default,
    minH: 1,
    maxH,
  },
  deviceInfoWidget: {
    component: DeviceInfoWidget.default,
    minH: 1,
    maxH,
  },
};

export default widgetMap;
