import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import IonIcon from 'atoms/IonIcon';
import { LicencePlate } from 'atoms/LicencePlate';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import VehicleSelector from 'components/Driver/Detail/Header/VehicleInfo/VehicleSelector';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useRole from 'components/User/useRole';

import { useDriverDetails } from '../../hooks';

export const VehicleInfo = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, error, data: [driverDetails] = [] } = useDriverDetails();

  const currentFleetId = useCurrentFleetId();
  const { isManager } = useRole();
  const [isEditMode, setIsEditMode] = useState(false);

  if (loading || !i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { error_text },
      general: { unavailable_text },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  if (!driverDetails)
    return (
      <div className="text-xl">
        {tSafe('components.Driver.Detail.Header.no-vehicle-assigned', { defaultValue: 'No vehicle assigned' })}
      </div>
    );

  const { make, model, fuelType, year, licencePlate, id } = driverDetails.associations?.vehicle?.vehicle ?? {};

  if (isEditMode) {
    return (
      <>
        <span className="flex items-center">
          <IonIcon name="carOutline" className="mr-1" aria-label="Car make and model" />

          <VehicleSelector
            currentVehicle={driverDetails.associations?.vehicle}
            cancelEditMode={() => setIsEditMode(false)}
          />
        </span>
      </>
    );
  }

  return (
    <div className="flex justify-start align-baseline mt-0.5 text-md">
      <div className="flex justify-center w-2">
        <IonIcon
          name="carOutline"
          className="mr-1"
          aria-label={tSafe('components.Driver.Detail.Header.VehicleInfo.icon-aria.car-make-model', {
            defaultValue: 'Car make and model',
          })}
        />
      </div>

      {driverDetails.associations?.vehicle ? (
        <>
          <span className="uppercase mr-2">{`${make} ${model} (${year ? `${year} ` : ''} ${fuelType})`}</span>

          <div className="ui-button">
            <Tooltip
              text={tSafe('common.domain.vehicleDetails.view-vehicle', {
                defaultValue: 'View vehicle',
              })}
            >
              <Link to={`/${currentFleetId}/vehicles/${id}`} aria-label="View Vehicle">
                <LicencePlate licenceNumber={licencePlate ?? unavailable_text} textClass="text-sm" />
              </Link>
            </Tooltip>
          </div>
        </>
      ) : (
        <span>
          {tSafe('components.Driver.Detail.Header.VehicleInfo.no-vehicle-assigned', {
            defaultValue: 'No vehicle assigned',
          })}
        </span>
      )}

      {isManager && (
        <span className="ml-1 ui-button">
          <Tooltip
            text={tSafe('components.Driver.Detail.Header.VehicleInfo.Tooltip.reassign-vehicle', {
              defaultValue: 'Re-assign vehicle',
            })}
          >
            <IonIcon name="pencil" className="w-2 h-2 cursor-pointer" onClick={() => setIsEditMode(true)} />
          </Tooltip>
        </span>
      )}
    </div>
  );
};
