// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB } from 'date-fns/locale';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { InfoPanel } from 'atoms/InfoPanel';
import { InfoPanelItem } from 'atoms/InfoPanel/InfoPanelItem';
import { deviceDiagnosticIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import { DriverDeviceDiagnosticEvent, VehicleDeviceDiagnosticEvent } from 'generated/graphql';
import { cx } from 'utils';

interface DeviceDiagnosticEventDetailProps {
  event: Partial<VehicleDeviceDiagnosticEvent | DriverDeviceDiagnosticEvent>;
}

const DeviceDiagnosticEventDetail = ({ event }: DeviceDiagnosticEventDetailProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { time_text, unavailable_text },
      enums: { deviceDiagnosticDescriptionMap },
      domain: {
        deviceDiagnostic: {
          fields: { type_text },
        },
      },
    },
  } = i18nContext;

  const { deviceDiagnosticMessageType: messageType, time } = event;

  return (
    <div className="mt-2">
      <InfoPanel>
        <Icon
          name={deviceDiagnosticIconMap[messageType!][0]}
          className={cx('!w-4', deviceDiagnosticIconMap[messageType!][1])}
        />

        <InfoPanelItem description={type_text} value={deviceDiagnosticDescriptionMap[messageType!]} />

        <InfoPanelItem
          description={time_text}
          value={time ? format(Date.parse(time), 'PPPPp', { locale: enGB }) : unavailable_text}
        />
      </InfoPanel>
    </div>
  );
};

export default DeviceDiagnosticEventDetail;
