import { useLazyQuery, useMutation } from '@apollo/client';
import { DetailedHTMLProps, HTMLAttributes, useContext, useState } from 'react';

import IonIcon from 'atoms/IonIcon';
import Modal, { useModal } from 'atoms/Modal';
import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import { DriverEditForm, DriverEditFormData } from 'components/Driver/Detail/Edit/DriverEditForm';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { ApiError, CreateUserDoc, GetEntityListDoc, GetNewlyCreatedDriverDetailsDoc } from 'generated/graphql';
import { getError } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';

const Trigger = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div {...props}>
      <span className="text-sm space-x-0.5 flex-center ui-button-dark">
        <IonIcon className="text-lg" name="addCircleOutline" />

        <span>
          {tSafe('components.Driver.DriverList.DriverListToolbar.AddDriverToolbarItem.Trigger.add-driver', {
            defaultValue: 'Add Driver',
          })}
        </span>
      </span>
    </div>
  );
};

const AddDriverToolbarItem = () => {
  const { tSafe } = useContext(I18nContext) ?? {};
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const currentFleetId = useCurrentFleetId();
  const { closeModal, props } = useModal({});
  const [getDriverDetails, { loading: driverDetailsLoading }] = useLazyQuery(GetNewlyCreatedDriverDetailsDoc, {
    onCompleted: () => {
      if (tSafe) {
        successToast(
          tSafe('components.ListPage.AddDriverToolbarItem.success-toast', {
            defaultValue: 'The driver has been successfully created',
          }),
        );
      }

      closeModal();
    },
    onError: (rawError) => {
      const { message } = getError(rawError) ?? {};

      if (message) {
        errorToast(message);
        setUploadInProgress(false);
      }
    },
  });
  const [createUser, { loading: createUserLoading }] = useMutation(CreateUserDoc, {
    onCompleted: async (newUserData) => {
      await getDriverDetails({
        variables: { driverId: newUserData.createUser.driverId! },
      });
    },
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
        setUploadInProgress(false);
      }
    },
  });

  const onSubmit = ({ name, email, phoneNumber, sendPasswordEmail }: DriverEditFormData) => {
    if (!name || !email) return;

    setUploadInProgress(true);

    createUser({
      variables: {
        email,
        name,
        phoneNumber,
        isDriver: true,
        active: true,
        fleetIds: currentFleetId,
        skipLoginEmail: !sendPasswordEmail,
      },
    });
  };

  return (
    <Modal {...props} title="Create Driver" trigger={<Trigger />} contentClassName="w-60" preventClose>
      {uploadInProgress && (createUserLoading || driverDetailsLoading) && (
        <div className="flex flex-col">
          <CenteredSpinner />
        </div>
      )}

      <DriverEditForm creationMode onSubmit={onSubmit} />
    </Modal>
  );
};

export default AddDriverToolbarItem;
