import { GetUserDoc } from 'generated/graphql';
import { Result } from 'types';
import {} from 'types/gridLayout';
import { Settings } from 'types/settings';
import { useQ } from 'utils/apolloClient';

const useUser = () => {
  const { data: user } = useQ(GetUserDoc, { fetchPolicy: 'cache-only' });

  return user as Omit<NonNullable<Result<typeof GetUserDoc>>, 'settings'> & { settings: Settings };
};

export default useUser;
