import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import IonIcon from 'atoms/IonIcon';
import Modal, { useModal } from 'atoms/Modal';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useRole from 'components/User/useRole';
import DriverModalContent from 'components/Vehicle/Detail/Header/DriverInfo/DriverModalContent';
import { DriverSelector } from 'components/Vehicle/Detail/Header/DriverInfo/DriverSelector';
import { useVehicleDetails } from 'components/Vehicle/Detail/hooks';

const DriverInfo = () => {
  const i18nContext = useContext(I18nContext);
  const { openModal: openDriverModal, props: driverModalProps } = useModal({
    title: i18nContext?.commonTranslations.domain.driverDetails.driver_details_text,
    triggerClassName: 'ui-button',
  });
  const { loading, error, data: [vehicleDetails] = [] } = useVehicleDetails();
  const currentFleetId = useCurrentFleetId();
  const { isManager } = useRole();
  const [isEditMode, setIsEditMode] = useState(false);

  if (loading || !i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      errors: { entity_not_found_tfn, error_text },
      domain: {
        driverDetails: { driver_details_text },
        driver: {
          fields: { driver_name_text },
        },
        vehicle: { vehicle_text },
      },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  if (!vehicleDetails) return <div className="text-xl">{entity_not_found_tfn(vehicle_text)}</div>;

  const {
    associations: { driver },
  } = vehicleDetails;

  if (isEditMode) {
    return (
      <>
        <span className="flex items-center">
          <IonIcon name="personCircleOutline" className="mx-1" aria-label={driver_name_text} />

          <DriverSelector currentDriver={driver} cancelEditMode={() => setIsEditMode(false)} />
        </span>
      </>
    );
  }

  return (
    <>
      <Modal {...driverModalProps}>
        <DriverModalContent />
      </Modal>

      <span className="flex items-center">
        <IonIcon name="personCircleOutline" className="mx-1" aria-label={driver_name_text} />

        {driver ? (
          <Tooltip text={driver_details_text}>
            <span className="hover:underline" onClick={openDriverModal}>
              {driver.user.name}
            </span>
          </Tooltip>
        ) : (
          <span>
            {tSafe('components.Vehicle.Detail.Header.DriverInfo.no-driver-assigned', {
              defaultValue: 'No driver assigned',
            })}
          </span>
        )}
      </span>

      {driver && (
        <>
          <span className="ml-1 max-h-full cursor-pointer items-center flex hover:outline hover:outline">
            <Tooltip
              text={tSafe('common.domain.driverDetails.view-driver', {
                defaultValue: 'View Driver',
              })}
            >
              <Link
                to={`/${currentFleetId}/drivers/${driver.id}`}
                aria-label={tSafe('common.domain.driverDetails.view-driver', {
                  defaultValue: 'View Driver',
                })}
              >
                <IonIcon className="text-lg" name="eyeOutline" />
              </Link>
            </Tooltip>
          </span>
        </>
      )}

      {isManager && (
        <span className="ml-1">
          <Tooltip
            text={tSafe('components.Vehicle.Detail.Header.DriverInfo.reassign-driver', {
              defaultValue: 'Reassign Driver',
            })}
          >
            <IonIcon name="pencil" className="w-2 h-2 cursor-pointer ui-button" onClick={() => setIsEditMode(true)} />
          </Tooltip>
        </span>
      )}
    </>
  );
};

export default DriverInfo;
