import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { DaysOfMonthDropdown, DaysOfWeekDropdown, ReportScheduleDropdown } from 'components/Reporting/Dropdowns';
import { Report, ReportSchedule, UpdateReportDoc, WeeklyReport } from 'generated/graphql';
import { cx } from 'utils';

export type ReportFrequencyUpdateFormData = Pick<
  Report,
  | 'schedule'
  | 'dayOfMonth'
  | 'dayOfWeek'
  | 'fleetIds'
  | 'type'
  | 'dateRange'
  | 'dateFrom'
  | 'dateTo'
  | 'currencyUnit'
  | 'dieselPrice'
  | 'idleTime'
  | 'petrolPrice'
  | 'volumeUnit'
  | 'distanceUnit'
>;

interface UpdateFrequencyFormProps {
  initialState: ReportFrequencyUpdateFormData;
  reportId: string;
}

const UpdateFrequencyForm = ({ initialState, reportId }: UpdateFrequencyFormProps) => {
  const i18nContext = useContext(I18nContext);
  const { setValue, watch } = useForm<ReportFrequencyUpdateFormData>({
    defaultValues: {
      schedule: initialState.schedule,
      dayOfMonth: initialState.dayOfMonth,
      dayOfWeek: initialState?.dayOfWeek,
      dateRange: initialState?.dateRange,
      dateFrom: initialState?.dateFrom,
      dateTo: initialState?.dateTo,
    },
  });
  const [updateReportMutation, { error }] = useMutation(UpdateReportDoc);
  const [showSuccess, setShowSuccess] = useState(false);

  const updateReport = () => {
    setShowSuccess(false);
    updateReportMutation({
      variables: {
        id: reportId,
        report: {
          fleetIds: initialState.fleetIds,
          type: initialState.type,
          schedule: watch('schedule'),
          dayOfMonth: watch('schedule') === ReportSchedule.Monthly ? watch('dayOfMonth') : null,
          dayOfWeek: watch('schedule') === ReportSchedule.Weekly ? watch('dayOfWeek') : null,
          dateRange: watch('dateRange'),
          dateFrom: watch('dateFrom'),
          dateTo: watch('dateTo'),
        },
      },
    }).then(() => setShowSuccess(true));
  };

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()} className="w-30 flex items-center">
        <ReportScheduleDropdown
          currentValue={watch('schedule')}
          onSelect={(item) => {
            setValue('schedule', item.value as ReportSchedule);
          }}
          className="w-[12rem]"
          optionsToDisplay={[ReportSchedule.Monthly, ReportSchedule.Weekly]}
        />

        {watch('schedule') === ReportSchedule.Weekly && (
          <>
            <span className="mx-0.5">
              {tSafe('components.Reporting.UpdateFrequencyForm.on-for-day-of-week', { defaultValue: ' on ' })}
            </span>

            <DaysOfWeekDropdown
              currentValue={watch('dayOfWeek')}
              onSelect={(item) => {
                setValue('dayOfWeek', item.value as WeeklyReport);
                updateReport();
              }}
              className={cx(
                'w-[11rem]',
                watch('schedule') === ReportSchedule.Weekly && !watch('dayOfWeek') && 'border-px border-error',
              )}
            />
          </>
        )}

        {watch('schedule') === ReportSchedule.Monthly && (
          <>
            <span className="mx-0.5">
              {tSafe('components.Reporting.UpdateFrequencyForm.on-for-day-of-month', { defaultValue: ' on day ' })}{' '}
            </span>

            <DaysOfMonthDropdown
              currentValue={watch('dayOfMonth')}
              onSelect={(item) => {
                setValue('dayOfMonth', parseInt(item.value));
                updateReport();
              }}
              className={cx(
                'w-6',
                watch('schedule') === ReportSchedule.Monthly && !watch('dayOfMonth') && 'border-px border-error',
              )}
            />
          </>
        )}

        {error && (
          <span>
            <IonIcon className="text-error text-2xl" name="alertOutline" />
          </span>
        )}

        {showSuccess && (
          <span>
            <IonIcon className="text-success text-2xl ml-0.5" name="checkmarkCircleOutline" />
          </span>
        )}
      </form>
    </div>
  );
};

export default UpdateFrequencyForm;
