// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB } from 'date-fns/locale';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { deviceDiagnosticIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import {
  DateTimeColumn,
  EventDetailContainer,
  EventTypeColumn,
  IconColumn,
} from 'components/ActivityHistoryList/styledComponents';
import { DriverDeviceDiagnosticEvent, VehicleDeviceDiagnosticEvent } from 'generated/graphql';
import { cx } from 'utils';

interface DeviceDiagnosticEventRowItemProps {
  event: Partial<VehicleDeviceDiagnosticEvent | DriverDeviceDiagnosticEvent>;
  time: string;
}

const DeviceDiagnosticEventRowItem = ({ event, time }: DeviceDiagnosticEventRowItemProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { deviceDiagnosticDescriptionMap },
    },
  } = i18nContext;

  const { deviceDiagnosticMessageType: messageType } = event;

  return (
    <EventDetailContainer>
      <DateTimeColumn>{format(parseISO(time), 'iii PPp', { locale: enGB })}</DateTimeColumn>

      <EventTypeColumn>{deviceDiagnosticDescriptionMap[messageType!]}</EventTypeColumn>

      <IconColumn>
        <Icon
          name={deviceDiagnosticIconMap[messageType!][0]}
          className={cx('!w-4', deviceDiagnosticIconMap[messageType!][1])}
        />
      </IconColumn>
    </EventDetailContainer>
  );
};

export default DeviceDiagnosticEventRowItem;
