import tw from 'twin.macro';

export const ItemWrapper = tw.div`w-full py-1 px-2 min-h-6 hover:bg-gray-300 border-b-px border-gray-300 cursor-pointer`;

export const EventDetailContainer = tw.div`flex items-center min-h-4`;

export const DateTimeColumn = tw.div`font-bold w-[220px]`;

export const IconColumn = tw.div`flex-center w-[120px]`;

export const EventTypeColumn = tw.div`text-center p-0.5 w-[18%] ml-[0%] rounded-8 bg-gray-100 font-bold`;

export const DefaultAdditionalColumn = tw.div`max-w-[18%] flex-grow text-center`;

export const TripAdditionalColumn = tw(DefaultAdditionalColumn)`flex flex-col items-center`;

export const DtcAdditionalColumn = tw.div`pl-[5%] w-[45%]`;
