import { UserListColumnId } from 'generated/graphql';
import { atom } from 'recoil';

/** @package */
export const userListState = atom({
  key: 'userListState',
  default: {
    isInEditMode: false,
    uniqueValues: {} as Partial<Record<UserListColumnId, NonNullable<string | null | undefined>[]>>,
    onReset: () => {},
  },
});
