import * as RAvatar from '@radix-ui/react-avatar';

import useUser from 'components/User/useUser';

const Avatar = () => {
  const { name } = useUser();

  return (
    <RAvatar.Root className="flex-center overflow-hidden select-none w-4 h-4 rounded-[100%] font-[system-ui]">
      <RAvatar.AvatarFallback className="flex-center w-full h-full bg-white text-dark-gray text-[1.3rem] font-medium leading-none focus:outline-none">
        {name
          ?.split(' ')
          .map((x) => x.charAt(0).toUpperCase())
          .join('')}
      </RAvatar.AvatarFallback>
    </RAvatar.Root>
  );
};

export default Avatar;
