import { useMutation } from '@apollo/client';
import { DetailedHTMLProps, HTMLAttributes, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import Modal, { useModal } from 'atoms/Modal';
import { I18nContext } from 'common/useT';
import { listState } from 'components/List/state';
import { ApiError, SaveListViewSettingsDoc } from 'generated/graphql';
import { keys } from 'utils';
import { getError } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';
import Button from 'atoms/Button';
import { useLocation } from 'react-router-dom';

const Trigger = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div {...props}>
      <span className="text-sm space-x-0.5 flex-center ui-button-dark">
        <IonIcon className="text-lg" name="addCircleOutline" />

        <span>{tSafe('components.ListPage.new-list', { defaultValue: 'New List' })}</span>
      </span>
    </div>
  );
};

const SaveListViewToolbarItem = ({
  isInEditMode = false,
  listNameToEdit,
}: {
  isInEditMode?: boolean;
  listNameToEdit?: string;
}) => {
  const location = useLocation();
  const { formState, register, getValues, reset } = useForm({
    defaultValues: { name: isInEditMode ? listNameToEdit ?? '' : '' },
    mode: 'onSubmit',
  });
  const [saveList] = useMutation(SaveListViewSettingsDoc, {
    onCompleted: async () => {
      successToast(
        isInEditMode
          ? tSafe('components.ListPage.toasts.list-name-edited', { defaultValue: 'List name edited' })
          : tSafe('components.ListPage.toasts.saved-new-list-view', { defaultValue: 'Saved new list view' }),
      );
    },
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });
  const [errorText, setErrorText] = useState<string | null>(null);

  const editViewName = (name: string, newName: string) => {
    const newViews = { ...listRecoilState.allListViews };
    newViews[newName] = newViews[name];
    delete newViews[name];

    setListRecoilState({
      ...listRecoilState,
      activeListViewName: newName,
      allListViews: newViews,
    });

    saveList({
      variables: {
        activeListView: newName,
        listViews: newViews,
      },
    });
  };
  const i18nContext = useContext(I18nContext);
  const { closeModal, props } = useModal({});
  const [listRecoilState, setListRecoilState] = useRecoilState(listState);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      domain: {
        user: {
          fields: { name_text },
        },
      },
      forms: {
        buttons: { cancel_text, save_text },
      },
    },
  } = i18nContext;

  const handleSaveList = () => {
    location.state = {};

    if (keys(listRecoilState.allListViews).includes(getValues().name)) {
      setErrorText(
        tSafe('components.ListPage.list-name-exists', {
          listName: getValues().name,
          defaultValue: `List with name "{{listName}}" already exists`,
        }),
      );
      return;
    }

    setErrorText(null);

    if (isInEditMode && listNameToEdit) {
      editViewName(listNameToEdit, getValues().name);
      return;
    }

    setListRecoilState({
      ...listRecoilState,
      activeListViewName: getValues().name,
      allListViews: {
        ...listRecoilState.allListViews,
        [getValues().name]: {
          ...listRecoilState.defaultListView,
          default: false,
        },
      },
    });
    saveList({
      variables: {
        activeListView: getValues().name,
        listViews: {
          ...listRecoilState.allListViews,
          [getValues().name]: {
            ...listRecoilState.defaultListView,
            default: false,
          },
        },
      },
    });
    reset();
    closeModal();
  };

  return (
    <Modal
      {...props}
      title={
        isInEditMode
          ? tSafe('components.ListPage.edit-list-name', { defaultValue: 'Edit list name' })
          : tSafe('components.ListPage.create-new-list-view', { defaultValue: 'Create new list view' })
      }
      trigger={
        isInEditMode ? (
          <div className="px-1 py-2 hover:bg-gray-100">
            <IonIcon name="pencilOutline" />
          </div>
        ) : (
          <Trigger />
        )
      }
    >
      <div className="text-md">
        <span className="mr-1">
          {isInEditMode ? tSafe('components.ListPage.edit-list-name', { defaultValue: 'Edit list name' }) : name_text}:
        </span>

        <input
          {...register('name', { required: true })}
          className="min-w-[400px] outline outline-gray-300 rounded-4 px-2 py-1 placeholder-gray-300"
          defaultValue={isInEditMode ? listRecoilState.activeListViewName : undefined}
        />
        <div className="mr-1 text-error mt-1">{errorText}</div>

        <div className="mt-1 flex-center">
          {formState.isDirty && (
            <Button className="px-2 py-0.5 bg-primary border-px text-white rounded-4" onClick={() => handleSaveList()}>
              {save_text}
            </Button>
          )}
          <Button
            className="px-2 py-0.5 bg-primary border-px text-white rounded-4"
            onClick={() => {
              reset();
              closeModal();
            }}
          >
            {cancel_text}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SaveListViewToolbarItem;
