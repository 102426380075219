import { TileIconMap } from 'atoms/WidgetTile';
import { StatsTile } from 'components/Driver/Detail/DrivingStats/StatsTile';
import { TripStatsHistoryRecord } from 'generated/graphql';

export interface Tile {
  name: string;
  description: string;
  value: number;
  icon: TileIconMap<string>[string];
  histogramData: TripStatsHistoryRecord[] | undefined;
}

interface StatsTileGroupProps<T extends Tile> {
  tiles: T[];
  valueClassName?: string;
  defaultIconClassName?: string;
  showZeroValues?: boolean;
  count?: boolean;
}

export const StatsTileGroup = <T extends Tile>({
  tiles,
  valueClassName,
  defaultIconClassName,
  showZeroValues,
  count,
}: StatsTileGroupProps<T>) => {
  return (
    <div className="grid gap-0.5 grid-rows-1 p-1 auto-rows-fr grid-cols-[repeat(auto-fill,minmax(23rem,1fr))]">
      {tiles.map(({ name, description, value, icon: iconName, histogramData }) => (
        <StatsTile
          key={name}
          name={name}
          description={description}
          value={value}
          icon={iconName}
          histogramData={histogramData}
          valueClassName={valueClassName}
          defaultIconClassName={defaultIconClassName}
          showZeroValues={showZeroValues}
          count={count}
        />
      ))}
    </div>
  );
};
