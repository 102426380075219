import useUser from 'components/User/useUser';
import {} from 'types/gridLayout';

const useSettings = () => {
  const user = useUser();

  return user.settings;
};

export default useSettings;
