import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import ButtonGroup from 'atoms/ButtonGroup';
import LocaleDropdown from 'atoms/LocaleDropdown';
import { I18nContext } from 'common/useT';
import Setting from 'components/Settings/Setting';
import SettingGroup from 'components/Settings/SettingGroup';
import useSettings from 'components/Settings/useSettings';
import { UpdateSettingsDoc, UserLocale } from 'generated/graphql';

const Preferences = () => {
  const i18nContext = useContext(I18nContext);
  const [updateSettings] = useMutation(UpdateSettingsDoc);
  const settings = useSettings();

  if (!i18nContext) return null;

  const { tSafe, i18n } = i18nContext;

  return (
    <SettingGroup title={tSafe('components.Settings.Preferences.title', { defaultValue: 'Preferences' })}>
      <div className="flex flex-col gap-1">
        <Setting label={tSafe('components.Settings.Preferences.language', { defaultValue: 'Language' })}>
          <LocaleDropdown
            className="text-sm"
            onSelect={(option) => {
              updateSettings({ variables: { locale: option.value as UserLocale } });
              localStorage.setItem('i18nextLng', option.value);
              i18n.changeLanguage(option.value);
            }}
            currentValue={settings.locale}
          />
        </Setting>

        <Setting label={tSafe('components.Settings.Preferences.distance-unit', { defaultValue: 'Distance Unit' })}>
          <ButtonGroup
            buttons={{
              km: () => updateSettings({ variables: { distanceInMiles: false } }),
              mi: () => updateSettings({ variables: { distanceInMiles: true } }),
            }}
            defaultButton={settings.distanceInMiles ? 'mi' : 'km'}
          />
        </Setting>

        <Setting label={tSafe('components.Settings.Preferences.volume-unit', { defaultValue: 'Volume Unit' })}>
          <ButtonGroup
            buttons={{
              l: () => updateSettings({ variables: { volumeInGallons: false } }),
              gal: () => updateSettings({ variables: { volumeInGallons: true } }),
            }}
            defaultButton={settings.volumeInGallons ? 'gal' : 'l'}
          />
        </Setting>

        <Setting label={tSafe('components.Settings.Preferences.idle-time', { defaultValue: 'Idle Time' })}>
          <ButtonGroup
            buttons={{
              h: () => updateSettings({ variables: { idleTimeAsPercentage: false } }),
              '%': () => updateSettings({ variables: { idleTimeAsPercentage: true } }),
            }}
            defaultButton={settings.idleTimeAsPercentage ? '%' : 'h'}
          />
        </Setting>
      </div>
    </SettingGroup>
  );
};

export default Preferences;
