import { PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CellProps, Column, useTable } from 'react-table';

import { getHeaderRenderer } from 'atoms/react-table/TableHeader';
import Separator from 'atoms/Separator';
import { I18nContext } from 'common/useT';
import LeaderboardTableHeader from 'components/Dashboard/LeaderboardWidget/LeaderboardTableHeader';
import { WidgetContext } from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useSettings from 'components/Settings/useSettings';
import { GetLeaderboardDoc } from 'generated/graphql';
import { Result } from 'types';
import { LeaderboardRecord } from 'types/gridLayout';
import { DashboardWidget } from 'types/widgets';
import { cx, kmToMi, lToGal } from 'utils';

type LeaderboardItem = Result<typeof GetLeaderboardDoc>[0];

export type LeaderboardCell<T> = PropsWithChildren<CellProps<LeaderboardItem, T>>;

interface LeaderboardTableProps {
  leaders: LeaderboardRecord[];
  className?: string;
}

const CenterCell = (cell: LeaderboardCell<number>) => <div className="text-center">{cell.value}</div>;

const header = getHeaderRenderer(LeaderboardTableHeader);

const useColumns = () => {
  const i18nContext = useContext(I18nContext);
  const { distanceInMiles, volumeInGallons, idleTimeAsPercentage } = useSettings();
  const currentFleetId = useCurrentFleetId();

  const { tSafe } = i18nContext ?? {};

  const columns: Column<LeaderboardTableProps['leaders'][number]>[] = useMemo(
    () =>
      tSafe
        ? [
            {
              accessor: 'ranking',
              Header: header('#', { alignment: 'right' }),
              Cell: (row) => <div className="text-right font-semibold">{row.value}.</div>,
            },
            {
              accessor: 'name',
              Header: header(
                tSafe('components.Dashboard.LeaderboardWidget.table.headers.name', { defaultValue: 'Name' }),
                { alignment: 'left' },
              ),
              Cell: ({ row, value }: LeaderboardCell<string>) => (
                <Link to={`/${currentFleetId}/drivers/${row.original.driverId}`} className="text-hover hover:underline">
                  {value}
                </Link>
              ),
            },
            {
              accessor: 'avgEcoScore',
              Header: header(
                tSafe('components.Dashboard.LeaderboardWidget.table.headers.eco-score', { defaultValue: 'Eco Score' }),
              ),
              Cell: CenterCell,
            },
            {
              accessor: 'totalDistance',
              Header: header(
                tSafe('components.Dashboard.LeaderboardWidget.table.headers.total-distance', {
                  unit: distanceInMiles ? 'mi' : 'km',
                  defaultValue: 'Distance ({{unit}})',
                }),
              ),
              Cell: ({ value }) => <div className="text-center">{distanceInMiles ? kmToMi(value) : value}</div>,
            },
            {
              accessor: 'totalFuelConsumption',
              Header: header(
                tSafe('components.Dashboard.LeaderboardWidget.table.headers.total-fuel-consumption', {
                  unit: volumeInGallons ? 'gal' : 'l',
                  defaultValue: 'Fuel ({{unit}})',
                }),
              ),
              Cell: ({ value }) => <div className="text-center">{volumeInGallons ? lToGal(value) : value}</div>,
            },
            {
              accessor: idleTimeAsPercentage ? 'idleTimePercentage' : 'idleTime',
              Header: header(
                tSafe('components.Dashboard.LeaderboardWidget.table.headers.idle-time', {
                  unit: idleTimeAsPercentage ? '%' : 'h',
                  defaultValue: 'Idle ({{unit}})',
                }),
              ),
              Cell: CenterCell,
            },
          ]
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [distanceInMiles, volumeInGallons, idleTimeAsPercentage, currentFleetId, tSafe],
  );

  return columns;
};

const LeaderboardTable = ({ className, leaders }: LeaderboardTableProps) => {
  const { isEditMode, widgetKey } = useContext(WidgetContext);
  const settings = useSettings();
  const history = useHistory();
  const currentFleetId = useCurrentFleetId();
  const { hiddenItems } = settings.dashboardWidgetSettings[widgetKey as DashboardWidget] ?? [];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable({
    columns: useColumns(),
    data: leaders,
  });

  useEffect(() => {
    isEditMode ? setHiddenColumns([]) : setHiddenColumns(hiddenItems);
  }, [hiddenItems, isEditMode, setHiddenColumns]);

  return (
    <table {...getTableProps()} className={className}>
      <thead>
        {headerGroups.map((group) => (
          <tr {...group.getHeaderGroupProps()}>
            {group.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className={cx('px-1 py-1 text-sm font-normal', hiddenItems?.includes(column.id) && 'opacity-40')}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        <tr>
          <td>
            <Separator absolute />
          </td>
        </tr>

        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr
              {...row.getRowProps()}
              className="cursor-pointer hover:bg-gray-100"
              onClick={() => history.push(`/${currentFleetId}/drivers/${row.original.driverId}`)}
            >
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={cx('px-1 py-1 text-sm', hiddenItems?.includes(cell.column.id) && 'opacity-40')}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default LeaderboardTable;
