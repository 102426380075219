import { useMutation } from '@apollo/client';
import produce from 'immer';
import { useContext, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useRecoilValue } from 'recoil';
import tw from 'twin.macro';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import Modal, { useModal } from 'atoms/Modal';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';
import FleetNotificationSettingsForm from 'components/Fleet/FleetList/Toolbar/EditSubfleetToolbarItem/FleetNotificationSettingsForm';
import TopFleetFeatureSettingsForm from 'components/Fleet/FleetList/Toolbar/EditSubfleetToolbarItem/TopFleetFeatureSettingsForm';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import useRole from 'components/User/useRole';
import { ApiError, CreateFleetDoc, FleetStatsDoc, GetUserDoc, UpdateFleetDoc } from 'generated/graphql';
import { getError } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';

const StyledTab = tw(
  Tab,
)`inline-flex justify-center p-0.5 w-1/3 text-gray-400 text-md border-px border-solid border-gray-400 bg-gray-300 hover:bg-gray-400 hover:text-black rounded-tl-8 rounded-tr-8 cursor-pointer list-none`;

const EditSubfleetToolbarItem = ({ isCreateMode }: { isCreateMode?: boolean }) => {
  const i18nContext = useContext(I18nContext);
  const { isAdmin, isSuperAdmin, isManager } = useRole();
  const focusedFleetTreeFleetId = useRecoilValue(focusedFleetTreeFleetIdState)!;
  const fleetMap = useAccessibleFleetMap();
  const currentFleet = focusedFleetTreeFleetId ? fleetMap[focusedFleetTreeFleetId] : undefined;
  const isTopLevelFleet = currentFleet ? !currentFleet.parentId : false;
  const { props: modalProps, closeModal, openModal, isOpen } = useModal({});

  const [fleetName, setFleetName] = useState(isCreateMode ? '' : currentFleet!.name);
  const notificationSettingsViewable = isSuperAdmin; // TODO: revert to isManager when ready

  useEffect(() => {
    if (!isOpen) {
      setFleetName(isCreateMode ? '' : currentFleet!.name);
    }
  }, [currentFleet, fleetMap, focusedFleetTreeFleetId, isCreateMode, isOpen]);

  const [createFleet] = useMutation(CreateFleetDoc, {
    update: (cache, { data: response }) => {
      if (!response) return;

      cache.updateQuery({ query: GetUserDoc }, (data) =>
        produce(data, (draft) => {
          draft?.user?.accessibleFleets.push(response.createFleet);
        }),
      );

      cache.modify({
        id: cache.identify({ __typename: 'Fleet', id: response.createFleet.parentId }),
        fields: {
          childrenIds: (ids: string[]) => [...ids, response.createFleet.id],
        },
      });

      cache.updateQuery({ query: FleetStatsDoc }, (data) =>
        produce(data, (draft) => {
          draft?.fleetStats.push({
            id: response.createFleet.id,
            driverCount: 0,
            userCount: 0,
            vehicleCount: 0,
            __typename: 'FleetStats',
          });
        }),
      );
    },
    onCompleted: () =>
      successToast(
        tSafe('components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.fleet-has-been-created-toast', {
          defaultValue: 'The fleet has been created',
        }),
      ),
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });

  const [updateFleet] = useMutation(UpdateFleetDoc, {
    onCompleted: () =>
      successToast(
        tSafe('components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.fleet-name-has-been-updated-toast', {
          defaultValue: 'The fleet name has been updated',
        }),
      ),
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });

  const onCreateFleet = () => createFleet({ variables: { parentId: focusedFleetTreeFleetId, name: fleetName } });
  const onUpdateFleet = () => updateFleet({ variables: { id: focusedFleetTreeFleetId, name: fleetName } });

  if (!currentFleet || !i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { edit_text, create_text },
      domain: {
        fleet: {
          fleet_text,
          fields: { name_text: fleet_name_text },
        },
      },
      forms: {
        buttons: { save_text },
      },
    },
  } = i18nContext;

  return (
    <>
      <Button className="text-sm space-x-0.5 flex-center hover:bg-gray-300" onClick={openModal}>
        <Tooltip
          text={tSafe('components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.fleet-settings-tooltip', {
            defaultValue: 'Please select the fleet you wish to edit',
          })}
        >
          <IonIcon className="text-lg" name={isCreateMode ? 'addCircleOutline' : 'settingsOutline'} />
        </Tooltip>

        <span>
          {isCreateMode
            ? tSafe('components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.create-a-sub-fleet', {
                defaultValue: 'Create a subfleet',
              })
            : tSafe('components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.fleet-settings', {
                defaultValue: 'Fleet settings',
              })}
        </span>
      </Button>

      <Modal {...modalProps} title={`${isCreateMode ? create_text : edit_text} ${fleet_text}`}>
        <div className="mt-2 flex flex-col min-w-100 min-h-25">
          <Tabs>
            <TabList className="flex flex-row mb-1">
              <StyledTab selectedClassName="!text-black font-bold !border-b-0 !bg-gray-100">
                {fleet_name_text}
              </StyledTab>

              {isSuperAdmin && isTopLevelFleet && (
                <StyledTab selectedClassName="!text-black font-bold !border-b-0 !bg-gray-100">
                  {tSafe('components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.admin-settings', {
                    defaultValue: 'Admin Settings',
                  })}
                </StyledTab>
              )}

              {notificationSettingsViewable && (
                <StyledTab selectedClassName="!text-black font-bold !border-b-0 !bg-gray-100">
                  {tSafe(
                    'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.notification-settings',
                    {
                      defaultValue: 'Notification settings',
                    },
                  )}
                </StyledTab>
              )}
            </TabList>

            <TabPanel>
              <div className="flex flex-col">
                <input
                  value={fleetName}
                  onChange={(e) => setFleetName(e.currentTarget.value)}
                  className="text-xl border-b mb-[1px] p-0.5 focus:outline-none focus:border-b-2 focus:border-hover focus:mb-0"
                  autoFocus={true}
                />

                <div className="flex-center">
                  <Button
                    className="bg-success text-white border border-success p-0.5 rounded-4 mt-2 w-20"
                    onClick={() => {
                      isCreateMode ? onCreateFleet() : onUpdateFleet();
                      closeModal();
                    }}
                  >
                    {isCreateMode ? create_text : save_text}
                  </Button>
                </div>
              </div>
            </TabPanel>

            {isSuperAdmin && isTopLevelFleet && (
              <TabPanel>
                <TopFleetFeatureSettingsForm />
              </TabPanel>
            )}

            {notificationSettingsViewable && (
              <TabPanel>
                <FleetNotificationSettingsForm />
              </TabPanel>
            )}
          </Tabs>
        </div>
      </Modal>
    </>
  );
};

export default EditSubfleetToolbarItem;
