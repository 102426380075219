import { useMutation } from '@apollo/client';
import produce from 'immer';
import { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import Button from 'atoms/Button';
import ButtonWithConfirmModal from 'atoms/ButtonWithConfirmModal';
import IonIcon from 'atoms/IonIcon';
import Modal from 'atoms/Modal';
import { CenteredSpinner } from 'atoms/Spinner';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';
import RecipientManager from 'components/Fleet/FleetList/Toolbar/EditSubfleetToolbarItem/RecipientManager';
import TableRow from 'components/Fleet/FleetList/Toolbar/EditSubfleetToolbarItem/TableRow';
import useRole from 'components/User/useRole';
import {
  ApiError,
  CascadedFleetNotificationChannels,
  CascadedFleetNotificationChannelSettings,
  CascadedFleetNotificationSettings,
  ChannelDefaults,
  ClearFleetSettingsOverrideDoc,
  DefaultFleetNotificationChannelsInput,
  FleetNotificationChannelRecipients,
  FleetNotificationChannelRecipientsInput,
  FleetNotificationChannelSettingsInput,
  FleetNotificationChannelsInput,
  GetFleetForSettingsDoc,
  Topic,
  UpdateFleetDoc,
} from 'generated/graphql';
import { cx, entries, keys, usePrevious } from 'utils';
import { getError, useQ } from 'utils/apolloClient';
import { errorToast, successToast } from 'utils/toasts';

export type NotificationSettings = Omit<CascadedFleetNotificationSettings, 'default' | '__typename'>;

export type NotifificationsSettingsFormData = Record<
  keyof Omit<CascadedFleetNotificationSettings, 'default' | '__typename'>, //
  CascadedFleetNotificationChannels
>;

const channelRecipientsToInput = ({
  customRecipients,
  recipientGroups,
  recipientUserIds,
}: FleetNotificationChannelRecipients): FleetNotificationChannelRecipientsInput => ({
  customRecipients: customRecipients?.map((x) => ({
    recipientEmailOrPhone: x.recipientEmailOrPhone,
    mandatory: x.mandatory,
    disabled: x.disabled,
  })),
  recipientGroups: recipientGroups?.map((x) => ({
    group: x.group,
    mandatory: x.mandatory,
    allowOptOut: x.allowOptOut,
    disabled: x.disabled,
  })),
  recipientUserIds: recipientUserIds?.map((x) => ({
    userId: x.userId,
    mandatory: x.mandatory,
    allowOptOut: x.allowOptOut,
    disabled: x.disabled,
  })),
});

const channelSettingsToInput = ({
  enabled,
  recipients,
}: CascadedFleetNotificationChannelSettings): FleetNotificationChannelSettingsInput => ({
  enabled,
  recipients: recipients ? channelRecipientsToInput(recipients) : undefined,
});

const notificationChannelsToInput = ({
  email,
  portal,
  sms,
}: CascadedFleetNotificationChannels): FleetNotificationChannelsInput => ({
  email: channelSettingsToInput(email),
  portal,
  sms: channelSettingsToInput(sms),
});

const defaultNotificationChannelsToInput = ({
  email,
  sms,
}: ChannelDefaults): DefaultFleetNotificationChannelsInput => ({
  email: channelRecipientsToInput(email),
  sms: channelRecipientsToInput(sms),
});

const fleetNotificationTopicsToInput = (settings: CascadedFleetNotificationSettings) => {
  return keys(Topic).reduce((acc, curr) => {
    const key = Topic[curr];
    acc[key] = notificationChannelsToInput(settings[key]);

    return acc;
  }, {} as Record<Topic, FleetNotificationChannelsInput>);
};

const fleetNotificationSettingsToInput = (settings: { notificationSettings: CascadedFleetNotificationSettings }) => ({
  notificationSettings: {
    default: defaultNotificationChannelsToInput(settings.notificationSettings.default),
    ...fleetNotificationTopicsToInput(settings.notificationSettings),
  },
});

const FleetNotificationSettingsForm = () => {
  const { isAdmin } = useRole();
  const i18nContext = useContext(I18nContext);
  const { tSafe } = i18nContext ?? {};
  const focusedFleetTreeFleetId = useRecoilValue(focusedFleetTreeFleetIdState)!;
  const { data: currentFleet, loading } = useQ(GetFleetForSettingsDoc, {
    variables: { fleetId: focusedFleetTreeFleetId },
    fetchPolicy: 'network-only',
  });

  // const currentFleet = focusedFleetTreeFleetId ? fleetMap[focusedFleetTreeFleetId] : undefined;
  const [formState, setFormState] = useState<{ notificationSettings: CascadedFleetNotificationSettings }>();
  const prevFormState = usePrevious(formState);

  const fleetSettingsUpdatedText =
    tSafe &&
    tSafe(
      'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.toast-fleet-settings-updated',
      { defaultValue: 'The fleet settings have been updated' },
    );

  useEffect(() => {
    if (currentFleet) {
      setFormState({
        notificationSettings: currentFleet.settings.notificationSettings,
      });
    }
  }, [currentFleet]);

  const [updateFleet, { loading: updatingFleet }] = useMutation(UpdateFleetDoc, {
    onCompleted: () => {
      if (fleetSettingsUpdatedText) successToast(fleetSettingsUpdatedText);
    },
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });

  const [clearFleetSettingsOverride, { loading: clearingOverride }] = useMutation(ClearFleetSettingsOverrideDoc, {
    onCompleted: () => successToast(fleetSettingsUpdatedText),
    onError: (rawError) => {
      const { message, type } = getError(rawError) ?? {};

      if (type !== ApiError.Unauthorized && message) {
        errorToast(message);
      }
    },
  });

  if (loading)
    return (
      <div className="h-[600px]">
        <CenteredSpinner className="absolute top-1/2" />
      </div>
    );
  if (!currentFleet?.settings || !i18nContext || !tSafe) return null;

  const {
    commonTranslations: {
      enums: { topicDescriptionMap },
      forms: {
        buttons: { save_text },
      },
      general: { enabled_text },
    },
  } = i18nContext;

  const {
    notificationSettings: { default: defaultSettings, hasOverride, ...notificationTopics },
  } = currentFleet.settings;
  const { topFleetSettings, name } = currentFleet;

  const enabledNotificationTopics = [Topic.Removed];
  const notificationSettingsFormFieldLabels: { key: Topic; label: string }[] = entries(topicDescriptionMap)
    // Temporarily disable topics
    .filter(([key, _]) => enabledNotificationTopics.includes(key))
    .map(([key, label]) => ({ key, label }));

  const visibleEmailRecipientGroups = [
    ...(formState?.notificationSettings.default?.email.recipientGroups?.filter((x) => !x.disabled) ?? []),
    ...(formState?.notificationSettings.default.email.recipientUserIds?.filter((x) => !x.disabled) ?? []),
    ...(formState?.notificationSettings.default.email.customRecipients?.filter((x) => !x.disabled) ?? []),
  ];
  const visibleSMSRecipientGroups = [
    ...(formState?.notificationSettings.default?.sms.recipientGroups?.filter((x) => !x.disabled) ?? []),
    ...(formState?.notificationSettings.default.sms.recipientUserIds?.filter((x) => !x.disabled) ?? []),
    ...(formState?.notificationSettings.default.sms.customRecipients?.filter((x) => !x.disabled) ?? []),
  ];

  return (
    <div>
      {(updatingFleet || clearingOverride) && <CenteredSpinner className="absolute top-1/2 left-1/2 z-600" />}

      <div
        className={cx(
          (loading || updatingFleet || clearingOverride) && 'opacity-20 pointer-events-none',
          'flex flex-col text-sm',
        )}
      >
        <h3 className="text-lg font-bold">
          {tSafe(
            'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.notification-settings-for-fleet-name',
            { defaultValue: 'Notification Settings for {{fleetName}}', fleetName: name },
          )}
        </h3>

        <div>
          <span>
            {tSafe(
              'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.fleet-notification-settings-persist-subfleets',
              { defaultValue: 'Fleet notification settings and mandatory recipients will persist for all subfleets.' },
            )}
          </span>
        </div>

        {currentFleet.parentId && (
          <div className="text-left">
            {hasOverride && (
              <div>
                <span>
                  {tSafe(
                    'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.fleet-has-settings-that-override',
                    {
                      defaultValue:
                        'This fleet has settings that override settings from higher fleets in the fleet tree.',
                    },
                  )}
                </span>
              </div>
            )}

            {!hasOverride && (
              <div>
                {tSafe(
                  'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.this-fleet-inherits-settings',
                  {
                    defaultValue: 'This fleet inherits its settings from higher fleets in the fleet tree.',
                  },
                )}
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col mt-1">
          <table className="border-spacing-2">
            <thead>
              <tr className="text-left">
                <th colSpan={2}>
                  {isAdmin && currentFleet.parentId && hasOverride && (
                    <span>
                      <ButtonWithConfirmModal
                        className="border-px rounded-4 px-1 py-0.5 bg-amber text-white mb-1"
                        confirmContentClassName={'text-md'}
                        onConfirm={() => {
                          clearFleetSettingsOverride({ variables: { id: focusedFleetTreeFleetId } });
                        }}
                      >
                        {tSafe(
                          'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.reset-to-parent-settings-fleet-button',
                          {
                            defaultValue: 'Reset to parent fleet settings',
                          },
                        )}
                      </ButtonWithConfirmModal>
                    </span>
                  )}
                </th>

                <th colSpan={2} style={{ minWidth: 200 }} className="text-center"></th>

                <th colSpan={2} style={{ minWidth: 200 }} className="text-center"></th>
              </tr>

              <tr className=" text-black font-bold text-left border-t-px border-gray-300 text-lg">
                <th>
                  {tSafe(
                    'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.notified-event',
                    {
                      defaultValue: 'Notified Event',
                    },
                  )}
                </th>

                <th className="text-center bg-gray-100">
                  {tSafe(
                    'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.portal-alerts',
                    {
                      defaultValue: 'Portal Alerts',
                    },
                  )}
                </th>

                <th className="text-center relative" colSpan={2}>
                  {tSafe(
                    'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.emails',
                    {
                      defaultValue: 'Emails',
                    },
                  )}

                  {topFleetSettings?.emailNotificationsEnabled && (
                    <Modal
                      contentClassName="w-full h-1/2"
                      trigger={
                        <Tooltip
                          text={tSafe(
                            'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.set-global-email-recipients',
                            {
                              defaultValue: 'Set global email recipients',
                            },
                          )}
                        >
                          <Button
                            className={cx('border-px h-2.5 rounded-full p-0.5 absolute right-6 -bottom-1 ui-button')}
                          >
                            <IonIcon name="peopleOutline" className="text-lg h-1" />
                          </Button>
                        </Tooltip>
                      }
                    >
                      <RecipientManager
                        title="Email default recipients"
                        isDefault
                        channelRecipientsState={formState?.notificationSettings.default?.email}
                        channelRecipients={defaultSettings.email}
                        channel={'Email'}
                        onChange={(recipients) => {
                          setFormState(
                            produce(formState, (draft) => {
                              draft!.notificationSettings.default.email = recipients;
                            }),
                          );
                        }}
                        onClearAll={() =>
                          setFormState(
                            produce(formState, (draft) => {
                              draft!.notificationSettings.default.email!.recipientGroups =
                                formState?.notificationSettings.default.email!.recipientGroups?.filter(
                                  (x) => !x.canDelete,
                                );
                              draft!.notificationSettings.default.email!.recipientGroups?.forEach((x) => {
                                if (x.canDisable) x.disabled = true;
                              });
                              draft!.notificationSettings.default.email!.customRecipients =
                                formState?.notificationSettings.default.email!.customRecipients?.filter(
                                  (x) => !x.canDelete,
                                );
                              draft!.notificationSettings.default.email!.customRecipients?.forEach((x) => {
                                if (x.canDisable) x.disabled = true;
                              });
                              draft!.notificationSettings.default.email!.recipientUserIds =
                                formState?.notificationSettings.default.email!.recipientUserIds?.filter(
                                  (x) => !x.canDelete,
                                );
                              draft!.notificationSettings.default.email!.recipientUserIds?.forEach((x) => {
                                if (x.canDisable) x.disabled = true;
                              });
                            }),
                          )
                        }
                      />
                    </Modal>
                  )}

                  {topFleetSettings?.emailNotificationsEnabled && !!visibleEmailRecipientGroups.length && (
                    <div className="absolute -mt-0.5 -mr-0.5 right-5 top-2 h-2 w-2 text-white text-sm font-normal bg-error rounded-full flex-center">
                      {visibleEmailRecipientGroups.length}
                    </div>
                  )}
                </th>

                <th className="text-center bg-gray-100 relative" colSpan={2}>
                  {tSafe(
                    'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.sms',
                    {
                      defaultValue: 'SMSs',
                    },
                  )}

                  {topFleetSettings?.smsNotificationsEnabled && (
                    <>
                      <Modal
                        contentClassName="w-full h-1/2"
                        trigger={
                          <Tooltip
                            text={tSafe(
                              'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.FleetNotificationSettingsForm.set-global-sms-recipients',
                              {
                                defaultValue: 'Set global SMS recipients',
                              },
                            )}
                          >
                            <Button
                              className={cx('border-px h-2.5 rounded-full p-0.5 absolute right-6 -bottom-1 ui-button')}
                            >
                              <IonIcon name="peopleOutline" className="text-lg h-1" />
                            </Button>
                          </Tooltip>
                        }
                      >
                        <RecipientManager
                          title="SMS default recipients"
                          isDefault
                          channelRecipientsState={formState?.notificationSettings.default?.sms}
                          channelRecipients={defaultSettings.sms}
                          channel={'SMS'}
                          onChange={(recipients) => {
                            setFormState(
                              produce(formState, (draft) => {
                                draft!.notificationSettings.default.sms = recipients;
                              }),
                            );
                          }}
                          onClearAll={() =>
                            setFormState(
                              produce(formState, (draft) => {
                                draft!.notificationSettings.default.sms!.recipientGroups =
                                  formState?.notificationSettings.default.sms!.recipientGroups?.filter(
                                    (x) => !x.canDelete,
                                  );
                                draft!.notificationSettings.default.sms!.recipientGroups?.forEach((x) => {
                                  if (x.canDisable) x.disabled = true;
                                });
                                draft!.notificationSettings.default.sms!.customRecipients =
                                  formState?.notificationSettings.default.sms!.customRecipients?.filter(
                                    (x) => !x.canDelete,
                                  );
                                draft!.notificationSettings.default.sms!.customRecipients?.forEach((x) => {
                                  if (x.canDisable) x.disabled = true;
                                });
                                draft!.notificationSettings.default.sms!.recipientUserIds =
                                  formState?.notificationSettings.default.sms!.recipientUserIds?.filter(
                                    (x) => !x.canDelete,
                                  );
                                draft!.notificationSettings.default.sms!.recipientUserIds?.forEach((x) => {
                                  if (x.canDisable) x.disabled = true;
                                });
                              }),
                            )
                          }
                        />
                      </Modal>

                      {!!visibleSMSRecipientGroups.length && (
                        <div className="absolute -mt-0.5 -mr-0.5 right-5 top-2 h-2 w-2 text-white text-sm font-normal bg-error rounded-full flex-center">
                          {visibleSMSRecipientGroups.length}
                        </div>
                      )}
                    </>
                  )}
                </th>
              </tr>

              <tr className="text-center">
                <th>&nbsp;</th>

                <th className="bg-gray-100">{enabled_text}</th>

                <th colSpan={2}>{topFleetSettings?.emailNotificationsEnabled ? enabled_text : 'disabled by admin'}</th>

                <th className="bg-gray-100" colSpan={2}>
                  {topFleetSettings?.smsNotificationsEnabled ? enabled_text : 'disabled by admin'}
                </th>
              </tr>
            </thead>

            <tbody>
              {notificationSettingsFormFieldLabels.map((field) => {
                return (
                  <TableRow
                    key={field.key}
                    channels={notificationTopics[field.key]}
                    data={formState?.notificationSettings[field.key]}
                    topic={field.key}
                    topicName={field.label}
                    onChangePortalEnabled={(value) => {
                      setFormState(
                        produce(formState, (draft) => {
                          draft!.notificationSettings[field.key].portal = value;
                        }),
                      );
                    }}
                    onChangeEmailEnabled={(value) => {
                      setFormState(
                        produce(formState, (draft) => {
                          draft!.notificationSettings[field.key].email!.enabled = value;
                        }),
                      );
                    }}
                    onChangeEmailRecipients={(recipients) => {
                      setFormState(
                        produce(formState, (draft) => {
                          draft!.notificationSettings[field.key].email!.recipients = recipients;
                        }),
                      );
                    }}
                    onClearAllEmailRecipients={() => {
                      setFormState(
                        produce(formState, (draft) => {
                          draft!.notificationSettings[field.key].email!.recipients!.recipientGroups =
                            formState?.notificationSettings[field.key].email!.recipients?.recipientGroups?.filter(
                              (x) => !x.canDelete,
                            );
                          draft!.notificationSettings[field.key].email!.recipients!.recipientGroups?.forEach((x) => {
                            if (x.canDisable) x.disabled = true;
                          });
                          draft!.notificationSettings[field.key].email!.recipients!.customRecipients =
                            formState?.notificationSettings[field.key].email!.recipients?.customRecipients?.filter(
                              (x) => !x.canDelete,
                            );
                          draft!.notificationSettings[field.key].email!.recipients!.customRecipients?.forEach((x) => {
                            if (x.canDisable) x.disabled = true;
                          });
                          draft!.notificationSettings[field.key].email!.recipients!.recipientUserIds =
                            formState?.notificationSettings[field.key].email!.recipients?.recipientUserIds?.filter(
                              (x) => !x.canDelete,
                            );
                          draft!.notificationSettings[field.key].email!.recipients!.recipientUserIds?.forEach((x) => {
                            if (x.canDisable) x.disabled = true;
                          });
                        }),
                      );
                    }}
                    onChangeSmsEnabled={(value) => {
                      setFormState(
                        produce(formState, (draft) => {
                          draft!.notificationSettings[field.key].sms!.enabled = value;
                        }),
                      );
                    }}
                    onChangeSmsRecipients={(recipients) => {
                      setFormState(
                        produce(formState, (draft) => {
                          draft!.notificationSettings[field.key].sms!.recipients = recipients;
                        }),
                      );
                    }}
                    onClearAllSmsRecipients={() => {
                      setFormState(
                        produce(formState, (draft) => {
                          draft!.notificationSettings[field.key].sms!.recipients!.recipientGroups =
                            formState?.notificationSettings[field.key].sms!.recipients?.recipientGroups?.filter(
                              (x) => !x.canDelete,
                            );
                          draft!.notificationSettings[field.key].sms!.recipients!.recipientGroups?.forEach((x) => {
                            if (x.canDisable) x.disabled = true;
                          });
                          draft!.notificationSettings[field.key].sms!.recipients!.customRecipients =
                            formState?.notificationSettings[field.key].sms!.recipients?.customRecipients?.filter(
                              (x) => !x.canDelete,
                            );
                          draft!.notificationSettings[field.key].sms!.recipients!.customRecipients?.forEach((x) => {
                            if (x.canDisable) x.disabled = true;
                          });
                          draft!.notificationSettings[field.key].sms!.recipients!.recipientUserIds =
                            formState?.notificationSettings[field.key].sms!.recipients?.recipientUserIds?.filter(
                              (x) => !x.canDelete,
                            );
                          draft!.notificationSettings[field.key].sms!.recipients!.recipientUserIds?.forEach((x) => {
                            if (x.canDisable) x.disabled = true;
                          });
                        }),
                      );
                    }}
                  />
                );
              })}
            </tbody>
          </table>

          {prevFormState &&
            formState &&
            prevFormState.notificationSettings !== formState.notificationSettings &&
            JSON.stringify(prevFormState.notificationSettings) !== JSON.stringify(formState.notificationSettings) && (
              <div className="flex-center">
                <Button
                  className="bg-success text-white border border-success p-0.5 rounded-4 mt-2 w-10 text-md"
                  onClick={() => {
                    updateFleet({
                      variables: { id: focusedFleetTreeFleetId, settings: fleetNotificationSettingsToInput(formState) },
                    });
                  }}
                >
                  {save_text}
                </Button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default FleetNotificationSettingsForm;
