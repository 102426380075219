import { useCallback, useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import FilterVehicle from 'components/Live/Geofence/GeofenceSidebar/GeofenceBreaches/GeofenceBreachSearch/FilterVehicle';
import useVehicleSearchSource from 'components/Live/Geofence/GeofenceSidebar/GeofenceBreaches/GeofenceBreachSearch/useVehicleSearchSource';
import { geofenceNotificationsListState } from 'components/Live/state';

const GeofenceBreachSearch = () => {
  const i18nContext = useContext(I18nContext);
  const [searchTerm, setSearchTerm] = useState('');
  const { filterVehicles } = useRecoilValue(geofenceNotificationsListState);

  const resetSearch = useCallback(() => setSearchTerm(''), []);

  const results = [...useVehicleSearchSource(searchTerm, resetSearch)]
    .sort((a, b) => b.score - a.score)
    .map(({ jsx }) => jsx);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="relative my-0.5">
      <div className="flex w-full bg-gray-100 border rounded-4 overflow-hidden">
        <input
          className="flex-1 px-1 min-h-3.5 text-sm bg-gray-100 focus:outline-none"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={tSafe(
            'components.Live.Geofence.GeofenceSidebar.GeofenceBreaches.GeofenceBreachSearch.licence-plate-or-driver-search-placeholder',
            { defaultValue: 'vehicle licence plate or driver name' },
          )}
        />

        <span className="flex-shrink-0 pr-1 text-lg flex-center" aria-label="Search">
          <IonIcon name="searchOutline" />
        </span>
      </div>

      <div className="flex flex-col overflow-x-scroll">
        {filterVehicles.length ? (
          <>
            <div className="flex ">
              {filterVehicles.map((vehicle) => (
                <FilterVehicle key={vehicle.vehicleId} vehicle={vehicle} />
              ))}
            </div>
          </>
        ) : null}
      </div>

      <ul className="absolute z-500 left-0 right-0 text-sm top-[calc(40%+.5rem)] children:focus:outline-none">
        {searchTerm && !!results?.length && (
          <div className="flex flex-col bg-gray-100 border rounded-8 overflow-hidden">
            <span className="max-h-80 overflow-auto bg-white">{results}</span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default GeofenceBreachSearch;
