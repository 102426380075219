import { EventWarning } from 'types/mapVehicleDetails';

import StatusMetric from './StatusMetric';

interface EventListContainerProps {
  metrics: EventWarning[];
  title: string;
}

export const EventListContainer = ({ metrics, title }: EventListContainerProps) => {
  if (!metrics.length) return null;

  return (
    <div className="flex flex-col mt-3 w-full border-gray-400">
      <h4 className="ml-0.5 text-lg font-bold">{title}</h4>

      {metrics
        .filter((x) => x.value !== 0)
        .map((record, idx) => (
          <StatusMetric
            key={idx + '_' + record.description}
            iconName={record.iconName}
            description={record.description}
            value={record.value}
            warning={record.warning}
          />
        ))}
    </div>
  );
};
