import { CellContext } from '@tanstack/react-table';
import { format, parseISO } from 'date-fns';
import { useContext } from 'react';

import DeviceStatusIcon from 'atoms/DeviceStatusIcon';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { DeviceConnectionStatus } from 'generated/graphql';
import { AggregateListRow } from 'types/settings';

const DeviceStatusCell = ({ cell }: { cell: CellContext<AggregateListRow, string> }) => {
  const value = cell.getValue() as DeviceConnectionStatus;
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { deviceConnectionStatusDescriptionMap },
      domain: {
        device: {
          fields: { latestConnectionDate_text, latestHeartbeat_text },
        },
      },
    },
  } = i18nContext;

  return (
    <span className="w-full flex-center">
      {value && (
        <Tooltip
          text={
            <div>
              <div>{deviceConnectionStatusDescriptionMap[value]}</div>

              <div>
                {value === DeviceConnectionStatus.NotReporting && cell.row.original.device?.latestHeartbeat
                  ? `${latestHeartbeat_text}: ${cell.row.original.device?.latestHeartbeat}`
                  : cell.row.original.device?.latestConnectionDate &&
                    `${latestConnectionDate_text}: ${format(
                      parseISO(cell.row.original.device?.latestConnectionDate),
                      'do MMM y - h:mm a',
                    )}`}
              </div>
            </div>
          }
          side="right"
        >
          <DeviceStatusIcon connectionStatus={value} />
        </Tooltip>
      )}
    </span>
  );
};

export default DeviceStatusCell;
