import L from 'leaflet';
import { useContext, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { MapContainer } from 'react-leaflet';
import { useLocation } from 'react-router';
import { useRecoilValue, useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { CenteredSpinner } from 'atoms/Spinner';
import Tooltip from 'atoms/Tooltip';
import { europeCenterLatlng } from 'common/constants';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import GeofenceSidebar from 'components/Live/Geofence/GeofenceSidebar';
import Map from 'components/Live/Map';
import MultiSearch from 'components/Live/MultiSearch';
import { mapState, selectedGeofenceState } from 'components/Live/state';
import VehicleSidebar from 'components/Live/VehicleSidebar';
import { GetVehicleLastKnownLocationsDoc, LatLng, VehicleLastKnownLocation } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

export type InitialCoordsState = { location: { lat: number; lng: number }; zoom: number } | undefined;

interface MapState {
  location: () => L.LatLng | LatLng;
  zoom: number;
}

enum FilterType {
  InTrip = 'InTrip',
  Parked = 'Parked',
  VehiclesWithNoDrivers = 'VehiclesWithNoDrivers',
}

const Live = () => {
  const selectedGeofence = useRecoilValue(selectedGeofenceState);
  const [map, setMap] = useRecoilState(mapState);
  const { state } = useLocation<InitialCoordsState>();
  const [mapResized, setMapResized] = useState(false);
  const [boundsState, setBoundsState] = useState<LatLng[] | null>(null);

  const [filters, setFilters] = useState<Record<FilterType, boolean>>({
    [FilterType.InTrip]: true,
    [FilterType.Parked]: true,
    [FilterType.VehiclesWithNoDrivers]: false,
  });
  const mapRef = useRef(true);
  const i18nContext = useContext(I18nContext);
  const pollingInterval = 60 * 1000;

  const {
    data: vehicles = [],
    loading,
    startPolling,
    stopPolling,
  } = useQ(GetVehicleLastKnownLocationsDoc, {
    variables: { fleetIds: useCurrentFleetId() },
    pollInterval: pollingInterval,
    onCompleted: (data) => {
      const bounds = data
        ? data.vehicleLastKnownLocations
            .map((vehicle) => L.latLng(vehicle.lastKnownLocation!.lat, vehicle.lastKnownLocation!.lng))
            .filter((x) => x.lat && x.lng)
        : boundsState;
      setBoundsState(bounds);
    },
  });

  useIdleTimer({
    onIdle: () => stopPolling(),
    onActive: () => startPolling(pollingInterval),
    timeout: 60000 * 5,
    throttle: 500,
  });

  useEffect(
    () => {
      if (mapRef.current) {
        mapRef.current = false;
      } else if (map && !mapResized && !state?.location) {
        boundsState?.length && map?.fitBounds(L.latLngBounds(boundsState!));
        setMapResized(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [boundsState],
  );

  const { inTripVehicles = [], parkedVehicles = [] } = vehicles.reduce(
    (acc, vehicle) => {
      if (vehicle.tripEnded) {
        acc.parkedVehicles.push(vehicle);
      } else {
        acc.inTripVehicles.push(vehicle);
      }
      return acc;
    },
    {
      inTripVehicles: [] as VehicleLastKnownLocation[],
      parkedVehicles: [] as VehicleLastKnownLocation[],
    },
  );

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const translationMap: Record<FilterType, string> = {
    [FilterType.InTrip]: tSafe('components.Live.common.vehicles-in-trip', {
      defaultValue: 'In trip',
    }),
    [FilterType.Parked]: tSafe('components.Live.common.vehicles-parked', {
      defaultValue: 'Parked',
    }),
    [FilterType.VehiclesWithNoDrivers]: tSafe('components.Live.common.vehicles-unassigned', {
      defaultValue: 'Unassigned',
    }),
  };

  const initialMapPosition = (): MapState => {
    const location = () => {
      if (state?.location) {
        return { lat: state.location.lat, lng: state.location.lng };
      }
      if (vehicles?.length) {
        const vehicleLocations = L.latLngBounds(
          vehicles
            .map((vehicle) => L.latLng(vehicle.lastKnownLocation!.lat, vehicle.lastKnownLocation!.lng))
            .filter((x) => x.lat && x.lng),
        );

        return { lat: vehicleLocations.getCenter().lat, lng: vehicleLocations.getCenter().lng };
      }

      return europeCenterLatlng;
    };

    const zoom: number = state?.zoom ?? 6;

    return { location, zoom };
  };

  const getVisibleVehicles = () => {
    const tripFilteredVehicles = [...(filters.InTrip ? inTripVehicles : []), ...(filters.Parked ? parkedVehicles : [])];
    const noDriver = (x: VehicleLastKnownLocation) => !x.driverId;

    if (filters[FilterType.VehiclesWithNoDrivers]) {
      if (filters[FilterType.InTrip] || filters[FilterType.Parked]) {
        return tripFilteredVehicles.filter(noDriver);
      }
      return vehicles.filter(noDriver);
    }

    return tripFilteredVehicles;
  };

  return (
    <div className="flex w-full h-full">
      <div className="relative flex-1 items-stretch">
        {loading && (
          <div className="absolute z-500 top-0 left-0 w-screen h-screen">
            <div className="absolute bg-black opacity-20 z-600 w-screen h-screen" />

            <CenteredSpinner className="absolute z-600" />
          </div>
        )}

        {!loading && (
          <>
            <div className="absolute flex flex-col left-1 top-1 text-sm z-600 bg-gray-100 w-15 rounded-8">
              <label htmlFor={FilterType.InTrip} className="cursor-pointer px-1 py-0.5 hover:bg-gray-300 rounded-t-8">
                <input
                  type="checkbox"
                  id={FilterType.InTrip}
                  checked={filters.InTrip}
                  className="mr-1"
                  onChange={(e) => {
                    setFilters({ ...filters, [FilterType.InTrip]: e.target.checked });
                  }}
                />

                {`${translationMap[FilterType.InTrip]} (${inTripVehicles.length})`}
              </label>

              <label htmlFor={FilterType.Parked} className="cursor-pointer px-1 py-0.5 hover:bg-gray-300">
                <input
                  type="checkbox"
                  id={FilterType.Parked}
                  checked={filters.Parked}
                  className="mr-1"
                  onChange={(e) => {
                    setFilters({ ...filters, [FilterType.Parked]: e.target.checked });
                  }}
                />

                {`${translationMap[FilterType.Parked]} (${parkedVehicles.length})`}
              </label>

              <label
                htmlFor={FilterType.VehiclesWithNoDrivers}
                className="cursor-pointer px-1 py-0.5 hover:bg-gray-300 rounded-b-8"
              >
                <input
                  type="checkbox"
                  id={FilterType.VehiclesWithNoDrivers}
                  checked={filters.VehiclesWithNoDrivers}
                  className="mr-1"
                  onChange={(e) => {
                    setFilters({ ...filters, [FilterType.VehiclesWithNoDrivers]: e.target.checked });
                  }}
                />

                {`${translationMap[FilterType.VehiclesWithNoDrivers]}`}
              </label>
            </div>

            <div className="absolute bottom-[76px] left-[13px] z-600 text-lg">
              <Tooltip
                text={tSafe('components.Live.common.zoom-to-show-vehicles', {
                  defaultValue: 'Zoom to show all vehicles',
                })}
              >
                <Button
                  className="bg-white p-0.5 mb-0.5 hover:bg-gray-100 border-px border-gray-600"
                  onClick={() => boundsState?.length && map?.fitBounds(L.latLngBounds(boundsState!))}
                >
                  <IonIcon name="contractOutline" />
                </Button>
              </Tooltip>
            </div>
          </>
        )}

        <MapContainer
          center={initialMapPosition().location()}
          zoom={initialMapPosition().zoom}
          minZoom={3}
          scrollWheelZoom={true}
          className="w-full h-full"
          whenCreated={setMap}
          zoomControl={false}
        >
          <Map vehicles={getVisibleVehicles()} />
        </MapContainer>

        {!loading && vehicles && <MultiSearch vehicles={vehicles} />}
      </div>

      <div className="absolute right-0 z-600 flex min-h-[734px]">
        <VehicleSidebar />

        {selectedGeofence && <GeofenceSidebar />}
      </div>
    </div>
  );
};

export default Live;
