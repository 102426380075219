import IonIcon from 'atoms/IonIcon';
import { defaultPropsWrapper } from 'utils';

import BatteryIcon from './BatteryIcon';

export const batteryFull = defaultPropsWrapper(BatteryIcon, { barCount: 3 });
export const batteryRecharge = defaultPropsWrapper(BatteryIcon, { barCount: 2 });
export const batteryLow = defaultPropsWrapper(BatteryIcon, { barCount: 1 });
export const batteryCritical = defaultPropsWrapper(BatteryIcon, {
  innerSvg: <IonIcon name="alertOutline" height="60%" width="75%" x="12.5%" y="27.5%" noWrapper />,
});
export const batteryEmpty = defaultPropsWrapper(BatteryIcon);
export const batteryHibernate = defaultPropsWrapper(BatteryIcon, {
  innerSvg: <IonIcon name="closeOutline" height="75%" width="75%" x="12.5%" y="20%" noWrapper />,
});
export const batteryNotEstimated = defaultPropsWrapper(BatteryIcon, {
  innerSvg: <IonIcon name="helpOutline" height="60%" width="75%" x="12.5%" y="27.5%" noWrapper fillCurrent={false} />,
});
export const batteryLikelyToFail = defaultPropsWrapper(BatteryIcon, {
  innerSvg: <IonIcon name="trendingDownOutline" height="60%" width="75%" x="12.5%" y="27.5%" noWrapper />,
});
export const batteryVoltage = defaultPropsWrapper(BatteryIcon, {
  innerSvg: <IonIcon name="flashOutline" height="60%" width="75%" x="12.5%" y="27.5%" noWrapper />,
});
export const batteryWarning = defaultPropsWrapper(BatteryIcon, {
  innerSvg: <IonIcon name="warningOutline" height="60%" width="75%" x="12.5%" y="27.5%" noWrapper />,
});
export const batteryStatus = defaultPropsWrapper(BatteryIcon, {
  innerSvg: <IonIcon name="fitness" height="60%" width="75%" x="12.5%" y="27.5%" noWrapper />,
});
