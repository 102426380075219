import { ComponentType } from 'react';
import { Layout } from 'react-grid-layout';

import * as DrivingStats from './DrivingStats';
import * as EcoScoreWidget from './EcoScore';
import * as LastKnownLocationWidget from './LastKnownLocation';
import * as VehicleInfoWidget from './Vehicle';

const maxH = 10;

const widgetMap: Record<string, Partial<Layout> & { component: ComponentType }> = {
  vehicleInfoWidget: {
    component: VehicleInfoWidget.default,
    minH: 1,
    maxH,
  },
  drivingStatsWidget: {
    component: DrivingStats.default,
    minH: 1,
    maxH,
  },
  driverLastKnownLocationWidget: {
    component: LastKnownLocationWidget.default,
    minH: 1,
    maxH,
  },
  ecoScoreWidget: {
    component: EcoScoreWidget.default,
    minH: 1,
    maxH,
  },
};

export default widgetMap;
