import { sub } from 'date-fns';

import { Period } from 'generated/graphql';

export const periodStartToDate = (p: Period, dateFrom: Date = new Date()): Date | undefined => {
  switch (p) {
    case Period.P24h:
      return sub(dateFrom, { hours: 24 });
    case Period.P7d:
      return sub(dateFrom, { days: 7 });
    case Period.P30d:
      return sub(dateFrom, { days: 30 });
    case Period.P90d:
      return sub(dateFrom, { days: 90 });
    case Period.Forever:
      return undefined;
  }
};
