import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { userListState } from 'components/User/UserList/state';
import { cx } from 'utils';

const UserListEditModeToolbarItem = () => {
  const i18nContext = useContext(I18nContext);
  const [state, setUserListState] = useRecoilState(userListState);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { edit_mode_text },
    },
  } = i18nContext;

  return (
    <span className="flex-center hover:bg-gray-300">
      <Button
        onClick={() => setUserListState({ ...state, isInEditMode: !state.isInEditMode })}
        className={cx('text-lg flex-center', !state.isInEditMode && 'opacity-40')}
      >
        <IonIcon name="settingsOutline" aria-label={edit_mode_text} />
      </Button>
    </span>
  );
};

export default UserListEditModeToolbarItem;
