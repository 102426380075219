import * as RadixPopover from '@radix-ui/react-popover';
import { ComponentProps, ReactNode, useContext } from 'react';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';

interface PopoverProps extends ComponentProps<typeof RadixPopover.Trigger> {
  content: ReactNode;
  children: ReactNode;
  label?: string;
  open?: boolean;
  showCloseButton?: boolean;
}

const Popover = ({ children, content, label, open, showCloseButton = true }: PopoverProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { close_text },
    },
  } = i18nContext;

  return (
    <RadixPopover.Root open={open}>
      <RadixPopover.Trigger>{children}</RadixPopover.Trigger>

      <RadixPopover.Content sideOffset={5}>
        <div className="flex flex-col gap-1 p-2 text-md bg-white rounded-4 shadow-card">
          {!label ? null : <div className="mb-1 mr-2 text-lg font-bold">{label}</div>}

          {content}
        </div>

        <RadixPopover.Arrow className="fill-[white]" />

        {showCloseButton && (
          <RadixPopover.Close asChild>
            <button aria-label={close_text} className="absolute right-0.5 top-0.5 w-2.5 h-2.5 flex-center">
              <IonIcon name="closeOutline" className="text-md" />
            </button>
          </RadixPopover.Close>
        )}
      </RadixPopover.Content>
    </RadixPopover.Root>
  );
};

export default Popover;
