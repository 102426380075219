import fuzzysort from 'fuzzysort';
import { useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import { useGeofenceBreachNotifications } from 'components/Live/Geofence/GeofenceSidebar/GeofenceBreaches/useGeofenceBreachNotifications';
import { MultiSearchData } from 'components/Live/MultiSearch';
import { geofenceNotificationsListState, selectedGeofenceState } from 'components/Live/state';
import { arrayDedupeOnKey } from 'utils';

const keys = ['driverName', 'licencePlate'] as const;

const useVehicleSearchSource = (searchTerm: string, onSelect: () => void) => {
  const [results, setResults] = useState<MultiSearchData[]>([]);
  const [{ filterVehicles: currentVehicleFilter }, setNotificationsListState] =
    useRecoilState(geofenceNotificationsListState);
  const selectedGeofence = useRecoilValue(selectedGeofenceState);

  const { data: geofenceNotifications } = useGeofenceBreachNotifications();

  const filteredVehicles = geofenceNotifications
    ?.filter((x) => {
      if (currentVehicleFilter.map((x) => x.vehicleId).includes(x.vehicle.id)) {
        return false;
      }
      return true;
    })
    .filter((x) => {
      if (selectedGeofence?.vehicleIds.length) {
        if (selectedGeofence.vehicleIds.includes(x.vehicle.id)) {
          return true;
        }
        return false;
      }
      return true;
    });

  const searchFormattedVehicles = filteredVehicles?.map((x) => ({
    vehicleId: x.vehicle.id,
    licencePlate: x.vehicle.licencePlate,
    driverId: x.driver.id,
    driverName: x.driver.name,
  }));

  useMemo(
    () =>
      fuzzysort
        .goAsync(searchTerm, searchFormattedVehicles ? arrayDedupeOnKey(searchFormattedVehicles, 'vehicleId') : [], {
          keys,
          limit: 10,
          threshold: -2000,
          allowTypo: true,
        })
        .then((results) =>
          results.map((result): MultiSearchData => {
            const [formattedDriverName, formattedLicencePlate] = result.map((res) =>
              fuzzysort.highlight(res, '<b>', '</b>'),
            );

            const { vehicleId, driverId, driverName, licencePlate } = result.obj;

            const onClick = () => {
              if (result.obj) {
                setNotificationsListState((prevState) => ({
                  ...prevState,
                  offset: 0,
                  filterVehicles: [...prevState.filterVehicles, { vehicleId, driverId, driverName, licencePlate }],
                }));
              }

              onSelect?.();
            };

            return {
              score: result.score,
              jsx: (
                <li
                  key={vehicleId}
                  className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
                  onClick={onClick}
                >
                  <IonIcon name="carSportOutline" className="mr-1 text-3xl" />

                  <div className="flex flex-col">
                    <span
                      className="text-md"
                      dangerouslySetInnerHTML={{ __html: formattedDriverName ?? driverName ?? '' }}
                    />

                    <span
                      className="text-sm"
                      dangerouslySetInnerHTML={{ __html: formattedLicencePlate ?? licencePlate ?? '' }}
                    />
                  </div>
                </li>
              ),
            };
          }),
        )
        .then(setResults),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchTerm, onSelect, setNotificationsListState],
  );

  return results;
};

export default useVehicleSearchSource;
