import { Map } from 'leaflet';
import { atom } from 'recoil';

import { Geofence, GeofenceBreachType, LastKnownLocation, Maybe } from 'generated/graphql';
import { OptionalField } from 'types';

/** @package */
export const selectedGeofenceState = atom<OptionalField<Geofence, 'id'> | undefined>({
  key: 'selectedGeofence',
  default: undefined,
});

/** @package */
export const showSelectedGeofenceVehiclesOnlyState = atom({
  key: 'showSelectedGeofenceVehiclesOnly',
  default: false,
});

export const selectedVehicleState = atom<
  | {
      vehicleId: string;
      driverId?: string | undefined | null;
      tripEnded?: boolean;
      lastKnownLocation?: Maybe<LastKnownLocation>;
      fleetIds?: string[];
      driverName?: string;
      licencePlate?: string;
      make?: string;
      model?: string;
    }
  | undefined
>({
  key: 'selectedVehicle',
  default: undefined,
});

/** @package */
export const liveSettingsState = atom({
  key: 'liveSettings',
  default: {
    fleetGeofenceDisplayMode: 'none' as 'all' | 'selectedFleet' | 'none',
  },
});

/** @package */
export const mapState = atom<Map | undefined>({
  key: 'map',
  default: undefined,
  dangerouslyAllowMutability: true,
});

/** @package */
export const geofenceNotificationsListState = atom<{
  filterVehicles: { vehicleId: string; licencePlate: string; driverId: string; driverName: string }[];
  breachTypeFilter: GeofenceBreachType | string;
  offset: number;
}>({
  key: 'geofenceNotificationsListState',
  default: {
    filterVehicles: [],
    breachTypeFilter: 'All',
    offset: 0,
  },
});
