import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

import { I18nContext } from 'common/useT';
import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';
import BulkExportToolbarItem from 'components/Fleet/FleetList/Toolbar/BulkExportToolbarItem';
import BulkUploadToolbarItem from 'components/Fleet/FleetList/Toolbar/BulkUploadToolbarItem';
import DeleteFleetToolbarItem from 'components/Fleet/FleetList/Toolbar/DeleteFleetToolbarItem';
import EditSubfleetToolbarItem from 'components/Fleet/FleetList/Toolbar/EditSubfleetToolbarItem';
import useRole from 'components/User/useRole';

const FleetPageToolbar = () => {
  const i18nContext = useContext(I18nContext);
  const { isManager } = useRole();
  const focusedFleetTreeFleetId = useRecoilValue(focusedFleetTreeFleetIdState);

  if (!isManager || !i18nContext) return null;

  const { tSafe } = i18nContext;

  if (!focusedFleetTreeFleetId) {
    return (
      <span>
        {tSafe('components.Fleet.FleetList.Toolbar.select-a-fleet-to-manage', {
          defaultValue: 'Select a fleet to manage...',
        })}
      </span>
    );
  }

  return (
    <>
      <BulkUploadToolbarItem />

      <BulkExportToolbarItem />

      <EditSubfleetToolbarItem />

      <EditSubfleetToolbarItem isCreateMode />

      <DeleteFleetToolbarItem />
    </>
  );
};

export default FleetPageToolbar;
