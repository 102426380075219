import { useContext, useState } from 'react';
import * as yup from 'yup';

import Button from 'atoms/Button';
import { useModalContext } from 'atoms/Modal';
import { I18nContext } from 'common/useT';

const emailSchema = yup.string().required().email();
const phoneSchema = yup
  .string()
  .required()
  .matches(
    /^(\+(?:[/\s.]|\d){10,16})+$/,
    'Please enter a valid telephone number including country code beginning with + (eg +44)',
  );

interface CustomRecipientFormProps {
  onSubmit: (value: string) => void;
  channel: 'Email' | 'SMS';
}

const CustomRecipientForm = ({ onSubmit, channel }: CustomRecipientFormProps) => {
  const i18nContext = useContext(I18nContext);
  const [value, setValue] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);
  const modalContext = useModalContext();
  const schema = channel === 'Email' ? emailSchema : phoneSchema;

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      forms: {
        buttons: { add_text },
      },
    },
  } = i18nContext;

  return (
    <div className="flex h-8 flex-col p-1 w-full">
      <div className="flex w-full">
        <input
          className="border-b-px w-full p-1 text-md"
          placeholder={channel === 'Email' ? 'email@example.com' : '+44 7456 789101'}
          type={'email'}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);

            schema
              .validate(e.target.value)
              .then((_) => {
                setError(undefined);
              })
              .catch((e) => {
                if (e instanceof yup.ValidationError) {
                  setError(e.message);
                }
              });
          }}
        />

        {!error && (
          <Button
            onClick={() => {
              onSubmit(value);
              if (modalContext) {
                modalContext.closeModal();
              }
            }}
            className="ml-1 border-px rounded-4 px-1 ui-button"
          >
            {add_text}
          </Button>
        )}
      </div>

      {error && <span className="text-error pt-1">* {error}</span>}
    </div>
  );
};

export default CustomRecipientForm;
