import useRole from 'components/User/useRole';

import AddDriverToolbarItem from '../DriverListToolbar/AddDriverToolbarItem';
import ExportListViewToolbarItem from './ExportListViewToolbarItem';
import SaveListViewToolbarItem from './SaveListViewToolbarItem';
import AddVehicleToolbarItem from './AddVehicleToolbarItem';

const ListViewToolbar = () => {
  const { isManager } = useRole();

  return (
    <>
      {isManager && (
        <>
          <AddVehicleToolbarItem />

          <AddDriverToolbarItem />

          <SaveListViewToolbarItem />

          <ExportListViewToolbarItem />
        </>
      )}
    </>
  );
};

export default ListViewToolbar;
