import { NotificationType, Topic } from 'generated/graphql';
import { keys } from 'utils';

export const enabledNotificationTopicChannels: Partial<Record<Topic, NotificationType[]>> = {
  // batteryCritical: [NotificationType.Portal],
  // batteryHibernate: [NotificationType.Portal],
  // batteryLikelyToFail: [NotificationType.Portal],
  // batteryLow: [NotificationType.Portal],
  // dashlights: [NotificationType.Portal],
  // dtcPending: [NotificationType.Portal],
  // dtcPermanent: [NotificationType.Portal],
  // dtcStored: [NotificationType.Portal],
  // geofence: [NotificationType.Portal],
  // impact: [NotificationType.Portal],
  // mil: [NotificationType.Portal],
  removed: [NotificationType.Portal, NotificationType.Email, NotificationType.Sms],
  // serviceReminder: [NotificationType.Portal],
};

export const allNotificationTopics = keys(enabledNotificationTopicChannels);
