import { useMutation } from '@apollo/client';
import { useContext, useState } from 'react';
import { useRecoilState } from 'recoil';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import ConfirmModal, { useConfirmModal } from 'atoms/Modal/ConfirmModal';
import { I18nContext } from 'common/useT';
import GeofenceBreaches from 'components/Live/Geofence/GeofenceSidebar/GeofenceBreaches';
import GeofenceSettings from 'components/Live/Geofence/GeofenceSidebar/GeofenceSettings';
import { selectedGeofenceState, showSelectedGeofenceVehiclesOnlyState } from 'components/Live/state';
import useRole from 'components/User/useRole';
import { DeleteGeofenceDoc } from 'generated/graphql';

const GeofenceSidebar = () => {
  const i18nContext = useContext(I18nContext);
  const { isManager } = useRole();
  const [showSettings, setShowSettings] = useState(false);
  const [deleteGeofence] = useMutation(DeleteGeofenceDoc);
  const [selectedGeofence, setSelectedGeofence] = useRecoilState(selectedGeofenceState);
  const [showSelectedGeofenceVehiclesOnly, setshowSelectedGeofenceVehiclesOnly] = useRecoilState(
    showSelectedGeofenceVehiclesOnlyState,
  );
  const { openModal, props } = useConfirmModal();
  const geofenceNotYetSaved = !selectedGeofence?.id;

  if (!selectedGeofence || !i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      domain: {
        geofence: {
          fields: { name_text, radius_text },
        },
        common: { latitude_text, longtitude_text },
      },
    },
  } = i18nContext;

  const onConfirmDelete = () => {
    deleteGeofence({
      variables: { id: selectedGeofence.id! },
      optimisticResponse: { deleteGeofence: { __typename: 'Geofence', id: selectedGeofence.id! } },
      update: (cache, { data }) => {
        if (!data) return;

        cache.evict({ id: cache.identify(data.deleteGeofence) });
        cache.gc();
      },
    });
    setSelectedGeofence(undefined);
  };

  return (
    <>
      {!geofenceNotYetSaved && isManager && (
        <ConfirmModal
          modalProps={props}
          confirmContent={
            <span className="max-w-40 mb-1 text-md">
              {tSafe('components.Live.Geofence.GeofenceSidebar.are-you-sure-delete', {
                defaultValue: 'Are you sure you would like to delete this geofence?',
              })}
            </span>
          }
          onConfirm={onConfirmDelete}
        />
      )}

      <div className="relative min-h-[420px] z-10 w-40 bg-gray-100 overflow-hidden shadow-xl ">
        <Button className="absolute left-1 top-1 ui-button-dark" aria-label="Close Vehicle Summary">
          <IonIcon name="closeOutline" className="text-2xl" onClick={() => setSelectedGeofence(undefined)} />
        </Button>

        <div className="flex flex-col px-3 pt-3 w-full first-letter:space-y-3">
          <div className="flex children:border-b-2 space-x-1 children:border-[transparent]">
            <input
              name="Label"
              placeholder={name_text}
              value={selectedGeofence.name}
              onChange={({ target: { value } }) => setSelectedGeofence({ ...selectedGeofence, name: value })}
              className="pl-0 w-full text-lg font-bold focus:border-dark-gray focus:outline-none bg-[transparent]"
              autoFocus={true}
            />

            <div className="flex absolute right-1 top-1 space-x-1">
              {!geofenceNotYetSaved && (
                <Button aria-label="Close Vehicle Summary">
                  <IonIcon
                    name={showSettings ? 'chevronDownOutline' : 'settingsOutline'}
                    className="text-lg ui-button-dark"
                    onClick={() => setShowSettings(!showSettings)}
                  />
                </Button>
              )}

              {!geofenceNotYetSaved && isManager && (
                <Button aria-label="Delete Geofence" onClick={openModal}>
                  <IonIcon name="trashOutline" className="text-error text-lg ui-button-dark" />
                </Button>
              )}
            </div>
          </div>

          <div className="relative flex flex-col !mt-1">
            <span>
              {latitude_text}: {selectedGeofence.coords.lat}
            </span>

            <span>
              {longtitude_text}: {selectedGeofence.coords.lng}
            </span>

            <span>
              {radius_text}: {selectedGeofence.radius}
            </span>

            {!geofenceNotYetSaved && (
              <Button
                onClick={() => setshowSelectedGeofenceVehiclesOnly(!showSelectedGeofenceVehiclesOnly)}
                className="mt-1 border-px rounded-4 py-0.5 px-1 w-fit ui-button-dark"
              >
                {showSelectedGeofenceVehiclesOnly
                  ? tSafe('components.Live.Geofence.GeofenceSidebar.show-all-vehicles', {
                      defaultValue: 'Show All Vehicles',
                    })
                  : tSafe('components.Live.Geofence.GeofenceSidebar.show-linked-vehicles-only', {
                      defaultValue: 'Show Linked Vehicles Only',
                    })}
              </Button>
            )}
          </div>

          {geofenceNotYetSaved ? <GeofenceSettings /> : showSettings ? <GeofenceSettings /> : <GeofenceBreaches />}
        </div>
      </div>
    </>
  );
};

export default GeofenceSidebar;
