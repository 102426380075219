import IonIcon from 'atoms/IonIcon';
import Modal from 'atoms/Modal';
import ListExport from 'components/List/ListExport';
import { useT } from 'common/useT';

const ExportListViewToolbarItem = () => {
  const { tSafe } = useT();

  return (
    <Modal
      title={tSafe('components.ListPage.export-list', { defaultValue: 'Export list' })}
      trigger={
        <div>
          <span className="text-sm space-x-0.5 flex-center">
            <IonIcon className="text-lg" name="downloadOutline" />

            <span> {tSafe('components.ListPage.export-list', { defaultValue: 'Export list' })} </span>
          </span>
        </div>
      }
      contentClassName="w-60"
      preventClose
    >
      <ListExport />
    </Modal>
  );
};

export default ExportListViewToolbarItem;
