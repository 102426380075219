import { Button } from '@radix-ui/react-toolbar';
import { useContext } from 'react';

import IonIcon from 'atoms/IonIcon';
import { CenteredSpinner } from 'atoms/Spinner';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { FleetNotificationRecipientGroup, GetUserCardDoc } from 'generated/graphql';
import { cx } from 'utils';
import { useQ } from 'utils/apolloClient';

type RecipientCardRecipientType = FleetNotificationRecipientGroup | 'customEmail' | 'customTelephone' | 'userId';

interface RecipientCardProps {
  recipientType: RecipientCardRecipientType;
  onClickDisable: (
    isRemoved: boolean,
    recipientType: RecipientCardRecipientType,
    valueToRemove: string | undefined,
  ) => void;
  onClickDelete: (recipientType: RecipientCardRecipientType, valueToRemove: string | undefined) => void;
  onClickMandatory: (
    recipientType: RecipientCardRecipientType,
    mandatory: boolean,
    userIdOrCustomRecipient: string | undefined,
  ) => void;
  customValue?: string;
  canDelete: boolean;
  canDisable: boolean;
  isDisabled: boolean;
  mandatory: boolean;
  showAllowOptOut: boolean;
  allowOptOut?: boolean;
  onClickAllowOptOut?: (
    recipientType: Omit<RecipientCardRecipientType, 'customEmail' | 'customTelephone'>,
    allowOptOut: boolean,
    userId: string | undefined,
  ) => void;
}

const RecipientCard = ({
  recipientType,
  customValue,
  canDelete,
  canDisable,
  isDisabled,
  mandatory,
  showAllowOptOut,
  allowOptOut,
  onClickDelete,
  onClickDisable,
  onClickMandatory,
  onClickAllowOptOut,
}: RecipientCardProps) => {
  const i18nContext = useContext(I18nContext);
  const { data: userData, loading: userDataLoading } = useQ(GetUserCardDoc, {
    variables: { userId: customValue! },
    skip: recipientType !== 'userId',
  });

  if (!i18nContext || isDisabled) return null;

  const {
    tSafe,
    commonTranslations: {
      enums: { fleetNotificationRecipientGroupDescriptionMap },
    },
  } = i18nContext;

  if (recipientType === 'userId' && (userDataLoading || !userData)) return <CenteredSpinner />;

  return (
    <div
      className={cx(
        'relative rounded-4 bg-white border-gray-400 border-px mx-0.5 shadow-sm min-w-[160px] max-h-12 mb-0.5',
      )}
    >
      {(canDelete || canDisable) && (
        <Button
          className="absolute right-0 top-0"
          onClick={() => {
            if (canDelete) {
              onClickDelete(recipientType, customValue);
            }
            if (canDisable) {
              onClickDisable(isDisabled, recipientType, customValue);
            }
          }}
          aria-label={tSafe(
            'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.RecipientManager.RecipientCard.remove-recipient',
            { defaultValue: 'Remove recipient' },
          )}
        >
          <IonIcon name="closeOutline" className="absolute right-1 top-1 text-xl ui-button" />
        </Button>
      )}

      <div className="flex flex-col h-full">
        <div className="text-md font-semibold mr-2 px-1 pt-1">
          {fleetNotificationRecipientGroupDescriptionMap[recipientType]}
        </div>

        <div className="px-1">{recipientType !== 'userId' ? customValue : userData?.name}</div>

        <div className="p-0.5 flex flex-col  items-start space-y-1 pt-1 text-sm">
          <Tooltip
            text={tSafe(
              'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.RecipientManager.tooltip-mandatory',
              { defaultValue: 'If selected, managers in fleets below cannot remove this recipient group' },
            )}
          >
            <label className={cx('p-1 w-full', (canDelete || canDisable) && 'hover:bg-gray-100 hover:cursor-pointer')}>
              <span className="mr-1">
                {tSafe(
                  'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.RecipientManager.RecipientCard.mandatory',
                  { defaultValue: 'Mandatory' },
                )}
              </span>

              <input
                className="ml-auto cursor-pointer"
                type="checkbox"
                placeholder="Yes"
                disabled={!canDelete && !canDisable}
                defaultChecked={mandatory}
                onChange={(e) => {
                  onClickMandatory(recipientType, e.target.checked, customValue);
                }}
              />
            </label>
          </Tooltip>

          {showAllowOptOut && (
            <Tooltip
              text={tSafe(
                'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.RecipientManager.tooltip-override',
                { defaultValue: 'If selected, users can still override notification settings' },
              )}
            >
              <label
                className={cx('p-1 text-left', (canDelete || canDisable) && 'hover:bg-gray-100 hover:cursor-pointer')}
              >
                <span className="mr-1">
                  {tSafe(
                    'components.Fleet.FleetList.Toolbar.EditSubfleetToolbarItem.RecipientManager.RecipientCard.allow-user-override',
                    { defaultValue: 'Allow user override?' },
                  )}
                </span>

                <input
                  className="ml-auto cursor-pointer"
                  type="checkbox"
                  placeholder="Yes"
                  disabled={!canDelete && !canDisable}
                  defaultChecked={allowOptOut}
                  onChange={(e) => {
                    onClickAllowOptOut?.(recipientType, e.target.checked, customValue);
                  }}
                />
              </label>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecipientCard;
