import { Layouts } from 'react-grid-layout';

import widgetMap from 'components/Vehicle/Detail/widgetMap';
import { GridBreakpoints, SerializedGridLayout } from 'types/gridLayout';
import { VehiclePageWidgetSettings } from 'types/settings';
import { VehicleDetailPageWidget } from 'types/widgets';

export const vehiclePageWidgetHeightMap: Record<VehicleDetailPageWidget, number> = {
  [VehicleDetailPageWidget.vehicleStatusWidget]: 4,
  [VehicleDetailPageWidget.vehicleLastKnownLocationWidget]: 4,
  [VehicleDetailPageWidget.geofencesWidget]: 4,
  [VehicleDetailPageWidget.vehicleLevelsWidget]: 7,
  [VehicleDetailPageWidget.tyrePressureWidget]: 4,
  [VehicleDetailPageWidget.dashboardLightsWidget]: 4,
  [VehicleDetailPageWidget.deviceInfoWidget]: 7,
};

export const serializedLayoutToLayout = ({
  widgetSettings,
  collapsedWidgets,
}: {
  widgetSettings: VehiclePageWidgetSettings;
  collapsedWidgets: string[];
}) =>
  Object.entries(widgetSettings).reduce((acc, [key, { layout }]) => {
    Object.entries(layout).forEach(([breakpoint, area]) => {
      acc[breakpoint] ??= [];

      if (!widgetMap[key]) return;

      const { component: _, ...defaultLayout } = widgetMap[key];

      acc[breakpoint].push({
        i: key,
        ...defaultLayout,
        ...area,
        h: collapsedWidgets.includes(key) ? 1 : vehiclePageWidgetHeightMap[key as VehicleDetailPageWidget]!,
      });
    });

    return acc;
  }, {} as Layouts);

export const layoutToSerializedLayout = (layout: Layouts) =>
  Object.entries(layout).reduce((acc, [breakpoint, layout]) => {
    layout.forEach((widget) => {
      acc[widget.i] ??= {} as SerializedGridLayout[string];
      acc[widget.i][breakpoint as GridBreakpoints] = { x: widget.x, y: widget.y, w: widget.w, h: widget.h };
    });

    return acc;
  }, {} as SerializedGridLayout);
