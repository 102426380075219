import { useContext } from 'react';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { useAddWidget } from 'components/Dashboard/Widget/hooks';
import widgetMap from 'components/Dashboard/widgetMap';
import useSettings from 'components/Settings/useSettings';
import ToolbarSelect from 'components/Toolbar/ToolbarSelect';
import { SelectOption } from 'types';
import { cx, keys } from 'utils';

const AddWidgetMenu = () => {
  const i18nContext = useContext(I18nContext);
  const addWidget = useAddWidget();
  const addedWidgets = Object.keys(useSettings().dashboardWidgetSettings);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap },
    },
  } = i18nContext;

  const availableWidgets = keys(widgetMap)
    .filter((key) => !addedWidgets.includes(key))
    .map((key) => ({ label: dashboardWidgetDescriptionMap[key], value: key }));

  const addWidgetLabel = tSafe('components.Toolbar.AddWidgetMenu.add-widget-with-count', {
    count: availableWidgets.length,
    defaultValue: 'Add widgets ({{count}})',
  });

  const titleItem = { label: addWidgetLabel, value: '' };

  const onSelect = ({ value }: SelectOption<string>) => addWidget(value);

  return (
    <ToolbarSelect
      className={cx(!availableWidgets.length && 'opacity-50')}
      disabled={!availableWidgets.length}
      items={[titleItem, ...availableWidgets]}
      onSelect={onSelect}
      selectedItem={titleItem}
      textRight
      icon={<IonIcon name="addCircleOutline" />}
    />
  );
};

export default AddWidgetMenu;
