import { useCallback } from 'react';

import IonIcon from 'atoms/IonIcon';
import useSearchSource, { SearchSourceComponent } from 'common/useSearchSource';
import { GetUserSearchDoc } from 'generated/graphql';
import { Result } from 'types';

export type MemoizedUser = Omit<Result<typeof GetUserSearchDoc>[0], 'typename'>;

const SearchUserItem: SearchSourceComponent = ({ id, innerHtml, onSelect }) => (
  <li
    className="flex px-2 py-1 text-left cursor-pointer hover:text-white hover:bg-hover"
    onClick={() => onSelect?.(id)}
  >
    <IonIcon name="personOutline" className="mr-1 text-3xl" />

    <div className="flex flex-col">
      <span className="text-md" dangerouslySetInnerHTML={{ __html: innerHtml }} />
    </div>
  </li>
);

const useUserSearchSource = (
  memoizedData: MemoizedUser[] | undefined,
  searchTerm: string,
  onSelect: (user: MemoizedUser) => void,
) => {
  const selectHandler = useCallback(
    (user: MemoizedUser) => {
      onSelect?.(user);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return useSearchSource({
    memoizedData,
    searchKey: 'name',
    searchTerm,
    Component: SearchUserItem,
    onSelect: selectHandler,
  });
};

export default useUserSearchSource;
