import { SVGProps } from 'react';

import icons, { IconName } from 'atoms/Icon/icons';

interface IconProps extends SVGProps<SVGSVGElement> {
  name: IconName;
  title?: string;
  className?: string;
}

const Icon = ({ name, title, className, ...props }: IconProps) => {
  const Icon = icons[name];

  return <Icon title={title} className={`svg-fill-current h-init min-w-[1em] w-init ${className ?? ''}`} {...props} />;
};

export default Icon;
