import { useMemo } from 'react';

import useUser from 'components/User/useUser';
import { FleetHierarchy } from 'types/fleet';

const useAccessibleFleetMap = () => {
  const { accessibleFleets } = useUser();

  return useMemo(
    () =>
      accessibleFleets.reduce((acc, fleet) => {
        acc[fleet.id] = fleet;

        return acc;
      }, {} as Record<string, typeof accessibleFleets[0] | undefined>),
    [accessibleFleets],
  );
};

export const useTopLevelFleetIds = () => {
  const { accessibleFleets } = useUser();

  return useMemo(() => accessibleFleets.filter((x) => !x.parentId).map((x) => x.id), [accessibleFleets]);
};

export const getChildFleetIdsFromFleetMap = (fleetId: string, fleetMap: Record<string, FleetHierarchy | undefined>) => {
  const out: string[] = [];

  const buildArray = (id: string) => {
    const childFleetsIds = fleetMap[id]?.childrenIds;

    if (!childFleetsIds?.length) {
      return;
    }

    childFleetsIds.forEach((id) => {
      out.push(id);

      buildArray(id);
    });
  };

  buildArray(fleetId);

  return out;
};

export default useAccessibleFleetMap;
