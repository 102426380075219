import { useContext, useState } from 'react';

import Popover from 'atoms/Popover';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { cx } from 'utils';

import Button from './Button';
import IonIcon from './IonIcon';

interface CopyToClipboardButtonProps {
  value: string | number | undefined;
  className?: string;
  label?: string;
  buttonAlignment?: 'left' | 'right';
}

export const CopyToClipboardButton = ({
  value,
  className,
  label,
  buttonAlignment = 'right',
}: CopyToClipboardButtonProps) => {
  const i18nContext = useContext(I18nContext);
  const icon = <IonIcon name="copyOutline" />;
  const [showPopover, setShowPopover] = useState(false);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const showPopoverMomentarily = () => {
    setShowPopover(true);
    setTimeout(() => {
      setShowPopover(false);
    }, 2000);
  };

  return (
    <>
      {value && (
        <Popover
          content={tSafe('atoms.CopyToClipboardButton.copied', { defaultValue: 'Copied!' })}
          open={showPopover}
          showCloseButton={false}
        >
          <Tooltip
            text={tSafe('atoms.CopyToClipboardButton.prompt', {
              value: value.toString(),
              defaultValue: 'Copy {{value}} to clipboard',
            })}
          >
            <Button
              className={cx(`flex items-center ui-button`, className)}
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(value.toString());
                showPopoverMomentarily();
              }}
            >
              {buttonAlignment === 'left' && icon}

              {label ? <span className={cx(buttonAlignment === 'right' ? 'mr-0.5' : 'ml-0.5')}>{label}</span> : null}

              {buttonAlignment === 'right' && icon}
            </Button>
          </Tooltip>
        </Popover>
      )}
    </>
  );
};
