import { format, formatDistance } from 'date-fns';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { LicencePlate } from 'atoms/LicencePlate';
import Tooltip from 'atoms/Tooltip';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetPortalNotificationsDoc } from 'generated/graphql';
import { Result } from 'types';
import { capitalize, cx } from 'utils';

interface NotificationItemProps {
  item: Result<typeof GetPortalNotificationsDoc>['notifications'][0];
}

const NotificationItem = ({ item }: NotificationItemProps) => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();
  const { time, topic, vehicle, driver, vehicleId } = item;

  if (!i18nContext) return null;

  const { licencePlate } = vehicle?.vehicle ?? {};

  const {
    commonTranslations: {
      enums: { topicDescriptionMap },
      domain: {
        driver: { driver_text },
        vehicle: { vehicle_text },
      },
    },
  } = i18nContext;

  return (
    <div className={cx('flex flex-col relative p-1 border-t-px border-gray-300 min-h-3 py-1 z-600 hover:bg-gray-100')}>
      <div className="text-md font-bold rounded-2 w-fit mb-0.5">{topic ? topicDescriptionMap[topic] : ''}</div>

      <div className="absolute top-1 right-1 text-gray-400">
        <Tooltip text={capitalize(format(Date.parse(time), 'iiii PPp'))}>
          {capitalize(formatDistance(Date.parse(time), new Date(), { addSuffix: true }))}
        </Tooltip>
      </div>

      {driver && (
        <div>
          <Link className="ui-button" to={`/${currentFleetId}/drivers/${driver.id}`}>
            <span className="text-md">{driver.user.name}</span>
          </Link>
        </div>
      )}

      {licencePlate && (
        <div className="flex w-full">
          <Link className="mt-0.5 ui-button" to={`/${currentFleetId}/vehicles/${vehicleId}`}>
            <LicencePlate textClass="text-md" licenceNumber={licencePlate} />
          </Link>
        </div>
      )}
    </div>
  );
};

export default NotificationItem;
