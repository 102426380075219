import React, { ReactElement } from 'react';

import { ClassName } from 'types';

interface EngineIconProps extends ClassName {
  innerSvg?: ReactElement<React.SVGProps<SVGSVGElement>>;
}

const EngineIcon = ({ innerSvg, className, ...props }: EngineIconProps) => (
  <svg
    viewBox="0.5292968153953552 1.984260082244873 7.408203125 4.630819797515869"
    className={className}
    fill="currentColor"
    {...props}
  >
    <g>
      <path d="m 0.52929688,3.17566 0,2.64454 0.26367187,0 0,-2.64454 -0.26367187,0 z" />

      <path d="m 0.59570312,4.36512 0,0.26562 0.85937498,0 0,-0.26562 -0.85937498,0 z" />

      <path d="m 2.984375,2.51356 c -0.092731,-2e-5 -0.1788329,0.0494 -0.2265625,0.1289 l -0.3183594,0.5332 -0.8515625,0 c -0.1445572,0 -0.2656231,0.11912 -0.265625,0.26368 l 0,2.38086 c 1.9e-6,0.14455 0.1210678,0.26562 0.265625,0.26562 l 0.7402344,0 c 0.090144,0 0.1776585,0.0296 0.2480469,0.0859 l 0.3710937,0.29883 c 0.1172644,0.0938 0.2638861,0.14452 0.4140625,0.14453 l 2.5917969,0 c 0.1445645,0 0.263681,-0.12106 0.2636719,-0.26563 l 0,-0.79297 0.4277343,0 L 6.75,5.98426 a 0.1323049,0.1323049 0 0 0 0.1289062,0.10156 l 0.6621094,0 c 0.2176192,0 0.3964816,-0.17886 0.3964844,-0.39648 l 0,-1.85352 c -2.8e-6,-0.21762 -0.1788652,-0.39648 -0.3964844,-0.39648 l -0.6621094,0 A 0.1323049,0.1323049 0 0 0 6.75,3.53895 l -0.1054688,0.42968 -0.4277343,0 0,-0.52929 c -1.9e-6,-0.14456 -0.1191147,-0.26368 -0.2636719,-0.26368 l -0.71875,0 -0.3203125,-0.5332 c -0.04773,-0.0795 -0.1338313,-0.12892 -0.2265625,-0.1289 l -0.4550781,0 0,0.26367 0.4550781,0 0.359375,0.59765 a 0.1323049,0.1323049 0 0 0 0.1132812,0.0645 l 0.7929688,0 0,0.66211 a 0.1323049,0.1323049 0 0 0 0.1328125,0.13281 l 0.6601563,0 A 0.1323049,0.1323049 0 0 0 6.875,4.1327 l 0.1074219,-0.42774 0.5585937,0 c 0.074629,0 0.1328116,0.0562 0.1328125,0.13086 l 0,1.85352 c -9e-7,0.0746 -0.058184,0.13086 -0.1328125,0.13086 l -0.5585937,0 L 6.875,5.39246 a 0.1323049,0.1323049 0 0 0 -0.1289062,-0.10156 l -0.6601563,0 a 0.1323049,0.1323049 0 0 0 -0.1328125,0.13281 l 0,0.92578 -2.5917969,0 c -0.090144,0 -0.1776584,-0.0296 -0.2480469,-0.0859 L 2.7402344,5.96668 c -0.1172643,-0.0938 -0.261933,-0.14648 -0.4121094,-0.14648 l -0.7402344,0 0,-2.38086 0.9257813,0 a 0.1323049,0.1323049 0 0 0 0.1132812,-0.0645 l 0.3574219,-0.59765 0.4550781,0 0,-0.26367 -0.4550781,0 z" />

      <path d="m 3.4394531,2.91004 0,0.26562 0.7929688,0 0,-0.26562 -0.7929688,0 z" />

      <path d="m 2.7773438,1.98426 0,0.26562 2.1171874,0 0,-0.26562 -2.1171874,0 z" />

      <path d="m 3.7050781,2.05067 0,1.0586 0.2636719,0 0,-1.0586 -0.2636719,0 z" />

      <g transform="translate(0.5292968153953552 1.984260082244873)">
        {innerSvg ? React.cloneElement(innerSvg, { height: '50%', width: '50%', x: '23%', y: '33%' }) : null}
      </g>
    </g>
  </svg>
);

export default EngineIcon;
