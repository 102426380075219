import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  useSensor,
  PointerSensor,
  useSensors,
  pointerWithin,
} from '@dnd-kit/core';
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { Column, Table, flexRender } from '@tanstack/react-table';
import { useContext, useRef, useState } from 'react';

import Button from 'atoms/Button';
import { ConditionalWrapper } from 'atoms/ConditionalWrapper';
import { Draggable } from 'atoms/Draggable';
import { Droppable } from 'atoms/Droppable';
import Icon from 'atoms/Icon';
import IonIcon from 'atoms/IonIcon';
import { CenteredSpinner } from 'atoms/Spinner';
import Tooltip from 'atoms/Tooltip';
import { useT } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { DtcEventType, ListColumnId, ListSortDirection, TripStatisticType, UserListColumnId } from 'generated/graphql';
import { createTableContext, cx } from 'utils';

import PageSelector from './PageSelector';
import { maxItemsPerPage } from 'components/List/utils';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends unknown, TValue> {
    alphaSort?: boolean;
    filterComponent?: (props: string | string[]) => JSX.Element;
  }
}

const FullPageTableContext = createTableContext<any>();
export const useFullPageTableContext = () => useContext(FullPageTableContext);

export type ColumnId = ListColumnId | UserListColumnId;

interface FullPageTableProps<T extends object> {
  table: Table<T>;
  totalCount?: number;
  currentPage: number;
  onPageChange?: (selected: number) => void;
  toggleSortBy?: (id?: ColumnId, direction?: ListSortDirection | null) => void;
  sortedColumn?: { field: ColumnId; direction?: ListSortDirection } | null;
  columnOrder?: Set<ColumnId> | false;
  setColumnOrder?: (newOrder: ListColumnId[]) => void;
  useTablePagination?: boolean;
  loading?: boolean;
  refetch?: () => void;
}

const FullPageTable = <T extends object>({
  table,
  totalCount,
  currentPage,
  onPageChange,
  toggleSortBy,
  sortedColumn,
  columnOrder,
  setColumnOrder,
  useTablePagination = false,
  loading = false,
  refetch,
}: FullPageTableProps<T>) => {
  const { getHeaderGroups, getRow } = table;
  const listRef = useRef<HTMLDivElement>(null);
  const { idleTimeAsPercentage } = useSettings();

  const getNextSort = ({ id, columnDef: { meta } }: Column<T>) => {
    const alphaSort = meta?.alphaSort;
    let returnObj: {
      direction: ListSortDirection | null;
      icon: JSX.Element;
    } = {
      // default / third toggle
      direction: null,
      icon: (
        <div className="group-hover:invisible">
          <IonIcon name={meta?.alphaSort ? 'arrowUp' : 'arrowDown'} className={cx('text-md group-hover:rotate-180')} />
        </div>
      ),
    };

    if (sortedColumn?.field !== id || !sortedColumn?.direction) {
      returnObj = {
        // first toggle
        direction: alphaSort ? ListSortDirection.Asc : ListSortDirection.Desc,
        icon: (
          <div className="invisible group-hover:visible">
            <IonIcon
              name={meta?.alphaSort ? 'arrowDown' : 'arrowUp'}
              className={cx('text-md group-hover:rotate-180')}
            />
          </div>
        ),
      };
    } else if (
      (sortedColumn.direction === ListSortDirection.Asc && alphaSort) ||
      (sortedColumn.direction === ListSortDirection.Desc && !alphaSort)
    ) {
      returnObj = {
        // second toggle
        direction: alphaSort ? ListSortDirection.Desc : ListSortDirection.Asc,
        icon: (
          <div className="group-hover:rotate-180 transition-all">
            <IonIcon name={meta?.alphaSort ? 'arrowDown' : 'arrowUp'} className={cx('text-md')} />
          </div>
        ),
      };
    }
    return returnObj;
  };

  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 10 } }));

  const {
    tSafe,
    commonTranslations: {
      general: { sort_by_text },
      enums: { dtcEventTypeDescriptionMap, tripStatisticTypeDescriptionMap },
      domain: {
        fleet: { fleet_text },
        impact: { impact_events_text },
        vehicle: {
          fields: { licencePlate_text, make_text, model_text },
        },
        vehicleDetails: {
          fields: {
            mil_status_text,
            activeDashboardLights_text,
            battery_voltage_text,
            battery_health_text,
            batteryStatus_text,
          },
        },
        driver: { driver_text, longIdling_text },
        device: {
          fields: { device_connection_status_text },
        },

        user: {
          fields: { active_text, email_text, name_text, role_text, actions_text },
        },
      },
      pagination: { count_records_tfn },
    },
  } = useT();

  const sortFieldTextMap: Record<ListColumnId | UserListColumnId, string> = {
    active: active_text,
    actions: actions_text,
    batteryHealth: battery_health_text,
    batteryCharge: battery_voltage_text,
    batteryStatus: batteryStatus_text,
    dashboardLights: activeDashboardLights_text,
    deviceStatus: device_connection_status_text,
    distanceDriven: tripStatisticTypeDescriptionMap[TripStatisticType.TotalDistanceDriven],
    dtcPending: dtcEventTypeDescriptionMap[DtcEventType.Pending],
    dtcPermanent: dtcEventTypeDescriptionMap[DtcEventType.Permanent],
    dtcStored: dtcEventTypeDescriptionMap[DtcEventType.Stored],
    drivingTime: tripStatisticTypeDescriptionMap[TripStatisticType.TotalTimeDriven],
    driver: driver_text,
    driverName: driver_text,
    email: email_text,
    ecoScore: tripStatisticTypeDescriptionMap[TripStatisticType.EcoScore],
    fleets: fleet_text,
    fuelConsumption: tripStatisticTypeDescriptionMap[TripStatisticType.FuelConsumption],
    fuelEfficiency: tripStatisticTypeDescriptionMap[TripStatisticType.FuelEfficiency],
    idleTime: idleTimeAsPercentage
      ? tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimePct]
      : tripStatisticTypeDescriptionMap[TripStatisticType.IdleTimeHrs],
    longIdlingEventCount: longIdling_text,
    impactCount: impact_events_text,
    licencePlate: licencePlate_text,
    make: make_text,
    model: model_text,
    name: name_text,
    role: role_text,
    tripCount: tripStatisticTypeDescriptionMap[TripStatisticType.TripCount],
  };
  const [movingColumnId, setMovingColumnId] = useState<ListColumnId | null>(null);
  const [targetColumnId, setTargetColumnId] = useState<ListColumnId | null>(null);

  const reorderColumn = (movingColumnId: ListColumnId, targetColumnId: ListColumnId): ListColumnId[] => {
    if (!columnOrder) return [];

    const newColumnOrder = [...Array.from(columnOrder)] as ListColumnId[];

    newColumnOrder.splice(
      newColumnOrder.indexOf(targetColumnId),
      0,
      newColumnOrder.splice(newColumnOrder.indexOf(movingColumnId), 1)[0],
    );
    return newColumnOrder;
  };

  const handleDragEnd = (e: DragEndEvent) => {
    setMovingColumnId(null);

    if (movingColumnId && targetColumnId && setColumnOrder)
      setColumnOrder(reorderColumn(movingColumnId, targetColumnId));
  };

  const viewedRangeStart = currentPage * maxItemsPerPage + 1;
  const viewedRangeEnd = viewedRangeStart + table.getRowModel().rows.length - 1;
  const viewedItemRange = `${viewedRangeStart} - ${viewedRangeEnd}`;

  const Headers = getHeaderGroups().map((group) => (
    <thead key={group.id} className="sticky top-0 bg-gray-100 pb-0.5 shadow-[0_4px_6px_-4px_rgba(0,0,0,0.2)] z-500">
      <tr>
        {group.headers.map(({ column, getContext, getSize, id }, index) => (
          <th
            className={cx('align-bottom text-sm font-normal', movingColumnId === id ? 'opacity-20' : 'opacity-100')}
            key={`${column}-${index}`}
          >
            <ConditionalWrapper
              condition={!!columnOrder}
              wrapper={({ children }: { children: JSX.Element }) => <Draggable id={id}>{children}</Draggable>}
            >
              <>
                <div
                  className={cx(
                    'group flex py-0.5 w-full min-w-16 transition-all hover:bg-gray-300',
                    id === sortedColumn?.field && sortedColumn.direction != null && 'bg-gray-300',
                  )}
                  style={{ width: getSize() }}
                >
                  {columnOrder && (
                    <Icon name="dots" width={10} className="hidden group-hover:block hover:cursor-grab" />
                  )}

                  <ConditionalWrapper
                    condition={column.getCanSort()}
                    wrapper={({ children }: { children: JSX.Element }) => (
                      <Tooltip text={`${sort_by_text} ${sortFieldTextMap[id as ListColumnId]}`}>{children}</Tooltip>
                    )}
                  >
                    <span
                      className={cx(
                        id === sortedColumn?.field && sortedColumn.direction != null && 'font-bold',
                        'flex text-left pl-[3px] capitalize',
                      )}
                      onClick={() => {
                        if (column.getCanSort()) {
                          if (!toggleSortBy) return;

                          toggleSortBy(id as ColumnId, getNextSort(column).direction);

                          if (useTablePagination) column.toggleSorting();
                        }
                      }}
                    >
                      {flexRender(column.columnDef.header, getContext())}

                      {column.getCanSort() ? (
                        <div className="inline ml-0.5 pt-[1px] text-[10px]">{getNextSort(column).icon}</div>
                      ) : null}
                    </span>
                  </ConditionalWrapper>
                </div>

                <div className="h-3 mb-[2px]">
                  {column.columnDef.filterFn ? (
                    <div className="w-full h-4 ">
                      {flexRender(column.columnDef.meta?.filterComponent, getContext())}
                    </div>
                  ) : null}
                </div>
              </>
            </ConditionalWrapper>

            {columnOrder && <Droppable id={id} />}
          </th>
        ))}
      </tr>
    </thead>
  ));

  return (
    <FullPageTableContext.Provider value={table}>
      <DndContext
        collisionDetection={pointerWithin}
        sensors={sensors}
        onDragStart={(e) => setMovingColumnId(e.active.id as ListColumnId)}
        onDragEnd={(e) => handleDragEnd(e)}
        onDragOver={(e) => setTargetColumnId((e.over?.id as ListColumnId) ?? null)}
      >
        <div className="relative h-full mx-auto pt-0.5">
          {loading && (
            <div className="absolute top-1/2 left-1/2 z-999">
              <CenteredSpinner className="" />
            </div>
          )}
          <div ref={listRef} className="pb-4 h-[calc(100vh_-_180px)] w-[calc(100vw_-_20px)]">
            <SimpleBar forceVisible="x" className="h-[calc(100vh_-_220px)]">
              <table className="mx-auto">
                {Headers}

                <tbody className={cx(loading && 'opacity-50')}>
                  {table.getRowCount()
                    ? { ...table }.getRowModel().rows.map((row, index) => {
                        getRow(row.id);

                        return (
                          <tr
                            className={cx(index % 2 && 'bg-white', 'bg-gray-100 my-1 hover:brightness-90')}
                            key={`${row.id}-${index}`}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <ConditionalWrapper
                                key={cell.id}
                                condition={!!columnOrder}
                                wrapper={({ children }: { children: JSX.Element }) => (
                                  <SortableContext
                                    key={cell.id}
                                    items={columnOrder ? (Array.from(columnOrder) as ListColumnId[]) : []}
                                    strategy={horizontalListSortingStrategy}
                                  >
                                    {children}
                                  </SortableContext>
                                )}
                              >
                                <td
                                  className={cx(
                                    'p-1 items-center text-sm',
                                    movingColumnId === cell.id ? 'opacity-20' : 'opacity-100',
                                  )}
                                  key={`${cell.id}-${index}`}
                                >
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              </ConditionalWrapper>
                            ))}
                          </tr>
                        );
                      })
                    : !loading && (
                        <tr>
                          <td colSpan={6} className="sticky top-0 left-0 text-center h-[400px]">
                            {tSafe('components.Vehicle.VehicleList.no-results', {
                              defaultValue: 'No results found for the selected filters',
                            })}
                          </td>
                        </tr>
                      )}
                </tbody>
              </table>
            </SimpleBar>

            <div className="w-full absolute bottom-0 shadow-card bg-gray-100">
              <div className="flex-center mt-1 -mb-3 min-w-[200px] h-[12px]">
                {!loading && (
                  <div className="flex z-600">
                    {count_records_tfn(viewedItemRange, totalCount ?? 0)}

                    <Button onClick={refetch} className="ml-1 ui-button">
                      <IonIcon name="refreshOutline" className="text-md" />
                    </Button>
                  </div>
                )}
              </div>

              <PageSelector
                className={cx(totalCount && totalCount < maxItemsPerPage && 'opacity-0')}
                totalCount={totalCount}
                currentPage={currentPage}
                onPageChange={(e) => {
                  onPageChange?.(e);
                }}
                useTablePagination={useTablePagination}
              />
            </div>
          </div>
        </div>

        <DragOverlay>
          <div className="w-[150px] h-[800px] border-px" style={{ borderColor: 'blue' }}>
            <div className="w-[150px] h-full bg-gray-100 opacity-40" />
          </div>
        </DragOverlay>
      </DndContext>
    </FullPageTableContext.Provider>
  );
};

export default FullPageTable;
