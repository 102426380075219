import { cx } from 'utils';

interface LicencePlateProps {
  licenceNumber: string;
  textClass: 'text-sm' | 'text-md' | 'text-4xl';
}

const defaultStyles: string[] = [
  `bg-white`,
  'border-px',
  'border-dark-gray',
  'inline-block',
  'rounded-4',
  'px-0.5',
  'flex',
  'max-w-25',
  'text-dark-gray',
  'font-bold',
  'flex-center',
];

const styleMap: Record<string, { margin: string; minWidth: string; shadow: string }> = {
  'text-4xl': {
    margin: 'mx-1',
    minWidth: 'min-w-20',
    shadow: 'shadow-card',
  },
  'text-md': {
    margin: 'mx-1',
    minWidth: 'min-w-6',
    shadow: 'shadow-none',
  },
  'text-sm': {
    margin: 'mx-0',
    minWidth: 'min-w-6',
    shadow: 'shadow-none',
  },
};

export const LicencePlate = ({ licenceNumber, textClass }: LicencePlateProps) => (
  <div
    className={cx(defaultStyles, styleMap[textClass].minWidth, styleMap[textClass].shadow, textClass)}
    data-testid="number-plate"
  >
    {licenceNumber}
  </div>
);
