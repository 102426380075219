import { useContext } from 'react';
import * as yup from 'yup';
import { number } from 'yup';

import { I18nContext } from 'common/useT';

interface NumericInputFieldProps {
  value?: number;
  className?: string;
  ariaLabel?: string;
  yupSchema?: yup.NumberSchema;
  validationMessage?: string;
  onValidate?: (isValid: boolean, updatedValueIfValid?: number, error?: Error) => void;
  onChange?: (value: number) => void;
}

const NumericInputField = ({
  value,
  onChange,
  className,
  ariaLabel,
  yupSchema,
  onValidate,
}: NumericInputFieldProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const fieldSchema =
    yupSchema ??
    number().typeError(tSafe('atoms.NumericInputField.type-error', { defaultValue: 'You must specify a number' }));

  return (
    <input
      aria-label={ariaLabel}
      className={className}
      defaultValue={value}
      onChange={async (e) => {
        try {
          const value = await fieldSchema.validate(e.target.value);
          onValidate?.(true, value, undefined);
          if (value) {
            onChange?.(value);
          } else {
            onValidate?.(true, value, undefined);
          }
        } catch (error) {
          onValidate?.(false, undefined, error as Error);
        }
      }}
    />
  );
};

export default NumericInputField;
