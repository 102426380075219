import { useMutation } from '@apollo/client';
import { DetailedHTMLProps, HTMLAttributes, useContext } from 'react';

import IonIcon from 'atoms/IonIcon';
import Modal, { useModal } from 'atoms/Modal';
import { I18nContext } from 'common/useT';
import CreateReportForm, { ReportEditFormData } from 'components/Reporting/ReportingToolbar/CreateReportForm';
import { CreateReportDoc, GetReportsDoc } from 'generated/graphql';

const Trigger = (props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div {...props}>
      <span className="text-sm space-x-0.5 flex-center ui-button-dark">
        <IonIcon className="text-lg" name="addCircleOutline" />

        <span>{tSafe('components.Reporting.ReportingToolbar.Trigger.add-report', { defaultValue: 'Add Report' })}</span>
      </span>
    </div>
  );
};

const ReportingToolbar = () => {
  const i18nContext = useContext(I18nContext);
  const [createReportMutation] = useMutation(CreateReportDoc);
  const { closeModal, props: modalProps } = useModal({});

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const createReport = (reportFormData: ReportEditFormData) => {
    createReportMutation({
      variables: { report: reportFormData },
      update: (cache, { data: mutationResult }) =>
        cache.updateQuery({ query: GetReportsDoc }, (getReportsQueryData) => {
          const currReports = getReportsQueryData?.reports ?? [];
          const newReport = mutationResult?.createReport;
          return {
            reports: newReport ? [newReport, ...currReports] : currReports,
          };
        }),
    }).then(closeModal);
  };

  return (
    <span className="text-right">
      <Modal
        {...modalProps}
        title={tSafe('components.Reporting.ReportingToolbar.create-report', { defaultValue: 'Create Report' })}
        contentClassName="w-1/2 p-1 text-left"
        trigger={<Trigger />}
      >
        <CreateReportForm onSubmit={createReport} hideButtons={false} />
      </Modal>
    </span>
  );
};

export default ReportingToolbar;
