import { useContext } from 'react';

import Modal from 'atoms/Modal';
import { I18nContext } from 'common/useT';

interface BlockingModalProps {
  title?: string;
  children?: JSX.Element | string;
}

const BlockingModal = ({ title, children }: BlockingModalProps) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;
  return (
    <Modal
      isOpen={true}
      title={
        title ??
        tSafe('atoms.Modal.BlockingModal.thankyou-for-your-patience', { defaultValue: 'Thank you for your patience!' })
      }
      showCloseButton={false}
      preventClose={true}
    >
      {children ?? (
        <span className="text-md">
          {tSafe('atoms.Modal.BlockingModal.please-wait', {
            defaultValue: 'Please wait until the operation completes. Do not refresh the page.',
          })}
        </span>
      )}
    </Modal>
  );
};

export default BlockingModal;
