import { useCallback, useContext, useState } from 'react';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import useAddressSearchSource from 'components/Live/MultiSearch/useAddressSearchSource';
import useGeofenceSearchSource from 'components/Live/MultiSearch/useGeofenceSearchSource';
import useLocationResults from 'components/Live/MultiSearch/useLocationResults';
import { VehicleLastKnownLocation } from 'generated/graphql';

export type MultiSearchData = { score: number; jsx: JSX.Element };

const MultiSearch = ({ vehicles }: { vehicles: VehicleLastKnownLocation[] }) => {
  const i18nContext = useContext(I18nContext);
  const [searchTerm, setSearchTerm] = useState('');
  const resetSearch = useCallback(() => setSearchTerm(''), []);

  const results = [
    ...useLocationResults(vehicles, searchTerm, setSearchTerm),
    ...useGeofenceSearchSource(searchTerm, resetSearch),
    ...useAddressSearchSource(searchTerm, resetSearch),
  ]
    .sort((a, b) => b.score - a.score)
    .map(({ jsx }) => jsx);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="absolute z-500 top-1 flex justify-center w-full">
      <div className="relative">
        <div className="flex w-60 bg-gray-100 border border-gray-400 rounded-8 overflow-hidden">
          <input
            className="flex-1 pl-2 pr-1 min-h-3.5 text-md bg-gray-100 focus:outline-none"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={tSafe('components.Live.MultiSearch.placeholder', {
              defaultValue: 'Search for an Address, Geofence, Driver or Vehicle',
            })}
          />

          <span className="flex-shrink-0 pr-1 text-lg flex-center" aria-label="Search">
            <IonIcon name="searchOutline" />
          </span>
        </div>

        <ul className="absolute z-500 left-0 right-0 text-sm top-[calc(100%+.5rem)] children:focus:outline-none">
          {searchTerm && !!results?.length && (
            <div className="flex flex-col bg-gray-100 border border-gray-400 rounded-8 overflow-hidden">
              <span className="max-h-80 overflow-auto">{results}</span>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default MultiSearch;
