import { differenceInDays, format, parse } from 'date-fns';
import { useContext } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';

import Button from 'atoms/Button';
import DriverSelectorWithSearch from 'atoms/DriverSearch/DriverSelectorWithSearch';
import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { useModalContext } from 'atoms/Modal';
import VehicleSelectorWithSearch from 'atoms/VehicleSearch/VehicleSelectorWithSearch';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { DaysOfMonthDropdown, DaysOfWeekDropdown, ReportScheduleDropdown } from 'components/Reporting/Dropdowns';
import MultipleFleetSelectorWithSearch from 'components/User/UserList/MultipleFleetSelectorWithSearch';
import { CreateReportDoc, ReportPeriod, ReportSchedule, ReportType, WeeklyReport } from 'generated/graphql';
import { Result, SelectOption } from 'types';

export type ReportEditFormData = Result<typeof CreateReportDoc>;

const maximumDateRangeErrorMsg = 'The maximum date range for Driver Summary reports is 30 days';
const vehicleOrDriverRequiredErrorMsg = 'Either Vehicle or Driver is required when report type is trip detailed';

interface CreateReportFormProps {
  onSubmit: (report: ReportEditFormData) => void;
  onCancel?: () => void;
  hideButtons?: boolean;
}

const CreateReportForm = ({ onSubmit, hideButtons, onCancel }: CreateReportFormProps) => {
  const i18nContext = useContext(I18nContext);
  const currentFleetId = useCurrentFleetId();

  const reportTypeOptions: SelectOption<ReportType>[] = i18nContext
    ? [
        {
          value: ReportType.Vehicle,
          label: i18nContext.commonTranslations.enums.reportTypeDescriptionMap[ReportType.Vehicle],
        },
        {
          value: ReportType.Driver,
          label: i18nContext.commonTranslations.enums.reportTypeDescriptionMap[ReportType.Driver],
        },
        {
          value: ReportType.Trip,
          label: i18nContext.commonTranslations.enums.reportTypeDescriptionMap[ReportType.Trip],
        },
        {
          value: ReportType.TripDetailed,
          label: i18nContext.commonTranslations.enums.reportTypeDescriptionMap[ReportType.TripDetailed],
        },
      ]
    : [];

  const reportPeriodOptions: SelectOption<ReportPeriod | 'custom'>[] = i18nContext
    ? [
        {
          value: ReportPeriod.P24h,
          label: i18nContext.commonTranslations.enums.reportPeriodDescriptionMap[ReportPeriod.P24h],
        },
        {
          value: ReportPeriod.P7d,
          label: i18nContext.commonTranslations.enums.reportPeriodDescriptionMap[ReportPeriod.P7d],
        },
        {
          value: ReportPeriod.P30d,
          label: i18nContext.commonTranslations.enums.reportPeriodDescriptionMap[ReportPeriod.P30d],
        },
        { value: 'custom', label: i18nContext.commonTranslations.enums.reportPeriodDescriptionMap['custom'] },
      ]
    : [];

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
  } = useForm<ReportEditFormData>({
    defaultValues: {
      type: ReportType.Vehicle,
      schedule: ReportSchedule.Once,
      dateRange: ReportPeriod.P7d,
      fleetIds: [currentFleetId],
    },
  });

  const validateAndSubmit = () => {
    validate();
    handleSubmit(onSubmit)();
  };

  const { getProps: getReportTypeProps } = useDropdownSelect(reportTypeOptions, {
    initialItem: reportTypeOptions.find((option) => option.value === watch('type'))!,
    onSelect: (item: SelectOption) => {
      setValue('type', item.value as ReportType);
      validate();
    },
  });

  const { getProps: getReportPeriodProps } = useDropdownSelect([...reportPeriodOptions], {
    initialItem: reportPeriodOptions.find((option) => option.value === (watch('dateRange') ?? 'custom'))!,
    onSelect: (item: SelectOption) => {
      if (item.value !== 'custom') {
        setValue('dateRange', item.value as ReportPeriod);
        setValue('dateFrom', undefined);
        setValue('dateTo', undefined);
      } else {
        setValue('dateRange', undefined);
        setValue('schedule', ReportSchedule.Once);
        setValue('dayOfMonth', undefined);
        setValue('dayOfWeek', undefined);
      }
      validate();
    },
  });

  const getTomorrowDate = (today: string) => {
    let tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.toISOString();
    return format(tomorrow, 'yyyy-MM-dd');
  };

  const modalContext = useModalContext();

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      forms: {
        buttons: { cancel_text, save_text },
      },
      enums: { reportPeriodDescriptionMap, reportScheduleDescriptionMap },
      domain: {
        report: {
          fields: {
            dateTo_text,
            dateFrom_text,
            dayOfMonth_text,
            dayOfWeek_text,
            schedule_text,
            type_text,
            dateRange_text,
          },
        },
        fleet: { fleet_text },
        vehicle: { vehicle_text },
        driver: { driver_text },
      },
    },
  } = i18nContext;

  const validate = () => {
    clearErrors();
    const values = getValues();

    if (!values['fleetIds'].length) setValue('fleetIds', [currentFleetId]);

    if (!values['type']) {
      setError('type', {
        message: tSafe('components.Reporting.ReportingToolbar.CreateReportForm.validate.report-type-required', {
          defaultValue: '{{type_text}} is required',
          type_text,
        }),
      });
    }

    if (!values['dateRange']) {
      if (!values['dateFrom']) {
        setError('dateFrom', {
          message: tSafe('components.Reporting.ReportingToolbar.CreateReportForm.validate.from-date-required', {
            defaultValue: '{{dateFrom_text}} is required when date range is {{customDatesText}}',
            customDatesText: reportPeriodDescriptionMap['custom'],
            dateFrom_text,
          }),
        });
      }

      if (values['type'] !== ReportType.TripDetailed && !values['dateTo']) {
        setError('dateTo', {
          message: tSafe('components.Reporting.ReportingToolbar.CreateReportForm.validate.to-date-required', {
            defaultValue: '{{dateTo_text}} is required when date range is {{customDatesText}}',
            customDatesText: reportPeriodDescriptionMap['custom'],
            dateTo_text,
          }),
        });
      }

      if (values['schedule'] !== ReportSchedule.Once) {
        setError('schedule', { message: 'When using custom date range the report can only be set to run once' });
      }
    }

    if (
      values['type'] === ReportType.Driver &&
      values['dateFrom'] &&
      values['dateTo'] &&
      differenceInDays(
        parse(values['dateTo']!, 'yyyy-MM-dd', new Date()),
        parse(values['dateFrom']!, 'yyyy-MM-dd', new Date()),
      ) > 30
    ) {
      setError('dateFrom', { message: maximumDateRangeErrorMsg });
    }

    if (values['type'] === ReportType.TripDetailed && !values['vehicleId'] && !values['driverId']) {
      setError('vehicleId', { message: vehicleOrDriverRequiredErrorMsg });
      setError('driverId', { message: vehicleOrDriverRequiredErrorMsg });
    }

    if (values['schedule'] === ReportSchedule.Weekly && !values['dayOfWeek']) {
      setError('dayOfWeek', {
        message: tSafe(
          'components.Reporting.ReportingToolbar.CreateReportForm.validate.day-of-week-required-when-weekly',
          {
            defaultValue: 'Day of Week is required when schedule is weekly',
          },
        ),
      });
    }

    if (values['schedule'] === ReportSchedule.Monthly && !values['dayOfMonth'])
      setError('dayOfMonth', {
        message: tSafe(
          'components.Reporting.ReportingToolbar.CreateReportForm.validate.day-of-month-required-when-monthly',
          {
            defaultValue: 'Day of Month is required when schedule is monthly',
          },
        ),
      });

    if (values['type'] === ReportType.TripDetailed && values['dateFrom']) {
      setValue('dateTo', getTomorrowDate(values['dateFrom']));
    }
  };

  return (
    <div className="flex flex-col w-full text-md rounded-8">
      <section about="Report Details">
        <form onSubmit={(e) => e.preventDefault()} className="space-y-2 items-start my-2 p-1">
          <div className="flex flex-col">
            <label className="font-bold">
              <span>{type_text}</span>

              <span className="ml-0.5 text-error font-bold">*</span>
            </label>

            <DropdownSelect {...getReportTypeProps()} className="!w-full text-lg" />
          </div>

          <div className="flex flex-col">
            <label className="font-bold">
              <span>{dateRange_text}</span>

              <span className="ml-0.5 text-error font-bold">*</span>
            </label>

            <DropdownSelect {...getReportPeriodProps()} className="!w-full text-lg" />
          </div>

          {!watch('dateRange') && (
            <>
              <div className="flex flex-col">
                <label className="font-bold">
                  <span>{dateFrom_text}</span>

                  <span className="ml-0.5 text-error font-bold">*</span>
                </label>

                {errors?.dateFrom && <div className="text-error text-sm">{errors.dateFrom.message}</div>}

                <ReactDatePicker
                  value={watch('dateFrom') ?? undefined}
                  selected={watch('dateFrom') ? parse(watch('dateFrom')!, 'yyyy-MM-dd', new Date()) : undefined}
                  className="border-px w-15 rounded-4 border-gray-300 text-md pl-1"
                  onBlur={validate}
                  onChange={(date) => {
                    const formattedString = date ? format(date, 'yyyy-MM-dd') : undefined;

                    if (formattedString) {
                      setValue('dateFrom', formattedString);
                    }

                    validate();

                    return date;
                  }}
                />

                {getValues()['type'] === ReportType.TripDetailed && (
                  <div className="text-sm">
                    {tSafe('components.Reporting.ReportingToolbar.CreateReportForm.detailed-trip-runs-24-hours', {
                      defaultValue: 'Detailed trip reports are run for 24 hours',
                    })}
                  </div>
                )}
              </div>

              {getValues('type') === ReportType.TripDetailed && !getValues('dateFrom') ? null : (
                <div className="flex flex-col">
                  <label className="font-bold">
                    <span>{dateTo_text}</span>

                    <span className="ml-0.5 text-error font-bold">*</span>
                  </label>

                  {errors?.dateTo && <div className="text-error text-sm">{errors.dateTo.message}</div>}

                  {getValues()['type'] === ReportType.TripDetailed ? (
                    <>
                      <input
                        disabled
                        className="border-px rounded-4 text-gray-300 border-gray-300 max-w-15 text-md pl-1"
                        value={getValues()['dateTo']?.toString()}
                      />
                    </>
                  ) : (
                    <ReactDatePicker
                      value={watch('dateTo') ?? undefined}
                      selected={watch('dateTo') ? parse(watch('dateTo')!, 'yyyy-MM-dd', new Date()) : undefined}
                      className="border-px w-15 rounded-4 border-gray-300 text-md pl-1"
                      onBlur={validate}
                      onChange={(date) => {
                        const formattedString = date ? format(date, 'yyyy-MM-dd') : undefined;

                        if (formattedString) {
                          setValue('dateTo', formattedString);
                        }

                        validate();

                        return date;
                      }}
                    />
                  )}
                </div>
              )}
            </>
          )}

          {getValues('type') !== ReportType.TripDetailed && (
            <div className="flex flex-col">
              <label className="font-bold">
                <span>{fleet_text}</span>

                <span className="ml-0.5 text-error font-bold">*</span>
              </label>

              {watch('type') !== ReportType.TripDetailed && (
                <div className="my-1">
                  {tSafe('components.Reporting.ReportingToolbar.CreateReportForm.runs-for-current-fleet-by-default', {
                    defaultValue:
                      'This report will run for the currently selected fleet unless a driver or vehicle are optionally specified.',
                  })}
                </div>
              )}

              <MultipleFleetSelectorWithSearch
                selectedFleetId={watch('fleetIds')?.[0]}
                onChange={(fleetIds) => {
                  setValue('fleetIds', fleetIds);
                }}
              />
            </div>
          )}

          {!watch('driverId') && (
            <div className="flex flex-col">
              <label className="font-bold">
                <span>{vehicle_text}</span>

                {watch('type') === ReportType.TripDetailed && <span className="ml-0.5 text-error font-bold">*</span>}
              </label>

              {errors?.vehicleId && <div className="text-error text-sm">{errors.vehicleId.message}</div>}

              <div className="w-1/2">
                <VehicleSelectorWithSearch
                  fleetId={watch('fleetIds')?.[0]}
                  initialVehicleId={watch('vehicleId') ?? undefined}
                  onSelect={(id) => {
                    setValue('vehicleId', id);
                    validate();
                  }}
                  onDeselect={() => {
                    setValue('vehicleId', undefined);
                    validate();
                  }}
                />
              </div>
            </div>
          )}

          {!watch('vehicleId') && (
            <div className="flex flex-col">
              <label className="font-bold">
                <span>{driver_text}</span>

                {watch('type') === ReportType.TripDetailed && <span className="ml-0.5 text-error font-bold">*</span>}
              </label>

              {errors?.driverId && <div className="text-error text-sm">{errors.driverId.message}</div>}

              <div className="w-1/2">
                <DriverSelectorWithSearch
                  fleetId={watch('fleetIds')?.[0]}
                  initialDriverId={watch('driverId') ?? undefined}
                  onSelect={(id) => {
                    setValue('driverId', id);
                    validate();
                  }}
                  onDeselect={() => {
                    setValue('driverId', undefined);
                    validate();
                  }}
                />
              </div>
            </div>
          )}

          <div className="flex flex-col">
            <label className="font-bold">
              <span>{schedule_text}</span>

              <span className="ml-0.5 text-error font-bold">*</span>
            </label>

            {errors?.schedule && <div className="text-error text-sm">{errors.schedule.message}</div>}

            {watch('dateRange') ? (
              <ReportScheduleDropdown
                currentValue={watch('schedule')}
                onSelect={(item) => {
                  setValue('schedule', item.value as ReportSchedule);
                  if (item.value !== ReportSchedule.Monthly) {
                    setValue('dayOfMonth', undefined);
                  }
                  if (item.value !== ReportSchedule.Weekly) {
                    setValue('dayOfWeek', undefined);
                  }
                  validate();
                }}
              />
            ) : (
              <div>
                <span className="font-bold">{reportScheduleDescriptionMap[ReportSchedule.Once]}</span>

                <span>
                  -
                  {tSafe('components.Reporting.ReportingToolbar.CreateReportForm.custom-date-range-runs-once', {
                    defaultValue: 'reports with a custom date range can only be configured to run once',
                  })}
                </span>
              </div>
            )}
          </div>

          {watch('schedule') === ReportSchedule.Weekly && (
            <div className="flex flex-col">
              <label className="font-bold">
                <span>{dayOfWeek_text}</span>

                <span className="ml-0.5 text-error font-bold">*</span>
              </label>

              {errors?.dayOfWeek && <div className="text-error text-sm">{errors.dayOfWeek.message}</div>}

              <DaysOfWeekDropdown
                currentValue={watch('dayOfWeek')}
                onSelect={(item) => {
                  setValue('dayOfWeek', item.value as WeeklyReport);
                  validate();
                }}
              />
            </div>
          )}

          {watch('schedule') === ReportSchedule.Monthly && (
            <div className="flex flex-col">
              <label className="font-bold">
                <span>{dayOfMonth_text}</span>

                <span className="ml-0.5 text-error font-bold">*</span>
              </label>

              {errors?.dayOfMonth && <div className="text-error text-sm">{errors.dayOfMonth.message}</div>}

              <DaysOfMonthDropdown
                currentValue={watch('dayOfMonth')}
                onSelect={(item) => {
                  setValue('dayOfMonth', parseInt(item.value));
                  validate();
                }}
              />
            </div>
          )}
        </form>

        {!hideButtons && (
          <div className="flex gap-1 justify-center my-3 w-full">
            <Button
              className="px-2 py-0.5 border-px hover:bg-gray-100 rounded-4"
              onClick={() => {
                modalContext?.closeModal?.();
                onCancel?.();
              }}
            >
              {cancel_text}
            </Button>

            <Button
              className="px-2 py-0.5 text-success border-px hover:bg-gray-100 rounded-4"
              onClick={validateAndSubmit}
            >
              {save_text}
            </Button>
          </div>
        )}
      </section>
    </div>
  );
};

export default CreateReportForm;
