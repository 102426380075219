import { format, parseISO } from 'date-fns';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { LicencePlate } from 'atoms/LicencePlate';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetGeofenceNotificationsDoc } from 'generated/graphql';
import { Result } from 'types';

interface GeofenceBreachListItemProps {
  geofenceNotification: Result<typeof GetGeofenceNotificationsDoc>[0];
}

const GeofenceBreachListItem = ({ geofenceNotification }: GeofenceBreachListItemProps) => {
  const i18nContext = useContext(I18nContext);
  const { time, type, driver, vehicle } = geofenceNotification;
  const currentFleetId = useCurrentFleetId();
  const timeAsDate = parseISO(time);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="flex flex-col p-0.5 ">
      <div className="flex">
        <div className="flex flex-col w-1/2 space-y-[2px]">
          {driver && (
            <div>
              <Link className="ml-0.5" to={`/${currentFleetId}/drivers/${driver.id}`}>
                <span className="font-bold hover:underline p-0.5">{driver.name}</span>
              </Link>
            </div>
          )}

          <div className="flex">
            <Link className="ml-1 ui-button-dark" to={`/${currentFleetId}/vehicles/${vehicle.id}`}>
              <LicencePlate licenceNumber={vehicle.licencePlate} textClass="text-sm" />
            </Link>
          </div>
        </div>

        <div className="flex flex-col w-1/2">
          <div>{`${format(timeAsDate, 'eee d MMM')} - ${format(timeAsDate, "h:mm aaaaa'm'")}`}</div>

          <div>
            <span className="font-bold">{type}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeofenceBreachListItem;
