import { useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { periodStartToDate } from 'common/periodHelpers';
import { selectedGeofenceState } from 'components/Live/state';
import { GetGeofenceNotificationsDoc, Period } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

export const useGeofenceBreachNotifications = () => {
  const selectedGeofence = useRecoilValue(selectedGeofenceState);
  const now = useRef(new Date()).current;
  const fromTime = periodStartToDate(Period.P90d, now)?.toISOString();

  return useQ(GetGeofenceNotificationsDoc, {
    skip: !selectedGeofence || !selectedGeofence?.id,
    variables: { geofenceId: selectedGeofence?.id!, fromTime, toTime: now.toISOString() },
  });
};
