import { useContext } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import Modal from 'atoms/Modal';
import { I18nContext } from 'common/useT';
import BulkUpload from 'components/BulkUpload';
import { bulkUploadState, validUploadDataState } from 'components/BulkUpload/state';
import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';

const BulkUploadToolbarItem = () => {
  const i18nContext = useContext(I18nContext);
  const [focusedFleetTreeFleetId, setFocusedFleetTreeFleetId] = useRecoilState(focusedFleetTreeFleetIdState);
  const resetBulkUploadState = useResetRecoilState(bulkUploadState);
  const resetData = useResetRecoilState(validUploadDataState);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="hover:bg-gray-300">
      <Modal
        trigger={
          <div
            onClick={() => {
              resetBulkUploadState();
              resetData();
            }}
            className="flex-center gap-x-0.5"
          >
            <IonIcon name="cloudUploadOutline" />

            <span>
              {tSafe('components.Fleet.FleetList.Toolbar.BulkUploadToolbarItem.bulk-upload', {
                defaultValue: 'Bulk Upload',
              })}
            </span>
          </div>
        }
        contentClassName="w-auto"
      >
        <BulkUpload fleetId={focusedFleetTreeFleetId!} onCompleted={() => setFocusedFleetTreeFleetId(undefined)} />
      </Modal>
    </div>
  );
};

export default BulkUploadToolbarItem;
