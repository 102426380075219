import { useContext } from 'react';
import { useRecoilState } from 'recoil';

import IonIcon from 'atoms/IonIcon';
import Modal from 'atoms/Modal';
import { I18nContext } from 'common/useT';
import BulkExport from 'components/BulkExport';
import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';

const BulkExportToolbarItem = () => {
  const i18nContext = useContext(I18nContext);
  const [focusedFleetTreeFleetId] = useRecoilState(focusedFleetTreeFleetIdState);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="hover:bg-gray-300">
      <Modal
        contentClassName="min-w-20"
        title={tSafe('components.Fleet.FleetList.Toolbar.BulkExportToolbarItem.export-fleet-data', {
          defaultValue: 'Export Fleet Data',
        })}
        trigger={
          <div className="flex-center gap-x-0.5">
            <IonIcon name="downloadOutline" />

            <span>
              {tSafe('components.Fleet.FleetList.Toolbar.BulkExportToolbarItem.bulk-export', {
                defaultValue: 'Bulk Export',
              })}
            </span>
          </div>
        }
      >
        <BulkExport fleetId={focusedFleetTreeFleetId!} />
      </Modal>
    </div>
  );
};

export default BulkExportToolbarItem;
