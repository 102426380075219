import { OrgChart } from 'd3-org-chart';
import { createContext, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useRecoilState } from 'recoil';
import useResizeObserver from 'use-resize-observer';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { FleetHierarchyMode } from 'components/Fleet/FleetList';
import FleetTreeNode from 'components/Fleet/FleetList/FleetTreeNode';
import { focusedFleetTreeFleetIdState } from 'components/Fleet/FleetList/state';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import useAccessibleFleetMap, { useTopLevelFleetIds } from 'components/User/useAccessibleFleetMap';
import { cx, entries, usePrevious, values } from 'utils';

import FleetSearch from '../../../atoms/FleetSearch';

type FleetTreeState = { isExpanded: boolean; onToggle?: () => void; domNode: HTMLElement };
const FleetTreeContext = createContext<Record<string, FleetTreeState>>({});
/** @package */
export const useFleetTreeContext = () => useContext(FleetTreeContext);

interface OrgChartProps {
  mode: FleetHierarchyMode;
  onFleetSelect?: (fleetId: string) => void;
}

const FleetTree = ({ mode, onFleetSelect }: OrgChartProps) => {
  const i18nContext = useContext(I18nContext);
  const d3Container = useRef<HTMLDivElement>(null);
  const fakeNode = useRef<HTMLDivElement>(null);
  const chart = useRef<OrgChart<{}>>(new OrgChart());
  const innerChartRef = useRef<HTMLElement | null>(null);
  const innerChartDimensions = useResizeObserver({ ref: innerChartRef });
  const [hasBeenFitted, setHasBeenFitted] = useState(false);

  const fleetMap = useAccessibleFleetMap();
  const [focusedFleetTreeFleetId, setFocusedFleetTreeFleetId] = useRecoilState(focusedFleetTreeFleetIdState);
  const prevFleetMap = usePrevious(fleetMap);
  const topLevelFleetIds = useTopLevelFleetIds();

  const [treeState, setTreeState] = useState<Record<string, FleetTreeState>>({});
  const [isExpanded, setIsExpanded] = useState(false);
  const currentFleetId = useCurrentFleetId();

  const initExpansionLevel = topLevelFleetIds.length > 1 ? 0 : 2;

  const getChildrenIdsToLevel = useCallback(
    (id: string, level: number, acc: string[] = [], depth = 1) => {
      if (depth <= level) {
        const childrenIds = fleetMap[id]!.childrenIds;
        acc.push(...childrenIds);
        childrenIds.forEach((childId) => getChildrenIdsToLevel(childId, level, acc, depth + 1));
      }

      return acc;
    },
    [fleetMap],
  );

  useEffect(
    () => setFocusedFleetTreeFleetId(currentFleetId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useLayoutEffect(() => {
    if (mode === FleetHierarchyMode.SELECTION || !focusedFleetTreeFleetId) return;
    if (innerChartRef.current && !treeState[focusedFleetTreeFleetId]) {
      topLevelFleetIds
        .flatMap((id) => getChildrenIdsToLevel(id, 5))
        .forEach((id) => chart.current.setExpanded(id, false));

      chart.current.setExpanded(focusedFleetTreeFleetId, true);
      chart.current.fit().render();
    }
  }, [mode, focusedFleetTreeFleetId, getChildrenIdsToLevel, setFocusedFleetTreeFleetId, topLevelFleetIds, treeState]);

  useLayoutEffect(() => {
    if (!innerChartRef.current || hasBeenFitted) return;

    const { height, width } = innerChartRef.current.getBoundingClientRect();

    if (height > d3Container.current!.offsetHeight - 150 || width > d3Container.current!.offsetWidth - 150) {
      chart.current.fit({ animate: false });
    }

    chart.current.setActiveNodeCentered(true).duration(750);

    // waiting for next tick to hide intermediate paint
    setTimeout(() => setHasBeenFitted(true), 0);
  }, [hasBeenFitted, innerChartDimensions.height, innerChartDimensions.width]);

  useLayoutEffect(() => {
    if (!d3Container.current || !chart.current.firstDraw() || !fakeNode.current) return;

    let treeStateAccumulator: ({ id: string } & FleetTreeState)[] = [];

    const root = topLevelFleetIds.length > 1 ? 'root' : null;

    const nodes = values(fleetMap).map((x) => ({ id: x?.id, parentId: x?.parentId ?? root }));

    if (topLevelFleetIds.length > 1) {
      nodes.push({ id: 'root', parentId: null });
    }

    chart.current
      .duration(0)
      .container(d3Container.current as unknown as string)
      .data(nodes)
      .compact(true)
      .nodeWidth(() => fakeNode.current?.offsetWidth ?? 0)
      .nodeHeight(() => fakeNode.current?.offsetHeight ?? 0)
      .svgHeight(d3Container.current.offsetHeight)
      .svgWidth(d3Container.current.offsetWidth)
      .setActiveNodeCentered(false)
      .nodeContent(() => `<div class="content" />`)
      .nodeUpdate((node, i, nodes) => {
        if (!node.id) return;

        if (i === 0) {
          treeStateAccumulator = [];
        }

        treeStateAccumulator.push({ id: node.id, isExpanded: !!node.children?.length, domNode: null as any });

        if (i !== nodes.length - 1) return;

        (nodes as unknown as HTMLElement[]).forEach((node, i) => {
          const toggleElement = node.querySelector('.toggle') as HTMLDivElement;
          treeStateAccumulator[i].onToggle = () => toggleElement?.click();
          treeStateAccumulator[i].domNode = node.querySelector('.content') as HTMLDivElement;
        });

        setTreeState(
          treeStateAccumulator.reduce((acc, { id, ...state }) => {
            acc[id] = state;
            return acc;
          }, {} as Record<string, FleetTreeState>),
        );
      })
      .buttonContent(({ node }) =>
        node.id === 'root'
          ? '<div class="w-full h-full flex-center"><div class="rounded-full bg-primary h-[25px] w-[25px]"></div></div>'
          : '<div class="toggle" />',
      )
      .initialZoom(0.8)
      .render();

    topLevelFleetIds
      .flatMap((id) => getChildrenIdsToLevel(id, initExpansionLevel))
      .forEach((id) => chart.current.setExpanded(id, true));

    chart.current.render();

    innerChartRef.current = d3Container.current.querySelector('.chart');
  }, [fleetMap, getChildrenIdsToLevel, initExpansionLevel, mode, setTreeState, topLevelFleetIds]);

  useLayoutEffect(() => {
    if (!chart.current || chart.current.firstDraw()) return;

    const prevFleets = values(prevFleetMap);
    const fleets = values(fleetMap);

    if (prevFleets.length < fleets.length) {
      const addedFleet = fleets.find((fleet) => !prevFleetMap[fleet?.id ?? '']);

      if (addedFleet) {
        chart.current
          .addNode({ id: addedFleet.id, parentId: addedFleet.parentId })
          .setExpanded(addedFleet.id, true)
          .render();
      }
    }

    if (prevFleets.length > fleets.length) {
      const deletedFleet = prevFleets.find((fleet) => !fleetMap[fleet?.id ?? '']);

      if (deletedFleet) {
        chart.current.removeNode(deletedFleet.id);
      }
    }
  }, [fleetMap, prevFleetMap, treeState]);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className={cx('relative h-full', !hasBeenFitted && '[visibility:hidden]')}>
      <div ref={d3Container} onClick={() => setFocusedFleetTreeFleetId(undefined)} className="h-full" />

      <FleetSearch className="absolute top-0 justify-center" onFleetSelect={onFleetSelect} />

      <FleetTreeContext.Provider value={treeState}>
        {entries(treeState).map(([id, state]) =>
          createPortal(
            id === 'root' ? null : <FleetTreeNode id={id} onFleetSelect={onFleetSelect} mode={mode} />,
            state.domNode,
          ),
        )}
      </FleetTreeContext.Provider>

      <span className="absolute top-1 right-1 flex-center flex-col">
        <Button
          className="p-1"
          onClick={() => {
            setIsExpanded(!isExpanded);
            chart.current[isExpanded ? 'collapseAll' : 'expandAll']();
          }}
        >
          <IonIcon name={isExpanded ? 'contractOutline' : 'expandOutline'} className="text-xl" />
        </Button>

        <Button className="p-1" onClick={() => chart.current.fit()}>
          {tSafe('components.Fleet.FleetList.FleetTree.fit-to-screen', { defaultValue: 'Fit' })}
        </Button>
      </span>

      <span ref={fakeNode} className="fixed [visibility:hidden]">
        <FleetTreeNode id={topLevelFleetIds[0]} mode={mode} />
      </span>
    </div>
  );
};

export default FleetTree;
