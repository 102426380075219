import { useContext } from 'react';

import { InfoPanel } from 'atoms/InfoPanel';
import { InfoPanelItem } from 'atoms/InfoPanel/InfoPanelItem';
import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { GetTripDoc } from 'generated/graphql';
import { formatTime, kmToMi } from 'utils';
import { useQ } from 'utils/apolloClient';

interface MiddleTripInfoPanelProps {
  tripId: string;
}

export const MiddleTripInfoPanel = ({ tripId }: MiddleTripInfoPanelProps) => {
  const i18nContext = useContext(I18nContext);
  const { distanceInMiles, idleTimeAsPercentage } = useSettings();
  const {
    loading,
    error,
    data: trip,
  } = useQ(GetTripDoc, {
    variables: {
      tripId: tripId!,
    },
    fetchPolicy: 'cache-only',
  });

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { unavailable_text },
      errors: { failed_to_fetch_data_text },
      uom: { mi_text, km_text, mph_text, kph_text },
    },
  } = i18nContext;

  if (error) return <div>{failed_to_fetch_data_text}</div>;
  if (loading) return null;
  if (!trip) return null;

  const { avgSpeed, duration, endOdo, idleTime, fuelConsumption, startOdo } = trip;

  const distanceTravelledMetres = startOdo && endOdo ? endOdo - startOdo : undefined;
  const distanceTravelledKm = distanceTravelledMetres ? distanceTravelledMetres / 1000 : undefined;
  const distanceTravelledConverted =
    distanceTravelledKm && distanceInMiles ? kmToMi(distanceTravelledKm) : distanceTravelledKm;
  const distanceTravelledRounded = distanceTravelledConverted ? Math.round(distanceTravelledConverted) : undefined;
  const distanceUnitOfMeasure = distanceInMiles ? mi_text : km_text;

  const avgSpeedConverted = avgSpeed && distanceInMiles ? kmToMi(avgSpeed) : avgSpeed;
  const avgSpeedRounded = avgSpeedConverted ? Math.round(avgSpeedConverted) : undefined;
  const speedUnitOfMeasure = distanceInMiles ? mph_text : kph_text;

  const idlingTimeOrPercentage =
    idleTimeAsPercentage && idleTime && duration ? Math.round((idleTime / duration) * 100) : idleTime;

  const avgSpeedText = tSafe(
    'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripInfoPanel.MiddleTripInfoPanel.avgSpeed',
    {
      defaultValue: 'Average Speed',
    },
  );
  const distanceTravelledText = tSafe(
    'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripInfoPanel.MiddleTripInfoPanel.distTravelled',
    {
      defaultValue: 'Distance Travelled',
    },
  );
  const tripDurationText = tSafe(
    'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripInfoPanel.MiddleTripInfoPanel.tripDuration',
    {
      defaultValue: 'Trip Duration',
    },
  );
  const idleTimeText = tSafe(
    'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripInfoPanel.MiddleTripInfoPanel.idleTime',
    {
      defaultValue: 'Idle Time',
    },
  );
  const fuelConsumptionText = tSafe(
    'components.ActivityHistoryList.EventTypes.Details.TripEventDetail.TripInfoPanel.MiddleTripInfoPanel.fuelConsumption',
    {
      defaultValue: 'Fuel Consumption',
    },
  );

  return (
    <InfoPanel>
      <InfoPanelItem
        description={avgSpeedText}
        value={avgSpeedRounded ?? unavailable_text}
        unitOfMeasure={avgSpeedRounded ? speedUnitOfMeasure : undefined}
      />

      <InfoPanelItem
        description={distanceTravelledText}
        value={distanceTravelledRounded ?? unavailable_text}
        unitOfMeasure={distanceTravelledRounded ? distanceUnitOfMeasure : undefined}
      />

      <InfoPanelItem description={tripDurationText} value={duration ? formatTime(duration) : unavailable_text} />

      <InfoPanelItem
        description={idleTimeText}
        value={idlingTimeOrPercentage ?? unavailable_text}
        unitOfMeasure={idlingTimeOrPercentage && idleTimeAsPercentage ? '%' : 'seconds'}
      />

      <InfoPanelItem
        description={fuelConsumptionText}
        value={fuelConsumption ?? unavailable_text}
        unitOfMeasure={fuelConsumption ? 'litres' : undefined}
      />
    </InfoPanel>
  );
};
