import { Layouts } from 'react-grid-layout';

import useWidgetMap from 'components/Dashboard/widgetMap';
import { GridBreakpoints, SerializedGridLayout } from 'types/gridLayout';
import { DashboardWidgetSettings } from 'types/settings';
import { DashboardWidget } from 'types/widgets';

export const serializedLayoutToLayout = (widgetSettings: DashboardWidgetSettings) =>
  Object.entries(widgetSettings).reduce((acc, [key, { layout }]) => {
    Object.entries(layout).forEach(([breakpoint, area]) => {
      acc[breakpoint] ??= [];

      if (!useWidgetMap[key as DashboardWidget]) return;

      const { component: _, ...defaultLayout } = useWidgetMap[key as DashboardWidget];

      acc[breakpoint].push({ i: key, ...defaultLayout, ...area! });
    });

    return acc;
  }, {} as Layouts);

export const layoutToSerializedLayout = (layout: Layouts) =>
  Object.entries(layout).reduce((acc, [breakpoint, layout]) => {
    layout.forEach((widget) => {
      acc[widget.i] ??= {} as SerializedGridLayout[string];
      acc[widget.i][breakpoint as GridBreakpoints] = { x: widget.x, y: widget.y, w: widget.w, h: widget.h };
    });

    return acc;
  }, {} as SerializedGridLayout);
