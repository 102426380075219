import EngineIcon from 'atoms/Icon/dashboardLightIcons/EngineIcon';
import IonIcon from 'atoms/IonIcon';
import { defaultPropsWrapper } from 'utils';

export { ReactComponent as abBlueStatusSolid } from './abBlueStatusSolid.svg';
export { ReactComponent as absStatusSolid } from './absStatusSolid.svg';
export { ReactComponent as adbluStatus } from './adbluStatus.svg';
export { ReactComponent as brakePadStatus } from './brakePadStatus.svg';
export { ReactComponent as brakePadStatusSolid } from './brakePadStatusSolid.svg';
export { ReactComponent as brakeWarning } from './brakeWarning.svg';
export { ReactComponent as dpfStatus } from './dpfStatus.svg';
export { ReactComponent as dpfStatusSolid } from './dpfStatusSolid.svg';
export { ReactComponent as engineCoolantLevel } from './engineCoolantLevel.svg';
export { ReactComponent as engineCoolantTemp } from './engineCoolantTemp.svg';
export { ReactComponent as engineCoolantTempSolid } from './engineCoolantTempSolid.svg';
export { ReactComponent as engineGlowplugStatus } from './engineGlowplugStatus.svg';
export { ReactComponent as engineGlowplugStatusSolid } from './engineGlowplugStatusSolid.svg';
export { ReactComponent as engineOilLevel } from './engineOilLevel.svg';
export { ReactComponent as engineOilLevelSolid } from './engineOilLevelSolid.svg';
export { ReactComponent as engineOilPressure } from './engineOilPressure.svg';
export { ReactComponent as engineOilPressureSolid } from './engineOilPressureSolid.svg';
export { ReactComponent as espStatus } from './espStatus.svg';
export { ReactComponent as espStatusSolid } from './espStatusSolid.svg';
export { ReactComponent as serviceReminder } from './serviceReminder.svg';
export { ReactComponent as srsStatus } from './srsStatus.svg';
export { ReactComponent as srsStatusSolid } from './srsStatusSolid.svg';
export { ReactComponent as tpmsStatus } from './tpmsStatus.svg';
export { ReactComponent as tpmsStatusSolid } from './tpmsStatusSolid.svg';
export { ReactComponent as washerFluidSolid } from './washerFluidSolid.svg';
export { ReactComponent as washerStatus } from './washerStatus.svg';

export const engine = defaultPropsWrapper(EngineIcon);

export const engineLightning = defaultPropsWrapper(EngineIcon, {
  innerSvg: <IonIcon name="flashOutline" noWrapper />,
});

export const engineInfinity = defaultPropsWrapper(EngineIcon, {
  innerSvg: <IonIcon name="infiniteOutline" noWrapper />,
});
