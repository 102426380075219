import { useMutation } from '@apollo/client';
import { useRecoilValue } from 'recoil';

import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { liveSettingsState, selectedVehicleState } from 'components/Live/state';
import { CreateGeofenceDoc, GeofenceInput, GetFleetGeofencesDoc, GetVehicleGeofencesDoc } from 'generated/graphql';

const useCreateGeofenceMutation = () => {
  const fleetId = useCurrentFleetId();
  const showAllGeofences = useRecoilValue(liveSettingsState).fleetGeofenceDisplayMode === 'all';
  const { vehicleId } = useRecoilValue(selectedVehicleState) ?? {};

  const [create, res] = useMutation(CreateGeofenceDoc);

  const createGeofence = (geofenceInput: GeofenceInput) => {
    const optimisticGeofence = {
      id: '',
      ...geofenceInput,
      fleetIds: !vehicleId ? [fleetId] : [],
      vehicleIds: vehicleId ? [vehicleId] : [],
    };

    create({
      variables: { fleetId, geofence: geofenceInput, vehicleId },
      optimisticResponse: {
        createGeofence: optimisticGeofence,
      },
      update: (cache, { data }) => {
        if (!data?.createGeofence) return;

        if (vehicleId) {
          const geofences = cache.readQuery({
            query: GetVehicleGeofencesDoc,
            variables: { vehicleId: vehicleId },
          })?.getVehicleGeofences;

          cache.writeQuery({
            query: GetVehicleGeofencesDoc,
            variables: { vehicleId: vehicleId },
            data: { getVehicleGeofences: [...(geofences ?? []), data.createGeofence] },
          });
        } else {
          const geofences = cache.readQuery({
            query: GetFleetGeofencesDoc,
            variables: { fleetId, withSubfleets: showAllGeofences, withParentFleets: showAllGeofences },
          })?.getFleetGeofences;

          cache.writeQuery({
            query: GetFleetGeofencesDoc,
            variables: { fleetId, withSubfleets: showAllGeofences, withParentFleets: showAllGeofences },
            data: { getFleetGeofences: [...(geofences ?? []), data.createGeofence] },
          });
        }
      },
    });
  };

  return [createGeofence, res] as [typeof createGeofence, typeof res];
};

export default useCreateGeofenceMutation;
