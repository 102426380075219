import { Maybe } from 'graphql/jsutils/Maybe';
import { useContext } from 'react';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { I18nContext } from 'common/useT';
import { ReportSchedule, WeeklyReport } from 'generated/graphql';
import { SelectOption } from 'types';

const daysOfMonthOptions = Array.from(Array(31).keys()).map((item) => ({
  label: (item + 1).toString(),
  value: (item + 1).toString(),
}));

export const ReportScheduleDropdown = ({
  currentValue,
  onSelect,
  disabled = false,
  className = '!w-full text-lg',
  optionsToDisplay,
}: {
  currentValue?: Maybe<ReportSchedule>;
  onSelect: (item: SelectOption) => void;
  disabled?: boolean;
  className?: string;
  optionsToDisplay?: ReportSchedule[];
}) => {
  const i18nContext = useContext(I18nContext);
  const {
    commonTranslations: {
      enums: { reportScheduleDescriptionMap },
    },
  } = i18nContext ?? { commonTranslations: { enums: {} } };

  const reportScheduleOptions: SelectOption<ReportSchedule>[] = reportScheduleDescriptionMap
    ? [
        { value: ReportSchedule.Once, label: reportScheduleDescriptionMap[ReportSchedule.Once] },
        { value: ReportSchedule.Weekly, label: reportScheduleDescriptionMap[ReportSchedule.Weekly] },
        { value: ReportSchedule.Monthly, label: reportScheduleDescriptionMap[ReportSchedule.Monthly] },
      ]
    : [];

  const { getProps } = useDropdownSelect(
    reportScheduleOptions.filter((x) => (optionsToDisplay ? optionsToDisplay.includes(x.value) : true)),
    {
      initialItem: reportScheduleOptions.find((option) => option.value === currentValue)!,
      onSelect,
    },
  );

  return <DropdownSelect {...getProps()} disabled={disabled} className={className} />;
};

export const DaysOfWeekDropdown = ({
  currentValue,
  onSelect,
  className = '!w-full text-lg',
}: {
  currentValue?: Maybe<WeeklyReport>;
  onSelect: (item: SelectOption) => void;
  className?: string;
}) => {
  const i18nContext = useContext(I18nContext);
  const {
    commonTranslations: {
      enums: { daysOfWeekDescriptionMap },
    },
  } = i18nContext ?? { commonTranslations: { enums: {} } };
  const daysOfWeekOptions: SelectOption<WeeklyReport>[] = daysOfWeekDescriptionMap
    ? [
        { value: WeeklyReport.Mon, label: daysOfWeekDescriptionMap[WeeklyReport.Mon] },
        { value: WeeklyReport.Tue, label: daysOfWeekDescriptionMap[WeeklyReport.Tue] },
        { value: WeeklyReport.Wed, label: daysOfWeekDescriptionMap[WeeklyReport.Wed] },
        { value: WeeklyReport.Thu, label: daysOfWeekDescriptionMap[WeeklyReport.Thu] },
        { value: WeeklyReport.Fri, label: daysOfWeekDescriptionMap[WeeklyReport.Fri] },
        { value: WeeklyReport.Sat, label: daysOfWeekDescriptionMap[WeeklyReport.Sat] },
        { value: WeeklyReport.Sun, label: daysOfWeekDescriptionMap[WeeklyReport.Sun] },
      ]
    : [];

  const { getProps } = useDropdownSelect(daysOfWeekOptions, {
    initialItem: daysOfWeekOptions.find((type) => type.value === currentValue)!,
    onSelect,
  });

  return <DropdownSelect {...getProps()} className={className} />;
};

export const DaysOfMonthDropdown = ({
  currentValue,
  onSelect,
  className = '!w-full text-lg',
}: {
  currentValue?: Maybe<number>;
  onSelect: (item: SelectOption) => void;
  className?: string;
}) => {
  const { getProps } = useDropdownSelect(daysOfMonthOptions, {
    initialItem: daysOfMonthOptions.find((type) => parseInt(type.value) === currentValue)!,
    onSelect,
  });

  return <DropdownSelect {...getProps()} className={className} />;
};
