import { useMutation } from '@apollo/client';
import { useContext } from 'react';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import SettingGroup from 'components/Settings/SettingGroup';
import UserDetailsEditableLabel from 'components/Settings/UserDetailsEditableLabel';
import useUser from 'components/User/useUser';
import { SendPasswordResetEmailDoc } from 'generated/graphql';
import { successToast } from 'utils/toasts';

const UserDetails = () => {
  const i18nContext = useContext(I18nContext);
  const user = useUser();

  const [resetPassword] = useMutation(SendPasswordResetEmailDoc, {
    variables: { userId: user.id },
    onCompleted: () =>
      successToast(
        tSafe('components.Settings.UserDetails.toast-email-sent', { defaultValue: 'The email has been sent' }),
      ),
  });

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      domain: {
        user: {
          fields: { email_text, name_text, phoneNumber_text, role_text },
        },
        driver: { driver_text },
      },
    },
  } = i18nContext;

  return (
    <SettingGroup
      title={tSafe('components.Settings.UserDetails.user-details', { defaultValue: 'User Details' })}
      className="space-y-2"
    >
      <div className="grid grid-cols-[auto,1fr] gap-y-1 gap-x-2 items-center">
        <span>{name_text}:</span>

        <UserDetailsEditableLabel field="name" />

        <span>{email_text}:</span>

        <UserDetailsEditableLabel field="email" />

        <span>{phoneNumber_text}:</span>

        <UserDetailsEditableLabel field="phoneNumber" />

        <span>{role_text}:</span>

        <span className="px-1 py-0.5">{user.role ?? driver_text}</span>
      </div>

      <div className="flex-center">
        <Button
          className="flex-center gap-1 p-1 border border-amber rounded-4 text-amber"
          onClick={() => resetPassword()}
        >
          <IonIcon name="syncOutline" className="text-md" />

          {tSafe('components.Settings.UserDetails.request-password-reset', { defaultValue: 'Request password reset' })}
        </Button>
      </div>
    </SettingGroup>
  );
};

export default UserDetails;
