import { useMemo } from 'react';

import { entries } from 'utils';

const useColumnUniqueValues = <T extends object, K extends string>(
  data: T[],
  columns: [id: K, accessor: (item: T) => string | null | undefined][],
) => {
  return useMemo(() => {
    const setMap = data.reduce((acc, item) => {
      columns.forEach(([id, accessor]) => {
        const value = accessor(item);

        if (value) {
          acc[id] ??= new Set<string>();
          acc[id].add(value);
        }
      });

      return acc;
    }, {} as Record<K, Set<NonNullable<string | null | undefined>>>);

    const arrayMap = entries(setMap).reduce((acc, [key, set]) => {
      acc[key] = Array.from(set.values());
      return acc;
    }, {} as Record<string, NonNullable<string | null | undefined>[]>);

    return arrayMap;
  }, [columns, data]);
};

export default useColumnUniqueValues;
