import { useContext, useEffect, useState } from 'react';

import DropdownSelect, { useDropdownSelect } from 'atoms/DropdownSelect';
import { I18nContext } from 'common/useT';
import { SelectOption } from 'types';
import { ActivityHistoryEventType } from 'types/activityHistory';

interface Props<T extends ActivityHistoryEventType> {
  eventTypes: T[];
  currentlySelectedEventTypes: T[];
  setEventTypeItems: (items: T[]) => void;
}

export const EventTypeFilter = <T extends ActivityHistoryEventType>({
  eventTypes,
  currentlySelectedEventTypes,
  setEventTypeItems,
}: Props<T>) => {
  const i18nContext = useContext(I18nContext);
  const [currentlySelectedLocalItems, setCurrentlySelectedLocalItems] = useState(currentlySelectedEventTypes);

  const {
    commonTranslations: {
      general: { select_all_text },
      enums: { activityHistoryEventTypeDescriptionMap },
      forms: {
        buttons: { clear_text },
      },
    },
  } = i18nContext ?? { commonTranslations: { general: {}, enums: {}, forms: { buttons: {} } } };

  const options = activityHistoryEventTypeDescriptionMap
    ? eventTypes.map((item) => ({
        label: activityHistoryEventTypeDescriptionMap[item],
        value: item,
      }))
    : [];

  const { getProps, setSelectedItems } = useDropdownSelect(options, {
    multiselect: true,
    initialItems: activityHistoryEventTypeDescriptionMap
      ? currentlySelectedLocalItems.map((item) => ({
          label: activityHistoryEventTypeDescriptionMap[item],
          value: item,
        }))
      : [],
    onSelect: (items: SelectOption<T>[]) => setCurrentlySelectedLocalItems(items.map((item) => item.value)),
  });

  useEffect(() => {
    if (activityHistoryEventTypeDescriptionMap) {
      setSelectedItems(
        currentlySelectedLocalItems.map((item) => ({
          label: activityHistoryEventTypeDescriptionMap[item],
          value: item,
        })),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentlySelectedLocalItems, setSelectedItems, activityHistoryEventTypeDescriptionMap]);

  useEffect(() => {
    setEventTypeItems(currentlySelectedLocalItems);
  }, [currentlySelectedLocalItems, setEventTypeItems]);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  return (
    <div className="flex-1 p-1 bg-gray-100 rounded-8">
      <div className="flex flex-col">
        <div className="flex">
          <span className="flex font-bold">
            {tSafe('components.ActivityHistoryList.EventTypeFilter.event-types', { defaultValue: 'Event Types' })}
          </span>

          <div className="flex items-end ml-auto mb-0.5 text-sm cursor-pointer">
            <div className="text-pretty hover:underline" onClick={() => setCurrentlySelectedLocalItems(eventTypes)}>
              {select_all_text}
            </div>
            &nbsp;&nbsp;&#124;&nbsp;&nbsp;
            <div className="hover:underline" onClick={() => setCurrentlySelectedLocalItems([])}>
              {clear_text}
            </div>
          </div>
        </div>

        <DropdownSelect {...getProps()} className="text-md" />
      </div>
    </div>
  );
};
