import Icon from 'atoms/Icon';
import { IconName } from 'atoms/Icon/icons';
import { cx } from 'utils';

interface StatusMetricProps {
  iconName: IconName;
  description: string;
  value: string | number;
  warning?: boolean;
}

const StatusMetric = ({ iconName, description, value, warning }: StatusMetricProps) => {
  return (
    <>
      <div className="flex items-center my-0.5 p-0.5 w-full bg-white border border-gray-300 rounded-8">
        <div className="w-1/4">
          <Icon
            name={iconName}
            className={cx('flex-shrink-0 !w-3', warning ? 'text-error' : 'text-success')}
            aria-label={description}
          />
        </div>

        <div className="justify-center w-1/2 text-center text-sm leading-7 space-y-0.5 break-word">{description}</div>

        <div className={cx('w-1/4 text-center text-md leading-7', warning ? 'text-error' : 'text-success')}>
          {value}
        </div>
      </div>
    </>
  );
};

export default StatusMetric;
