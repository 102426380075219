import { useContext } from 'react';
import ReactPaginate from 'react-paginate';

import { I18nContext } from 'common/useT';

import { useFullPageTableContext } from './FullPageTable';

export interface PageSelectorProps {
  totalCount?: number;
  currentPage?: number;
  onPageChange?: (eselected: number) => void;
  useTablePagination: boolean;
  className?: string;
}

const PageSelector = ({
  totalCount,
  currentPage,
  onPageChange,
  useTablePagination = false,
  className,
}: PageSelectorProps) => {
  const i18nContext = useContext(I18nContext);
  const table = useFullPageTableContext();
  const pageCount = totalCount ? Math.ceil(totalCount / 20) : 0;

  if (!table || !i18nContext) return null;

  const { setPageIndex, getState } = table;

  const handlePageChange = (selectedPage: number) => {
    onPageChange?.(selectedPage);

    if (useTablePagination) setPageIndex(selectedPage);
  };

  return (
    <div className={`flex justify-end bg-gray-100 ml-auto ${className}`}>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        forcePage={useTablePagination ? getState().pagination.pageIndex : currentPage ?? 0}
        onPageChange={(e) => handlePageChange(e.selected)}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        containerClassName="flex my-1 mr-2 text-md"
        disabledClassName="opacity-0"
        activeLinkClassName="bg-gray-300"
        previousLinkClassName="px-1.5 py-1 hover:bg-gray-300"
        nextLinkClassName="px-1.5 py-1 hover:bg-gray-300"
        pageLinkClassName="px-1.5 py-1 hover:bg-gray-300"
        previousLabel="<"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default PageSelector;
