import { useCombobox } from 'downshift';
import { useContext, useMemo, useState } from 'react';

import IonIcon, { IonIconName } from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { SelectOption, ClassName } from 'types';
import { cx } from 'utils';

type MultiselectProps<T> =
  | {
      multiselect: true;
      initialItem?: never;
      initialItems?: SelectOption<T>[];
      onSelect?: (item: SelectOption<T>[]) => void;
    }
  | {
      multiselect?: false;
      initialItem?: SelectOption<T>;
      initialItems?: never;
      onSelect?: (item: SelectOption<T>) => void;
    };

interface DropdownSelectProps {
  buttonIcon?: IonIconName | JSX.Element;
  searchable?: boolean;
  onInputChange?: (value: string) => void;
  onSelectedItemChange?: (value: string) => void;
}

export const useDropdownSelect = <T,>(
  items: SelectOption<T>[] | undefined | null,
  {
    initialItem,
    initialItems = [],
    onSelect,
    buttonIcon = 'chevronDownOutline',
    searchable,
    multiselect,
    onInputChange,
  }: DropdownSelectProps & MultiselectProps<T>,
) => {
  const itemsWithLabels = useMemo(() => items?.filter((item) => item.label) ?? [], [items]);

  // this may be problematic when being called as a hook as the initial value of items may be null / undefined which will result in filteredItems having no length
  const [filteredItems, setFilteredItems] = useState(
    itemsWithLabels.filter((item) => item.label !== initialItem?.label),
  );

  const [selectedItems, setSelectedItems] = useState<SelectOption<T>[]>(initialItems);

  const {
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    isOpen,
    getComboboxProps,
    getInputProps,
    selectedItem,
    setInputValue,
  } = useCombobox({
    items: filteredItems,
    onInputValueChange: ({ inputValue = '' }) => {
      onInputChange?.(inputValue);

      setFilteredItems(
        itemsWithLabels.filter(
          (item) =>
            item.value !== selectedItem?.value &&
            item.label?.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
        ),
      );
    },
    onSelectedItemChange: ({ selectedItem }) => {
      setInputValue('');

      if (!selectedItem) {
        return;
      }

      if (!multiselect) {
        (onSelect as (item: SelectOption<T>) => void)(selectedItem);
        return;
      }

      const alreadyChecked = selectedItems.some((item) => item.value === selectedItem.value);
      const updatedSelectedItems = alreadyChecked
        ? selectedItems.filter((item) => item.value !== selectedItem.value)
        : [...selectedItems, selectedItem];
      setSelectedItems(updatedSelectedItems);
      (onSelect as (item: SelectOption<T>[]) => void)(updatedSelectedItems);
    },
    initialSelectedItem: initialItem,
    onIsOpenChange: () => {
      setInputValue('');
    },
    ...(multiselect ? { selectedItem: null } : {}),
  });

  const publicProps = {
    items: filteredItems,
    isOpen,
  };

  return {
    ...publicProps,
    reset: () => setSelectedItems([]),
    setSelectedItems,
    getProps: () => ({
      ...publicProps,
      selectedItem,
      selectedItems,
      buttonIcon,
      searchable,
      multiselect,
      getItemProps,
      getMenuProps,
      getToggleButtonProps,
      getComboboxProps,
      getInputProps,
    }),
  };
};

// for typing purposes
const useDropdownSelectReturn = () => useDropdownSelect<any>([], {});

const DropdownSelect = ({
  items,
  getItemProps,
  getMenuProps,
  getToggleButtonProps,
  isOpen,
  getComboboxProps,
  getInputProps,
  buttonIcon,
  searchable,
  multiselect,
  selectedItem,
  selectedItems,
  className,
  innerWrapperClassName,
  disabled,
  overrideSelected,
}: ClassName & { innerWrapperClassName?: string } & ReturnType<
    ReturnType<typeof useDropdownSelectReturn>['getProps']
  > & { disabled?: boolean; overrideSelected?: string | null }) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const selectedOption = () => {
    if (multiselect)
      return tSafe('atoms.DropdownSelect.count-selected', {
        count: selectedItems.length,
        defaultValue: '{{count}} item selected',
      });
    if (overrideSelected) return overrideSelected;

    return selectedItem?.label;
  };

  return (
    <div className={`relative w-full ${className}`}>
      <div
        {...(disabled ? {} : getToggleButtonProps())}
        className={cx(
          'flex items-center justify-between px-1 py-[7px] w-full bg-white hover:bg-gray-100 border border-gray-300 rounded-4 focus:outline-none cursor-pointer',
          innerWrapperClassName,
          disabled && 'opacity-50',
          selectedItems.length && 'border-1 border-dark-gray font-bold',
        )}
      >
        <span className="leading-none">{selectedOption()}</span>

        {typeof buttonIcon === 'string' ? <IonIcon name={buttonIcon} /> : buttonIcon}
      </div>

      <div
        className={cx(
          'absolute z-10 left-0 flex flex-col bg-white border border-gray-300 rounded-4 shadow-card overflow-hidden top-[calc(100%+0.5rem)]',
          !isOpen && 'hidden',
        )}
      >
        <div {...getComboboxProps()} className={cx('w-full', !searchable && 'hidden')}>
          <input
            className="m-1 px-1 py-0.5 bg-gray-100 rounded-4 focus:outline-none w-[calc(100%-2rem)]"
            placeholder={tSafe('atoms.DropdownSelect.search', { defaultValue: 'Search' })}
            {...getInputProps({ autoFocus: true })}
          />
        </div>

        <ul {...getMenuProps()} className="max-h-40 overflow-auto">
          {items.map((item, index) =>
            !item.label ? null : (
              <li
                key={item.label}
                className="flex items-center px-2 py-1 hover:text-white hover:bg-hover cursor-pointer"
                {...getItemProps({ item, index })}
              >
                {multiselect && (
                  <input
                    type="checkbox"
                    checked={!!selectedItems.find(({ value }) => item.value === value)}
                    className="mr-1"
                    readOnly
                  />
                )}

                <span>{item.label}</span>
              </li>
            ),
          )}

          {items.length ? null : (
            <li className="py-1 italic flex-center">
              {tSafe('atoms.DropdownSelect.no-options', { defaultValue: 'No options' })}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default DropdownSelect;
