import { format } from 'date-fns';
import { useContext } from 'react';
import { CSVLink } from 'react-csv';

import { CenteredSpinner } from 'atoms/Spinner';
import { I18nContext } from 'common/useT';
import useAccessibleFleetMap from 'components/User/useAccessibleFleetMap';
import { GetBulkExportDataDoc } from 'generated/graphql';
import { useQ } from 'utils/apolloClient';

interface BulkExportProps {
  fleetId: string;
}

type HeaderDataKey =
  | 'vehicleId'
  | 'make'
  | 'model'
  | 'licencePlate'
  | 'fuelType'
  | 'year'
  | 'vin'
  | 'serialNumber'
  | 'driverId'
  | 'driverName'
  | 'email'
  | 'phoneNumber'
  | 'fleetName';

type CsvData = { [key in HeaderDataKey]: string };

const BulkExport = ({ fleetId }: BulkExportProps) => {
  const i18nContext = useContext(I18nContext);
  const { data: exportData, loading } = useQ(GetBulkExportDataDoc, {
    variables: {
      fleetIds: fleetId,
    },
    fetchPolicy: 'no-cache',
  });
  const accessibleFleetMap = useAccessibleFleetMap();

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;
  const selectedFleet = accessibleFleetMap[fleetId];
  const fleetVehicleMetaFields = selectedFleet?.metaFields.vehicle
    ? selectedFleet.metaFields.vehicle?.map((x) => ({ label: x.displayName, key: x.name }))
    : [];
  const fleetDriverMetaFields = selectedFleet?.metaFields.driver
    ? selectedFleet.metaFields.driver?.map((x) => ({ label: x.displayName, key: x.name }))
    : [];

  const headers = [
    { label: 'vehicle ID', key: 'vehicleId' },
    { label: 'licence plate', key: 'licencePlate' },
    { label: 'make', key: 'make' },
    { label: 'model', key: 'model' },
    { label: 'year', key: 'year' },
    { label: 'fuel type', key: 'fuelType' },
    { label: 'VIN', key: 'vin' },
    { label: 'serial number', key: 'serialNumber' },
    ...fleetVehicleMetaFields,
    { label: 'driver ID', key: 'driverId' },
    { label: 'name', key: 'driverName' },
    { label: 'email', key: 'email' },
    { label: 'phone number', key: 'phoneNumber' },
    { label: 'fleet name', key: 'fleetName' },
    ...fleetDriverMetaFields,
  ];

  if (!exportData || loading || !accessibleFleetMap) return <CenteredSpinner />;

  const fileName = `${selectedFleet?.name.replace(/,|\s|\./g, '_') ?? 'Fleet'}_${format(new Date(), 'PPPPp').replace(
    /,|\s/g,
    '_',
  )}`;

  const csvData: CsvData[] = exportData.data.map((x) => {
    const metaFieldMap: Record<string, string> = {};
    fleetVehicleMetaFields.forEach((metaField) => {
      const metaFieldData = x.vehicleMetaFields?.find((x) => x.key === metaField.key);
      if (metaFieldData) {
        metaFieldMap[metaFieldData.key] = metaFieldData.value;
      }
    });
    if (x.driverMetaFields) {
      fleetDriverMetaFields.forEach((metaField) => {
        const metaFieldData = x.driverMetaFields?.find((x) => x.key === metaField.key);
        if (metaFieldData) {
          metaFieldMap[metaFieldData.key] = metaFieldData.value;
        }
      });
    }

    return {
      vehicleId: x.vehicleId ?? '',
      fuelType: x.fuelType ?? '',
      licencePlate: x.licencePlate ?? '',
      make: x.make ?? '',
      model: x.model ?? '',
      year: x.year?.toString() ?? '',
      vin: x.vin ?? '',
      serialNumber: x.serialNumber ?? '',
      fleetName: x.fleetName ?? '',
      driverId: x.driverId ?? '',
      driverName: x.driverName ?? '',
      email: x.email ?? '',
      phoneNumber: x.phoneNumber ?? '',
      ...metaFieldMap,
    };
  });

  return (
    <div className="mt-2">
      <CSVLink data={csvData} headers={headers} filename={fileName}>
        {tSafe('components.BulkExport.download-csv-file', { defaultValue: 'Download CSV File' })}
      </CSVLink>
    </div>
  );
};

export default BulkExport;
