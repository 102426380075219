import { CellContext } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { AggregateListRow } from 'types/settings';

const DriverNameCell = ({ cell }: { cell: CellContext<AggregateListRow, string> }) => {
  const driverName = cell.getValue<string>();
  const currentFleetId = useCurrentFleetId();

  return (
    <Link to={`/${currentFleetId}/drivers/${cell.row.original.driverId}`} className="text-hover hover:underline">
      {driverName}
    </Link>
  );
};

export default DriverNameCell;
