import * as Dialog from '@radix-ui/react-dialog';
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useState } from 'react';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import { cx } from 'utils';

const ModalContext = createContext<{ closeModal: () => void } | undefined>(undefined);

export const useModalContext = () => useContext(ModalContext);

export type ModalProps = {
  title?: string;
  children?: ReactNode;
  trigger?: JSX.Element;
  triggerAsChild?: boolean;
  triggerClassName?: string;
  contentClassName?: string;
  showCloseButton?: boolean;
  preventClose?: boolean;
  initialIsOpen?: boolean;
} & ({ isOpen: boolean; setIsOpen?: Dispatch<SetStateAction<boolean>> } | { isOpen?: never; setIsOpen?: never });

export const ModalTrigger = Dialog.Trigger;
export const ModalClose = Dialog.Close;

const Modal = ({
  title,
  children,
  trigger,
  triggerClassName,
  contentClassName,
  triggerAsChild = false,
  isOpen,
  setIsOpen,
  showCloseButton = true,
  preventClose = false,
  initialIsOpen = false,
}: ModalProps) => {
  const i18nContext = useContext(I18nContext);
  const [isOpenUncontrollable, setIsOpenUncontrollable] = useState(initialIsOpen);

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      general: { close_text },
    },
  } = i18nContext;

  return (
    <ModalContext.Provider value={{ closeModal: () => (setIsOpen ?? setIsOpenUncontrollable)(false) }}>
      <Dialog.Root open={isOpen ?? isOpenUncontrollable} onOpenChange={setIsOpen ?? setIsOpenUncontrollable}>
        {trigger && (
          <Dialog.Trigger className={triggerClassName} asChild={triggerAsChild}>
            {trigger}
          </Dialog.Trigger>
        )}

        <Dialog.Portal>
          <Dialog.Overlay className="fixed z-600 inset-0 bg-black/30" />

          <Dialog.Content
            className={cx(
              'fixed z-600 left-1/2 top-1/2 p-2.5 bg-white rounded-4 focus:outline-none shadow-card -translate-x-1/2 -translate-y-1/2 max-w-[85vw] max-h-[95vh]',
              contentClassName,
            )}
            onEscapeKeyDown={(event) => (preventClose ? event.preventDefault() : {})}
            onPointerDownOutside={(event) => (preventClose ? event.preventDefault() : {})}
          >
            <div className="mb-2">
              {title && <Dialog.Title className="text-lg font-semibold">{title}</Dialog.Title>}

              {showCloseButton && (
                <Dialog.Close asChild>
                  <button aria-label={close_text} className="absolute right-1 top-1 w-2.5 h-2.5 ui-button">
                    <IonIcon name="closeOutline" className="text-lg" />
                  </button>
                </Dialog.Close>
              )}
            </div>

            {children}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </ModalContext.Provider>
  );
};

export default Modal;

export const useModal = (opts: Omit<ModalProps, 'children' | 'isOpen' | 'setIsOpen'>) => {
  const [isOpen, setIsOpen] = useState(false);

  const publicProps = {
    isOpen,
    setIsOpen,
  };

  return {
    ...publicProps,
    openModal: useCallback(() => setIsOpen(true), [setIsOpen]),
    closeModal: useCallback(() => setIsOpen(false), [setIsOpen]),
    props: { ...opts, ...publicProps },
  };
};
