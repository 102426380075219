import React from 'react';

import FleetSelectorWithOptions from 'components/FleetSelector/FleetSelectorWithOptions';
import MoveVehicleFleetOptions from 'components/Vehicle/Detail/Edit/VehicleFleetSelector/MoveVehicleFleetOptions';
import { useVehicleDetails } from 'components/Vehicle/Detail/hooks';

const VehicleFleetSelector = () => {
  const currentFleetId = useVehicleDetails().data?.[0].associations.fleetIds[0];

  return !currentFleetId ? null : (
    <div className="flex flex-col text-md">
      <FleetSelectorWithOptions
        currentFleetId={currentFleetId}
        optionsComponent={MoveVehicleFleetOptions}
        title="Re-assign fleet"
      />
    </div>
  );
};

export default VehicleFleetSelector;
