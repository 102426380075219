import { CellContext } from '@tanstack/react-table';
import { useContext } from 'react';

import Icon from 'atoms/Icon';
import Tooltip from 'atoms/Tooltip';
import { batteryNotificationIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import { BatteryNotificationType } from 'generated/graphql';
import { cx } from 'utils';
import { AggregateListRow } from 'types/settings';

const BatteryStatusCell = ({ cell }: { cell: CellContext<AggregateListRow, string> }) => {
  const i18nContext = useContext(I18nContext);
  const value = cell.getValue() as BatteryNotificationType;

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { unavailable_text: unavailableText },
      enums: { batteryNotificationTypeDescriptionMap },
    },
  } = i18nContext;

  return (
    <div className="w-full flex-center">
      <Tooltip
        text={tSafe('components.Vehicle.VehicleList.cells.BatteryStatusCell.battery-status-is-value', {
          defaultValue: 'Battery status is {{statusValue}}',
          statusValue: value ? batteryNotificationTypeDescriptionMap[value] : unavailableText,
        })}
        side="right"
      >
        <Icon
          name={value ? batteryNotificationIconMap[value][0] : 'batteryNotEstimated'}
          className={cx('!w-3', value ? batteryNotificationIconMap[value][1] : 'text-gray-400')}
        />
      </Tooltip>
    </div>
  );
};

export default BatteryStatusCell;
