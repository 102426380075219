import { useCallback, useContext, useState } from 'react';

import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import useFleetSearchSource from 'components/Live/Geofence/GeofenceSidebar/GeofenceSettings/AssociationSearch/useFleetSearchSource';
import useVehicleSearchSource from 'components/Live/Geofence/GeofenceSidebar/GeofenceSettings/AssociationSearch/useVehicleSearchSource';

const AssociationSearch = () => {
  const i18nContext = useContext(I18nContext);
  const [searchTerm, setSearchTerm] = useState('');
  const resetSearch = useCallback(() => setSearchTerm(''), []);

  const results = [...useFleetSearchSource(searchTerm, resetSearch), ...useVehicleSearchSource(searchTerm, resetSearch)]
    .sort((a, b) => b.score - a.score)
    .map(({ jsx }) => jsx);

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      general: { search_ellipsis_text },
    },
  } = i18nContext;

  return (
    <div className="relative">
      <div className="flex w-full bg-gray-100 border rounded-4 overflow-hidden">
        <input
          className="flex-1 pl-2 pr-1 min-h-3.5 text-md bg-gray-100 focus:outline-none"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={tSafe(
            'components.Live.Geofence.GeofenceSidebar.GeofenceSettings.AssociationSearch.fleet-driver-vehicle-search-placeholder',
            { defaultValue: 'Fleet, Driver or Vehicle' },
          )}
        />

        <span className="flex-shrink-0 pr-1 text-lg flex-center" aria-label={search_ellipsis_text}>
          <IonIcon name="searchOutline" />
        </span>
      </div>

      <ul className="absolute z-500 left-0 right-0 text-sm top-[calc(100%+.5rem)] children:focus:outline-none">
        {searchTerm && !!results?.length && (
          <div className="flex flex-col bg-gray-100 border rounded-8 overflow-hidden">
            <span className="max-h-80 overflow-auto">{results}</span>
          </div>
        )}
      </ul>
    </div>
  );
};

export default AssociationSearch;
