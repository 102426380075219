import { ComponentProps, PropsWithChildren, VoidFunctionComponent } from 'react';
import { HeaderProps } from 'react-table';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { cx } from 'utils';

export const getHeaderRenderer =
  <T extends VoidFunctionComponent<any>>(component: T) =>
  (text: string, props?: Omit<ComponentProps<T>, keyof PropsWithChildren<HeaderProps<object>> | 'text'>) =>
  (headerProps: PropsWithChildren<HeaderProps<object>>) =>
    component({ text, ...props, ...headerProps });

interface LeaderboardTableHeaderProps extends PropsWithChildren<HeaderProps<object>> {
  text: string;
  hiddenColumns: string[];
  isEditMode: boolean;
  isWidget?: boolean;
  onChange: (hiddenColumns: string[]) => void;
  alignment?: 'left' | 'center' | 'right';
}

const TableHeader = ({
  column,
  text,
  alignment,
  isEditMode,
  onChange,
  hiddenColumns = [],
}: LeaderboardTableHeaderProps) => {
  const isHidden = hiddenColumns.includes(column.id);

  const toggleItem = (columnId: string) =>
    onChange(isHidden ? hiddenColumns.filter((item) => item !== columnId) : [...hiddenColumns, columnId]);

  return (
    <div
      className={cx(
        'inline',
        !alignment && 'justify-center',
        alignment === 'center' && 'justify-center',
        alignment === 'right' && 'justify-end',
        isHidden && 'opacity-90',
      )}
    >
      {isEditMode ? (
        <Button className="ui-button rounded-4 p-0.5" onClick={() => toggleItem(column.id)}>
          <IonIcon className="ml-0.5" name={isHidden ? 'eyeOutline' : 'eyeOffOutline'} />

          {text}
        </Button>
      ) : (
        text
      )}
    </div>
  );
};

export default TableHeader;
