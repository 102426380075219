import { useContext, useState } from 'react';

import Button from 'atoms/Button';
import IonIcon from 'atoms/IonIcon';
import { I18nContext } from 'common/useT';
import ReportTable from 'components/Reporting/ReportTable';
import useTenant from 'components/Tenant/useTenant';
import useRole from 'components/User/useRole';
import { GetReportNotificationsDoc, GetReportsDoc } from 'generated/graphql';
import { withTitle } from 'utils';
import { useQ } from 'utils/apolloClient';

const Reporting = () => {
  const i18nContext = useContext(I18nContext);
  const tenant = useTenant();
  const { isManager, isAdmin, isSuperAdmin } = useRole();
  const { data: reportsData } = useQ(GetReportsDoc, { fetchPolicy: 'cache-first' });
  const { data: notificationsData } = useQ(GetReportNotificationsDoc);

  const [reportsListEditMode, setReportsListEditMode] = useState(false);

  if (
    !i18nContext ||
    (!tenant?.reportingEnabled && !isSuperAdmin) ||
    (!isManager && !isAdmin && !isSuperAdmin) ||
    !reportsData ||
    !notificationsData
  ) {
    return null;
  }

  const { tSafe } = i18nContext;

  return (
    <div className="flex flex-col p-1">
      <span className="text-md font-bold">
        {tSafe('components.Reporting.reporting', { defaultValue: 'Reporting' })}
      </span>

      <div className="flex flex-col p-1">
        <div className="flex flex-col w-full bg-white p-1 m-1 rounded-4">
          <div className="flex relative">
            <span className="text-lg font-bold">
              {tSafe('components.Reporting.scheduled-reports', { defaultValue: 'Scheduled Reports' })}
            </span>

            <span className="absolute right-1">
              <Button onClick={() => setReportsListEditMode(!reportsListEditMode)} className="ui-button">
                <IonIcon name={!reportsListEditMode ? 'settingsOutline' : 'chevronDownOutline'} className="text-lg" />
              </Button>
            </span>
          </div>

          <ReportTable key="reports" data={reportsData} isNotifications={false} editMode={reportsListEditMode} />
        </div>

        <div className="flex flex-col w-full bg-white p-1 m-1 rounded-4">
          <span className="text-lg font-bold">
            {tSafe('components.Reporting.report-history', { defaultValue: 'Report History' })}
          </span>

          <ReportTable key="notifications" data={notificationsData} isNotifications={true} editMode={false} />
        </div>
      </div>
    </div>
  );
};

export default withTitle(Reporting, 'Reporting');
