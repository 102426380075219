import { useContext } from 'react';
import { I18nContext } from 'common/useT';
import { GetVehicleSearchDoc } from 'generated/graphql';
import Search, { SearchProps } from 'atoms/Search';

const VehicleSearch = ({ fleetId, onSelect }: { fleetId?: string; onSelect?: (id: string) => void }) => {
  const i18nContext = useContext(I18nContext);

  if (!i18nContext) return null;

  const { tSafe } = i18nContext;

  const vehicleSearchProps: SearchProps = {
    key: 'vehicleSearch',
    searchQueryDoc: GetVehicleSearchDoc,
    placeholder: tSafe('atoms.VehicleSearch.search-vehicle-by-licence-plate', {
      defaultValue: 'Search for vehicle by licence plate',
    }),
    highlightKey: 'licencePlate',
    iconName: 'carSportOutline',
  };

  return <Search fleetId={fleetId} onSelect={onSelect} props={vehicleSearchProps} />;
};

export default VehicleSearch;
