import React, { ReactElement } from 'react';

import { ClassName } from 'types';

interface BatteryIconProps extends ClassName {
  barCount?: 0 | 1 | 2 | 3;
  text?: string;
  innerSvg?: ReactElement<React.SVGProps<SVGSVGElement>>;
}

const BatteryIcon = ({ barCount = 0, text, innerSvg, className }: BatteryIconProps) => (
  <svg viewBox="0 0 256 224.7" xmlSpace="preserve" className={className} fill="currentColor">
    <g>
      <path
        d="M12,32.6c0-8.6,0-17,0-25.3C12,1.6,13.5,0,19,0c20.3,0,40.7,0,61,0c5.5,0,7,1.6,7,7.2c0,8.3,0,16.7,0,25.4
		c27.2,0,54.3,0,82,0c0-8.1,0-16.4,0-24.7c0-6.6,1.3-7.9,7.8-7.9c19.8,0,39.7,0,59.5,0c6.3,0,7.7,1.3,7.7,7.5c0,8.3,0,16.6,0,24.7
		c0.7,0.4,0.9,0.6,1.2,0.7C256,33.4,256,33.4,256,44c0,58.2,0,116.3,0,174.5c0,2,0,3.9,0,6.2c-85.4,0-170.4,0-256,0c0-2.1,0-4,0-6
		c0-57.7,0-115.3,0-173C0,33.9,0,33.9,12,32.6z M15.2,209.7c75.4,0,150.5,0,225.4,0c0-54.1,0-107.8,0-161.4c-75.3,0-150.2,0-225.4,0
		C15.2,102.1,15.2,155.7,15.2,209.7z"
      />

      <rect visibility={barCount >= 3 ? 'visible' : 'hidden'} x="10%" y="60" width="80%" height="40" />

      <rect visibility={barCount >= 2 ? 'visible' : 'hidden'} x="10%" y="110" width="80%" height="40" />

      <rect visibility={barCount >= 1 ? 'visible' : 'hidden'} x="10%" y="160" width="80%" height="40" />

      <text
        x="50%"
        y="65%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontFamily="ArialRoundedMTBold"
        fontSize="148"
      >
        {text}
      </text>

      <g>{innerSvg}</g>
    </g>
  </svg>
);

export default BatteryIcon;
